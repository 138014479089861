<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
<table mat-table [dataSource]="storageTotal">
       
    <ng-container matColumnDef="NAME_WAREHOUSE_TYPE">
        <th  align="center" *matHeaderCellDef>Tipo</th>
        <td  align="center"  class="description-cell" *matCellDef="let t">{{t.NAME_WAREHOUSE_TYPE}} </td>   
    </ng-container>
    <ng-container matColumnDef="NAME_WAREHOUSE">
        <th  align="center" *matHeaderCellDef>Nombre</th>
        <td  align="center"  class="description-cell" *matCellDef="let t">{{t.NAME_WAREHOUSE}} </td>      
    </ng-container>
    <ng-container matColumnDef="QUANTITY_AVAILABLE">
        <th  align="center" *matHeaderCellDef>Cantidad <br/> Disponible</th>
        <td  align="center" style="color:#00A300" *matCellDef="let t">{{t.QUANTITY_AVAILABLE}}  </td>     
    </ng-container>
    <ng-container matColumnDef="QUANTITY_HOLD">
        <th  align="center" *matHeaderCellDef>Cantidad <br/> Retenido</th>
        <td  align="center"  style="color:#D1D100" *matCellDef="let t">{{t.QUANTITY_HOLD}} </td>      
    </ng-container>
    <ng-container matColumnDef="QUANTITY_BOOKING">
        <th  align="center" *matHeaderCellDef>Cantidad <br/> Reservado</th>
        <td  align="center"  style="color:#000000" *matCellDef="let t">{{t.QUANTITY_BOOKING}}   </td>    
    </ng-container>    
    <ng-container matColumnDef="QUANTITY_SALES">
        <th  align="center" *matHeaderCellDef>Cantidad <br/> Vendida</th>
        <td  align="center" style="color:#000000" *matCellDef="let t"><b>{{t.QUANTITY_SALES}}</b>    </td>   
    </ng-container>
    <ng-container matColumnDef="QUANTITY_REMOVE">
        <th  align="center" *matHeaderCellDef>Cantidad <br/> Removida</th>
        <td  align="center" style="color:#000000" *matCellDef="let t">{{t.QUANTITY_REMOVE}}  </td>     
    </ng-container>
    <ng-container matColumnDef="DATE_UPDATE">
        <th  align="center" *matHeaderCellDef>Fecha <br/> Actualizacion</th>
        <td  align="center"  style="color:#000000" *matCellDef="let t">{{t.DATE_UPDATE | date: 'medium'}}  </td>      
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
</table>
<button mat-raised-button color="primary" mat-dialog-close>Cerrar</button>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->


