import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, of, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { RcService } from  'app/services/rc.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { ClienteService } from 'app/services/cliente.service';

@Component({
  selector: 'app-select-contact',
  templateUrl: './select-contact.component.html',
  styleUrls: ['./select-contact.component.scss']
})
export class SelectContactComponent implements OnInit {
//######################################################
//
@ViewChild('pagContact'  ) pagContact  : MatPaginator;    
displayedContact    : string[] = ['type','legalNumber','firstName','lastName','mail','phone','acc']
isLoadingContact    : boolean = false;
dataContact         : any = [];
tableContact        : any = [];
//
pag                 : number = 50;
pageSizeOptions     : any    = [ 50, 100,150,200];
//######################################################
constructor(
          @Inject(MAT_DIALOG_DATA) public data: any,
          public _dialogRef: MatDialogRef<SelectContactComponent>,
          private _fb: FormBuilder,
          private _fuseConfirmationService: FuseConfirmationService,
          private _rc:RcService,
          private ref: ChangeDetectorRef,
          private _clienteService:ClienteService,
) {

 }
//######################################################
async ngOnInit() { 
await this.getCustomerRelated(this.data.rut,null)
await this.loadTable()
}
//######################################################
loadTable(){
  this.isLoadingContact=true; 
  this.tableContact =  new MatTableDataSource(this.dataContact);
  this.ref.detectChanges();
  this.tableContact.paginator = this.pagContact;
  this.isLoadingContact=false; 
}
//###########################################################
async getCustomerRelated(rut: string,uuid: string): Promise<any>{
  var tmp
  tmp = await this._clienteService.customerRelated(rut,uuid);
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    this.dataContact =tmp.contact
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$     
}
//###########################################################
addContact(o){
  this._dialogRef.close({reload:true,dataContact:o});
}
//######################################################
btnClose(){
  this._dialogRef.close();
}
//######################################################
}
