import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
//
import { SalesPointService } from 'app/services/salesPoint.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
//
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//
@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent implements OnInit { 
//
tipoMessageList= [
    {tipo:'Y',gls:'Publica'}
   ,{tipo:'N',gls:'Privada'}
]
//
countMessage: number = 0;
infoForm: UntypedFormGroup;
//
  constructor(
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DialogMessageComponent>, 
    private ref: ChangeDetectorRef,
    private _salesPointService : SalesPointService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _fb: FormBuilder,
    private _toast: ToastrService
  ) { }
//############################################################
//############################################################
  ngOnInit(): void {
    this.infoForm = this._fb.group({
       tipoMessage: [null, [Validators.required]]
      ,txtMessage: ['', [Validators.required,Validators.maxLength(80)]]
    });
  }
//############################################################
//############################################################
async btnClose(): Promise<any> {
  this._dialogRef.close();
}
//############################################################
//############################################################
async addMessage(): Promise<any> {
  var json = {
                   language                   :"es"
                  ,appToken                   : null
                  ,uuidSSO                    : null
                  ,uuidEomOrder               : this.data.order.UUID_EOM_ORDER
                  ,flagEomOrderMessagePublic  : this.infoForm.value.tipoMessage
                  ,idEmisorContacto           : 0
                  ,idEmisorRecpetor           : 0
                  ,idEmisorUsr                : 0
                  ,txtOrderMessage            : this.infoForm.value.txtMessage
                }
  var add = await this._salesPointService.addorderMessage(json)
  if(add.status == true){
    this._toast.success('Nota agregada Correctamente', 'Éxito');
    this._dialogRef.close({orderMessage:add.orderMessage});
  }
  else{
    this._toast.warning('Error al agregadar Nota', 'Éxito');
    this._dialogRef.close({orderMessage:add.orderMessage});
  }
}
//############################################################
//############################################################
countCharacter(field: string){
  //
  if(field == 'txtMessage'){
    this.countMessage = this.infoForm.value.txtMessage.length;
  }
  //
}
//############################################################
//############################################################
}
