<div
    class="fixed flex items-center justify-center right-0 w-10 h-10 shadow-lg rounded-l-lg z-90 cursor-pointer bg-red-600 bg-opacity-90 print:hidden"
    style="top: 275px"
    (click)="openDialog()"
    >
    <mat-icon
        class="icon-size-5 text-white"
        [svgIcon]="'bug_report'"></mat-icon>
</div>

