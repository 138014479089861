import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { Error404Component } from './components/error/error404/error404.component';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'home'},
    {
        path: '404',
        component: Error404Component
    },
    // {path: '**', redirectTo: '/404'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'home'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'empty'
        },
        children   : [
            // {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },


    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
            {path: 'docs', loadChildren: () => import('app/components/docs/docs.module').then(m => m.DocsModule)},
            {path: 'home', loadChildren: () => import('app/components/home/home.module').then(m => m.HomeModule)},
            {path: 'catalogos', loadChildren: () => import('app/components/catalog/catalog.module').then(m => m.CatalogModule)},
            // {path: 'clientes', loadChildren: () => import('app/components/client/client.module').then(m => m.ClientModule)},
            {path: 'receptores', loadChildren: () => import('app/components/receptores/receptores.module').then(m => m.ReceptoresModule)},
            {path: 'settings', loadChildren: () => import('app/components/settings/settings.module').then(m => m.SettingsModule)},
            {path: 'users', loadChildren: () => import('app/components/users/users.module').then(m => m.UsersModule)},
            {path: 'inventario', loadChildren: () => import('app/components/inventario/inventario.module').then(m => m.InventarioModule)},
            {path: 'clientes', loadChildren: () => import('app/components/clientes/clientes.module').then(m => m.ClientesModule)},
            {path: 'reporte', loadChildren: () => import('app/components/reporte/reporte.module').then(m => m.ReporteModule)},
            {path: 'employee', loadChildren: () => import('app/components/employee/employee.module').then(m => m.EmployeeModule)},
            {path: 'casos', loadChildren: () => import('app/components/casos/casos.module').then(m => m.CasosModule)},
            {path: 'profile', loadChildren: () => import('app/components/profile/profile.module').then(m => m.ProfileModule)},
         //   {path: 'oms', loadChildren: () => import('app/components/oms/oms.module').then(m => m.OmsModule)},
            {path: 'ordenes', loadChildren: () => import('app/components/ordenes/ordenes.module').then(m => m.OrdenesModule)},
            {path: 'rc', loadChildren: () => import('app/components/rc/rc.module').then(m => m.RcModule)},
            {path: 'ctz', loadChildren: () => import('app/components/ctz/ctz.module').then(m => m.CtzModule)},
            {path: 'ec', loadChildren: () => import('app/components/ec/ec.module').then(m => m.EcModule)}, 
        ]
    }
];
