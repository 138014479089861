import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
//
import { SalesPointService } from 'app/services/salesPoint.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
//
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, NumberFormatStyle } from '@angular/common';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
 //
 virtualSP    : any={}
 spDetail     : any={}

 spWarehouse  : any = []
 tipDoc       : any = []
 receptor :any = {}

 //2312-BZS-FYU-1228
 payMeansParam  : any='';  
 payMeansParam2 : any='';  
 isErr    : boolean = false;
 flagShowpayMeansParam : boolean = true;
 flagIsOkDiscountCoupon : boolean = false;

 orderDiscountCoupon   : number =0;
 totales :any={
                   MntNeto       : 0     
                  ,MntExe        : 0   
                  ,IVA           : 0 
                  ,MntTotal      : 0     
                  ,MntoNF        : 0   
               }

selectPayMeans   : any={}
selectWarehouse  : any={}               
selectPayMeans2  : any={}
spPayMeans    : any = []
spPayMeans2   : any = []
amountAjusteSencillo  : number =0;
amountAjusteSencillo2 : number =0;

orderBalance          : number =0;
orderBalance2         : number =0;

importe : number =0;
importe2: number =0;

newOrderBalance          : number =0;
 /////////////////////////////
  //
    constructor(
      private _dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _dialogRef: MatDialogRef<PaymentsComponent>, 
      private ref: ChangeDetectorRef,
      private _salesPointService : SalesPointService,
      private _fuseConfirmationService: FuseConfirmationService,
      private _fb: FormBuilder,
      private _toast: ToastrService,
      private _toastr: ToastrService,
      private _currencyPipe:CurrencyPipe
    ) { }
  //############################################################
  //2312-BZS-FYU-1228
  //############################################################
  async  ngOnInit(): Promise<any> {
      await this.getSpListEmployee()
      this.totales.MntNeto  = this.data.order.TOTAL_NETO     
      this.totales.MntExe   = this.data.order.TOTAL_EXENTO   
      this.totales.IVA      = this.data.order.TOTAL_IVA 
      this.totales.MntTotal = this.data.order.TOTAL     
      this.totales.MntoNF   = 0
      this.orderBalance     = this.totales.MntTotal
      if(this.selectPayMeans.COD_PM_PAY_MEANS == 'EF'){
        this.redondeo('A');  
      }
    }
//############################################################
//############################################################
async btnClose(): Promise<any> {
  this._dialogRef.close();
}
//############################################################
//############################################################
async getSpListEmployee(): Promise<any>{
  var tmp,tmp2,tmp3,tmp4,tmp5;
  tmp = await this._salesPointService.getSpListEmployee();
  tmp2 = tmp.dataSet.sp.filter(item => item.TYPE_SP == 'V')
  if(tmp2.length == 0){
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    alert("Error, sin punto de venta virtual")
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$    
  } 
  else{
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$    
      this.virtualSP=tmp2[0]
      tmp3 = await this._salesPointService.spEployee(this.virtualSP.UUID_SP);
      this.spDetail            = tmp3.sp
      this.spPayMeans          = tmp3.pmPaysMeans
      this.spWarehouse         = tmp3.wmWarehouse
      this.tipDoc              = tmp3.doc
      /////////////////////////////////////////////////
      //##Medio de pago por defecto
      if(this.spPayMeans.length > 0){
        tmp4 = this.spPayMeans.filter(item => item.FLAG_DEFAULT_PAY_MEANS == 'Y')
        tmp5 = this.spPayMeans.filter(i => i.COD_PM_PAY_MEANS != 'CD' && i.FLAG_DEFAULT_PAY_MEANS == 'Y' )
        this.spPayMeans2 = this.spPayMeans.filter(i => i.COD_PM_PAY_MEANS != 'CD')
        if(tmp4.length > 0) this.selectPayMeans = tmp4[0]; else this.selectPayMeans = this.spPayMeans[0];
      //  if(tmp5.length == 0 )  this.selectPayMeans2=this.spPayMeans.filter(i => i.COD_PM_PAY_MEANS != 'CD')[0]; else this.selectPayMeans2=tmp5[0]        
      }
      else{
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
         alert("Punto de venta sin medios de pago configurados")
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  
      }
      //##Bodega por defecto
      if(this.spWarehouse.length > 0){
        tmp4 = this.spWarehouse.filter(item => item.FLAG_DEFAULT_WAREHOUSE == 'Y')
        if(tmp4.length > 0) this.selectWarehouse = tmp4[0]; else this.selectWarehouse = this.spWarehouse[0];
      }
      else{
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
         alert("Punto de venta sin bodegas configuradas")
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  
      }
      /////////////////////////////////////////////////    
      
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$      
  }
}
//############################################################
//############################################################
setPayMeans(p,ori)
{
  //==  
  if(ori == 'selectPayMeans' ){
      this.selectPayMeans  = p;
      console.log("redondeoA")
      this.redondeo('A')
  }
  //###############################################
  if(ori == 'selectPayMeans2') {
      this.selectPayMeans2 = p;
      if(p.COD_PM_PAY_MEANS == 'EF'){
        console.log("redondeoB")
        this.redondeo('B')
      }
  }
  //==
}
//############################################################
//############################################################
async discountCouponChk(): Promise<any>{
  var codEpcDiscountCoupon = this.payMeansParam.split(' ').join('');
  var uuidCustomer         = '0'
  var rutreceptor          = this.receptor.RUT
  var idEpcSalesChannel    = this.virtualSP.ID_EPC_SALES_CHANNEL
  var codPmPayMeans        = this.selectPayMeans.COD_PM_PAY_MEANS
if(codEpcDiscountCoupon){
  let tmp = await this._salesPointService.discountCouponChk(codEpcDiscountCoupon,uuidCustomer, rutreceptor,idEpcSalesChannel,codPmPayMeans);
  if(tmp.discountCoupon.STATUS == true){
       //===============================================================
           //###########################################################
             if(tmp.discountCoupon.COD_REASON_CODE == 'CDP'){
               //###########################################################  2306-SZU-SYH-13770           
                 //CUPON VALIDO SOLO COMO MEDIO DE PAGO  currency:'CLP':'symbol-narrow'
                 let tmp2 = this._currencyPipe.transform(tmp.discountCoupon.AMOUNT_DISCOUNT_COUPON,'CLP','symbol-narrow')
                   const dialogRef = this._fuseConfirmationService.open({
                       title: `¡¡Atencion!!`,
                       message: `${tmp.discountCoupon.NAME_COD_REASON_CODE}<br>Monto Cupon: <b>${tmp2}</b><br>¿Desea utlizar Cupon?`,
                       icon: {
                           show: true,
                           name: 'delete',
                           color: 'primary'
                       },
                       actions: {
                           confirm: {
                               label: 'Aceptar',
                               color: 'warn'
                           },
                           cancel: {
                               show : true,
                               label : "Cancel"
                           }
                       },
                       dismissible: true    
                   });
                   // 
                   dialogRef.afterClosed().subscribe(async (result) => {
                    if (result === 'confirmed') {
                      //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
                      //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°                      
                        this._toastr.success('Cupon Aplicado');
                        this.isErr  = false;
                        this.flagIsOkDiscountCoupon= true; 
                        this.flagShowpayMeansParam = false;
                        this.orderBalance        = this.totales.MntTotal  - tmp.discountCoupon.AMOUNT_DISCOUNT_COUPON;
                        this.orderDiscountCoupon = tmp.discountCoupon.AMOUNT_DISCOUNT_COUPON
                      //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
                      //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°                                              
                    } else {
                      this.payMeansParam = ''
                        return;
                    }
                });                    
               //###########################################################                            
             }else{
               //########################################################### 
               if(tmp.discountCoupon.COD_REASON_CODE == 'CPP'){
                  //===============================================================
                 //CUPON VALIDO SOLO COMO descuento
                 let tmp2 = this._currencyPipe.transform(tmp.discountCoupon.AMOUNT_DISCOUNT_COUPON,'CLP','symbol-narrow')
                   const dialogRef = this._fuseConfirmationService.open({
                       title: `¡¡Atencion!!`,
                       message: `${tmp.discountCoupon.NAME_COD_REASON_CODE}<br><b>Valido solo como Descuento</b><br>Monto Cupon: <b>${tmp2}</b>`,
                       icon: {
                           show: true,
                           name: 'delete',
                           color: 'warning'
                       },
                       actions: {
                           confirm: {
                               label: 'Aceptar',
                               color: 'warn'
                           },
                           cancel: {
                               show : false,
                               label : "Cancel"
                           }
                       },
                       dismissible: true    
                   });  
                   this.payMeansParam = ''                  
                  //===============================================================                  
               }
               else{
                  //===============================================================
                  let tmp2 = this._currencyPipe.transform(tmp.discountCoupon.AMOUNT_DISCOUNT_COUPON,'CLP','symbol-narrow')
                  const dialogRef = this._fuseConfirmationService.open({
                      title: `¡¡Atencion!!`,
                      message: `${tmp.discountCoupon.NAME_COD_REASON_CODE}<br><b>No Valido</b><br>Monto Cupon: <b>${tmp2}</b>`,
                      icon: {
                          show: true,
                          name: 'delete',
                          color: 'warn'
                      },
                      actions: {
                          confirm: {
                              label: 'Aceptar',
                              color: 'warn'
                          },
                          cancel: {
                              show : false,
                              label : "Cancel"
                          }
                      },
                      dismissible: true    
                  });  
                  this.payMeansParam = ''                                    
                  //===============================================================                  
               }           
               //###########################################################            
             }
       //===============================================================            
  }
  else{ 
       this.payMeansParam = ''    
       this.isErr  = true;
       if( tmp.discountCoupon.AMOUNT_DISCOUNT_COUPON <= 0){
        this._toastr.warning("Cupon sin saldo")
       }else{
        this._toastr.warning(tmp.discountCoupon.ERR)
       }
       
  }
}
}
//############################################################
//############################################################
async redondeo(t){
  ////////////////////////////////////////////    
  if(t == 'A'){
      var last = Number(this.orderBalance.toString().charAt(this.orderBalance.toString().length - 1))
      if(last >= 6 ){
        this.amountAjusteSencillo =10 - last
        this.orderBalance = Number(this.orderBalance) +  Number(this.amountAjusteSencillo)
       }
      else{
        this.amountAjusteSencillo =last
        this.orderBalance =  Number(this.orderBalance) -  Number(this.amountAjusteSencillo)
      }
  }
  else{
    var last = Number(this.orderBalance.toString().charAt(this.orderBalance.toString().length - 1))
    if(last >= 6 ){
      this.amountAjusteSencillo2 =10 - last
      this.orderBalance2 =  Number(this.orderBalance) +  Number(this.amountAjusteSencillo2)
     }
    else{
      this.amountAjusteSencillo2 =last
      this.orderBalance2 =  Number(this.orderBalance) -  Number(this.amountAjusteSencillo2)
    }    
  }
  ////////////////////////////////////////////  
}
//############################################################
//############################################################
async payOrder(): Promise<any>{
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
        //////////////////////////////////////////// 
        const dialogRef = $this._fuseConfirmationService.open({
          title: `Pagar Orden: ${$this.data.order.FRIENLY_EOM_ORDER}`,
          message: `Se aplicara el pago a la orden`,
          icon: {
              show: true,
              name: 'local_atm',
              color: 'primary'
          },
          actions: {
              confirm: {
                  label: 'Aceptar',
                  color: 'primary'
              },
              cancel: {
                  show : true,
                  label : "Cancel"
              }
          },
          dismissible: true    
      });
      // 
      dialogRef.afterClosed().subscribe(async (result) => {
       if (result === 'confirmed') {
         //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
         //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°           
                  var placeOrder,pay:any = {},payCoupon
                  try{
                      //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°    
                            if(!$this.payMeansParam)  $this.payMeansParam='0';
                            if(!$this.payMeansParam2) $this.payMeansParam2='0';
                                if($this.selectPayMeans.COD_PM_PAY_MEANS == 'CD'){
                                  //dos medios de pago
                                  $this.newOrderBalance = $this.orderBalance - $this.amountAjusteSencillo2         
                                  if($this.orderDiscountCoupon >= $this.orderBalance ){ payCoupon = $this.orderBalance } else { payCoupon =  $this.orderDiscountCoupon  }
                                            pay = {
                                                        "language"        : "es"
                                                      ,"appToken"        : null
                                                      ,"uuidSSO"         : null
                                                      ,"uuidEomOrder"    : $this.data.order.UUID_EOM_ORDER
                                                      ,"codOrderPayment" : "OK"
                                                      ,"orderPayment":[{                                                                    
                                                                          
                                                                          "uuidScRelPmPaysMeans"     : $this.selectPayMeans.UUID_SP_REL_PM_PAYS_MEANS
                                                                          ,"txtScRelPmPaysMeans"      : $this.payMeansParam
                                                                          ,"dataOrderPayment"         : {"vci":$this.selectPayMeans.COD_PM_PAY_MEANS,"amount":$this.orderDiscountCoupon,"status":"AUTHORIZED","buyOrder":$this.data.order.FRIENLY_EOM_ORDER,"sessionId":null,"cardDetail":{"card_number":""},"accountingDate":"","transactionDate":"","authorizationCode":"","paymentTypeCode":"","responseCode":0,"installmentsAmount":null,"installmentsNumber":null,"balance":null}
                                                                          ,"codOrderPayment"          : 'OK'
                                                                      }
                                                                                                                                                
                                                                      ]
                                                  
                                                      }  
                                            if($this.orderBalance - $this.amountAjusteSencillo2 > 0){
                                              pay.orderPayment.push({                                                                    
                                                                          "uuidScRelPmPaysMeans"        : $this.selectPayMeans2.UUID_SP_REL_PM_PAYS_MEANS
                                                                          ,"txtScRelPmPaysMeans"         : $this.payMeansParam2
                                                                          ,"dataOrderPayment"            : {"vci":$this.selectPayMeans2.COD_PM_PAY_MEANS,"amount":$this.orderBalance ,"status":"AUTHORIZED","buyOrder":$this.data.order.FRIENLY_EOM_ORDER,"sessionId":null,"cardDetail":{"card_number":""},"accountingDate":"","transactionDate":"","authorizationCode":"","paymentTypeCode":"","responseCode":0,"installmentsAmount":null,"installmentsNumber":null,"balance":null}
                                                                          ,"codOrderPayment"             : 'OK'
                                                                      } )

                                            }                                    
                                }
                                else{
                                  //Solo uno
                                          pay = {
                                                      "language" : "es"
                                                      ,"appToken" : null
                                                      ,"uuidSSO"  : null
                                                      ,"uuidEomOrder"    : $this.data.order.UUID_EOM_ORDER
                                                      ,"codOrderPayment" : "OK"
                                                      ,"orderPayment":[{                                                                    
                                                                          "uuidScRelPmPaysMeans"        : $this.selectPayMeans.UUID_SP_REL_PM_PAYS_MEANS
                                                                        ,"txtScRelPmPaysMeans"         : $this.payMeansParam
                                                                        ,"dataOrderPayment"            : {"vci":$this.selectPayMeans.COD_PM_PAY_MEANS,"amount":$this.orderBalance ,"status":"AUTHORIZED","buyOrder":$this.data.order.FRIENLY_EOM_ORDER,"sessionId":null,"cardDetail":{"card_number":""},"accountingDate":"","transactionDate":"","authorizationCode":"","paymentTypeCode":"","responseCode":0,"installmentsAmount":null,"installmentsNumber":null,"balance":null}
                                                                        ,"codOrderPayment"             : 'OK'
                                                                      }]
                                                          
                                                      }                                                                       
                                        }

                            let payOrder = await $this._salesPointService.orderPayment(pay)
                            //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                            if($this.data.order.COD_REASON_CODE == 'EN' || $this.data.order.COD_REASON_CODE == 'ODF' || $this.data.order.COD_REASON_CODE == 'POK'){
                              if(payOrder.status == true && payOrder.codStatus == '200'){
                                //en paralelo emito y cierro la orden
                                var close = {
                                                  "language" :"es"
                                                  ,"appToken" : null
                                                  ,"uuidSSO"  : null
                                                ,"orderClose":{
                                                                  "uuidEomOrder" : $this.data.order.UUID_EOM_ORDER
                                                              }
                                                    
                                          }     
                                let closeOrder = await $this._salesPointService.orderClose(close)  
                                $this.btnClose()

                                }
                              }else{
                                $this.btnClose()
                              }              

                      //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
                    }
                    catch(e)
                    {
                        console.log(e)
                      //  $this.ctrlClose = 0;    
                        reject({success:false,error:e,jsonDte:null})
                  }
        //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
        //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°                                              
      } else {
        this.payMeansParam = ''
          return;
      }
  });          
   }); 
}
//############################################################
//############################################################

}