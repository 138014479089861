import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output,Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { debounceTime, map, merge, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CatalogService } from 'app/services/catalog.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
// import { InventoryService } from 'app/modules/admin/apps/ecommerce/inventory/inventory.service';
import { ClienteService } from 'app/services/cliente.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-select-clientes',
  templateUrl: './select-clientes.component.html',
  styleUrls: ['./select-clientes.component.scss']
})
export class SelectClientesComponent implements OnInit {
  @ViewChild('pagClientes' ) pagClientes : MatPaginator;
  

  displayedColumnClientes  : string[] = ['rut','razon','giro','email','acc']; 

  pag                     : number = 10;
  pageSizeOptions         : any    = [ 10,20,50, 100];

  isLoadingClientes    : boolean = false;
  dataClientes         : any = [];
  tableClientes        : any = [];

  isLoadingInactivos    : boolean = false;
  dataInactivos         : any = [];
  tableInactivos        : any = [];
  searchSelect : any = '';

  matTabGroupSelected=0
  //
  emisorData : any =[]
  rutEmisor  : string =''
//###########################################################  
constructor(
  private _changeDetectorRef: ChangeDetectorRef,
  private _fuseConfirmationService: FuseConfirmationService,
  private _formBuilder: UntypedFormBuilder,
  private _catalogService: CatalogService,
  private _router: Router,
  private ref: ChangeDetectorRef,
  private _dialog: MatDialog,
  private _clienteService: ClienteService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  public _dialogRef: MatDialogRef<SelectClientesComponent>,
  private _currencyPipe:CurrencyPipe,
  private _datePipe:DatePipe
)
{
  this.emisorData = JSON.parse(localStorage.getItem('emisorData'));
  this.rutEmisor  = this.emisorData.emisorData[0].RUT

}
//###########################################################  
async ngOnInit(): Promise<any>{

    await this.getClientes()
    await this.loadClientes()
  }
//###########################################################
  async getClientes(): Promise<void> {
    let tmp = await this._clienteService.getClientes();
    //==
     if(this.data.flagSupplier     ==  true){
      this.dataClientes = tmp.activo.filter(item => item.FLAG_SUPPLIER == 'Y')
     }
     //==
     if(this.data.flagEmployee     ==  true){
      this.dataClientes = tmp.activo.filter(item => item.FLAG_EMPLOYEE == 'Y')
     }
     //==
     if(this.data.flagAssociate    ==  true){
      this.dataClientes = tmp.activo.filter(item => item.FLAG_ASSOCIATE == 'Y')
     }
     //==
     if(this.data.flagDistributor  ==  true){
      this.dataClientes = tmp.activo.filter(item => item.FLAG_DISTRIBUTOR == 'Y')
     }
     //==
     if(this.data.flagCustomer     ==  true){
      this.dataClientes = tmp.activo.filter(item => item.FLAG_CUSTOMER == 'Y')
     }
     //== 
     if(this.data.flagExtranjero     ==  true){
      this.dataClientes = tmp.activo.filter(item => item.EXTRANJERO == 'Y')
     }
    //== 
    if(this.data.flagDTE     ==  true){
      this.dataClientes = tmp.activo.filter(item => item.EXTRANJERO != 'Y')
      }
   //==       
    if(this.data.flagALL     ==  true){
      this.dataClientes = tmp.activo
      }
    //==  
    //==  
    if(this.data.flagSelf     ==  false){
      this.dataClientes = this.dataClientes.filter(item => item.RUT!= this.rutEmisor)
      }
    //==     
    /*     
    if(this.data.flagCustomer ==  false && this.data.flagDistributor == false && this.data.flagAssociate == false && this.data.flagEmployee == false && this.data.flagSupplier == false && this.data.flagExtranjero ==  false){
      this.dataClientes = tmp.activo
     }
     */
     
  };
//###########################################################
async loadClientes():Promise<any>{
  this.isLoadingClientes=true; 
  this.tableClientes =  new MatTableDataSource(this.dataClientes);
  this.ref.detectChanges();
  this.tableClientes.paginator = this.pagClientes;
  this.isLoadingClientes=false; 
}  
//###########################################################
async resetFilters():Promise<any>{
  this.searchSelect ='';
  await this.getClientes()
  await this.loadClientes()
}
//###########################################################
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.tableClientes.filter  = filterValue.trim().toLowerCase();
  this.ref.detectChanges();
}
//###########################################################
closePage(): void{
  this._dialogRef.close();
}
//###########################################################
async addReceptor(e: any):Promise<any>{
  if(e.FLAG_FLASH == 'Y'){
    await this.getCustomerRelated(e.RUT,null);
    this._dialogRef.close(e);
  }
  else{  
    this._dialogRef.close(e);
  }
}
//###########################################################
async getCustomerRelated(rut: string,uuid: string): Promise<any>{
  var tmp : any = []
  tmp = await this._clienteService.customerRelated(rut,uuid);
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  
      for(let i=0;i<tmp.flashed.length;i++){
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& 
        const dialogRefa = this._fuseConfirmationService.open({
                title: `Flash!!`,
                message: `<b>${tmp.flashed[i].TXT_CUSTOMER_NOTES}</b><br>Agregado: ${this._datePipe.transform(tmp.flashed[i].DATE_CREATION)}<br>@${tmp.flashed[i].EMPLOYEE_FIRST_NAME} ${tmp.flashed[i].EMPLOYEE_LAST_NAME}`,
                icon: {
                  show: true,
                  name: 'info',
                  color: 'warning'
                },    
                actions: {
                    confirm: {
                        label: 'Aceptar',
                        color: 'primary',
                    },
                    cancel: {
                        show: false,
                        label: 'Cancelar',
                    },
                },
              });
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
      }
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$     

}
//###########################################################
}
