/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { customerTable } from '../model/customer';
import { interactionTable } from '../model/interaction';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClienteService { 

  // appToken = 'CCA067D69814CADAE0536B01A8C00150';
  appToken = localStorage.getItem('appToken');//environment.appTokenUser; 

  constructor(
    private _http: HttpClient,
    private _toast: ToastrService
  ) {

   }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////    
   async getClientes(): Promise<any> {
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        this.appToken = localStorage.getItem('appToken');
        const data = await $this._http.get(`${environment.endPoints.clientes}${$this.appToken}`);
        data.subscribe(
          (res: any) => {
            resolve(res.dataSet );
          }
        );
      }catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////    
async getAddressType(): Promise<any> {
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      this.appToken = localStorage.getItem('appToken');
      const data = await $this._http.get(`${environment.endPoints.addressType}/${$this.appToken}`);
      data.subscribe(
        (res: any) => {
          resolve(res.dataSet );
        }
      );
    }catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
public getCustomerListPagination(
  rankCustomer        : string
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<customerTable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + rankCustomer
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.clientesPagination}/${url}`;

 return this._http.get<customerTable>(url);
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  // Obtener producto por codigo
  async getProductByBarcode(barcode: string): Promise<any>{ 
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getPsBarcode}/${$this.appToken}/${barcode}`; 
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            //       
            resolve(data.dataSet.barcodePsServices);
          },
          (error) => {
            console.log(error);
            resolve('-1');
          }
        );
      }
      catch (err) {
        console.log(err);
        resolve('-1');
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getClienteByUUID(uuid: string,year,month): Promise<any> {
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      this.appToken = localStorage.getItem('appToken');
      const data = await $this._http.get(`${environment.endPoints.cliente}${$this.appToken}/${uuid}/${year}/${month}`);
      data.subscribe(
        (res: any) => {
          ////////////////////////////////////////////////////////////////////
          res.dataSet.customer[0].EMPLOYEE_DATA_CREATION =JSON.parse(res.dataSet.customer[0].EMPLOYEE_DATA_CREATION)
          res.dataSet.customer[0].EMPLOYEE_DATA_UPDATE   =JSON.parse(res.dataSet.customer[0].EMPLOYEE_DATA_UPDATE  )           
          /////////////////////////////////////////////////////////////////// 
          resolve(res.dataSet);
        }
      );
    }catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getClienteByRut(rut: string): Promise<any> {
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      this.appToken = localStorage.getItem('appToken');
      const data = $this._http.get(`${environment.endPoints.cliente}${$this.appToken}/0/${rut}`);
      data.subscribe(
        (res: any) => {
          // console.log(res);
          resolve(res.dataSet);
        },
        (error: any) => {
          
          reject(error.error);
        }  
      );
    }catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getClienteByRutV2(rut: string,year,month): Promise<any> {
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      this.appToken = localStorage.getItem('appToken');
      const data = $this._http.get(`${environment.endPoints.cliente}${$this.appToken}/0/${rut}/${year}/${month}`);
      data.subscribe(
        (res: any) => {
          // console.log(res);
          resolve(res.dataSet);
        },
        (error: any) => {
          
          reject(error.error);
        }  
      );
    }catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async createInteraction(json: any): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.inteactions}`;
    const data = {
      appToken                     : $this.appToken
     ,uuidCrmCustomer              : json.uuidCrmCustomer
     ,uuidCmEmployee               : json.uuidCmEmployee
     ,idCmInteractionT2            : json.idCmInteractionT2
     ,idCmInteractionStatus        : json.idCmInteractionStatus
     ,idParentCustomerInteraction  : json.idParentCustomerInteraction
     ,jCustomerInteraction         : json.jCustomerInteraction
     ,flexibleAttributes           : json.flexibleAttributes
    };

    return await new Promise((resolve, reject) => {
      try{
        const resp = $this._http.post(url, {
          "data": JSON.stringify(data)
        });
        resp.subscribe(
          (res: any) => {
            resolve(res.dataSet);
            this._toast.success('Se ha creado el caso correctamente', 'Éxito');
          }
        );
      } catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async updateInteraction(json: any): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.inteactions}`;
    const data = {
      appToken                     : $this.appToken
     ,uuidCrmCustomer              : json.uuidCrmCustomer
     ,uuidCrmCustomerInteraction   : json.uuidCrmCustomerInteraction
     ,idCmInteractionStatus        : json.idCmInteractionStatus
     ,jCustomerInteraction         : json.jCustomerInteraction
    };

    return await new Promise((resolve, reject) => {
      try{
        const resp = $this._http.patch(url, {
          "data": JSON.stringify(data)
        });
        resp.subscribe(
          (res: any) => {
           // console.log(res);
            resolve(res.dataSet);
            this._toast.success('Se ha actualizado el caso correctamente', 'Éxito');
          }
        );
      } catch(err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////   
  async getInteractionByUUID(uuidCustomer: string, uuidInteraction: string): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => { 
      try {
        const data = await $this._http.get(`${environment.endPoints.interactionsDetail}${$this.appToken}/${uuidCustomer}/${uuidInteraction}`);
        data.subscribe(
          (res: any) => {
            // console.log(res);
            resolve(res.dataSet);
          }
        );
      }catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async getInteractionList(uuid?:string): Promise<any> {
    const $this = this;
    var url;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        if(uuid) url = `${environment.endPoints.interactionList}${$this.appToken}/${uuid}`
            else url = `${environment.endPoints.interactionList}${$this.appToken}`

        const data = await $this._http.get(url);
        data.subscribe(
          (res: any) => {
            // console.log(res);
            resolve(res.dataSet);
          },
          (err: any) => {
            reject(err);
          }          
        );
      }catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
public getInteractionListPagination(
  uuidCustomer          : string
 ,codInteractionStatus  : string
 ,uuidInteractionT1     : string
 ,uuidInteractionT2     : string
 ,year                  : number 
 ,month                 : number  

 ,page                  : number
 ,perPage               : number
 ,from                  : string 
 ,searchTerm            : string 
 ,orderBy               : string 
): Observable<interactionTable> {

 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  this.appToken 
 + '/' + uuidCustomer
 + '/' + codInteractionStatus
 + '/' + uuidInteractionT1   
 + '/' + uuidInteractionT2   
 + '/' + year  
 + '/' + month    
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.interactionListPagination}/${url}`;

 return this._http.get<interactionTable>(url);
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async getTypeInteractions(): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.inteactionsTree}${$this.appToken}`;
    return await new Promise((resolve, reject) => {
      try{
        const resp = $this._http.get(url);
        resp.subscribe(
          (data: any) => {
            // console.log(data);
            resolve(data.dataSet);
          }
        );
      } catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async createNote(json: any): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.customerNote}`;
    const data = {
      "appToken": this.appToken,
      ...json
    }
    return await new Promise((resolve, reject) => {
      try {
        const resp = $this._http.post(url, {
          "data": JSON.stringify(data)
        });
        resp.subscribe(
          (data: any) => {
            this._toast.success('Se ha creado la nota correctamente', 'Éxito');
            resolve(data.dataSet);
          },
          (err: any) => {
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async deleteNote(uuidCustomer: string, uuidNote: number): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.customerNote}${$this.appToken}/${uuidCustomer}/${uuidNote}`;
    return await new Promise((resolve, reject) => {
      try {
        const resp = $this._http.delete(url);
        resp.subscribe(
          (data: any) => {
            this._toast.success('Se ha eliminado la nota correctamente', 'Éxito');
            resolve(data.dataSet);
          },
          (err: any) => {
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async createAddress(json: any): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.customerAddress}`;
    const data = {
      "appToken": this.appToken,
      ...json
    };
    return await new Promise((resolve, reject) => {
      try {
        const resp = $this._http.post(url, {
          "data": JSON.stringify(data)
        });
        resp.subscribe(
          (data: any) => {
            this._toast.success('Se ha creado la dirección correctamente', 'Éxito');
            resolve(data.dataSet);
          },
          (err: any) => {
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async updateAddress(json: any): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.customerAddress}`;
    const data = {
      "appToken": this.appToken,
      ...json
    };

    return await new Promise((resolve, reject) => {
      try {
        const resp = $this._http.patch(url, {
          "data": JSON.stringify(data)
        });
        resp.subscribe(
          (data: any) => {
            this._toast.success('Se ha actualizado la dirección correctamente', 'Éxito');
            resolve(data.dataSet);
          },
          (err: any) => {
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
  async deleteAddress(uuidCustomer: string, idAddres: number): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.customerAddress}${$this.appToken}/${uuidCustomer}/${idAddres}`;
    return await new Promise((resolve, reject) => {
      try {
        const resp = $this._http.delete(url);
        resp.subscribe(
          (data: any) => {
            this._toast.success('Se ha eliminado la dirección correctamente', 'Éxito');
            resolve(data.dataSet);
          },
          (err: any) => {
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  } 
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async createUpdateCustomer(json: any): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.cliente}`;
  json.appToken=this.appToken
  return await new Promise((resolve, reject) => {
    try {
      const resp = $this._http.post(url, {
        "data": JSON.stringify(json)
      });
      resp.subscribe(
        (data: any) => {
          this._toast.success(data.dataSet.glsStatus, 'Éxito');
          resolve(data.dataSet);
        },
        (err: any) => {
          this._toast.error("Cliente no Actualizado", 'Error');
          reject(err);
        }
      );
    } catch (err) {
      console.log(err); 
      reject(err);
    }
  });
}  
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async customerRelated(rut: string,uuid: string): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  var url =''
  if(rut == null  ) url=`${environment.endPoints.customerRelated}${$this.appToken}/${uuid}`
  if(uuid == null)  url=`${environment.endPoints.customerRelated}${$this.appToken}/0/${rut}`
  
  return await new Promise((resolve, reject) => {
    try{
      const resp = $this._http.get(url);
      resp.subscribe(
        (data: any) => {
          // console.log(data);
          resolve(data.dataSet);
        }
      );
    } catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getCustomerValue(): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.customerValue}/${$this.appToken}`;
  return await new Promise((resolve, reject) => {
    try{
      const resp = $this._http.get(url);
      resp.subscribe(
        (data: any) => {
          // console.log(data);
          resolve(data.dataSet.value);
        }
      );
    } catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async newCustomerContact(json: any): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.customerContact}`;
  json.appToken=this.appToken
  return await new Promise((resolve, reject) => {
    try {
      const resp = $this._http.post(url, {
        "data": JSON.stringify(json)
      });
      resp.subscribe(
        (data: any) => {
          this._toast.success(data.dataSet.glsStatus, 'Éxito');
          resolve(data);
        },
        (err: any) => {
          this._toast.error("Contacto no creado", 'Error');
          reject(err);
        }
      );
    } catch (err) {
      console.log(err); 
      reject(err);
    }
  });
}  
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async udpCustomerContact(json: any): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.customerContact}`;
  json.appToken=this.appToken
  return await new Promise((resolve, reject) => {
    try {
      const resp = $this._http.patch(url, {
        "data": JSON.stringify(json)
      });
      resp.subscribe(
        (data: any) => {
          this._toast.success(data.dataSet.glsStatus, 'Éxito');
          resolve(data);
        },
        (err: any) => {
          this._toast.error("Contacto no Actualizado", 'Error');
          reject(err);
        }
      );
    } catch (err) {
      console.log(err); 
      reject(err);
    }
  });
}  
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async delCustomerContact(json: any): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.customerContact}`;
  json.appToken=this.appToken
  return await new Promise((resolve, reject) => {
    try {
      const resp = $this._http.post(url, {
        "data": JSON.stringify(json)
      });
      resp.subscribe(
        (data: any) => {
          this._toast.success(data.dataSet.glsStatus, 'Éxito');
          resolve(data.dataSet);
        },
        (err: any) => {
          this._toast.error("Contacto no creado", 'Error');
          reject(err);
        }
      );
    } catch (err) {
      console.log(err); 
      reject(err);
    }
  });
}  
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////  
async getCustomerContactType(): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.contactType}/${$this.appToken}`;
  return await new Promise((resolve, reject) => {
    try{
      const resp = $this._http.get(url);
      resp.subscribe(
        (data: any) => {
          // console.log(data);
          resolve(data.dataSet);
        }
      );
    } catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getRutExtranjero(type): Promise<any> {
  const $this = this;
  const url = `${environment.endPoints.getRutExtranjero}/${type}`;
  return await new Promise((resolve, reject) => {
    try{
      const resp = $this._http.get(url);
      resp.subscribe(
        (data: any) => {
          // console.log(data);
          resolve(data.dataSet);
        }
      );
    } catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getCustomerData(rut): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.customerData}/${$this.appToken}/${rut}`;
  return await new Promise((resolve, reject) => {
    try{
      const resp = $this._http.get(url);
      resp.subscribe(
        (data: any) => {
          // console.log(data);
          resolve(data.dataSet);
        }
      );
    } catch(err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
}
