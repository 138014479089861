import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, of, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { RcService } from  'app/services/rc.service';

@Component({
  selector: 'app-select-attributes',
  templateUrl: './select-attributes.component.html',
  styleUrls: ['./select-attributes.component.scss']
})
export class SelectAttributesComponent implements OnInit {
//######################################################
attributesForm: UntypedFormGroup;  
arrOp = [
         {cod:"Y" ,gls:"Mensual" }  
        ,{cod:"N" ,gls:"Fijo" }
        ]  

 minDateInfo = new Date(2000, 0, 1);
 today = new Date();
 maxDate = new Date(9999, 11, 31)
//######################################################
  constructor(
                @Inject(MAT_DIALOG_DATA) public data: any,
                public _dialogRef: MatDialogRef<SelectAttributesComponent>,
                private _fb: FormBuilder,
                private _fuseConfirmationService: FuseConfirmationService,
                private _rc:RcService,

  ) {


   }
//######################################################
  ngOnInit(): void {
    this.attributesForm = this._fb.group({
                                               idRcAttributes        : [0]
                                              ,idRcAttributesType    : [this.data.rcAttributesTypeSelected.ID_RC_ATTRIBUTES_TYPE,[Validators.required]]
                                              ,valueRcAttributesType : [null,[Validators.required]]
                                              ,dateRcAttributesType  : [null]
                                              ,flagRecurrent         : [null]
    })
    if(this.data.rcAttributesTypeSelected.FLAG_RECURRENT == 'N' ){
      this.attributesForm.get('flagRecurrent').setValue('N')
      this.attributesForm.get('flagRecurrent').disable();
    }else{
      if(this.data.quantityAttributes > 0 ){
        //========================================================
           let tmp = this.data.dataAttributes.filter(i => i.FLAG_RECURRENT == 'N')
          if(tmp.length > 0)
          this.attributesForm.get('flagRecurrent').setValue('N')
          this.attributesForm.get('flagRecurrent').enable();
        //========================================================        
      }else{
        //========================================================        
          this.attributesForm.get('flagRecurrent').setValue('Y')
          this.attributesForm.get('flagRecurrent').enable();
        //========================================================        
      }
    }  
    
    
  }
//######################################################
enableDisable(e,val){
if( e == 'flagRecurrent'){
  if(val == 'Y'){
    this.attributesForm.get('dateRcAttributesType').setValidators([Validators.required]);
    this.attributesForm.updateValueAndValidity();
  }
  else{
    this.attributesForm.get('dateRcAttributesType').setValidators([]);
    this.attributesForm.updateValueAndValidity();
  }
  
}
}
//######################################################                
addAttributes(){
  let $this = this;
  var attributes = {};
  const dialogRefa = this._fuseConfirmationService.open({
    title: `Agregar Atributo  ${this.data.rcAttributesTypeSelected.COD_RC_ATTRIBUTES_TYPE} ${this.data.rcAttributesTypeSelected.NAME_RC_ATTRIBUTES_TYPE}`,
    message: `valor: <b>${this.attributesForm.value.valueRcAttributesType}<b>`,
    icon: {
      show: true,
      name: 'assignment_turned_in',
      color: 'primary'
    },    
    actions: {
        confirm: {
            label: 'Aceptar',
            color: 'primary',
        },
        cancel: {
             show: true,
            label: 'Cancelar',
        },
    },
  });
  dialogRefa.afterClosed().subscribe(async (result) => {
    if (result === 'confirmed') {
        //////////////////////////////////////////
          this.attributesForm.enable();
          attributes = {
                             appToken                : null
                            ,language                : 'es'
                            ,uuidSSO                 : null
                            ,uuidRc                  :this.data.uuidRc      
                            ,detailRc                : [
                                                          {
                                                             idRcAttributes        : $this.attributesForm.value.idRcAttributes    
                                                            ,idRcAttributesType    : $this.attributesForm.value.idRcAttributesType    
                                                            ,valueRcAttributesType : $this.attributesForm.value.valueRcAttributesType 
                                                            ,dateRcAttributesType  : $this.formatDate(this.attributesForm.value.dateRcAttributesType  )      
                                                            ,flagRecurrent         : $this.attributesForm.value.flagRecurrent         
                                                            ,acction               : 'ADD'   
                                                          }
                            ]
                        }
            let j = await await this._rc.setRcAttributes(attributes)
            if(j.codStatus == 200 ){
              this._dialogRef.close({reload:true,idRcAttributesType:$this.attributesForm.value.idRcAttributesType    });
            }
        //////////////////////////////////////////                    
    } else {
        return;
    }
  });
}
//######################################################
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('/');
}
//######################################################
btnClose(){
  this._dialogRef.close();
}
//######################################################
}
