/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore'; /* http://underscorejs.org/# */
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, NumberFormatStyle } from '@angular/common';
import { DatePipe,DecimalPipe } from '@angular/common';
import * as rut from '@fdograph/rut-utilities';
import * as qz from 'qz-tray';
@Injectable({
  providedIn: 'root'
})
//####################################################

//####################################################
export class LabelPrinterService { 
//####################################################
_typePrinter    : string = '';// ESC/POS LASER ZPL 
_stringOconn    : any
_namePrinter    : string
_flagDegub      : boolean = false;


_currencyPipe  : CurrencyPipe
_datePipe      : DatePipe
_decimalPipe   : DecimalPipe

_IsConnect     : boolean = false;
_glsStatus     : string

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
constructor()
  { 

    this._currencyPipe = new CurrencyPipe('es-CL')
    this._datePipe     = new  DatePipe('es-CL')
    this._decimalPipe  = new DecimalPipe('es-CL')
  }
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
////////////////////////////////////////       
////////////////////////////////////////
setPrinter(
           typePrinter   : string
          ,stringOconn   : any
          ,namePrinter   : string = 'DEFAULT'
          ,flagDegub     : boolean = false
        ){ 
            //==            
            this._typePrinter   = typePrinter  ; 
            this._stringOconn    = stringOconn; 
            this._namePrinter   = namePrinter  ; 
            this._flagDegub     = flagDegub    ;
            //==
}
////////////////////////////////////////       
////////////////////////////////////////
fnConsoleLog(txt){
    if( this._flagDegub == true)
        console.log(txt)
    return(true)
}
////////////////////////////////////////       
////////////////////////////////////////
async initQZ() {
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%    
        if (qz.websocket.isActive()){
            await qz.websocket.disconnect();
            await $this.initQZ()
        } 
        else{
            //=====================================================================
                qz.security.setCertificatePromise(function(resolve, reject) {resolve(environment.qzTrayDigitalKey)});
                qz.security.setSignatureAlgorithm("SHA256"); // Since 2.1
                if($this._namePrinter == 'DEFAULT'){
                    //#############################################################
                        qz.websocket.connect($this._stringOconn)
                        .then(qz.printers.getDefault)
                        .then(async  function(printer) { 
                            //=================================================
                                $this._IsConnect = true;
                                $this._namePrinter = printer
                                $this._glsStatus = "A-Impresora predeterminada -> Conectado a: " + printer
                                $this.fnConsoleLog("A-Impresora predeterminada -> Conectado a: " + printer)
                                resolve(true)
                            //=================================================                    
                        })
                        .catch(async function(err) { 
                            //=================================================                
                                $this._IsConnect = false;
                                $this._namePrinter = '';
                                $this._glsStatus = "A-Error: No conectado Impresora predeterminada: " + err
                                $this.fnConsoleLog("A-Error: No conectado Impresora predeterminada: " + err)
                                resolve(true)
                            //=================================================  
                        })
                    //#############################################################                    
                }
                else{
                    //#############################################################
                        qz.websocket.connect($this._stringOconn)
                        .then(qz.printers.getDefault)
                        .then(async  function(printer) { 
                            //=================================================
                                $this._IsConnect = true;
                                $this._glsStatus = "B-Impresora predeterminada -> Conectado a: " + printer
                                $this.fnConsoleLog("B-Impresora predeterminada -> Conectado a: " + printer)
                                resolve(true)
                            //=================================================                    
                        })
                        .catch(async function(err) { 
                            //=================================================             
                                $this._IsConnect = false;
                                $this._glsStatus = "B-Error: No conectado Impresora predeterminada:"+ this._namePrinter +" " + err
                                $this.fnConsoleLog("B-Error: No conectado Impresora predeterminada:"+ this._namePrinter +" " + err)
                                resolve(true)
                            //=================================================  
                        })                    
                    //#############################################################                    
                }
            //=====================================================================
        }
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%    
          ////////////////////////////////////////////
      });
}                  
////////////////////////////////////////       
////////////////////////////////////////
async closeQZ() {
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%    
        if (qz.websocket.isActive()){
            await qz.websocket.disconnect();
            resolve(true)
        }
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%    
          ////////////////////////////////////////////
        });
    }  
////////////////////////////////////////       
////////////////////////////////////////
async printLabel(type,data){
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////    
          var printZpl;
          var config = qz.configs.create($this._namePrinter);  
            //####
            $this.fnConsoleLog($this._typePrinter)
            $this.fnConsoleLog(type)
            //####
            if($this._typePrinter == 'ZPL' && type == 'zpl_label_100_x_150'){
                //==
                printZpl = await $this._zpl_label_100_x_150(data)
                await qz.print(config, printZpl).then(function() {
                    $this.fnConsoleLog("A-Sent: Enviado Impresora :"+ $this._namePrinter )
                    });
                await qz.websocket.disconnect();
                //==
                }
            //####
            //####
            if($this._typePrinter == 'ZPL' && type == 'chx_label_100_x_50'){
                //==
                printZpl = await $this._chx_label_100_x_50(data)
                await qz.print(config, printZpl).then(function() {
                    $this.fnConsoleLog("A-Sent: Enviado Impresora :"+ $this._namePrinter )
                    });
                await qz.websocket.disconnect();
                //==
                }
            //####
            //####    
            if($this._typePrinter == 'ZPL' && type == 'own_label_100_x_50'){
                //==
                printZpl = await $this._own_label_100_x_50(data)
                await qz.print(config, printZpl).then(function() {
                    $this.fnConsoleLog("A-Sent: Enviado Impresora :"+ $this._namePrinter )
                    });
                await qz.websocket.disconnect();
                //==
                }
            //####
            //####    
            if($this._typePrinter == 'ZPL' && type == 'str_label_100_x_50'){
                //==
                printZpl = await $this._str_label_100_x_50(data)
                await qz.print(config, printZpl).then(function() {
                    $this.fnConsoleLog("A-Sent: Enviado Impresora :"+ $this._namePrinter )
                    });
                await qz.websocket.disconnect();
                //==
                }
            //####
            //####  
            if($this._typePrinter == 'ZPL' && type == 'CC_label_100_x_50'){
                //==
                printZpl = await $this._CC_label_100_x_50(data)
                await qz.print(config, printZpl).then(function() {
                    $this.fnConsoleLog("A-Sent: Enviado Impresora :"+ $this._namePrinter )
                    });
                await qz.websocket.disconnect();
                //==
                }
            //####
            //####           
                                   
          resolve(true)
          ////////////////////////////////////////////
      })          
}
////////////////////////////////////////       
////////////////////////////////////////
async _zpl_label_100_x_150(data){
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////       
                var dataPrint=[];
                dataPrint.push('^XA' )
                dataPrint.push('^FX Top section with logo, name and address.' )
                dataPrint.push('^CF0,60' )
                dataPrint.push('^FO50,50^GB100,100,100^FS' )
                dataPrint.push('^FO75,75^FR^GB100,100,100^FS' )
                dataPrint.push('^FO93,93^GB40,40,40^FS' )
                dataPrint.push('^FO220,50^FDIntershipping, Inc.^FS' )
                dataPrint.push('^CF0,30' )
                dataPrint.push('^FO220,115^FD1000 Shipping Lane^FS' )
                dataPrint.push('^FO220,155^FDShelbyville TN 38102^FS' )
                dataPrint.push('^FO220,195^FDUnited States (USA)^FS' )
                dataPrint.push('^FO50,250^GB700,3,3^FS' )
                dataPrint.push('^FX Second section with recipient address and permit information.' )
                dataPrint.push('^CFA,30' )
                dataPrint.push('^FO50,300^FDJohn Doe^FS' )
                dataPrint.push('^FO50,340^FD100 Main Street^FS' )
                dataPrint.push('^FO50,380^FDSpringfield TN 39021^FS' )
                dataPrint.push('^FO50,420^FDUnited States (USA)^FS' )
                dataPrint.push('^CFA,15' )
                dataPrint.push('^FO600,300^GB150,150,3^FS' )
                dataPrint.push('^FO638,340^FDPermit^FS' )
                dataPrint.push('^FO638,390^FD123456^FS' )
                dataPrint.push('^FO50,500^GB700,3,3^FS' )
                dataPrint.push('^FX Third section with bar code.' )
                dataPrint.push('^BY5,2,270' )
                dataPrint.push('^FO100,550^BC^FD12345678^FS' )
                dataPrint.push('^FX Fourth section (the two boxes on the bottom).' )
                dataPrint.push('^FO50,900^GB700,250,3^FS' )
                dataPrint.push('^FO400,900^GB3,250,3^FS' )
                dataPrint.push('^CF0,40' )
                dataPrint.push('^FO100,960^FDCtr. X34B-1^FS' )
                dataPrint.push('^FO100,1010^FDREF1 F00B47^FS' )
                dataPrint.push('^FO100,1060^FDREF2 BL4H8^FS' )
                dataPrint.push('^CF0,190' )
                dataPrint.push('^FO470,955^FDCA^FS' )
                dataPrint.push('^XZ' )

                resolve(dataPrint)
                ////////////////////////////////////////////
            })                   
}
////////////////////////////////////////       
////////////////////////////////////////
async _chx_label_100_x_50(data){
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////     
            var dataPrint=[];
            var tmp1 = data.destinationCoverageAreaName.padEnd(24,' ')
            var tmp2 = data.distributionDescription.padEnd(28,' ')
            var tmp3 = data.serviceDescription.padEnd(7,' ')
            var tmp4 = data.recipient.substr(0,41)
            dataPrint.push('\n' )
            dataPrint.push('N' )
            dataPrint.push('\n' )
            dataPrint.push('q800' )
            dataPrint.push('\n' )
            dataPrint.push('Q400,24+0' )
            dataPrint.push('\n' )
            dataPrint.push('S4' )
            dataPrint.push('\n' )
            dataPrint.push('D12' )
            dataPrint.push('\n' )
            dataPrint.push('ZB' )
            dataPrint.push('\n' )
            dataPrint.push('A040,0,0,4,1,2,N,"'+data.companyName+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A310,10,0,3,1,1,N,"'+data.classificationData+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A510,0,0,4,1,2,N,"'+data.transportOrderNumber+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A740,0,0,4,1,2,N,"'+data.productDescription+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A370,95,0,2,1,1,N,"'+data.labelVersion+' IMP:'+data.printedDate +'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,42,0,3,1,1,N,"'+data.groupReference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,60,0,3,1,1,N,"'+tmp4+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,78,0,3,1,1,N,"'+data.address+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,97,0,3,1,1,N,"'+data.reference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,050,0,4,1,1,N,"'+data.deliveryTypeCode+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,090,0,3,1,1,N,"'+data.genericString2+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,84,160,2' )
            dataPrint.push('\n' )
            dataPrint.push('A030,121,0,3,1,2,R,"'+tmp1+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A340,121,0,3,1,2,R,"'+tmp2+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A680,121,0,3,1,2,R,"'+tmp3+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO777,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,117,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,164,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('B050,186,0,1,4,4,187,N,"'+data.barcode+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A700,0,0,d,1,1,N,""' )
            dataPrint.push('\n' )
            dataPrint.push('FE' )
            dataPrint.push('\n' )
            dataPrint.push('P1' )
            dataPrint.push('\n' )

            resolve(dataPrint)
            ////////////////////////////////////////////
        })  
}
////////////////////////////////////////        
////////////////////////////////////////
async _own_label_100_x_50(data){
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////     
            var dataPrint=[];
            var tmp1 = data.destinationCoverageAreaName.padEnd(21,' ')
            var tmp2 = data.distributionDescription.padEnd(31,' ')
         //   var tmp3 = data.serviceDescription.padEnd(7,' ')
            var tmp4 = data.recipient.substr(0,41)
            dataPrint.push('\n' )
            dataPrint.push('N' )
            dataPrint.push('\n' )
            dataPrint.push('q800' )
            dataPrint.push('\n' )
            dataPrint.push('Q400,24+0' )
            dataPrint.push('\n' )
            dataPrint.push('S4' )
            dataPrint.push('\n' )
            dataPrint.push('D12' )
            dataPrint.push('\n' )
            dataPrint.push('ZB' )
            dataPrint.push('\n' )
            dataPrint.push('A040,0,0,4,1,2,N,"'+data.companyName+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A310,10,0,3,1,1,N,"'+data.classificationData+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A510,0,0,4,1,2,N,"'+data.transportOrderNumber+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A740,0,0,4,1,2,N,"'+data.productDescription+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A370,95,0,2,1,1,N,"'+data.labelVersion+' IMP:'+data.printedDate +'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,42,0,3,1,1,N,"'+data.groupReference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,60,0,3,1,1,N,"'+tmp4+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,78,0,3,1,1,N,"'+data.address+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,97,0,3,1,1,N,"'+data.reference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,050,0,4,1,1,N,"'+data.deliveryTypeCode+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,090,0,3,1,1,N,"'+data.genericString2+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,84,160,2' )
            dataPrint.push('\n' )
            dataPrint.push('A030,121,0,3,1,2,R,"'+tmp1+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A340,121,0,3,1,2,R,"'+tmp2+'"' )
           // dataPrint.push('\n' )
           // dataPrint.push('A680,121,0,3,1,2,R,"'+tmp3+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO777,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,117,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,164,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('b50,180,Q,,s9,"'+data.frienlyEomOrder+'"' )
            ////
            
            if(data.sgtmCustomer == 'E'){
            dataPrint.push('\n' )
            dataPrint.push('A300,180,0,4,1,1,R,"Contacto:"' )                
            dataPrint.push('\n' )
            dataPrint.push('A300,210,0,3,1,1,N,"Nombre:'+data.nameCmCustomerValue + ' ' +data.customerContactLastName+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A300,240,0,3,1,1,N,"Email:'+data.customerContactMail+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A300,270,0,3,1,1,N,"Tel:'+data.customerContactPhone+'"' )
            }
            dataPrint.push('\n' )
            dataPrint.push('A300,300,0,2,1,2,R,"v: '+data.nameCmCustomerValue+'"' )
            
            ////
            dataPrint.push('\n' )
            dataPrint.push('A700,0,0,d,1,1,N,""' )
            dataPrint.push('\n' )
            dataPrint.push('FE' )
            dataPrint.push('\n' )
            dataPrint.push('P1' )
            dataPrint.push('\n' )

            resolve(dataPrint)
            ////////////////////////////////////////////
        })  

}
////////////////////////////////////////        
////////////////////////////////////////
async _CC_label_100_x_50(data){
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////     
            var dataPrint=[];
            var tmp1 = data.destinationCoverageAreaName.padEnd(21,' ')
            var tmp2 = data.distributionDescription.padEnd(31,' ')
         //   var tmp3 = data.serviceDescription.padEnd(7,' ')
            var tmp4 = data.recipient.substr(0,41)
            dataPrint.push('\n' )
            dataPrint.push('N' )
            dataPrint.push('\n' )
            dataPrint.push('q800' )
            dataPrint.push('\n' )
            dataPrint.push('Q400,24+0' )
            dataPrint.push('\n' )
            dataPrint.push('S4' )
            dataPrint.push('\n' )
            dataPrint.push('D12' )
            dataPrint.push('\n' )
            dataPrint.push('ZB' )
            dataPrint.push('\n' )
            dataPrint.push('A040,0,0,4,1,2,N,"'+data.companyName+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A310,10,0,3,1,1,N,"'+data.classificationData+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A510,0,0,4,1,2,N,"'+data.transportOrderNumber+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A740,0,0,4,1,2,N,"'+data.productDescription+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A370,95,0,2,1,1,N,"'+data.labelVersion+' IMP:'+data.printedDate +'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,42,0,3,1,1,N,"'+data.groupReference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,60,0,3,1,1,N,"'+tmp4+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,78,0,3,1,1,N,"'+data.address+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,97,0,3,1,1,N,"'+data.reference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,050,0,4,1,1,N,"'+data.deliveryTypeCode+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,090,0,3,1,1,N,"'+data.genericString2+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,84,160,2' )
            dataPrint.push('\n' )
            dataPrint.push('A030,121,0,3,1,2,R,"'+tmp1+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A340,121,0,3,1,2,R,"'+tmp2+'"' )
           // dataPrint.push('\n' )
           // dataPrint.push('A680,121,0,3,1,2,R,"'+tmp3+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO777,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,117,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,164,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('b50,180,Q,,s9,"'+data.frienlyEomOrder+'"' )
            ////
            
            if(data.sgtmCustomer == 'E'){
            dataPrint.push('\n' )
            dataPrint.push('A300,180,0,4,1,1,R,"Contacto:"' )                
            dataPrint.push('\n' )
            dataPrint.push('A300,210,0,3,1,1,N,"Nombre:'+data.nameCmCustomerValue + ' ' +data.customerContactLastName+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A300,240,0,3,1,1,N,"Email:'+data.customerContactMail+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A300,270,0,3,1,1,N,"Tel:'+data.customerContactPhone+'"' )
            }
            dataPrint.push('\n' )
            dataPrint.push('A300,300,0,2,1,2,R,"v: '+data.nameCmCustomerValue+'"' )
            
            ////
            dataPrint.push('\n' )
            dataPrint.push('A700,0,0,d,1,1,N,""' )
            dataPrint.push('\n' )
            dataPrint.push('FE' )
            dataPrint.push('\n' )
            dataPrint.push('P1' )
            dataPrint.push('\n' )

            resolve(dataPrint)
            ////////////////////////////////////////////
        })  

}
//====================================================
//====================================================
async _str_label_100_x_50(data){
    let $this=this;
    return await new Promise(
      async function (resolve, reject) {
          ////////////////////////////////////////////     
            var dataPrint=[];
            var tmp1 = data.destinationCoverageAreaName.padEnd(24,' ')
            var tmp2 = data.distributionDescription.padEnd(28,' ')
            var tmp3 = data.serviceDescription.padEnd(7,' ')
            dataPrint.push('\n' )
            dataPrint.push('N' )
            dataPrint.push('\n' )
            dataPrint.push('q800' )
            dataPrint.push('\n' )
            dataPrint.push('Q400,24+0' )
            dataPrint.push('\n' )
            dataPrint.push('S4' )
            dataPrint.push('\n' )
            dataPrint.push('D12' )
            dataPrint.push('\n' )
            dataPrint.push('ZB' )
            dataPrint.push('\n' )
            dataPrint.push('A040,0,0,4,1,2,N,"'+data.companyName+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A310,10,0,3,1,1,N,"'+data.classificationData+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A510,0,0,4,1,2,N,"'+data.transportOrderNumber+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A710,0,0,4,1,2,N,"'+data.productDescription+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A370,95,0,2,1,1,N,"'+data.labelVersion+' IMP:'+data.printedDate +'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,42,0,3,1,1,N,"'+data.groupReference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,60,0,3,1,1,N,"'+data.recipient+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,78,0,3,1,1,N,"'+data.address+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A040,97,0,3,1,1,N,"'+data.reference+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,050,0,4,1,1,N,"'+data.deliveryTypeCode+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A655,090,0,3,1,1,N,"'+data.genericString2+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,84,160,2' )
            dataPrint.push('\n' )
            dataPrint.push('A030,121,0,3,1,2,R,"'+tmp1+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A340,121,0,3,1,2,R,"'+tmp2+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A680,121,0,3,1,2,R,"'+tmp3+'"' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO777,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO617,38,2,126' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,117,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('LO026,164,752,2' )
            dataPrint.push('\n' )
            dataPrint.push('b50,180,Q,,s9,"'+data.frienlyEomOrder+'"' )
            dataPrint.push('\n' )
            dataPrint.push('A700,0,0,d,1,1,N,""' )
            dataPrint.push('\n' )
            dataPrint.push('FE' )
            dataPrint.push('\n' )
            dataPrint.push('P1' )
            dataPrint.push('\n' )

            resolve(dataPrint)
            ////////////////////////////////////////////
        })  
}
//====================================================
//====================================================
amountFormat(amount){

  return this._currencyPipe.transform(amount, 'CLP', 'symbol-narrow') 
}
//====================================================
//====================================================
numberFormat(n){

  return this._decimalPipe.transform(n) 
}
//====================================================
//====================================================
rutFormat(rutUnformat){
return rut.formatRut(rutUnformat, rut.RutFormat.DOTS_DASH)
}
//====================================================
//====================================================
dateFormat(date){  
  return this._datePipe.transform(date, 'dd-MM-yyyy')
}
//====================================================
//====================================================
 ucFirstAllWords( str )
{   var pieces 
    str = str.toLowerCase()
    pieces = str.split(" ");
    for ( var i = 0; i < pieces.length; i++ )
    {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
}
//####################################################


}    

