import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
}) 
export class UserService {

  appToken = localStorage.getItem('appToken');//environment.appTokenUser; 



  constructor(
    private _http: HttpClient,
    private _toast: ToastrService
  ) { }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  async getMenu(appToken?:string,uuidSSO?:string,): Promise<any> { 
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        var tmp = $this.appToken || appToken;
        var tmp2 = null;
        if(uuidSSO) tmp2 = uuidSSO;else tmp2 =  localStorage.getItem('uuidEmployee');
       // console.log(`${environment.endPoints.userMenu}/${tmp}/${tmp2}`)
        if(tmp){
              let url = `${environment.endPoints.userMenu}/${tmp}/${tmp2}` 
              const data =  $this._http.get(url);
                  data.subscribe(
                    (res: any) => {
                      resolve(res.dataSet);
                    }
                  );
              }
            else
            {
              resolve([])
            }
          }catch(err) {
            console.log(err);
            reject(err);
          }
        });
  }
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  async getNotification(uuidSSO:string, appToken: string): Promise<any> {
     const $this = this;
     this.appToken = localStorage.getItem('appToken');
     return await new Promise(async (resolve, reject) => {
       try {
         const data = await $this._http.get(`${environment.endPoints.userNotifications}${appToken}/${uuidSSO}`);
         data.subscribe(
           (res: any) => {
            ////////////////////////////////////////
            for(let i=0; i<res.dataSet.notifications.length;i++){
                try{
                  res.dataSet.notifications[i].description =  JSON.parse(res.dataSet.notifications[i].description);
                }catch(e){
                  res.dataSet.notifications[i].description =  res.dataSet.notifications[i].description;
                }
            }
             resolve(res.dataSet.notifications); 
            ////////////////////////////////////////
           }
         );
       }catch(err) {
         console.log(err);
         reject(err);
       }
     });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  async getAppOwnerModule(rut: string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        const data = await $this._http.get(`${environment.endPoints.getAppOwnerModule}${rut}`);
        data.subscribe(
          (res: any) => {
            resolve(res.dataSet);
          }
        );
      }catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  async getAppModuleAll(): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise(async (resolve, reject) => {
      try {
        const data = await $this._http.get(`${environment.endPoints.modulosAll}/${this.appToken}`);
        data.subscribe(
          (res: any) => {
            resolve(res.dataSet);
          }
        );
      }catch(err) {
        console.log(err);
        reject(err);
      }
    });
  }
  //////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/*
                   login    : req.body.txtuser
                  ,device   : "na"
                  ,language : req.body.language
                  ,appToken : req.body.appToken
*/
async forgotpasswdEmailApp(dataUsr): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.forgotpasswdEmailApp}`

      dataUsr.appToken = environment.appTokenSSO
      dataUsr.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(dataUsr)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Correo enviado con intrucciones para resestablecer contraseña ', 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.detail.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('Error al solcitar al solicitar resestablecer contraseña ', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
  
  /*
    authEmit = {
      appToken: 'CCA067D69814CADAE0536B01A8C00150',
      language: 'es',
      environment: 'TEST-DTE',
      user: 'icortes',
      passwd: '12345',
      pin: '1234',
      log: false,
    };

  */

}
