import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-decline-or-acept',
  templateUrl: './dialog-decline-or-acept.component.html',
  styleUrls: ['./dialog-decline-or-acept.component.scss']
})
export class DialogDeclineOrAceptComponent implements OnInit {
//############################################################
//############################################################  
dteForm: UntypedFormGroup;

  toggleMotivoRechazo: boolean = false;
  

recepcionEnvio=[
                 {cod:"0" ,gls:"DTE Recibido OK"}
                ,{cod:"1" ,gls:"DTE No Recibido - Error de Firma"}
                ,{cod:"2" ,gls:"DTE No Recibido - Error en RUT Emisor"}
                ,{cod:"3" ,gls:"DTE No Recibido - Error en RUT Receptor"}
                ,{cod:"4" ,gls:"DTE No Recibido - DTE Repetido"}
                ,{cod:"99",gls:"DTE No Recibido - Otros"}
              ]
resultadoDTE=[
               {cod:"0",gls:"DTE Aceptado OK"              ,glsRsp:"ACEPTADO OK"}
              ,{cod:"1",gls:"DTE Aceptado con Discrepancia",glsRsp:"ACEPTADO CON DISCREPANCIAS"}
              ,{cod:"2",gls:"DTE Rechazado"                ,glsRsp:"RECHAZADO"}
            ]

//############################################################
//############################################################  
  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogDeclineOrAceptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dteForm =  this._fb.group({
       resultadoDTE:   [null]
      ,recepcionEnvio: [null]
      ,glsRechazo:     [null ,[Validators.required, Validators.maxLength(90)]]
      ,motivoRechazo:  [null ,[Validators.required, Validators.maxLength(150)]]
    });
  }
//############################################################
//############################################################  
  ngOnInit(): void {
  

    console.log(this.data)

  }
//############################################################
//############################################################  
closeBtn(){
    this.dialogRef.close();
  }
//############################################################
//############################################################  
setReason(e,type){
var tmp
  if(type == 'recepcionEnvio'){
  //======================
  //====================== 
  }
  if(type == 'resultadoDTE'){
    //======================
      tmp = this.resultadoDTE.filter(i => i.cod == e)[0]
      if(tmp.cod== '2') {
        this.toggleMotivoRechazo=true; 
        this.dteForm.get('motivoRechazo').setValue(null);
      }else{
         this.toggleMotivoRechazo=false;
         this.dteForm.get('motivoRechazo').setValue('OK');
      }
      this.dteForm.get('glsRechazo').setValue(tmp.glsRsp);
    //====================== 
  }  

}
//############################################################
//############################################################  
makeRspComercial(){

}

//############################################################
//############################################################  
makRerecepcionEnvio(){

}
//############################################################
//############################################################  
}
