import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-bug-info',
  templateUrl: './dialog-bug-info.component.html',
  styleUrls: ['./dialog-bug-info.component.scss']
})
export class DialogBugInfoComponent implements OnInit {

  

  constructor(
    private _dialogRef: MatDialogRef<DialogBugInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data, 
  ) { }

  ngOnInit(): void {
  }

  closeWithoutSend() {
    this._dialogRef.close();
  }

  closeAndSend() {
    this._dialogRef.close();
  };
}
