import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as _ from 'underscore'; /* http://underscorejs.org/# */
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, NumberFormatStyle } from '@angular/common';
import { DatePipe,DecimalPipe } from '@angular/common';
import * as rut from '@fdograph/rut-utilities';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

  _currencyPipe  : CurrencyPipe
  _datePipe      : DatePipe
  _decimalPipe   : DecimalPipe

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  constructor() { 
    this._currencyPipe = new CurrencyPipe('es-CL')
    this._datePipe     = new  DatePipe('es-CL')
    this._decimalPipe  = new DecimalPipe('es-CL')

  }
//====================================================
//====================================================
/**
 * 
 * @param {*} master 
 * @param {*} child 
 * @returns 
 * 
 * master : [ '33', '34', '46', '52', '56', '61' ]
 * child  : [ '33', '56', '61' ]
 * return true
 */
async  _arrIn(master,child) {
    return new Promise((resolve, reject) =>{
        // ========================================================
          try{
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""    
             var value;
             for( let i in child){ 
                 value = master.find(element => element ==child[i])
                 if(value == undefined){
                    resolve(false);
                 }
             }
             resolve(true);
            //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
            }
            catch(e)
            {
              reject(false)
            }
        // ========================================================
        })
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  
    }
//====================================================
//====================================================
_formatDate(date,sep) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join(sep);
}
//====================================================
//====================================================
_amountFormat(amount){

  return this._currencyPipe.transform(amount, 'CLP', 'symbol-narrow') 
}
//====================================================
//====================================================
_numberFormat(n){

  return this._decimalPipe.transform(n) 
}
//====================================================
//====================================================
_rutFormat(rutUnformat){
return rut.formatRut(rutUnformat, rut.RutFormat.DOTS_DASH)
}
//====================================================
//====================================================
_dateFormat(date){  
  return this._datePipe.transform(date, 'dd-MM-yyyy')
}
//====================================================
//====================================================
_ucFirstAllWords( str )
{   var pieces 
    str = str.toLowerCase()
    pieces = str.split(" ");
    for ( var i = 0; i < pieces.length; i++ )
    {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
}
//====================================================
//====================================================
_numericOnly(event): boolean {    
  let patt = /^([0-9])$/;
  let result = patt.test(event.key);
  return result;
}
//====================================================
//====================================================
_alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
   let patt = /^([a-zA-Z0-9])$/;
  let result = patt.test(e.key);
  return result;
}
//====================================================
//====================================================
_onPaste(e) {
  e.preventDefault();
  return false;
}
//====================================================
//====================================================
_removeSpecialCharacters(event) {
  let newVal = event.target.value.replace('[^A-Za-z,]', '');
  return newVal;
}
//====================================================
//====================================================
_openExternal(url){
  window.open(url, "_blank");
  }
//====================================================
//====================================================
_isNumber(number){
  try{
      return /^-?[\d.]+(?:e-?\d+)?$/.test(number); 
      //  return(parseInt(number,10))
  } catch (e) {
      console.log(e);
      return false;
  }        
}
//====================================================
//====================================================
_roundAmount(amount,currency,float){
  try{
          var a = Number(amount)
          var b = Number(float)
          if(currency == null || currency == undefined){
              return(Math.round(a))
          }
          else
          {
              return(Math.round(a))
          }
      }
      catch(e)
      {
        console.log(e);
        return null;
  
      }  
  }
//====================================================
//====================================================  
_chkRut(strRut){
  if(strRut){
    let tmpRut = strRut.split("-")
    if(tmpRut[1] == 'k' || tmpRut[1] == 'K') strRut =  tmpRut[0] +'-K'
    ///////////////
        if(rut.validateRut(strRut) == true)
          return {status:true,srtRut:rut.formatRut(strRut, rut.RutFormat.DOTS_DASH),srtRutClean:rut.formatRut(strRut, rut.RutFormat.DASH)}
        else
          return {status:false,srtRut:null}
}
return {status:false,srtRut:null,srtRutClean:null}
}
//====================================================
//====================================================
/*
checkRut(rut,field) {
  var valor,cuerpo,dv,rutTmp
  
  var suma,multiplo,index,dvEsperado


  if(field == 'RUTTrans'){
      this.errRUTTrans     = ''
      this.errFlagRUTTrans = false
  }
  //
  if(field == 'RUTChofer'){
      this.errRUTChofer     = ''
      this.errFlagRUTChofer = false
  }
  //
  if(rut == undefined || rut == null || rut == ''  )
  {    
      console.log("Rut Nulo")
  }
  else{
      
      //Quito espacios en blanco
      valor = rut.trim();        
      // Despejar Puntos
      valor = valor.replaceAll('.','');
      // Despejar Guión
      valor = valor.replaceAll('-',''); 
      //
      cuerpo = valor.substring(0,valor.length -1)
      dv     = valor.substring(valor.length -1,valor.length )
     
      //console.log(cuerpo)
      //console.log(dv)
      // Formatear RUN
      rut = cuerpo + '-'+ dv
      //////////////////////////////////////////////////
      // Si no cumple con el mínimo ej. (n.nnn.nnn)
      
      if(cuerpo.length < 7) { 
          if(field == 'RUTTrans'){
              this.errRUTTrans     = "RUT Empresa Incompleto"
              this.errFlagRUTTrans = true
          }
          //
          if(field == 'RUTChofer'){
              this.errRUTChofer     = "RUT Chofer Incompleto"
              this.errFlagRUTChofer = true
          }
          return false;
      }
        
      //////////////////////////////////////////////////        
      let rutTmp = this._fn._chkRut(rut)
      console.log(rutTmp)
      //////////////////////////////////////////////////  
      if(rutTmp.status == false){
          if(field == 'RUTTrans'){
              this.errRUTTrans     = "RUT Empresa Inválido"
              this.errFlagRUTTrans = true
              //this.transporteForm.get('RUTTrans').setValue(null);
          }
          //
          if(field == 'RUTChofer'){
              this.errRUTChofer     = "RUT Chofer Inválido"
              this.errFlagRUTChofer = true
              //this.transporteForm.get('RUTChofer').setValue(null);
          }
      }  
      else{
          if(field == 'RUTTrans'){
              this.transporteForm.get('RUTTrans').setValue(rutTmp.srtRutClean);
              this.errRUTTrans     = ""
              this.errFlagRUTTrans = false
          }
          //
          if(field == 'RUTChofer'){
              this.transporteForm.get('RUTChofer').setValue(rutTmp.srtRutClean);
              this.errRUTChofer     =""
              this.errFlagRUTChofer = false
          }            
      }
      //////////////////////////////////////////////////          
      return true; 
  }   
  }
*/
//====================================================
//====================================================
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
}