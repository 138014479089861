<div class="w-full py-2">
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!-- 
<div>
    <button mat-button>
        <mat-icon
            color="primary"
            matTooltip="Volver"
             (click)="btnBack()"
            >arrow_back</mat-icon
        >
    </button>
</div>
-->
<!-- ##########################################################  -->
<!-- ##########################################################  -->
<div class="w-full">
        <!-- ##########################################################  -->
        <div class="grid grid-cols-3">   
            <div >
                <!-- ## -->
                <div *ngIf="customerData.RAZON_SOCIAL != customerData.CUSTOMER_FANCY_NAME">
                           
                    
                    <h2 class="prose prose-xl"style="color: rgb(9, 9, 132)">
                        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  -->
                            <b *ngIf="customerData.SGTM_CUSTOMER == 'P' || customerData.SGTM_CUSTOMER == 'E'">{{customerData.RUT || '1-9' | rut:'DOTS_DASH'  }} </b> <b>{{ customerData.CUSTOMER_FANCY_NAME | titlecase}}</b>
                        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  -->                        
                    </h2>

                    <h2> {{ customerData.RAZON_SOCIAL | titlecase}}</h2>
                    
                </div>                 
                <div *ngIf="customerData.RAZON_SOCIAL == customerData.CUSTOMER_FANCY_NAME">
                    
                    
                    <h2 class="prose prose-xl"style="color: rgb(9, 9, 132)">
                        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  -->                        
                            <b *ngIf="customerData.SGTM_CUSTOMER == 'P' || customerData.SGTM_CUSTOMER == 'E'">{{customerData.RUT || '1-9' | rut:'DOTS_DASH'  }} </b> <b>{{ customerData.CUSTOMER_FANCY_NAME | titlecase}}</b>
                        <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  -->
                    </h2>
                </div>                
                <!-- ## -->
            </div>
        
            <div>
                <!-- ## -->
                <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button     (click)="editOrCreateCliente('add')" > <mat-icon class="icon-size-5" color="warn" [svgIcon]="'edit'"></mat-icon> </button>                                        
                <!-- ## -->
            </div>  
            <div>
                <!-- ## -->
                <button class="close" mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>         
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->

<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<mat-tab-group  [(selectedIndex)]="matTabGroupSelected"  (selectedTabChange)="refresh($event,null)" >
<!-- ///////////////////////////////////////////////////////////////////////////-->           
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->        
<mat-tab label="Informacion General" >
<!-- ================================================================== -->  
<div class="bg-white rounded-lg p-4">
        <!-- ##########################################################  -->
        <!-- ##########################################################  -->
        <div class="grid grid-cols-1">   
            <!--//-->                 
            <div class="prose prose-xl">                             
                
                <div *ngIf="customerData.SGTM_CUSTOMER == 'E'">
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:briefcase'"></mat-icon>
                        <div class="ml-6 leading-6">Giro: <b>{{customerData.GIRO_IMPRESO | titlecase}}</b></div>
                    </div>                    
                </div>  
                <div *ngIf="customerData.SGTM_CUSTOMER == 'X'">
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:briefcase'"></mat-icon>
                        <div class="ml-6 leading-6"  style="color: rgb(255, 0, 0)"> <b>Extranjero</b></div>
                    </div>                    
                </div>                 
                <div >
                    <div class="flex sm:items-center" *ngIf="customerData.SGTM_CUSTOMER == 'P' || customerData.SGTM_CUSTOMER == 'E'">
                        <mat-icon [svgIcon]="'heroicons_outline:location-marker'"></mat-icon>
                        <div class="ml-6 leading-6">Dirección: <b>{{customerData.DIRECCION | titlecase}}, {{customerData.GLS_COMUNA}},{{customerData.GLS_CIUDAD }}<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{customerData.GLS_REGION}}</b></div>
                       
                        
                    </div>                       
                </div>                   
            </div>
            <div class="flex sm:items-center" >
                <mat-icon [svgIcon]="'heroicons_outline:light-bulb'" style="color: rgb(30, 158, 7)"></mat-icon>
                <div class="ml-6 leading-6" *ngIf="customerData.FLAG_BLOQ_CUSTOMER == 'Y'" style="color: rgb(255, 0, 0)"><b>Cliente Activo:</b> <b>No</b></div>
                <div class="ml-6 leading-6" *ngIf="customerData.FLAG_BLOQ_CUSTOMER == 'N'" style="color: rgb(30, 158, 7)"><b>Cliente Activo:</b> <b>Si</b></div>
            </div>   
                <!-- //////////////////////-->
                <div class="flex sm:items-center" *ngIf="customerData.CUSTOMER_CSAT <= 4" style="color: rgb(132, 9, 9)">
                    <mat-icon [svgIcon]="'heroicons_outline:emoji-sad'" style="color: rgb(132, 9, 9)"></mat-icon>
                    <div class="ml-6 leading-6">ISN: <b>{{customerData.CUSTOMER_CSAT}} Insatisfecho </b></div>
                </div>  
                <div class="flex sm:items-center" *ngIf="customerData.CUSTOMER_CSAT == 5" style="color: rgb(248, 193, 11)">
                    <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'" style="color: rgb(248, 193, 11)"></mat-icon>
                    <div class="ml-6 leading-6">ISN: <b>{{customerData.CUSTOMER_CSAT}} Pasivo</b></div>
                </div>  
                <div class="flex sm:items-center" *ngIf="customerData.CUSTOMER_CSAT >= 6" style="color: rgb(27, 132, 9)">
                    <mat-icon [svgIcon]="'heroicons_outline:emoji-happy'" style="color: rgb(27, 132, 9)"></mat-icon>
                    <div class="ml-6 leading-6">ISN: <b>{{customerData.CUSTOMER_CSAT}} Satisfecho</b></div>
                </div>                                  
                <!-- //////////////////////-->               
        <br>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider></mat-divider>            
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
        <br>
        <div class="grid grid-cols-4">   
    
                <div >
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:at-symbol'"></mat-icon>
                        <div class="ml-6 leading-6">Email DTE: <b> <a class="hover:underline text-primary-500" [href]="'mailto:' + customerData.EMAIL_DTE" target="_blank"> {{customerData.EMAIL_DTE}}</a></b></div>
                    </div>                        
                </div>                              
                <div *ngIf="customerData.SGTM_CUSTOMER == 'E'">
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:globe'"></mat-icon>
                        <div class="ml-6 leading-6">Sitio Web: <b> <a class="hover:underline text-primary-500" [href]="'http://' +customerData.WEBSITE" target="_blank"> {{customerData.WEBSITE}}</a></b></div>

                    </div>                        
                </div>                                  
                <div >
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:office-building'"></mat-icon>
                        <div class="ml-6 leading-6" *ngIf="customerData.SGTM_CUSTOMER == 'E'">Segmento: <b>Empresas</b></div>
                        <div class="ml-6 leading-6" *ngIf="customerData.SGTM_CUSTOMER == 'P'">Segmento: <b>Personas</b></div>
                        <div class="ml-6 leading-6" *ngIf="customerData.SGTM_CUSTOMER == 'X'">Segmento: <b>Extranjero</b></div>
                    </div>                      
                </div>  
                <div >
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:phone'"></mat-icon>
                        <div class="ml-6 leading-6">Telefono: <b>{{customerData.TELEFONO_DTE}}</b></div>
                    </div>                        
                </div>                  
                <div >
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:star'"></mat-icon>
                        <div class="ml-6 leading-6">Valor Cliente: <b style="color: rgb(28, 154, 3)">{{customerData.NAME_CM_CUSTOMER_VALUE}}</b></div>
                    </div>                      
                </div> 
                <div>
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:currency-dollar'"></mat-icon>
                        <div class="ml-6 leading-6">Limite de Credito: <b>{{customerData.CREDIT_LIMIT | currency:'CLP':'symbol-narrow' }}</b></div>
                    </div>                        
                </div>  
                <div>
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:exclamation-circle'"></mat-icon>
                        <div class="ml-6 leading-6">Plazo de Vencimiento: <b>{{customerData.MAX_PAYMENT_DAYS }} Dias</b></div>
                    </div>                        
                </div>  
                <div>
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:receipt-tax'"></mat-icon>
                        <div class="ml-6 leading-6">DTE Predeterminado: <b>{{customerData.DESC_COD_REASON_CODE}}</b></div>
                    </div>                        
                </div>                                   
                <!--//-->    
                <!-- 
                <div  *ngIf="customerData.FLAG_SUPPLIER == 'Y'">
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:truck'"></mat-icon>
                        <div class="ml-6 leading-6" *ngIf="customerData.FLAG_BLOQ_SUPPLIER == 'Y'">Proveedor Activo: <b>Si</b></div>
                        <div class="ml-6 leading-6" *ngIf="customerData.FLAG_BLOQ_SUPPLIER == 'N'">Proveedor Activo: <b>No</b></div>
                    </div>                        
                </div>   
                -->
                <div>
                    <div class="flex sm:items-center">
                        <mat-icon [svgIcon]="'heroicons_outline:speakerphone'"></mat-icon>
                        <div class="ml-6 leading-6" *ngIf="customerData.FLAG_NEWSLETTER == 'Y'">NewsLetter : <b>Si</b></div>
                        <div class="ml-6 leading-6" *ngIf="customerData.FLAG_NEWSLETTER == 'N'">NewsLetter : <b>No</b></div>
                    </div>                        
                </div>                  
            </div>
            <!--//-->             
        </div>
        <!-- ##########################################################  -->
        <!-- ##########################################################  -->        
</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider></mat-divider>  
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="grid grid-cols-5">   
        <!--//-->            
        <div *ngIf="customerData.FLAG_CUSTOMER == 'Y'">Cliente: <b style="color: rgb(28, 154, 3)">Si</b></div>
        <div *ngIf="customerData.FLAG_CUSTOMER == 'N'">Cliente: <b>No</b></div>
        <!--//-->                                                   
        <div *ngIf="customerData.FLAG_SUPPLIER == 'Y' && customerData.FLAG_BLOQ_SUPPLIER == 'Y'">Proveedor: <b style="color: rgb(158, 15, 8)">Bloqueado</b></div>
        <div *ngIf="customerData.FLAG_SUPPLIER == 'Y' && customerData.FLAG_BLOQ_SUPPLIER == 'N'">Proveedor: <b style="color: rgb(28, 154, 3)">Si</b> </div>
        <div *ngIf="customerData.FLAG_SUPPLIER == 'N'">Proveedor: <b>No</b></div>        
        <!--//-->             
        <div *ngIf="customerData.FLAG_DISTRIBUTOR == 'Y'">Distribuidor: <b style="color: rgb(28, 154, 3)">Si</b></div>
        <div *ngIf="customerData.FLAG_DISTRIBUTOR == 'N'">Distribuidor: <b>No</b></div>
        <!--//-->   
        <div *ngIf="customerData.FLAG_EMPLOYEE == 'Y'">Colaborador: <b style="color: rgb(28, 154, 3)">Si</b></div>
        <div *ngIf="customerData.FLAG_EMPLOYEE == 'N'">Colaborador: <b>No</b></div>                      
        <!--//-->   
        <div *ngIf="customerData.FLAG_ASSOCIATE == 'Y'">Socio: <b style="color: rgb(28, 154, 3)">Si</b></div>
        <div *ngIf="customerData.FLAG_ASSOCIATE == 'N'">Socio: <b>No</b></div>                    
        <!--//-->                                     
    </div>
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->        
</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider></mat-divider>            
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="grid grid-cols-4">   
        <!--//-->                                                    
        <div>Casos: <b>{{customerData.COUNT_PENDINETES_CASOS | number }}/{{customerData.COUNT_TOTAL_CASOS | number }}</b></div>
        <!--//-->             
        <div>Notas: <b>{{customerData.COUNT_TOTAL_NOTAS | number }}</b></div>
        <!--//-->     
        <div  *ngIf="customerData.LAST_SHOP != '1900-01-01'">Ultima Compra: <b>{{customerData.LAST_SHOP | date : "dd-MM-yyyy" }}</b></div>
        <!--//-->    
        <div  *ngIf="customerData.ECOMMERCE_ACTIVE  == 'Y'">Registrado en E-Commerce: <b>Si</b></div>
        <div  *ngIf="customerData.ECOMMERCE_ACTIVE  == 'N'">Registrado en E-Commerce: <b>No</b></div>
        <!--//-->                                                  
    </div>
    <div *ngIf="customerData.EXTRANJERO == 'Y'" class="grid grid-cols-2">   
        <!--//-->                                                    
        <div>Pais: <b>{{customerData.GLS_NACIONALIDAD}}</b></div>

        <!--//-->                                                            
    </div>    
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->        
</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider></mat-divider>            
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<!-- 
<div class="bg-white rounded-lg p-4">
    <div class="grid grid-cols-4">   

        <div> <h2 class="mat-h2"  style="color: rgb(255, 157, 0)">Deuda Vigente: <b >{{customerData.NEXT_DUE | currency:'CLP':'symbol-narrow' }}</b></h2></div>

        <div> <h2 class="mat-h2"  style="color: rgb(255, 2, 2)">Deuda Vencida: <b >{{customerData.PAST_DUE | currency:'CLP':'symbol-narrow' }}</b></h2></div>


    </div>
</div>

<mat-divider></mat-divider>            

-->
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  --> 
    <!-- ##########################################################  -->
    <div class="grid grid-cols-2">   
        <!--//-->       
        <div>
            <!-- ================================================================== -->
            <h2 class="mat-h2" style="color: rgb(9, 9, 132)">Ventas: <b>{{customerData.GRAND_TOTAL  | currency:'CLP':'symbol-narrow'}}</b> Q:<b>{{customerData.Q_ORDERS | number}}</b></h2>
            <mat-table [dataSource]="tableSummary" class="mat-elevation-z8">
                <!--//-->                    
                <ng-container matColumnDef="year">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Periodo</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.YEAR_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                      
                <ng-container matColumnDef="count">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Q</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.COUNT_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                                               
                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Monto</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div style="color: rgb(9, 9, 132)"><b>{{o.TOTAL_SALES | currency:'CLP':'symbol-narrow'}}</b></div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->                
                <mat-header-row *matHeaderRowDef="displayedSummary" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedSummary" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingSummary" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagSummary
                [pageSize]="pagHome"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptionsHome" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->     
        <!-- ================================================================== -->
        </div>          
        <!--//-->          
        <div>           
            <!-- ================================================================== -->
            <h2 class="mat-h2" style="color: rgb(106, 0, 255)">Devoluciones: <b>{{customerData.GRAND_TOTAL_RETURN  | currency:'CLP':'symbol-narrow'}}</b> Q:<b>{{customerData.Q_ORDERS_RETURN | number}}</b></h2>
            <mat-table [dataSource]="tableReturn" class="mat-elevation-z8">
                <!--//-->                    
                <ng-container matColumnDef="year">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Periodo</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.YEAR_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                      
                <ng-container matColumnDef="count">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Q</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.COUNT_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                                               
                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Monto</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div style="color: rgb(106, 0, 255)"><b>{{o.TOTAL_SALES | currency:'CLP':'symbol-narrow'}}</b></div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->                
                <mat-header-row *matHeaderRowDef="displayedReturn" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedReturn" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingReturn" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagReturn
                [pageSize]="pagHome"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptionsHome" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->     
        <!-- ================================================================== -->
        </div>          
        <!--//-->                 
    </div>
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->       
</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider></mat-divider>            
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  --> 
    <!-- ##########################################################  -->
    <div class="grid grid-cols-2">   
        <!--//-->       
        <div>
            <!-- ================================================================== -->
            <h2 class="mat-h2" style="color: rgb(255, 157, 0)">Deuda Vigente: <b>{{customerData.NEXT_DUE  | currency:'CLP':'symbol-narrow'}}</b> Q:<b>{{customerData.Q_NEXT_DUE | number}}</b></h2>
            <mat-table [dataSource]="tableToDueSummary" class="mat-elevation-z8">
                <!--//-->                    
                <ng-container matColumnDef="year">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Periodo</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.YEAR_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                      
                <ng-container matColumnDef="count">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Q</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.COUNT_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                                               
                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Monto</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div style="color: rgb(255, 157, 0)"><b>{{o.TOTAL_SALES | currency:'CLP':'symbol-narrow'}}</b></div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->                
                <mat-header-row *matHeaderRowDef="displayedToDueSummary" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedToDueSummary" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingToDueSummary" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagToDueSummary
                [pageSize]="pagHome"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptionsHome" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->     
        <!-- ================================================================== -->
        </div>          
        <!--//-->          
        <div>           
            <!-- ================================================================== -->
            <h2 class="mat-h2" style="color: rgb(255, 0, 0)">Deuda Vencida: <b>{{customerData.PAST_DUE  | currency:'CLP':'symbol-narrow'}}</b> Q:<b>{{customerData.Q_PAST_DUE | number}}</b></h2>
            <mat-table [dataSource]="tableDueSummary" class="mat-elevation-z8">
                <!--//-->                    
                <ng-container matColumnDef="year">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Periodo</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.YEAR_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                      
                <ng-container matColumnDef="count">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Q</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.COUNT_SALES}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                                               
                <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Monto</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div style="color: rgb(255, 0, 0)"><b>{{o.TOTAL_SALES | currency:'CLP':'symbol-narrow'}}</b></div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->                
                <mat-header-row *matHeaderRowDef="displayedDueSummary" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedDueSummary" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingDueSummary" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagDueSummary
                [pageSize]="pagHome"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptionsHome" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->   
        <!-- ================================================================== -->
        </div>          
        <!--//-->                 
    </div>
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->       
</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider></mat-divider>            
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<div class="bg-white rounded-lg p-4" *ngIf="customerData.SGTM_CUSTOMER == 'E' || customerData.SGTM_CUSTOMER == 'P'">
    <!-- ##########################################################  -->
    <div class="flex  items-center ">
        <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event,'contact',null)" placeholder="Buscar..." #input [(ngModel)]="searchContact">
            <mat-icon matPrefix> find_in_page </mat-icon> 
        </mat-form-field> 

        <div class="flex justify-end"> <!-- createOrUpdateComponent('casos', 'create') create()-->
            <button  class="ml-4" mat-raised-button color="primary"  (click)="createContact('add')" >
                Crear Contacto
            </button>
        </div>            
    </div>   

    <!-- ##########################################################  -->
    <div class="grid grid-cols-1">   
        <!--//-->       
        <div>
            <!-- ================================================================== -->
            <mat-table [dataSource]="tableContact" class="mat-elevation-z8">
                <!--//-->                    
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Tipo</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.GLS_CONTACT_TYPE}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                        
                <ng-container matColumnDef="legalNumber">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small  (click)="updateContact(o)">{{o.LEGAL_NUMBER || '1-9' | rut:'DOTS_DASH'}}</small></mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Nombre</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                    <small>
                                        <!--$$-->
                                        <b (click)="updateContact(o)">
                                            <span  style="cursor: pointer;color: rgb(9, 9, 132)">{{o.FIRST_NAME}} {{o.LAST_NAME}}</span>
                                        </b>
                                        <!--$$-->
                                            <span *ngIf="o.LEGAL_NUMBER != '11111111-1'"> 
                                                <br>
                                                <b >{{o.LEGAL_NUMBER || '1-9' | rut:'DOTS_DASH'}}</b>
                                            </span>                                        
                                        <!--$$-->
                                        <span *ngIf="o.BIRTHDAY"> 
                                            <br>
                                            <b >Cumpleaños:{{o.BIRTHDAY | date : "dd-MM-yyyy"}}</b>
                                        </span>                                          
                                    </small>
                        </mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="lastName">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Apellido</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.LAST_NAME}} {{o.LAST_NAME2}}</small></mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="phone">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Telefono</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small *ngIf="o.PHONE != '56900000000'">{{o.PHONE}}</small></mat-cell>
                </ng-container>
                <!--//-->             
                <ng-container matColumnDef="mail">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Email</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div (click)="updateContact(o)" style="cursor: pointer;color: rgb(9, 9, 132)"><b>{{o.MAIL}}</b></div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->   
                <ng-container matColumnDef="birthday">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Cumpleaños</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.BIRTHDAY | date : "dd-MM-yyyy"}}</small></mat-cell>
                </ng-container>
                <!--//-->                       
                <ng-container matColumnDef="acc">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                    <mat-cell *matCellDef="let o">
                        <!-- //////////////////////////////////////////////////  -->
                            <div ><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="updateContact(o)"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'info'"></mat-icon> </button></div>
                            <div *ngIf="o.COD_CONTACT_TYPE == 'S'"><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="deleteContact(o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                        <!-- //////////////////////////////////////////////////  -->                                    
                    </mat-cell>
                </ng-container>
                <!--//-->               
                <mat-header-row *matHeaderRowDef="displayedContact" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedContact" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingContact" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagContact
                [pageSize]="pag"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptions" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->     
        <!-- ================================================================== -->
        </div>          
        <!--//-->             
    </div>
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->        
</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider></mat-divider>            
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->

<!-- ================================================================== -->
</mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<mat-tab label="Direcciones" >
    <!-- ================================================================== -->

        <div class="flex  items-center ">
            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event,'direcciones',null)" placeholder="Buscar..." #input [(ngModel)]="searchDireccion">
                <mat-icon matPrefix> find_in_page </mat-icon> 
            </mat-form-field> 

            <div class="flex justify-end"> <!-- createOrUpdateComponent('casos', 'create') create()-->
                <button  class="ml-4" mat-raised-button color="primary"  (click)="createOrUpdateComponent('direcciones', 'create')" >
                    Crear Dirección
                </button>
            </div>            
        </div>       
 
            <!-- ================================================================== -->
            <mat-table [dataSource]="tableDireccion" class="mat-elevation-z8">
                <!--//-->                        
                <ng-container matColumnDef="alias" >
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Alias</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b (click)="createOrUpdateComponent('direcciones', 'update', o)" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.ALIAS_CUSTOMER_ADDRESS}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                      
                <ng-container matColumnDef="default">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Predeterminada</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                                    <div *ngIf="o.FLAG_DEFAULT == 'Y'"><small>Si</small></div>
                                                    <div *ngIf="o.FLAG_DEFAULT == 'N'"><small>No</small></div>
                                            </mat-cell>
                </ng-container>
                <!--//-->                        
                <ng-container matColumnDef="address1">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Dirección</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small (click)="createOrUpdateComponent('direcciones', 'update', o)" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.ADDRESS1 | titlecase}} N°{{o.ADDRESS2}} <br> <span *ngIf="o.FLAG_ADDRESS_3 == 'Y'"> {{o.GLS_CUSTOMER_ADDRESS_TYPE}} {{o.ADDRESS3}}</span></small></mat-cell>
                </ng-container>
                <!--//-->                 
                <ng-container matColumnDef="adrress2">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Dirección linea 2</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.ADDRESS2}}</small></mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Región</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.GLS_REGION}}</small></mat-cell>
                </ng-container>
                <!--//-->             
                <ng-container matColumnDef="ciudad">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Provincia</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div >{{o.GLS_CIUDAD}}</div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->             
                <ng-container matColumnDef="comuna">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Comuna</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div ><b>{{o.GLS_COMUNA}}</b></div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->             
                <ng-container matColumnDef="telefono">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Teléfono</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div >{{o.PHONE_NUMBER}}</div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->             
                <ng-container matColumnDef="typeAddress">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Tipo</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div *ngIf="o.TYPE_ADDRESS == 'D'">Despacho</div>
                                <div *ngIf="o.TYPE_ADDRESS == 'O'">Otra</div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->                                                                                      
                <ng-container matColumnDef="acc">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                    <mat-cell *matCellDef="let o">
                        <!-- //////////////////////////////////////////////////  -->
                            <div ><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="createOrUpdateComponent('direcciones', 'update', o)"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'info'"></mat-icon> </button></div>
                            <div ><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="deleteMethod('direccion', o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                        <!-- //////////////////////////////////////////////////  -->                                    
                    </mat-cell>
                </ng-container>
                <!--//-->               
                <mat-header-row *matHeaderRowDef="displayedDireccion" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedDireccion" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingDireccion" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagDireccion
                [pageSize]="pag"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptions" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->     
        <!-- ================================================================== -->    
    <!-- ================================================================== -->
    </mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->   
<mat-tab label="Productos comprados" >
    <!-- ================================================================== -->
    
            <!--########################################################################-->
            <div class="flex">
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Mes a mostrar</mat-label>
                    <mat-select #monthSelect [(value)]="monthSelected">
                        <mat-option *ngFor="let month of months" [value]="month.value" (click)="ngOnInit()">
                            {{month.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
    
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Año a mostrar</mat-label>
                    <mat-select #monthSelect [(value)]="yearSelected">
                        <mat-option *ngFor="let year of last5Years" [value]="year" (click)="ngOnInit()">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
    
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event,'products',null)" placeholder="Buscar..." #input>
                    <mat-icon matPrefix> find_in_page </mat-icon> 
                </mat-form-field> 
           
            </div>       
    
        <!--########################################################################-->        
                <!-- ================================================================== -->
                <mat-table [dataSource]="tableOrderProducts" class="mat-elevation-z8">
                    <!--//-->                                          
                    <ng-container matColumnDef="cod">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">SKU</mat-header-cell>
                        <mat-cell *matCellDef="let o">
                            <b (click)="editOrCreateProduct(o.UUID_PS_SERVICES)" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.COD_PS_SERVICES}}</b>
                        </mat-cell>
                    </ng-container>
                    <!--//--> 
                    <ng-container matColumnDef="ean">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">EAN13</mat-header-cell>
                        <mat-cell *matCellDef="let o"><b>{{o.BARCODE}}</b></mat-cell>
                    </ng-container>
                    <!--//-->                                          
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Nombre</mat-header-cell>
                        <mat-cell *matCellDef="let o">{{o.NAME_PS_SERVICES}}</mat-cell>
                    </ng-container>                        
                    <!--//-->   
                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Cantidad</mat-header-cell>
                        <mat-cell *matCellDef="let o"><small>{{o.QUANTITY_PS_SERVICES | number }}</small></mat-cell>
                    </ng-container>
                    <!--//-->                                          
                    <ng-container matColumnDef="order">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Orden</mat-header-cell>
                        <mat-cell *matCellDef="let o">
                                                        <div (click)="orderDetail(o.UUID_EOM_ORDER)" style="cursor: pointer;color: rgb(9, 9, 132)"><b>{{o.FRIENLY_EOM_ORDER}}</b></div>
                        </mat-cell>
                    </ng-container>
                    <!--//-->    
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha</mat-header-cell>
                        <mat-cell *matCellDef="let o"><small>{{o.DATE_UPDATE  | date:'dd/MM/yyyy'}} </small></mat-cell>
                    </ng-container>
                    <!--//-->                                                                                                                
                    <ng-container matColumnDef="acc">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                        <mat-cell *matCellDef="let o">
                            <!-- //////////////////////////////////////////////////  -->
                                <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button   (click)="orderDetail(o.UUID_EOM_ORDER)"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'info'"></mat-icon> </button>  
                            <!-- //////////////////////////////////////////////////  -->                                    
                        </mat-cell>
                    </ng-container>
                    <!--//-->               
                    <mat-header-row *matHeaderRowDef="displayedOrderProducts" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                    <mat-row *matRowDef="let row;columns: displayedOrderProducts" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    <!--//-->                    
                </mat-table>
                    <!--//-->      
                    <mat-spinner *ngIf="isLoadingOrderProducts" style="margin:0 auto"></mat-spinner>         
                    <mat-paginator
                    #pagOrderProducts
                    [pageSize]="pag"
                    showFirstLastButtons
                    [pageSizeOptions]="pageSizeOptions" 
                    aria-label="Select page of periodic elements">
                </mat-paginator>               
                <!--//-->     
            <!-- ================================================================== -->
    <!-- ================================================================== -->
    </mat-tab>
    <!-- ///////////////////////////////////////////////////////////////////////////-->
    <!-- ///////////////////////////////////////////////////////////////////////////-->  
<mat-tab label="Casos" >
<!-- ================================================================== -->
        <!--########################################################################-->
        <div class="flex  items-center ">
            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event,'casos',null)" placeholder="Buscar..." #input [(ngModel)]="searchCasos">
                <mat-icon matPrefix> find_in_page </mat-icon> 
            </mat-form-field> 

            <div class="flex justify-end"> <!-- createOrUpdateComponent('casos', 'create') create()-->
                <button  class="ml-4" mat-raised-button color="primary" (click)="create()" >
                    Crear Caso
                </button>
            </div>            
        </div>       

    <!--########################################################################-->
            <!-- ================================================================== -->
            <mat-table [dataSource]="tableCasos" class="mat-elevation-z8">
                <!--//-->                    
                <ng-container matColumnDef="iconSLA">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">SLA</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                                    <mat-icon style="color: #008f39" *ngIf="o.SLA_LIGHT === 0">
                                                        flag
                                                    </mat-icon>

                                                    <mat-icon style="color: #e5be01" *ngIf="o.SLA_LIGHT === 1">
                                                        flag
                                                    </mat-icon>
                                                    <mat-icon style="color: #e01601" *ngIf="o.SLA_LIGHT === 2">
                                                        flag
                                                    </mat-icon>
                    </mat-cell>
                </ng-container>
                <!--//-->                        
                <ng-container matColumnDef="cod">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Caso</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b (click)="update(o)" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.COD_CUSTOMER_INTERACTION}}</b></mat-cell>
                </ng-container>
                <!--//--> 
                <ng-container matColumnDef="t1t2">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">T1 / T2</mat-header-cell>
                    <mat-cell *matCellDef="let o"><b>{{o.T1}} / {{o.T2}}</b></mat-cell>
                </ng-container>                        
                <!--//-->                        
                <ng-container matColumnDef="assignedTo">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Asignado</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.EMPLOYEE_FIRST_NAME}} {{o.EMPLOYEE_LAST_NAME}} </small></mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha de creación</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small> {{o.DATE_CREATION  | date:'dd/MM/yyyy'}}</small></mat-cell>
                </ng-container>
                <!--//-->   
                <ng-container matColumnDef="updateDate">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha resolucion</mat-header-cell>
                    <mat-cell *matCellDef="let o"><small>{{o.FLAG_END === 'N' ? 'Sin resolver' : o.DATE_CLOSE  | date:'dd/MM/yyyy'}}</small></mat-cell>
                </ng-container>
                <!--//-->             
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Estado</mat-header-cell>
                    <mat-cell *matCellDef="let o">
                                <div style="color: rgb(9, 9, 132)"><b>{{o.NAME_CM_INTERACTION_STATUS}}</b></div>
                        </mat-cell>
                </ng-container>                 
                <!--//-->                                                                                           
                <ng-container matColumnDef="acc">
                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                    <mat-cell *matCellDef="let o">
                        <!-- //////////////////////////////////////////////////  -->
                            <!-- ###############################  -->
                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  *ngIf="o.FLAG_END === 'N'" (click)="update(o)"> <mat-icon class="icon-size-5" color="primary"  [svgIcon]="'edit'"></mat-icon>   </button>                                        
                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  *ngIf="o.FLAG_END === 'Y'" (click)="update(o)"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'info'"></mat-icon> </button>   
                            <!-- ###############################  -->                            
                        <!-- //////////////////////////////////////////////////  -->                                    
                    </mat-cell>
                </ng-container>
                <!--//-->               
                <mat-header-row *matHeaderRowDef="displayedCasos" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                <mat-row *matRowDef="let row;columns: displayedCasos" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                <!--//-->                    
            </mat-table>
                <!--//-->      
                <mat-spinner *ngIf="isLoadingCasos" style="margin:0 auto"></mat-spinner>         
                <mat-paginator
                #pagCasos
                [pageSize]="pag"
                showFirstLastButtons
                [pageSizeOptions]="pageSizeOptions" 
                aria-label="Select page of periodic elements">
            </mat-paginator>               
            <!--//-->     
        <!-- ================================================================== -->
<!-- ================================================================== -->
</mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->        
<mat-tab label="Notas" >
<!-- ================================================================== -->
<div class="flex  items-center ">
    <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event,'notas',null)" placeholder="Buscar..." #input  [(ngModel)]="searchNotas">
        <mat-icon matPrefix> find_in_page </mat-icon> 
    </mat-form-field> 

    <div class="flex justify-end">
        <button class="ml-2"  mat-raised-button color="primary" (click)="createOrUpdateComponent('notas', 'create')" >
            Crear Nota
        </button> 
    </div>         
</div>  


            <!-- ================================================================== -->
                <mat-table [dataSource]="tableNotas" class="mat-elevation-z8">
                    <!--//-->
                    <ng-container matColumnDef="createdBy">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Creado</mat-header-cell>
                        <mat-cell *matCellDef="let o"><b>{{o.EMPLOYEE_FIRST_NAME}} {{o.EMPLOYEE_LAST_NAME}}</b></mat-cell>
                    </ng-container>                        
                    <!--//-->                        
                    <ng-container matColumnDef="reminder">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Tipo</mat-header-cell>
                        <mat-cell *matCellDef="let o">
                                                        <small><div style="color: rgb(219, 191, 11)" *ngIf="o.FLAG_REMINDERS == 'F'"><b>Flash</b></div></small>
                                                        <small><div style="color: rgb(57, 159, 10)"  *ngIf="o.FLAG_REMINDERS == 'Y'"><b>Recordatorio</b></div></small>
                                                        <small><div style="color: rgb(117, 9, 218)"    *ngIf="o.FLAG_REMINDERS == 'N'"><b>Nota</b></div></small>
                        </mat-cell>
                    </ng-container>
                    <!--//-->   
                    <ng-container matColumnDef="scheduleAt">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Programado</mat-header-cell>
                        <mat-cell *matCellDef="let o">
                                {{o.FLAG_REMINDERS === "Y"? (o.DATE_SCHEDULE| date : "dd/MM/yyyy"): ""}}
                        </mat-cell>
                    </ng-container>
                    <!--//-->   
                    <ng-container matColumnDef="createdAt">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha</mat-header-cell>
                        <mat-cell *matCellDef="let o"> {{ o.DATE_CREATION | date : "dd/MM/yy" }}</mat-cell>
                    </ng-container>
                    <!--//-->             
                    <ng-container matColumnDef="txt">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Nota</mat-header-cell>
                        <mat-cell *matCellDef="let o">
                                    <small><div style="color: rgb(9, 9, 132)"><b>{{o.TXT_CUSTOMER_NOTES}}</b></div></small>
                            </mat-cell>
                    </ng-container>                 
                    <!--//-->                       
                    <ng-container matColumnDef="view">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                        <mat-cell *matCellDef="let o">
                            <!-- //////////////////////////////////////////////////  -->
                                <div><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="deleteMethod('nota', o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button></div>
                            <!-- //////////////////////////////////////////////////  -->                                    
                        </mat-cell>
                    </ng-container>
                    <!--//-->               
                    <mat-header-row *matHeaderRowDef="displayedNotas" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                    <mat-row *matRowDef="let row;columns: displayedNotas" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    <!--//-->                    
                </mat-table>
                    <!--//-->      
                    <mat-spinner *ngIf="isLoadingNotas" style="margin:0 auto"></mat-spinner>         
                    <mat-paginator
                    #pagNotas
                    [pageSize]="pag"
                    showFirstLastButtons
                    [pageSizeOptions]="pageSizeOptions" 
                    aria-label="Select page of periodic elements">
                </mat-paginator>               
                <!--//-->     
            <!-- ================================================================== -->
<!-- ================================================================== -->
</mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->     
<mat-tab label="Cotizaciones" >
    <!-- ================================================================== -->
            <!--########################################################################-->
            <div class="flex">
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Mes a mostrar</mat-label>
                    <mat-select #monthSelect [(value)]="monthSelected">
                        <mat-option *ngFor="let month of months" [value]="month.value" (click)="ngOnInit()">
                            {{month.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
    
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Año a mostrar</mat-label>
                    <mat-select #monthSelect [(value)]="yearSelected">
                        <mat-option *ngFor="let year of last5Years" [value]="year" (click)="ngOnInit()">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
    
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event,'orderCtz',null)" placeholder="Buscar..." #input [(ngModel)]="searchOrderCtz">
                    <mat-icon matPrefix> find_in_page </mat-icon> 
                </mat-form-field> 
            </div>        
        <!--########################################################################-->
         
                <div>
                    <h2 class="mat-h2">Cotizaciones</h2>
                    <mat-table [dataSource]="tableOrderCtz" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="FRIENLY_EOM_CTZ">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Cotización</mat-header-cell>
                            <mat-cell *matCellDef="let s"><b (click)="ctzDetail(s.UUID_EOM_CTZ)" style="cursor: pointer;color: rgb(9, 9, 132)">{{s.FRIENLY_EOM_CTZ}}</b></mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="TOTAL">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Total</mat-header-cell>
                            <mat-cell *matCellDef="let s">
                                            <!-- ////////////////////////////////////////////////-->
                                                <small *ngIf="s.COD_EOM_CTZ_STATUS =='EXPIRED' || s.COD_EOM_CTZ_STATUS =='REMOVE'">
                                                    <mat-icon class="text-red-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                                    {{s.TOTAL | currency:'CLP':'symbol-narrow'}} 
                                                </small>
                                                <small *ngIf="s.COD_EOM_CTZ_STATUS =='INVOICED'">
                                                    <mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                                    {{s.TOTAL | currency:'CLP':'symbol-narrow'}} 
                                                </small>   
                                                <small *ngIf="s.COD_EOM_CTZ_STATUS =='OPEN' || s.COD_EOM_CTZ_STATUS =='DRAF'">
                                                    <mat-icon class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                                    {{s.TOTAL | currency:'CLP':'symbol-narrow'}} 
                                                </small>                                                                                               
                                            <!-- ////////////////////////////////////////////////-->                                                                                     
                            </mat-cell>
                        </ng-container>                        
                        <!--//-->     
                        <ng-container matColumnDef="GLS_EOM_CTZ_STATUS">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Estado</mat-header-cell>
                            <mat-cell *matCellDef="let s">{{s.GLS_EOM_CTZ_STATUS | titlecase}}</mat-cell>
                        </ng-container>
                        <!--//-->                       
                        <ng-container matColumnDef="CUSTOMER">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Cliente</mat-header-cell>
                            <mat-cell *matCellDef="let s"><b>{{s.RUT  || '1-9' | rut:'DOTS_DASH' }} {{s.RAZON_SOCIAL | titlecase}}</b></mat-cell>
                        </ng-container>
                        <!--//-->             
                        <ng-container matColumnDef="DATE_CREATION">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Creación</mat-header-cell>
                            <mat-cell *matCellDef="let s">{{s.DATE_CREATION | date:'dd/MM/yy'}}</mat-cell>
                        </ng-container>
                        <!--//-->                        
                        <ng-container matColumnDef="DUE_DATE">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">                              Vencimiento</mat-header-cell>
                            <mat-cell *matCellDef="let s">{{s.DATE_DUE_DAY | date:'dd/MM/yy'}}</mat-cell>
                        </ng-container>                    
                        <!--//-->                                              
                        <ng-container matColumnDef="EMPLOYEE">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Colaborador</mat-header-cell>
                            <mat-cell *matCellDef="let s">{{s.EMPLOYEE_FIRST_NAME }} {{s.EMPLOYEE_LAST_NAME }} {{s.EMPLOYEE_LAST_NAME2 }}</mat-cell>
                        </ng-container>                    
                        <!--//-->  
                        <ng-container matColumnDef="UUID_EOM_CTZ">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                            <mat-cell *matCellDef="let s">
                                    <!-- ////////////////////////////////////////////////--> 
                                            <div><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                                                                       (click)="ctzDetail(s.UUID_EOM_CTZ)" >     <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button> </div>  
                                            <div><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button [disabled]=" s.UUID_EOM_ORDER =='0'"       (click)="orderDetail(s.UUID_EOM_ORDER)">  <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button> </div>                                     
                                            <div *ngIf="s.COD_EOM_CTZ_STATUS =='INVOICED' || s.COD_EOM_CTZ_STATUS =='OPEN'"><button mat-button matTooltip="Vista previa PDF" (click)="openPdf(s.UUID_DTE,'CTZ')"> <mat-icon> visibility </mat-icon> </button>       </div>  
                                            <div *ngIf="s.COD_EOM_CTZ_STATUS =='INVOICED' || s.COD_EOM_CTZ_STATUS =='OPEN'"><button mat-button matTooltip="Descargar PDF" (click)="downloadFile(s.UUID_DTE,'CTZ')"><mat-icon> file_download </mat-icon> </button>   </div>                                                
                                    <!-- ////////////////////////////////////////////////-->                               
                            </mat-cell>
                        </ng-container>                    
                        <!--//-->               
                        <mat-header-row *matHeaderRowDef="displayedOrderCtz" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: displayedOrderCtz" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoadingOrderCtz" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagOrderCtz
                        [pageSize]="pag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div> 
    <!-- ================================================================== -->
    </mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->     
<mat-tab label="Factura Recurrente" >
<!-- ================================================================== -->
        <!--########################################################################-->
        <div class="flex  items-center ">
            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Buscar</mat-label> 
                <input matInput (keyup)="applyFilter($event,'rc',null)" placeholder="Buscar..." #input [(ngModel)]="searchRc">
                <mat-icon matPrefix> find_in_page </mat-icon> 
            </mat-form-field> 

            <div class="flex justify-end"> <!-- createOrUpdateComponent('casos', 'create') create()-->
                <button class="ml-4" mat-raised-button color="primary" (click)="createOOFF()" >
                    Crear OF
                </button>
            </div>            
        </div>           
    <!--########################################################################-->
    <!--########################################################################-->
    <div>
       
        <mat-table [dataSource]="tableRc" class="mat-elevation-z8">
            <!--//-->                    
            <ng-container matColumnDef="cod_ooff">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">OF</mat-header-cell>
                <mat-cell *matCellDef="let s"><small><b (click)="viewOOFF(s.UUID_RC,s.UUID_CRM_CUSTOMER,s.RUT,s.CUSTOMER_FANCY_NAME)" style="cursor: pointer;color: rgb(9, 9, 132)">{{s.COD_OOFF}}-{{s.VERSION_COD_OOFF}}</b><br>{{s.GLS_DOC}}</small></mat-cell>
            </ng-container>
            <!--//-->     
            <ng-container matColumnDef="recurring_day">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Dia de Emisión</mat-header-cell>
                <mat-cell *matCellDef="let s">{{s.RECURRING_DAY}}</mat-cell>
            </ng-container>
            <!--//-->    
            <ng-container matColumnDef="status_rc">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Estado</mat-header-cell>
                <mat-cell *matCellDef="let s">
                                <!-- ////////////////////////////////////////////////-->
                                            <mat-icon *ngIf="s.STATUS_RC == 'I'" class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                                            <mat-icon *ngIf="s.STATUS_RC == 'A'" class="text-green-400  icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>                                                                  
                                <!-- ////////////////////////////////////////////////-->                                            
                </mat-cell>
            </ng-container>                        
            <!--//-->     
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Tipo</mat-header-cell>
                <mat-cell *matCellDef="let s">
                                <!-- ////////////////////////////////////////////////-->
                                    <div *ngIf="s.FLAG_INSTALLMENTS == 'Y'">Cuota:<br><b>{{s.NUMBER_INSTALLMENTS}}/{{s.TOTAL_INSTALLMENTS}}</b></div>
                                    <div *ngIf="s.FLAG_INSTALLMENTS == 'N'">Periodo:<br><b>{{s.DATE_START | date:'dd/MM/yy' }} al {{s.DATE_END| date:'dd/MM/yy'}}</b></div>
                                <!-- ////////////////////////////////////////////////-->    
                </mat-cell>
            </ng-container>
            <!--//-->                       
            <ng-container matColumnDef="count_child">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Q Items</mat-header-cell>
                <mat-cell *matCellDef="let s"><b>{{s.COUNT_CHILD | number}}</b></mat-cell>
            </ng-container>
            <!--//-->          
            <ng-container matColumnDef="date_creation">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Creación</mat-header-cell>
                <mat-cell *matCellDef="let s">{{s.DATE_CREATE | date:'dd/MM/yy'}}</mat-cell>
            </ng-container>
            <!--//-->                        
            <ng-container matColumnDef="date_update">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Actualización</mat-header-cell>
                <mat-cell *matCellDef="let s">{{s.DATE_UPDATE | date:'dd/MM/yy'}}</mat-cell>
            </ng-container>                    
        <!--//-->                        
        <ng-container matColumnDef="INSTALLMENTS">
            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Emitidas</mat-header-cell>
            <mat-cell *matCellDef="let s"><b>{{s.NUMBER_INSTALLMENTS}}</b></mat-cell>
        </ng-container>                    
        <!--//-->                        
        <ng-container matColumnDef="LAST_OOFF">
            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">última OF</mat-header-cell>
            <mat-cell *matCellDef="let s">
                                            <span *ngIf="s.LAST_OOFF != '1900-01-01'"><b>{{s.LAST_OOFF | date:'dd/MM/yy'}}</b></span>

                                    </mat-cell>
        </ng-container>                    
        <!--//--> 
            <ng-container matColumnDef="acc">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                <mat-cell *matCellDef="let s">
                                <!-- ////////////////////////////////////////////////--> 
                                    <div >
                                        <!-- 
                                        <button mat-raised-button color="warn"    [disabled]="s.COD_REASON_CODE !='FN'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:emoji-sad" ></mat-icon></button>
                                        <button mat-raised-button color="primary" (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:clipboard-list" ></mat-icon></button>
                                        -->
                                     <!--    <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  [disabled]="s.COD_REASON_CODE !='FN'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="warn"    [svgIcon]="'edit'"></mat-icon>   </button>    -->
                                         <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                                        (click)="viewOOFF(s.UUID_RC,s.UUID_CRM_CUSTOMER,s.RUT,s.CUSTOMER_FANCY_NAME)">  <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>
                                    </div>                                          
                                <!-- ////////////////////////////////////////////////-->                               
                </mat-cell>
            </ng-container>                    
            <!--//-->               
            <mat-header-row *matHeaderRowDef="displayedRC" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
            <mat-row *matRowDef="let row;columns: displayedRC" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
            <!--//-->                    
        </mat-table>
            <!--//-->      
            <mat-spinner *ngIf="isLoadingRc" style="margin:0 auto"></mat-spinner>         
            <mat-paginator
            #pagRc
            [pageSize]="pag"
            showFirstLastButtons
            [pageSizeOptions]="pageSizeOptions" 
            aria-label="Select page of periodic elements">
        </mat-paginator>               
            <!--//-->                
    </div> 
<!-- ================================================================== -->


<!-- ================================================================== -->
</mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->  
<mat-tab label="Ordenes" >
<!-- ================================================================== -->
        <!--########################################################################-->
        <div class="flex">
            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Mes a mostrar</mat-label>
                <mat-select #monthSelect [(value)]="monthSelected">
                    <mat-option *ngFor="let month of months" [value]="month.value" (click)="ngOnInit()">
                        {{month.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field> 

            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Año a mostrar</mat-label>
                <mat-select #monthSelect [(value)]="yearSelected">
                    <mat-option *ngFor="let year of last5Years" [value]="year" (click)="ngOnInit()">
                        {{year}}
                    </mat-option>
                </mat-select>
            </mat-form-field> 

            <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event,'order',null)" placeholder="Buscar..." #input  [(ngModel)]="searchOrder">
                <mat-icon matPrefix> find_in_page </mat-icon> 
            </mat-form-field> 
        </div> 
        <mat-tab-group #tabsDocs  [(selectedIndex)]="matTabDteSelected"  (selectedTabChange)="refreshDTE($event,null)" >
            <!--########################################################################-->
                <mat-tab label="Ordenes" >
                    <!-- ================================================================== -->
                        <div>
                            <h2 class="mat-h2">Ordenes</h2>
                            <mat-table [dataSource]="tableOrder" class="mat-elevation-z8">
                                <!--//-->                    
                                <ng-container matColumnDef="FRIENLY_EOM_ORDER">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Orden</mat-header-cell>
                                    <mat-cell *matCellDef="let s"><b  (click)="orderDetail(s.UUID_EOM_ORDER)" style="cursor: pointer;color: rgb(9, 9, 132)">{{s.FRIENLY_EOM_ORDER}}</b></mat-cell>
                                </ng-container>
                                <!--//-->
                                <ng-container matColumnDef="TOTAL">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Total</mat-header-cell>
                                    <mat-cell *matCellDef="let s">
                                                    <!-- ////////////////////////////////////////////////-->
                                                        <small *ngIf="s.FLAG_PAYMENT == 'Y'">
                                                            <div *ngIf="s.COD_REASON_CODE == 'AO'">
                                                                <mat-icon class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                                                                <b>{{s.TOTAL | currency:'CLP':'symbol-narrow'}}</b>
                                                            </div>  
                                                            <div *ngIf="s.COD_REASON_CODE != 'AO'">
                                                                <mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                                                                <b>{{s.TOTAL | currency:'CLP':'symbol-narrow'}}</b>
                                                            </div>                                                                     
                                                        </small>
                                                        <small *ngIf="s.FLAG_PAYMENT != 'Y'">
                                                            <mat-icon class="text-red-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                                            {{s.TOTAL | currency:'CLP':'symbol-narrow'}} 
                                                        </small>
                                                    <!-- ////////////////////////////////////////////////-->                                            
                                    </mat-cell>
                                </ng-container>                        
                                <!--//-->     
                                <ng-container matColumnDef="NAME_COD_REASON_CODE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Estado</mat-header-cell>
                                    <mat-cell *matCellDef="let s">{{s.NAME_COD_REASON_CODE | titlecase}}</mat-cell>
                                </ng-container>
                                <!--//-->                       
                                <ng-container matColumnDef="COD_SP">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Punto Venta</mat-header-cell>
                                    <mat-cell *matCellDef="let s"><b>{{s.COD_SP}} {{s.NAME_SP | titlecase}}</b></mat-cell>
                                </ng-container>
                                <!--//-->             
                                <ng-container matColumnDef="DATE_CREATION">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha Creación</mat-header-cell>
                                    <mat-cell *matCellDef="let s">{{s.DATE_CREATION | date:'dd/MM/yy hh:mm'}}</mat-cell>
                                </ng-container>
                                <!--//-->                        
                                <ng-container matColumnDef="DATE_UPDATE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Fecha Actualizacion</mat-header-cell>
                                    <mat-cell *matCellDef="let s">{{s.DATE_UPDATE | date:'dd/MM/yy hh:mm'}}</mat-cell>
                                </ng-container>                    
                                <!--//-->                        
                                <ng-container matColumnDef="RUT">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Cliente</mat-header-cell>
                                    <mat-cell *matCellDef="let s">{{s.RUT | rut:'DOTS_DASH' }}</mat-cell>
                                </ng-container>                    
                                <!--//-->                        
                                <ng-container matColumnDef="EMPLOYEE">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Colaborador</mat-header-cell>
                                    <mat-cell *matCellDef="let s">{{s.EMPLOYEE_FIRST_NAME }} {{s.EMPLOYEE_LAST_NAME }} {{s.EMPLOYEE_LAST_NAME2 }}</mat-cell>
                                </ng-container>                    
                                <!--//-->  
                                <ng-container matColumnDef="UUID_EOM_ORDER">
                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                                    <mat-cell *matCellDef="let s">
                                                    <!-- ////////////////////////////////////////////////--> 
                                                        <div *ngIf="s.COD_REASON_CODE !='AO'">
                                                            <!-- 
                                                            <button mat-raised-button color="warn"    [disabled]="s.COD_REASON_CODE !='FN'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:emoji-sad" ></mat-icon></button>
                                                            <button mat-raised-button color="primary" (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:clipboard-list" ></mat-icon></button>
                                                            -->
                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  [disabled]="s.COD_REASON_CODE !='FN'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="warn"    [svgIcon]="'edit'"></mat-icon>   </button>                                        
                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                                        (click)="orderDetail(s.UUID_EOM_ORDER)">  <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>                                            
                                                        </div>
                                                        <div *ngIf="s.COD_REASON_CODE =='AO'">
                                                            <!--
                                                            <button  mat-raised-button color="warn" disabled="true"   (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:emoji-sad" ></mat-icon></button>
                                                            <button mat-raised-button color="primary" (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:clipboard-list" ></mat-icon></button>
                                                            -->
                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  disabled="true"   (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="warn" [svgIcon]="'edit'"></mat-icon> </button>                                        
                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                    (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>                                               
                                                        </div>                                              
                                                    <!-- ////////////////////////////////////////////////-->                               
                                    </mat-cell>
                                </ng-container>                    
                                <!--//-->               
                                <mat-header-row *matHeaderRowDef="displayedOrder" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                                <mat-row *matRowDef="let row;columns: displayedOrder" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                                <!--//-->                    
                            </mat-table>
                                <!--//-->      
                                <mat-spinner *ngIf="isLoadingOrder" style="margin:0 auto"></mat-spinner>         
                                <mat-paginator
                                #pagOrder
                                [pageSize]="pag"
                                showFirstLastButtons
                                [pageSizeOptions]="pageSizeOptions" 
                                aria-label="Select page of periodic elements">
                            </mat-paginator>               
                                <!--//-->                
                        </div> 
                    <!-- ================================================================== -->
                </mat-tab>
                <mat-tab label="Pendientes Pago" >
                    <!-- ================================================================== -->
                                        <div>
                                            <h2 class="mat-h2">Pendientes Pago</h2>
                                            <mat-table [dataSource]="tableOrderDue" class="mat-elevation-z8">
                                                <!--//-->                    
                                                <ng-container matColumnDef="FRIENLY_EOM_ORDER">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Orden</mat-header-cell>
                                                    <mat-cell *matCellDef="let s"><b (click)="orderDetail(s.UUID_EOM_ORDER)" style="cursor: pointer;color: rgb(9, 9, 132)">{{s.FRIENLY_EOM_ORDER}}</b></mat-cell>
                                                </ng-container>
                                                <!--//-->
                                                <ng-container matColumnDef="TOTAL">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Total</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">
                                                                    <!-- ////////////////////////////////////////////////-->
                                                                        <small *ngIf="s.FLAG_PAYMENT == 'Y'">
                                                                            <div *ngIf="s.COD_REASON_CODE == 'AO'">
                                                                                <mat-icon class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                                                                                <b>{{s.TOTAL | currency:'CLP':'symbol-narrow'}}</b>
                                                                            </div>  
                                                                            <div *ngIf="s.COD_REASON_CODE != 'AO'">
                                                                                <mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                                                                                <b>{{s.TOTAL | currency:'CLP':'symbol-narrow'}}</b>
                                                                            </div>                                                                     
                                                                        </small>
                                                                        <small *ngIf="s.FLAG_PAYMENT != 'Y'">
                                                                            <mat-icon class="text-red-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                                                            {{s.TOTAL | currency:'CLP':'symbol-narrow'}} 
                                                                        </small>
                                                                    <!-- ////////////////////////////////////////////////-->                                            
                                                    </mat-cell>
                                                </ng-container>                        
                                                <!--//-->     
                                                <ng-container matColumnDef="NAME_COD_REASON_CODE">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Estado</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">{{s.NAME_COD_REASON_CODE | titlecase}}</mat-cell>
                                                </ng-container>
                                                <!--//-->                       
                                                <ng-container matColumnDef="COD_SP">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Punto Venta</mat-header-cell>
                                                    <mat-cell *matCellDef="let s"><b>{{s.COD_SP}} {{s.NAME_SP | titlecase}}</b></mat-cell>
                                                </ng-container>
                                                <!--//-->             
                                                <ng-container matColumnDef="DATE_CREATION">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Fecha Creación</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">{{s.DATE_CREATION | date:'dd/MM/yy hh:mm'}}</mat-cell>
                                                </ng-container>
                                                <!--//-->                        
                                                <ng-container matColumnDef="DATE_UPDATE">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Fecha Actualizacion</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">{{s.DATE_UPDATE | date:'dd/MM/yy hh:mm'}}</mat-cell>
                                                </ng-container>                    
                                                <!--//-->                        
                                                <ng-container matColumnDef="DUE_DATE">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Fecha Vencimiento</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">{{s.DATE_DUE_DAY | date:'dd/MM/yy'}}</mat-cell>
                                                </ng-container>                    
                                                <!--//-->                        
                                                <ng-container matColumnDef="DUE_DATE_DAYS">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Dias Mora</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">
                                                                                        <div *ngIf="s.DAYS_DUE_DAY > 0 "  ><b style="color: rgb(169, 8, 8)"  >{{s.DAYS_DUE_DAY | number}}</b></div> 
                                                                                        <div *ngIf="s.DAYS_DUE_DAY == 0 " ><b style="color: rgb(239, 161, 5)">{{s.DAYS_DUE_DAY | number}}</b></div> 
                                                                                        <div *ngIf="s.DAYS_DUE_DAY < 0"   ><b style="color: rgb(28, 154, 3)" >{{s.DAYS_DUE_DAY | number}}</b></div>                                                                 
                                                    </mat-cell>
                                                </ng-container>                    
                                                <!--//-->                     
                                                <ng-container matColumnDef="RUT">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Cliente</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">{{s.RUT | rut:'DOTS_DASH' }}</mat-cell>
                                                </ng-container>                    
                                                <!--//-->                        
                                                <ng-container matColumnDef="EMPLOYEE">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Colaborador</mat-header-cell>
                                                    <mat-cell *matCellDef="let s">{{s.EMPLOYEE_FIRST_NAME }} {{s.EMPLOYEE_LAST_NAME }} {{s.EMPLOYEE_LAST_NAME2 }}</mat-cell>
                                                </ng-container>                    
                                                <!--//-->  
                                                <ng-container matColumnDef="UUID_EOM_ORDER">
                                                    <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                                                    <mat-cell *matCellDef="let s">
                                                                    <!-- ////////////////////////////////////////////////--> 
                                                                        <div *ngIf="s.COD_REASON_CODE !='AO'">
                                                                            <!-- 
                                                                            <button mat-raised-button color="warn"    [disabled]="s.COD_REASON_CODE !='FN'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:emoji-sad" ></mat-icon></button>
                                                                            <button mat-raised-button color="primary" (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:clipboard-list" ></mat-icon></button>
                                                                            -->
                                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  [disabled]="s.COD_REASON_CODE !='FN' || s.COD_STATUS_DTE != 'OK'" (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="warn"    [svgIcon]="'edit'"></mat-icon>   </button>                                        
                                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                                        (click)="orderDetail(s.UUID_EOM_ORDER)">  <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>                                            
                                                                        </div>
                                                                        <div *ngIf="s.COD_REASON_CODE =='AO'">
                                                                            <!--
                                                                            <button  mat-raised-button color="warn" disabled="true"   (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:emoji-sad" ></mat-icon></button>
                                                                            <button mat-raised-button color="primary" (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon  svgIcon="heroicons_solid:clipboard-list" ></mat-icon></button>
                                                                            -->
                                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  disabled="true"   (click)="returnOrder(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="warn" [svgIcon]="'edit'"></mat-icon> </button>                                        
                                                                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                    (click)="orderDetail(s.UUID_EOM_ORDER)" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>                                               
                                                                        </div>                                                                                    
                                                                    <!-- ////////////////////////////////////////////////-->                               
                                                    </mat-cell>
                                                </ng-container>                    
                                                <!--//-->               
                                                <mat-header-row *matHeaderRowDef="displayedOrderDue" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                                                <mat-row *matRowDef="let row;columns: displayedOrderDue" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                                                <!--//-->                    
                                            </mat-table>
                                                <!--//-->      
                                                <mat-spinner *ngIf="isLoadingOrderDue" style="margin:0 auto"></mat-spinner>         
                                                <mat-paginator
                                                #pagOrderDue
                                                [pageSize]="pag"
                                                showFirstLastButtons
                                                [pageSizeOptions]="pageSizeOptions" 
                                                aria-label="Select page of periodic elements">
                                            </mat-paginator>               
                                                <!--//-->                
                                        </div> 
                            <!-- ================================================================== -->
                            </mat-tab>
                    <!-- ================================================================== -->
          </mat-tab-group>      
<!-- ================================================================== -->
</mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->  
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ================================================================== -->     
<mat-tab label="Documentos" >
<!-- ================================================================== -->
        <!--########################################################################-->
            <div class="flex">
                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Mes a mostrar</mat-label>
                    <mat-select #monthSelect [(value)]="monthSelected">
                        <mat-option *ngFor="let month of months" [value]="month.value" (click)="ngOnInit()">
                            {{month.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Año a mostrar</mat-label>
                    <mat-select #monthSelect [(value)]="yearSelected">
                        <mat-option *ngFor="let year of last5Years" [value]="year" (click)="ngOnInit()">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event,'dte',null)" placeholder="Buscar..." #input [(ngModel)]="searchDte">
                    <mat-icon matPrefix> find_in_page </mat-icon> 
                </mat-form-field> 
            </div>        
        <!--########################################################################-->
    <mat-tab-group #tabsDocs  [(selectedIndex)]="matTabDteSelected"  (selectedTabChange)="refreshDTE($event,null)" >
        <!--########################################################################-->
            <mat-tab label="DTE" >
                <!-- ================================================================== -->
                    <!--//-->
                    <mat-table [dataSource]="tableDte" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="folio">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Folio</mat-header-cell>
                            <mat-cell *matCellDef="let o"><b (click)="openPdf(o.UUID_DTE,'DTE')" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.FOLIO | number}}</b></mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="fechaEmision">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Fecha Emision</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.FECHA_EMISION | date:'dd/MM/yy'}}</mat-cell>
                        </ng-container>                        
                        <!--//-->     
                        <ng-container matColumnDef="typeDoc">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Tipo Doc</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.GLS | titlecase}}</mat-cell>
                        </ng-container>
                        <!--//-->                       
                        <ng-container matColumnDef="emitedTo">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Emitido a</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.RAZON_SOCIAL | titlecase}}</small></mat-cell>
                        </ng-container>
                        <!--//-->             
                        <ng-container matColumnDef="rutRecep">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut Receptor</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.RUT | rut:'DOTS_DASH'}}</mat-cell>
                        </ng-container>
                        <!--//-->                        
                        <ng-container matColumnDef="tlt">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Total</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <div *ngIf="o.COD_DOC == '61'"><p style="color: red"><b>{{o.MNTTOTAL ||  0 | currency:'CLP':'symbol-narrow'}}</b></p></div>
                                <div *ngIf="o.COD_DOC != '61'"><p style="color: rgb(4, 9, 103)"><b>{{o.MNTTOTAL ||  0 | currency:'CLP':'symbol-narrow'}}</b></p></div>
                            </mat-cell>
                        </ng-container>                    
                        <!--//-->      
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <button mat-button matTooltip="Vista previa PDF" (click)="openPdf(o.UUID_DTE,'DTE')"> <mat-icon> visibility </mat-icon> </button>
                                <button mat-button matTooltip="Descargar PDF" (click)="downloadFile(o.UUID_DTE,'DTE')"> <mat-icon> file_download </mat-icon> </button>
                                <button mat-button matTooltip="Descargar XML" (click)="downloadXMLFileEmit(o.UUID_DTE)"> <mat-icon> file_download </mat-icon> </button>
                           <!--    <button mat-button matTooltip="Generar Nota de credito/debito" (click)="openGenNotaCreditoDebito(o)"> <mat-icon> money_off </mat-icon> </button> -->
                            </mat-cell>
                        </ng-container>                    
                        <!--//-->               
                        <mat-header-row *matHeaderRowDef="displayedDte" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: displayedDte" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoadingDte" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDte
                        [pageSize]="pag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>              
                <!-- ================================================================== -->
            </mat-tab>
            <mat-tab label="Cotizaciones" >               
                <!-- ================================================================== -->
                    <!--//-->
                    <mat-table [dataSource]="tableCtz" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="folio">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Folio</mat-header-cell>
                            <mat-cell *matCellDef="let o"><b (click)="openPdf(o.UUID_CTZ,'CTZ')" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.FOLIO | number}}</b></mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="fechaEmision">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Fecha Emision</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.FECHA_EMISION | date:'dd/MM/yy'}}</mat-cell>
                        </ng-container>                        
                        <!--//-->     
                        <ng-container matColumnDef="typeDoc">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Tipo Doc</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.GLS | titlecase}}</mat-cell>
                        </ng-container>
                        <!--//-->                       
                        <ng-container matColumnDef="emitedTo">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Emitido a</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.RAZON_SOCIAL | titlecase}}</small></mat-cell>
                        </ng-container>
                        <!--//-->             
                        <ng-container matColumnDef="rutRecep">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut Receptor</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.RUT | rut:'DOTS_DASH'}}</mat-cell>
                        </ng-container>
                        <!--//-->                        
                        <ng-container matColumnDef="tlt">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Total</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <p style="color: rgb(204, 162, 25)"><b>{{o.MNTTOTAL ||  0 | currency:'CLP':'symbol-narrow'}}</b></p>
                            </mat-cell>
                        </ng-container>                    
                        <!--//-->      
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <button mat-button matTooltip="Vista previa PDF" (click)="openPdf(o.UUID_CTZ,'CTZ')"> <mat-icon> visibility </mat-icon> </button>
                            <!--     <button mat-button matTooltip="Transformar a factura" (click)="ctzToDte(o.UUID_CTZ)" *ngIf="o.FLAG_DTE === 'N'; else dteEmited"> <mat-icon> {{o.FECHA_VENCIMIENTO < today ? 'event_busy':'request_quote'}} </mat-icon> </button> 
                                <button mat-button matTooltip="Ver DTE asociado" (click)="openPdf(element.UUID_FACTURA, true)"><mat-icon > picture_as_pdf </mat-icon></button> -->
                                <button mat-button matTooltip="Descargar PDF" (click)="downloadFile(o.UUID_CTZ ,'CTZ')"> <mat-icon> file_download </mat-icon> </button>
                            </mat-cell>
                        </ng-container>                    
                        <!--//-->               
                        <mat-header-row *matHeaderRowDef="displayedCtz" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: displayedCtz" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoadingCtz" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagCtz
                        [pageSize]="pag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>  
                    <!--//-->            
                <!-- ================================================================== -->
            </mat-tab>     
            <mat-tab label="Ticket" >                  
                <!-- ================================================================== -->
                    <!--//-->
                    <mat-table [dataSource]="tableTkt" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="folio">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Folio</mat-header-cell>
                            <mat-cell *matCellDef="let o"><b (click)="openPdf(o.UUID_TKT,'TKT')" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.FOLIO | number}}</b></mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="fechaEmision">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Fecha Emision</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.FECHA_EMISION | date:'dd/MM/yy'}}</mat-cell>
                        </ng-container>                        
                        <!--//-->     
                        <ng-container matColumnDef="typeDoc">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Tipo Doc</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.GLS | titlecase}}</mat-cell>
                        </ng-container>
                        <!--//-->                       
                        <ng-container matColumnDef="emitedTo">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Emitido a</mat-header-cell>
                            <mat-cell *matCellDef="let o"><small>{{o.RAZON_SOCIAL | titlecase}}</small></mat-cell>
                        </ng-container>
                        <!--//-->             
                        <ng-container matColumnDef="rutRecep">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut Receptor</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.RUT | rut:'DOTS_DASH'}}</mat-cell>
                        </ng-container>
                        <!--//-->                        
                        <ng-container matColumnDef="tlt">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Total</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <p style="color: rgb(4, 9, 103)"><b>{{o.MNTTOTAL ||  0 | currency:'CLP':'symbol-narrow'}}</b></p>
                            </mat-cell>
                        </ng-container>                    
                        <!--//-->      
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <button mat-button matTooltip="Vista previa PDF" (click)="openPdf(o.UUID_TKT,'TKT')"> <mat-icon> visibility </mat-icon> </button>
                                <button mat-button matTooltip="Descargar PDF" (click)="downloadFile(o.UUID_TKT,'TKT')"> <mat-icon> file_download </mat-icon> </button>
                           <!--      <button mat-button matTooltip="Generar Nota de credito/debito" (click)="openGenNotaCreditoDebito(o)"> <mat-icon> money_off </mat-icon> </button> -->
                            </mat-cell>
                        </ng-container>                    
                        <!--//-->               
                        <mat-header-row *matHeaderRowDef="displayedTkt" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: displayedTkt" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoadingTkt" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagTkt
                        [pageSize]="pag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>  
                    <!--//-->            
                <!-- ================================================================== -->
            </mat-tab>                     
        <!--########################################################################--> 
                <mat-tab label="DTE Recibidos" >
                    <!-- ================================================================== -->
                        <!--//-->
                        <mat-table [dataSource]="tableReceivedDTE" class="mat-elevation-z8">
                            <!--//-->                    
                            <ng-container matColumnDef="folio">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">Folio</mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                    <b  *ngIf="o.KEY_EMAIL != '-'" (click)="openPdfExt(o.UUID_DTE_GET || o.UUID_CTZ || o.UUID_TKT)" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.FOLIO | number}}</b>
                                    <b  *ngIf="o.KEY_EMAIL == '-'" >{{o.FOLIO | number}}</b>
                                
                                </mat-cell>
                            </ng-container>
                            <!--//-->
                            <ng-container matColumnDef="fechaEmision">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Fecha Emision</mat-header-cell>
                                <mat-cell *matCellDef="let o">{{o.FECHA_EMISION | date:'dd/MM/yy'}}</mat-cell>
                            </ng-container>                        
                            <!--//-->     
                            <ng-container matColumnDef="typeDoc">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Tipo Doc</mat-header-cell>
                                <mat-cell *matCellDef="let o">{{o.GLS | titlecase}}</mat-cell>
                            </ng-container>
                            <!--//-->                       
                            <ng-container matColumnDef="emitedTo">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Emitido</mat-header-cell>
                                <mat-cell *matCellDef="let o"><small>{{o.RAZON_SOCIAL | titlecase}}</small></mat-cell>
                            </ng-container>
                            <!--//-->             
                            <ng-container matColumnDef="rutRecep">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut Emisor</mat-header-cell>
                                <mat-cell *matCellDef="let o">{{o.RUT | rut:'DOTS_DASH'}}</mat-cell>
                            </ng-container>
                            <!--//-->                        
                            <ng-container matColumnDef="tlt">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label">Total</mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                    <div *ngIf="o.COD_DOC == '61'"><p style="color: red"><b>{{o.MNTTOTAL ||  0 | currency:'CLP':'symbol-narrow'}}</b></p></div>
                                    <div *ngIf="o.COD_DOC != '61'"><p style="color: rgb(4, 9, 103)"><b>{{o.MNTTOTAL ||  0 | currency:'CLP':'symbol-narrow'}}</b></p></div>
                                </mat-cell>
                            </ng-container>                    
                            <!--//-->      
                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                    <button mat-button matTooltip="Confirmar recepcion" (click)="confirmReception(o)"> <mat-icon> done_all </mat-icon> </button>
                                    <button mat-button matTooltip="Conforme/Desconforme" (click)="declineOrAccept(o.UUID_DTE_GET || o.UUID_CTZ || o.UUID_TKT)"> <mat-icon> thumbs_up_down </mat-icon> </button>
                                </mat-cell>
                            </ng-container>                    
                            <!--//-->    
                            <ng-container matColumnDef="actions2">
                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-label"></mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                    <button *ngIf="o.KEY_EMAIL != '-'" mat-button (click)="openPdfExt(o.UUID_DTE_GET || o.UUID_CTZ || o.UUID_TKT)"> <mat-icon> visibility </mat-icon> </button> 
                                    <button *ngIf="o.KEY_EMAIL != '-'" mat-button  matTooltip="Descargar PDF" (click)="downloadPDFFile(o.UUID_DTE_GET)"> <mat-icon> file_download </mat-icon> </button>
                                    <button *ngIf="o.KEY_EMAIL != '-'" mat-button  matTooltip="Descargar XML" (click)="downloadXMLFile(o.UUID_DTE_GET)"> <mat-icon> save_alt </mat-icon> </button>
                                </mat-cell>
                            </ng-container>                    
                            <!--//-->                  
                            <!--//-->               
                            <mat-header-row *matHeaderRowDef="displayedReceivedDTE" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                            <mat-row *matRowDef="let row;columns: displayedReceivedDTE" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                            <!--//-->                    
                        </mat-table>
                            <!--//-->      
                            <mat-spinner *ngIf="isLoadingReceivedDTE" style="margin:0 auto"></mat-spinner>         
                            <mat-paginator
                            #pagReceivedDTE
                            [pageSize]="pag"
                            showFirstLastButtons
                            [pageSizeOptions]="pageSizeOptions" 
                            aria-label="Select page of periodic elements">
                        </mat-paginator>              
                    <!-- ================================================================== -->
                </mat-tab>
        <!--########################################################################-->                 
    </mat-tab-group>
<!-- ================================================================== -->
</mat-tab>
<!-- ///////////////////////////////////////////////////////////////////////////--> 
<!-- ///////////////////////////////////////////////////////////////////////////-->  
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
</mat-tab-group>
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  -->
    <div class="grid grid-cols-1">   
        <!--//-->   
        
        <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
        <p>Creado:<small *ngIf="customerData?.EMPLOYEE_DATA_CREATION?.LOGIN != '00000000-0'">{{customerData?.EMPLOYEE_DATA_CREATION?.LOGIN  || '1-9' | rut:'DOTS_DASH'}}</small><b> {{customerData?.EMPLOYEE_DATA_CREATION?.FIRST_NAME}} {{customerData?.EMPLOYEE_DATA_CREATION?.LAST_NAME}}  {{customerData.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss'}}</b></p>
        <div *ngIf="customerData?.EMPLOYEE_DATA_UPDATE?.LOGIN != '00000000-0' ">
            <p>Actualizado:<small>{{customerData?.EMPLOYEE_DATA_UPDATE?.LOGIN || '1-9' |rut:'DOTS_DASH'}}</small><b> {{customerData?.EMPLOYEE_DATA_UPDATE?.FIRST_NAME}} {{customerData?.EMPLOYEE_DATA_UPDATE?.LAST_NAME}}  {{customerData.DATE_UPDATE |  date:'dd-MM-yyyy HH:mm:ss'}}</b></p>
        </div>
        <br>
        <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->

        <!-- 
        <div *ngIf=" customerData.DATE_UPDATE == '1900-01-01T00:00:00'">Creado: <b>{{customerData.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss'}}</b></div>
        <div *ngIf=" customerData.DATE_UPDATE != '1900-01-01T00:00:00'">Creado: <b>{{customerData.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss'}}</b> Actualizado: <b>{{customerData.DATE_UPDATE |  date:'dd-MM-yyyy HH:mm:ss'}}</b></div>
        -->
        <!--//-->    

    </div>
    <!-- ##########################################################  -->
</div>
</div>

</div>