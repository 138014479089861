<div class="p-10  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- ##########################################################  -->
    <div class="close">
        <button mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>
        <!-- <button mat-button (click)="test()"> TEST </button> -->
      </div>
    <!-- ##########################################################  -->
    <div class="bg-white rounded-lg p-4">
        <!-- ##########################################################  -->
        <!-- ##########################################################  -->
                <div class="grid grid-cols-2">   
                    <!--//-->                 
                    <div class="prose prose-xl">
                        Orden:<h2><b>{{data.order.FRIENLY_EOM_ORDER }}</b></h2>
                        Estado:<b>{{data.order.NAME_COD_REASON_CODE }}</b>    
                        Monto:<b>{{this.data.order.TOTAL | currency:'CLP':'symbol-narrow'}}</b>    
                    </div>
                    <!--//-->              
                </div>
                <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
                <mat-divider></mat-divider>            
                <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
                <!--#################################-->
                <mat-form-field class="w-3/4">
                    <mat-label> Medio de Pago</mat-label>
                    <mat-select (selectionChange)="setPayMeans($event.value,'selectPayMeans')" [(ngModel)]="selectPayMeans">
                        <ng-container *ngFor="let p of spPayMeans">
                            <mat-option [value]="p"> {{p.COD_PM_PAY_MEANS}} - {{p.NAME_PM_PAY_MEANS}} </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <!--#################################-->
        <!-- ##########################################################  -->
            <div> 
                <mat-form-field *ngIf="selectPayMeans.FLAG_PARAM == 'Y'"  class="w-full">
                    <mat-label > {{selectPayMeans.NAME_PARAM}}</mat-label>
                        <div *ngIf="selectPayMeans.FLAG_MANDATORY_PARAM == 'Y'">
                            <input [(ngModel)]= "payMeansParam" #number *ngIf="selectPayMeans.TYPE_PARAM === 'NUMBER'"    matInput type="number" [minlength]="selectPayMeans.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans.MIN_LENGTH_PARAM_C" required >
                            <input [(ngModel)]= "payMeansParam"         *ngIf="selectPayMeans.TYPE_PARAM === 'DATE'"      matInput type="date" required>
                            <input [(ngModel)]= "payMeansParam"         *ngIf="selectPayMeans.TYPE_PARAM === 'DATE_TIME'" matInput type="date" required>
                            <input [(ngModel)]= "payMeansParam"         *ngIf="selectPayMeans.TYPE_PARAM === 'VARCHAR'"   matInput type="text" [minlength]="selectPayMeans.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans.MIN_LENGTH_PARAM_C" required>
                        </div>  
                        <div *ngIf="selectPayMeans.FLAG_MANDATORY_PARAM == 'N'">
                            <input [(ngModel)]= "payMeansParam" #number *ngIf="selectPayMeans.TYPE_PARAM === 'NUMBER'"    matInput type="number" [minlength]="selectPayMeans.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans.MIN_LENGTH_PARAM_C"  >
                            <input [(ngModel)]= "payMeansParam"         *ngIf="selectPayMeans.TYPE_PARAM === 'DATE'"      matInput type="date" >
                            <input [(ngModel)]= "payMeansParam"         *ngIf="selectPayMeans.TYPE_PARAM === 'DATE_TIME'" matInput type="date" >
                            <input [(ngModel)]= "payMeansParam"         *ngIf="selectPayMeans.TYPE_PARAM === 'VARCHAR'"   matInput type="text" [minlength]="selectPayMeans.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans.MIN_LENGTH_PARAM_C">
                        </div>                    
                </mat-form-field>

                <div *ngIf="selectPayMeans.COD_PM_PAY_MEANS == 'EF' ">
    
                    <div class="flex"  *ngIf="selectPayMeans.COD_PM_PAY_MEANS == 'EF'">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Ley Redondeo: </h1>
                        <p class="w-1/2 prose prose-xl font-bold"> {{amountAjusteSencillo | currency:'CLP':'symbol-narrow'}} </p>
                    </div>
                    <div class="flex">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Por pagar: </h1>
                        <p class="w-1/2 prose prose-xl font-bold"> {{orderBalance | currency:'CLP':'symbol-narrow'}} </p>
                    </div>
                    <div class="flex">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Efectivo : </h1>
                        <p class="w-1/2 prose prose-xl font-bold"> 
                                                                    <mat-form-field >
                                                                        <input matInput type="number"  [(ngModel)]="importe"  required> 
                                                                    </mat-form-field>
                        </p>
                    </div>        
                    <div class="flex">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Vuelto: </h1>
                        <p class="w-1/2 prose prose-xl font-bold" > {{importe - orderBalance | currency:'CLP':'symbol-narrow'}} </p>
                    </div>
                </div>  

            </div>
            <!-- ##########################################################  -->
            <!-- ##########################################################  -->
        <div *ngIf="selectPayMeans.COD_PM_PAY_MEANS == 'CD' "> 
                    <div class="flex" *ngIf="flagIsOkDiscountCoupon == false">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Validar Cupon: </h1>
                        <button type="button" class="ml-5 bottom-3" color="primary" mat-raised-button (click)="discountCouponChk()">
                            <mat-icon> restore </mat-icon>
                        </button>
                    </div>   
                    <div class="flex" *ngIf="flagIsOkDiscountCoupon == true">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Cupon: </h1>
                        <p class="w-1/2 prose prose-xl font-bold" > <b>{{payMeansParam}}</b> </p>
                    </div>                
                    <div class="flex" *ngIf="flagIsOkDiscountCoupon == true">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Monto Cupon Descuento: </h1>
                        <p class="w-1/2 prose prose-xl font-bold" > {{orderDiscountCoupon | currency:'CLP':'symbol-narrow'}} </p>
                    </div>        
                    <div class="flex" *ngIf="flagIsOkDiscountCoupon == true">
                        <h1 class="w-1/2 prose prose-xl font-bold"> Diferencia por Pagar: </h1>
                        <div class="w-1/2 prose prose-xl font-bold" > 
                                                                    <div *ngIf="orderBalance <= 0 " >{{ 0 | currency:'CLP':'symbol-narrow'}} </div>
                                                                    <div *ngIf="orderBalance > 0 " >{{orderBalance | currency:'CLP':'symbol-narrow'}} </div>
                        </div>
                        <div *ngIf="orderBalance < 0 "> <p style="color: red">¡¡ALERTA!!: Una vez utilizado el Cupon el nuevo saldo sera :<b> {{orderBalance * -1| currency:'CLP':'symbol-narrow'}} </b>  </p></div>
                  </div>
                <!-- ##########################################################  -->
                <div *ngIf="orderBalance > 0 && flagIsOkDiscountCoupon == true" >
                    <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                            <mat-form-field class="w-3/4">
                                <mat-label> Medio de Pago</mat-label>
                                <mat-select (selectionChange)="setPayMeans($event.value,'selectPayMeans2')" [(ngModel)]="selectedPayMeans2">
                                    <ng-container *ngFor="let p of spPayMeans2">
                                        <mat-option [value]="p"> {{p.COD_PM_PAY_MEANS}} - {{p.NAME_PM_PAY_MEANS}} </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
     
                            <mat-form-field *ngIf="selectPayMeans2.FLAG_PARAM == 'Y'"  class="w-full">
                                <mat-label > {{selectPayMeans2.NAME_PARAM}}</mat-label>
                                    <div *ngIf="selectPayMeans2.FLAG_MANDATORY_PARAM == 'Y'">
                                        <input [(ngModel)]= "payMeansParam2" #number *ngIf="selectPayMeans2.TYPE_PARAM === 'NUMBER'"    matInput type="number" [minlength]="selectPayMeans2.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans2.MIN_LENGTH_PARAM_C" required >
                                        <input [(ngModel)]= "payMeansParam2"         *ngIf="selectPayMeans2.TYPE_PARAM === 'DATE'"      matInput type="date"   required>
                                        <input [(ngModel)]= "payMeansParam2"         *ngIf="selectPayMeans2.TYPE_PARAM === 'DATE_TIME'" matInput type="date"   required>
                                        <input [(ngModel)]= "payMeansParam2"         *ngIf="selectPayMeans2.TYPE_PARAM === 'VARCHAR'"   matInput type="text"   [minlength]="selectPayMeans2.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans2.MIN_LENGTH_PARAM_C" required>
                                    </div>  
                                    <div *ngIf="selectedPayMeans2.FLAG_MANDATORY_PARAM == 'N'">
                                        <input [(ngModel)]= "payMeansParam2" #number *ngIf="selectPayMeans2.TYPE_PARAM === 'NUMBER'"    matInput type="number" [minlength]="selectPayMeans2.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans2.MIN_LENGTH_PARAM_C"  >
                                        <input [(ngModel)]= "payMeansParam2"         *ngIf="selectPayMeans2.TYPE_PARAM === 'DATE'"      matInput type="date"   >
                                        <input [(ngModel)]= "payMeansParam2"         *ngIf="selectPayMeans2.TYPE_PARAM === 'DATE_TIME'" matInput type="date"   >
                                        <input [(ngModel)]= "payMeansParam2"         *ngIf="selectPayMeans2.TYPE_PARAM === 'VARCHAR'"   matInput type="text"   [minlength]="selectPayMeans2.MIN_LENGTH_PARAM_C" [maxlength]="selectPayMeans2.MIN_LENGTH_PARAM_C">
                                    </div>                    
                            </mat-form-field>
                             
                            <div *ngIf="selectPayMeans2.COD_PM_PAY_MEANS == 'EF' ">
    
                                <div class="flex"  *ngIf="selectPayMeans2.COD_PM_PAY_MEANS == 'EF'">
                                    <h1 class="w-1/2 prose prose-xl font-bold"> Ley Redondeo: </h1>
                                    <p class="w-1/2 prose prose-xl font-bold"> {{amountAjusteSencillo2 | currency:'CLP':'symbol-narrow'}} </p>
                                </div>
                                <div class="flex">
                                    <h1 class="w-1/2 prose prose-xl font-bold"> Por pagar: </h1>
                                    <p class="w-1/2 prose prose-xl font-bold"> {{orderBalance2 | currency:'CLP':'symbol-narrow'}} </p>
                                </div>
                                <div class="flex">
                                    <h1 class="w-1/2 prose prose-xl font-bold"> Efectivo : </h1>
                                    <p class="w-1/2 prose prose-xl font-bold"> 
                                                                                <mat-form-field >
                                                                                    <input matInput type="number"  [(ngModel)]="importe2"  value="0" > 
                                                                                </mat-form-field>
                                    </p>
                                </div>        
                                <div class="flex">
                                    <h1 class="w-1/2 prose prose-xl font-bold"> Vuelto: </h1>
                                    <p class="w-1/2 prose prose-xl font-bold" > {{importe2 - orderBalance2 | currency:'CLP':'symbol-narrow'}} </p>
                                </div>
                            </div>        
                                    
                    <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->                        
                </div>                
                <!-- ##########################################################  -->  
                    <div>
                        <div *ngIf="selectPayMeans2.COD_PM_PAY_MEANS == 'EF' "> 
                            <button mat-raised-button (click)="payOrder('A')" class="payBtn" [disabled]=" (importe2 - orderBalance2 < 0) " > Pagar</button>                     
                        </div>
                        <!--////////////////////////-->
                        <div *ngIf="selectPayMeans2.COD_PM_PAY_MEANS != 'EF' "> 
                            <div > 
                                <div *ngIf="selectPayMeans2.FLAG_MANDATORY_PARAM == 'Y'">
                                    <button mat-raised-button (click)="payOrder('A')" class="payBtn" [disabled]="!payMeansParam2" > Pagar </button>               
                                </div>
                                <div *ngIf="selectPayMeans2.FLAG_MANDATORY_PARAM == 'N'">
                                    <button mat-raised-button (click)="payOrder('A')" class="payBtn" > Emitir </button>
                                </div>
                            </div>
                    </div>           
                <!-- ##########################################################  -->                   
        </div>
    <!-- ##########################################################  -->
</div>

    <div *ngIf="selectPayMeans.COD_PM_PAY_MEANS != 'CD' ">
        <div *ngIf="selectPayMeans.COD_PM_PAY_MEANS == 'EF' "> 
            <button mat-raised-button (click)="payOrder('B')" class="payBtn" [disabled]=" (importe - orderBalance < 0) " > Pagar</button>                     
        </div>
        <!--////////////////////////-->
        <div *ngIf="selectPayMeans.COD_PM_PAY_MEANS != 'EF' "> 
            <div *ngIf="flagShowpayMeansParam == true"> 
                <div *ngIf="selectPayMeans.FLAG_MANDATORY_PARAM == 'Y'">
                    <button mat-raised-button (click)="payOrder('B')" class="payBtn" [disabled]="!payMeansParam" > Pagar </button>               
                </div>
                <div *ngIf="selectPayMeans.FLAG_MANDATORY_PARAM == 'N'">
                    <button mat-raised-button (click)="payOrder('B')" class="payBtn" > Emitir </button>
                </div>
            </div>
        </div>    
   </div>
    <!-- ##########################################################  -->
    
