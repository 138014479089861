

  <div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->

<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl">Referencia: <b> {{this.data.rcAttributesTypeSelected.COD_RC_ATTRIBUTES_TYPE}} {{this.data.rcAttributesTypeSelected.NAME_RC_ATTRIBUTES_TYPE}}  </b></h2>                       
                    <small>Agregar  </small>
                   <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
 



<form [formGroup]="attributesForm">
    <!-- ========================================= -->
     <!-- 
    <mat-card-header>
        <mat-card-title> 
                       
                                               
        </mat-card-title>
    </mat-card-header>
-->
            <mat-form-field class="w-1/2">
                <mat-label>Periodicidad:</mat-label>
                <mat-select  formControlName="flagRecurrent"  (selectionChange)="enableDisable('flagRecurrent',$event.value)">
                    <ng-container *ngFor="let p of arrOp">
                        <mat-option [value]="p.cod">{{p.gls}} </mat-option>
                    </ng-container>
                </mat-select>  
            </mat-form-field>  

            <div *ngIf="attributesForm.value.flagRecurrent == 'Y'">
                <mat-form-field class="w-1/5">
                    <mat-label>Fecha :</mat-label>   
                    <input matInput formControlName="dateRcAttributesType" [matDatepicker]="emision" [min]="minDateInfo" [value]="today" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="emision" ></mat-datepicker-toggle>
                    <mat-datepicker #emision ></mat-datepicker>
                </mat-form-field>  
            </div>
            <div class="flex">
                <mat-form-field class="w-1/2">
                    <mat-label>Folio:</mat-label>
                    <input matInput formControlName="folioRcAttributesType"   minlength="3" maxlength="18"   />  
                </mat-form-field>
            </div>            
            <div class="flex">
                <mat-form-field class="w-1/2">
                    <mat-label>Glosa:</mat-label>
                    <input matInput formControlName="valueRcAttributesType"   minlength="3" maxlength="50"   />  
                </mat-form-field>
            </div>
    <!-- ========================================= -->
</form>
<button mat-raised-button color="primary" (click)="addAttributes()" [disabled]="attributesForm.invalid"> <mat-icon>add</mat-icon>Agregar {{this.data.rcAttributesTypeSelected.NAME_RC_ATTRIBUTES_TYPE}}       </button>