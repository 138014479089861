<div class="close">
    <button mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>
    <!-- <button mat-button (click)="test()"> TEST </button> -->
  </div>

<form [formGroup]="attributesForm">
    <!-- ========================================= -->
    <mat-card-header>
        <mat-card-title> 
                        {{this.data.rcAttributesTypeSelected.COD_RC_ATTRIBUTES_TYPE}} 
                        {{this.data.rcAttributesTypeSelected.NAME_RC_ATTRIBUTES_TYPE}}                         
        </mat-card-title>
    </mat-card-header>

            <mat-form-field class="w-1/2">
                <mat-label>Tipo Factura:</mat-label>
                <mat-select  formControlName="flagRecurrent"  (selectionChange)="enableDisable('flagRecurrent',$event.value)">
                    <ng-container *ngFor="let p of arrOp">
                        <mat-option [value]="p.cod">{{p.gls}} </mat-option>
                    </ng-container>
                </mat-select>  
            </mat-form-field>  

            <div *ngIf="attributesForm.value.flagRecurrent == 'Y'">
                <mat-form-field class="w-1/5">
                    <mat-label>Fecha :</mat-label>   
                    <input matInput formControlName="dateRcAttributesType" [matDatepicker]="emision" [min]="minDateInfo" [value]="today" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="emision" ></mat-datepicker-toggle>
                    <mat-datepicker #emision ></mat-datepicker>
                </mat-form-field>  
            </div>
            
            <div class="flex">
                <mat-form-field class="w-1/2">
                    <mat-label>Glosa:</mat-label>
                    <input matInput formControlName="valueRcAttributesType"   minlength="3" maxlength="80"   />  
                </mat-form-field>
            </div>
    <!-- ========================================= -->
</form>
<button mat-raised-button color="primary" (click)="addAttributes()" [disabled]="attributesForm.invalid"> <mat-icon>add</mat-icon>Agregar {{this.data.rcAttributesTypeSelected.NAME_RC_ATTRIBUTES_TYPE}}       </button>