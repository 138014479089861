<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">


<!--#######################################-->
<form [formGroup]="casosForm" *ngIf="data.type === 'create'" class="w-full">

    <!--############################################################-->
<div class="bg-white rounded-lg p-4">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-8">
                <!-- ## -->
                    <h2 class="prose prose-xl"><b> Crear Caso</b></h2>                       
                   <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                <!-- ## -->
            </div>
        </div>
        <!-- ##########################################################  -->    

    </div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->


 <mat-card >   
    <!-- 
    <div *ngIf="flagShowCustomer == true">
        <mat-form-field class="w-1/2">
            <mat-label> Cliente</mat-label>
            <mat-select formControlName="customer">
                <ng-container *ngFor="let customer of customerList">
                    <mat-option [value]="customer.UUID_CRM_CUSTOMER">
                        {{ customer.RUT || '1-9' | rut:'DOTS_DASH'  }} {{ customer.CUSTOMER_FANCY_NAME }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
    -->
    <mat-card-content>    
    <!--#######################################-->
    <div *ngIf="flagShowCustomer == true">
        <button class="ml-4" mat-flat-button [color]="'primary'" (click)="showSupplier()">
            <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
            <span class="ml-2 mr-1"> Selecione Cliente </span>
        </button>
    </div>
    <!--#######################################-->  
    <br>  
    <div *ngIf="flagShowCustomerName == true">
        <div  class="text-3xl font-extrabold tracking-tight">{{customerRut || '1-9' | rut:'DOTS_DASH'}} {{customerFancyName}} </div>
    </div>
    <!--#######################################-->  

    <div class="flex">
        <mat-form-field class="w-1/2">
            <mat-label> T1 </mat-label>
            <mat-select formControlName="t1">
                <ng-container *ngFor="let t1 of t1List">
                    <mat-option [value]="t1.ID_CM_INTERACTION_T1" (click)="t1Selected()">
                        {{ t1.T1 }}
                    </mat-option>
                </ng-container> 
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-1/2">
            <mat-label> T2 </mat-label>
            <mat-select formControlName="t2">
                <ng-container *ngFor="let t2 of t2ListFiltered">
                    <mat-option [value]="t2" (click)="t2Selected(t2)">
                        {{ t2.T2 }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
    <form [formGroup]="flexibleAttributesForm" class="flex w-full" *ngIf="flexibleAttributesFiltered">
        <mat-form-field *ngFor="let fa of flexibleAttributesFiltered" class="w-1/2">
            <mat-label> {{fa.NAME_PARAM}} </mat-label>
            <input 
                #number 
                *ngIf="fa.TYPE_PARAM === 'NUMBER'" 
                matInput 
                type="number" 

                [formControlName]="fa.NAME_PARAM_FORM" 
                [min]="fa.MIN_LENGTH_PARAM" 
                [max]="fa.MAX_LENGTH_PARAM">

            <input 
                *ngIf="fa.TYPE_PARAM === 'DATE'"
                matInput 
                type="date"
                [formControlName]="fa.NAME_PARAM_FORM">

            <input 
                *ngIf="fa.TYPE_PARAM === 'DATE_TIME'"
                matInput
                type="date"
                [formControlName]="fa.NAME_PARAM_FORM">

            <input 
                *ngIf="fa.TYPE_PARAM === 'VARCHAR'" 
                matInput 
                type="text"
                [formControlName]="fa.NAME_PARAM_FORM"
                [minlength]="fa.MIN_LENGTH_PARAM" 
                [maxlength]="fa.MAX_LENGTH_PARAM">
            
            <input 
            *ngIf="fa.TYPE_PARAM === 'AMOUNT'" 
            matInput 
            type="text"
            [formControlName]="fa.NAME_PARAM_FORM">
                
            <mat-select *ngIf="fa.TYPE_PARAM === 'LIST'" [formControlName]="fa.NAME_PARAM_FORM">
                <ng-container *ngFor="let list of fa.LIST_VALUES_PARAM.value">
                    <mat-option [value]="list.id">
                        {{list.value}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </form>
    <div>
        <!-- <h1> SLA {{casosForm.value.t2}}</h1> -->
    </div>
    <mat-form-field class="w-1/3">
        <mat-label> Estado </mat-label>
        <mat-select formControlName="status">
            <ng-container *ngFor="let stat of status">
                <mat-option [value]="stat.ID_CM_INTERACTION_STATUS">
                    {{ stat.NAME_CM_INTERACTION_STATUS }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label>Descripcion del caso</mat-label>
        <textarea matInput formControlName="txt"></textarea>
    </mat-form-field>
    <!-- {{flexibleAttributesForm.invalid}} -->
    <button 
        mat-raised-button color="primary"
        [disabled]="casosForm.invalid || flexibleAttributesForm.invalid" 
        (click)="create()">
        Crear Caso
    </button>
</mat-card-content>
</mat-card >  
</div>  
</form>

<!-- ############################################################## -->

<form [formGroup]="casosForm" *ngIf="data.type === 'update'" class="w-full">

    <!--############################################################-->
    <div class="bg-white rounded-lg p-4">
        <!-- ##########################################################  -->
        <!-- ##########################################################  -->
        <div class="w-full">
            <!-- ##########################################################  --> 
            <div class="grid grid-cols-10">   
                <div class="col-span-8">
                    <!-- ## -->
                        <h2 class="prose prose-xl">Caso -<b>  {{interaction.COD_CUSTOMER_INTERACTION}}</b></h2>                       
                        <h2 class="prose" >Cliente:<b> {{interaction.RUT || '1-9'  | rut:'DOTS_DASH' }} {{interaction.CUSTOMER_FANCY_NAME}}</b></h2>   
                    <!-- ## -->
                </div>
                <div class="col-span-1">
                    <!-- ## -->
                    <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                    <!-- ## -->
                </div>
            </div>
            <!-- ##########################################################  -->    
    
        </div>
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
    <mat-divider class="py-2"></mat-divider>
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->


     <!--#################################################################-->  
     <table>
        <tr>
            <td>
                <h1 *ngIf="interaction.SLA_LIGHT === 0"><mat-icon style="color: #008f39" >flag</mat-icon> En plazo  </h1>
                <h1 *ngIf="interaction.SLA_LIGHT === 1"><mat-icon style="color: #e5be01" >flag</mat-icon> Por vencer</h1>    
                <h1 *ngIf="interaction.SLA_LIGHT === 2"><mat-icon style="color: #e01601" >flag</mat-icon> Vencido   </h1>
            </td>
            <td>
               SLA : <b>{{interaction.SLA}} Horas</b>
            </td>
            <td > 
                &nbsp; &nbsp; &nbsp; Valor del Cliente: <b>{{ interaction.NAME_CM_CUSTOMER_VALUE }} </b>
            </td>                        
        </tr>
        <tr>
            <td>
                <h1>Creado : <b>{{interaction.DATE_CREATION | date:'medium'}}</b></h1>
            </td>
            <td>
                
            </td>
            <td>
                <h1 *ngIf="interaction.FLAG_END == 'Y'">Cerrado : <b>{{interaction.DATE_CLOSE | date:'medium'}}</b></h1>
                <h1 *ngIf="interaction.FLAG_END == 'N'">Cerrado : <b>Sin resolver </b></h1>
            </td>          
        </tr>
        <tr>
            <td colspan="3">
                Asignado a: <b>{{ interaction.EMPLOYEE_FIRST_NAME }} {{ interaction.EMPLOYEE_FIRST_NAME2 }} {{ interaction.EMPLOYEE_LAST_NAME }} {{ interaction.EMPLOYEE_LAST_NAME2 }}</b>
            </td>        
        </tr>        
     </table>
<!--#################################################################-->       
    <div class="hidden">
        <mat-form-field class="w-1/2">
            <mat-label> Cliente</mat-label>
            <mat-select formControlName="customer" disabled="true">
                <ng-container *ngFor="let customer of customerList">
                    <mat-option [value]="customer.UUID_CRM_CUSTOMER">
                        {{ customer.CUSTOMER_FIRST_NAME }} {{ customer.CUSTOMER_LAST_NAME }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="flex">
        <mat-form-field class="w-1/2">
            <mat-label> T1 </mat-label>
            <input matInput type="text" readonly formControlName="t1">
        </mat-form-field>
        <mat-form-field class="w-1/2">
            <mat-label> T2 </mat-label>
            <input matInput type="text" readonly formControlName="t2">
        </mat-form-field>
    </div>
    <div>
        <!--#################################################################-->
        <div class="flex" *ngIf="flexibleAttributesValue.length">
            <div  *ngFor="let flex of flexibleAttributesValue">
                <mat-form-field>
                    <mat-label> {{flex.NAME_PARAM}} </mat-label>
                    <input matInput type="text" readonly value="{{flex.VALUE_FLEXIBLE_ATTRIBUTES}}">
                </mat-form-field>
            </div>
    </div>
    <!--#################################################################-->  
    </div>
    <mat-form-field class="w-1/3">
        <mat-label> Estado </mat-label>
        <mat-select formControlName="status">
            <ng-container *ngFor="let stat of status">
                <mat-option [value]="stat.ID_CM_INTERACTION_STATUS">
                    {{ stat.NAME_CM_INTERACTION_STATUS }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <h3 class="prose"> Interacciones previas </h3>
    <mat-card *ngFor="let desc of interaction.J_CUSTOMER_INTERACTION">
        <mat-card-subtitle> {{desc.by}} ({{desc.date | date:'medium'}}) </mat-card-subtitle>
        <mat-card-content *ngIf="interaction">
            <p> {{desc.txt}} </p>
        </mat-card-content>
    </mat-card>
    <mat-form-field class="w-full" *ngIf="interaction.FLAG_END !== 'Y'">
        <mat-label>Descripcion del caso</mat-label>
        <textarea matInput formControlName="txt"></textarea>
    </mat-form-field>
    <button mat-raised-button color="primary" *ngIf="interaction.FLAG_END !== 'Y'" (click)="update()"> Actualizar Caso</button>
    </div>
</form>
<!-- ############################################################## -->

<!-- <button (click)="test()"> TEST </button> -->


</div>