

<!-- ################################################################################################################################################################################
     ################################################################################################################################################################################
     ################################################################################################################################################################################ -->

<div
    *ngIf="data.movement.COD_ESTATUS != 'M'"
    class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible"
    cdkScrollable>

    <!-- Main -->
    <br>
    <div class="w-3/4">
        <!-- ##########################################################  --> 
        <div class="grid grid-cols-10">   
            <div class="col-span-7">
                <!-- ## -->
                  <button mat-raised-button color="primary" (click)="generatePDF()">
                    <!-- <mat-icon svgIcon="picture_as_pdf"></mat-icon> -->
                    <mat-icon svgIcon="feather:arrow-down-circle"></mat-icon>
                    <p>PDF</p>
                </button>              
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <!-- ## -->
            </div>
            <div class="col-span-1">
                <!-- ## -->
                <!-- ## -->
            </div> 
            <div class="col-span-1">
                <!-- ## -->
                <button class="close " mat-button mat-dialog-close> <mat-icon>close</mat-icon></button>
                <!-- ## -->
            </div>                    
        </div>
        <!-- ##########################################################  -->   
    </div>



    <div class="inline-block p-6 sm:p-10 text-left print:p-3">
        <!-- Invoice -->
        <div class="w-240 p-16 rounded-2xl shadow bg-card print:w-auto print:rounded-none print:shadow-none print:bg-transparent">
            <div id="dialogContent">
            <!-- ##########################################################################  -->    
            <div class="w-full">
            <!-- ##########################################################  --> 
            <!-- ##########################################################################  -->                   
                <div class="grid grid-cols-10">   
                    <div class="col-span-3">
                        <!-- ## -->
                         <div *ngIf="data.movement.COD_DOC_TYPE == 'GV'">
                            <h2 class="text-3xl tracking-tight text-primary"><b> {{data.movement.FRIENLY_EOM_ORDER}} </b></h2>                       
                            <h2 class="text-3xl col-span-2"><b> {{data.movement.NAME_DOC_TYPE }} </b></h2>  
                            <h2 ><b> {{data.movement.COD_WAREHOUSE_MOVEMENTS }} </b></h2>  
                         </div>
                          
                         <div *ngIf="data.movement.COD_DOC_TYPE != 'GV'">
                            <h2 class="text-3xl tracking-tight text-secondary"><b> {{data.movement.COD_WAREHOUSE_MOVEMENTS}} </b></h2>                       
                            <h2 class="text-3xl col-span-2"><b> {{data.movement.NAME_DOC_TYPE }} </b></h2>   
                         </div>   
                         
                        <!-- ## -->
                    </div>
                    <div class="col-span-7">
                        <!-- ## -->
                        <div class="grid auto-cols-max grid-flow-col gap-x-8 -mr-16 py-6 px-8 rounded-l-2xl">
                            <div class="place-self-center">
                                <img
                                    class="w-18"
                                    [src]="emisorLogo">
                            </div>
                            <div class="pl-10 border-l text-md">
                                <div class="font-medium"><b> {{emisorData.RAZON_SOCIAL}}</b> </div>
                                <div> {{ emisorData.GIRO_IMPRESO }}</div>
                                <div> {{emisorData.RUT | rut:'DOTS_DASH'}} </div>
                                <!-- <div>hello@fuseinc.com</div> -->
                                <!-- <div>www.fuseinc.com</div> -->
    
    
                            </div>
                        </div>   
                        <!-- ## -->
                    </div>
                </div>
                <!-- ##########################################################  -->    
        
            </div>       
            <div class="col-span-12 my-3 border-b"></div>     
            <!-- ##########################################################################  -->       
            <!-- ##########################################################################  --> 
            <div class="grid grid-cols-3">   
                <div class="col-span-3">
                    <!-- ## -->
                   
                        <div class="grid   ">
                            <div class="font-medium tracking-tight text-secondary"> Proveedor </div>
                            <div class="prose prose-xl"> <b>{{data.movement.DATA_SUPPLIER.RAZON_SOCIAL | titlecase}}</b> </div>
                        </div>
                     
                    <!-- ## -->
                </div>              
            </div>                  
            <!-- ##########################################################################  -->       
            <!-- ##########################################################################  -->   
            <div class="grid grid-cols-2">   
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                            <div class="font-medium tracking-tight text-secondary"> Creado </div>
                            <div class="font-medium"> {{data.movement.CM_EMPLOYEE_CREATION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_CREATION.LAST_NAME}} </div>
                        </div>
                    </div>     
                    <!-- ## -->
                </div>
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                            <div class="font-medium tracking-tight text-secondary"> Modificado:</div>
                            <div class="font-medium"> {{data.movement.CM_EMPLOYEE_MODIFICACION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_MODIFICACION.LAST_NAME}} </div>
                        </div>
                    </div>   
                    <!-- ## -->
                </div>
               
            </div>               
            <!-- ##########################################################################  -->       
            <!-- ##########################################################################  -->                                          
            <div class="grid grid-cols-2">   
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                            <div class="font-medium tracking-tight text-secondary">  Fecha de Creación </div>
                            <div class="font-medium">  {{data.movement.DATE_CREATION | date:'dd/MM/yyyy'}}</div>
                        </div>
                    </div> 
                    <!-- ## -->
                </div>
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                            <div class="font-medium tracking-tight text-secondary"> Fecha Contable </div>
                            <div class="font-medium"> {{data.movement.DATE_ACCOUNTING | date:'dd/MM/yyyy'}}</div>
                        </div>
                    </div> 
                    <!-- ## -->
                </div>                
            </div>
            <!-- ##########################################################################  -->    
            <!-- ##########################################################################  -->  
            <div class="grid grid-cols-2">   
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="">
                            <div class="font-medium tracking-tight text-secondary">  Bodega Origen </div>
                            <div class="font-medium"> {{data.movement.COD_WAREHOUSE}} {{data.movement.NAME_WAREHOUSE}}</div>
                        </div>
                    </div> 
                    <!-- ## -->
                </div>
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between" *ngIf="data.movement.COD_DOC_TYPE == 'GT'">
                        <div class="">
                            <div class="font-medium tracking-tight text-secondary"> Bodega Destino </div>
                            <div class="font-medium">{{data.movement.NAME_WAREHOUSE_DESTINATION}} {{data.movement.NAME_WAREHOUSE_DESTINATION}}</div>
                        </div>
                    </div> 
                    <!-- ## -->
                </div>                
            </div>
            <!-- ##########################################################################  -->    
            <!-- ##########################################################################  -->  
            <div class="grid grid-cols-2">   
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="">
                            <div class="font-medium tracking-tight text-secondary"> Documento </div>
                            <div class="font-medium">  {{data.movement.NAME_COD_REASON_CODE}}</div>
                        </div>
                    </div> 
                    <!-- ## -->
                </div>
                <div class="col-span-1">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="">
                            <div class="font-medium tracking-tight text-secondary"> Folio </div>
                            <div class="font-medium"><b>{{data.movement.FOLIO_DTE | number }}</b></div>
                        </div>
                    </div> 
                    <!-- ## -->
                </div>                
            </div>
            <!-- ##########################################################################  -->    
            <!-- ##########################################################################  -->   
            <div class="grid grid-cols-2">   
                <div class="col-span-2">
                    <!-- ## -->
                    <div class="flex items-start justify-between">
                        <div class="">
                            <div class="font-medium tracking-tight text-secondary"> Descripción </div>
                            <div class="font-medium">  {{data.movement.DESCRIPTION}}</div>
                        </div>
                    </div> 
                    <!-- ## -->
                    <div class="flex items-start justify-between" *ngIf="data.movement.OBS_MOVEMENTS != '*'">
                        <div class="">
                            <div class="font-medium tracking-tight text-secondary"> Obsevaciones </div>
                            <div class="font-medium">  {{data.movement.OBS_MOVEMENTS}}</div>
                        </div>
                    </div> 
                    <!-- ## -->
                </div>               
            </div>
            <!-- ##########################################################################  -->    
            <!-- ##########################################################################  -->                                                      
<!-- 
                <div class="flex items-start justify-between">
                    <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                       
                        <div class="font-medium tracking-tight text-secondary"> Fecha de creación</div>
                        <div class="font-medium"> {{data.movement.DATE_CREATION | date:'dd/MM/yyyy'}} </div>
                        <div class="font-medium tracking-tight text-secondary">Fecha Contable</div>
                        <div class="font-medium"> {{data.movement.DATE_ACCOUNTING | date:'dd/MM/yyyy'}} </div>
                        <div class="font-medium tracking-tight text-secondary"> Proveedor </div>
                        <div class="font-medium"> {{data.movement.DATA_SUPPLIER.RAZON_SOCIAL}} </div>
                        <div class="font-medium tracking-tight text-secondary"> Creado:</div>
                        <div class="font-medium"> {{data.movement.CM_EMPLOYEE_CREATION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_CREATION.LAST_NAME}} </div>
                        <div class="font-medium tracking-tight text-secondary"> Modificado:</div>
                        <div class="font-medium"> {{data.movement.CM_EMPLOYEE_MODIFICACION.FIRST_NAME}} {{data.movement.CM_EMPLOYEE_MODIFICACION.LAST_NAME}} </div>

                    </div>

                </div>
    -->

                <!-- <div class="grid grid-cols-12 gap-x-1 mt-12">
                    <table mat-table [dataSource]="data.detailMovement" class="w-full">
                        AGRegar bodega!!!
                        si es traslado origen y destino
                        mejorar la cabecera
                    </table>
                </div> -->
                <div class="col-span-12 my-3 border-b"></div>
                <!-- ##################################################### -->
                <div class="table-responsive">
                    <table class="table table-wishlist table-mobile">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>SKU/BAR</th>
                                <!-- <th>BAR</th> -->
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Ubicación</th>
                                <th>Total</th>
                                
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let o of data.detailMovement; let i = index;">
                                <td><b>{{ (i+1) }}</b></td>
                                <td>{{ o.COD_PS_SERVICES}}<br>{{ o.BARCODE }}</td>
                                <!-- <td>{{ o.BARCODE }}</td> -->
                                <td><b>{{ o.NAME_PS_SERVICES  }}</b></td>
                                <td><b>{{ o.QUANTITY | number}}</b></td>
                                <!-- ## --> 
                                <td> {{o.DATA_BOX_STORAGE.NAME_LOCATION_PS}}<br>
                                     <span *ngFor="let s of o.DATA_BOX_STORAGE.LOCATION_PS">
                                        <small>{{s.name}}:<small>{{s.value}} </small></small>
                                     </span>
                                </td>
                                <!-- ## --> 
                                <td>${{ o.SALES_PRICE | number}}</td>
                               
                            </tr>
                        </tbody>
                    </table>
                </div>                
                <!-- ##################################################### -->                
<!-- 
                <ng-container *ngFor="let movement of data.detailMovement">
                    <div class="my-4">
                        <div class="bg-white shadow-md rounded-lg p-4 flex items-center justify-between">
                            <h1 class="font-bold">{{ movement.NAME_PS_SERVICES }}</h1>
                            <div class="flex">
                                <div class="mr-8">
                                    <p class="font-semibold">SKU:</p>
                                    <p>{{ movement.COD_PS_SERVICES }}</p>
                                </div>
                                <div class="mr-8">
                                    <p class="font-semibold">Cantidad:</p>
                                    <p>{{ movement.QUANTITY }}</p>
                                </div>
                                <div class="mr-8">
                                    <p class="font-semibold">Precio de venta:</p>
                                    <p>{{ movement.SALES_PRICE }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-span-12 my-4 border-b"></div>
                  </ng-container>
-->                  
                <!-- <button mat-button (click)="getColumnsDetailMovement()" >getColumnsDetailMovement()</button> -->

                <div class="grid grid-cols-12 gap-x-1 mt-12">      
                    <div class="col-span-12 my-3 border-b"></div>            
                    <!-- Discount -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary"> Total de Items </div>
                    <div class="col-span-2 text-right text-lg"> <b>{{ totalItems }}</b></div>
                  
                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>
                  
                    <!-- Total -->
                    <ng-container *ngIf="basePrice">
                        <div class="col-span-10 self-center text-2xl font-medium tracking-tight text-secondary">TOTAL</div>
                        <div class="col-span-2 text-right text-2xl font-medium"> 0 </div>
                    </ng-container>
                  </div>
            </div>
        </div>

    </div>

</div>

