import { Component, Inject, OnInit,HostListener,  ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
//
import { SalesPointService } from 'app/services/salesPoint.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FormBuilder,FormControl } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as uuid from 'uuid';
import { OrderService } from 'app/services/orders.service';
import { CatalogService } from 'app/services/catalog.service';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss']
})
export class CustomizeComponent implements OnInit {
//###########################################################  
customizeForm: UntypedFormGroup;
imgToUpload: File;
previewImageSrc:any;
uuidPsImgUpload =uuid.v4(); 
showImage : boolean = false
showButtonUpload : boolean = false
//###########################################################  
constructor(
  private _changeDetectorRef: ChangeDetectorRef,
  private _fuseConfirmationService: FuseConfirmationService,
  private _formBuilder: UntypedFormBuilder,
  private ref: ChangeDetectorRef,
  private _dialog: MatDialog,
  private _toast: ToastrService,
  public _dialogRef: MatDialogRef<CustomizeComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _orderService : OrderService,
  private _catalogService: CatalogService,
  ){
    this.customizeForm = this._formBuilder.group({
                                                     uuidEomOrder       : [false, Validators.required]
                                                    ,uuidEomOrderDetail : [false, Validators.required]
                                                    ,uuidImgCustomize   : [false, Validators.required]
                                                    ,uuidFileCustomize  : [false, Validators.required]
                                                    ,txtCustomize       : [false, Validators.required]
                                          })
   }
//###########################################################  
  ngOnInit(): void {

    if(this.data.ps.TYPE_CUSTOMIZE == 'IMG' ){
      this.customizeForm.patchValue({
                                         uuidEomOrder       : this.data.uuidEomOrder
                                        ,uuidEomOrderDetail : this.data.ps.UUID_EOM_ORDER_DETAIL
                                        ,uuidImgCustomize   : null
                                        ,uuidFileCustomize  : '0'
                                        ,txtCustomize       : '0'
                                      }) 
    }
    //============================
    //============================
    if(this.data.ps.TYPE_CUSTOMIZE == 'FILE'){
      this.customizeForm.patchValue({
                                       uuidEomOrder       : this.data.uuidEomOrder
                                      ,uuidEomOrderDetail : this.data.ps.UUID_EOM_ORDER_DETAIL
                                      ,uuidImgCustomize   : '0'
                                      ,uuidFileCustomize  : null
                                      ,txtCustomize       : '0'
                                    }) 
    }
    //============================
    //============================
    if(this.data.ps.TYPE_CUSTOMIZE == 'TXT' ){
      this.customizeForm.patchValue({
                                     uuidEomOrder       : this.data.uuidEomOrder
                                    ,uuidEomOrderDetail : this.data.ps.UUID_EOM_ORDER_DETAIL
                                    ,uuidImgCustomize   : '0'
                                    ,uuidFileCustomize  : '0'
                                    ,txtCustomize       : null
                                  }) 
    }
    //============================
    //============================


  }
//###########################################################  
  closeBtn(){
    // Unsuscribe to all observables
    this._dialogRef.close({refresh:false});
  };
//###########################################################  
addDetailCustomize(){

  var txt;
    if(this.data.ps.TYPE_CUSTOMIZE == 'IMG'    ) txt = `Imagen`
    if(this.data.ps.TYPE_CUSTOMIZE == 'TXT'    ) txt = `Texto`
    if(this.data.ps.TYPE_CUSTOMIZE == 'FILE'   ) txt = `Archivo`

  //
  const dialogRef = this._fuseConfirmationService.open({
    title: `Se agregara ${txt} Customizado para Producto: ${this.data.ps.NAME_PS_SERVICES}`,
    message: `Nota:${txt}`,
    icon: {
        show: true,
        name: 'image',
        color: 'primary'
    },
    "actions": {
      "confirm": {
        "show": true,
        "label": "Aceptar",
        "color": "primary"
      },
      "cancel": {
        "show": true,
        "label": "Cancel"
      }
    },
    "dismissible": false      
  });
  dialogRef.afterClosed().subscribe(async (result) => {
    if (result === 'confirmed') {
        ////////////////////////////////////////// 
        var json ={
                     appToken           : null
                    ,language           : 'es'
                    ,uuidSSO            : null
                    ,typeCustomize      : this.data.ps.TYPE_CUSTOMIZE
                    ,uuidEomOrder       : this.customizeForm.value.uuidEomOrder
                    ,uuidEomOrderDetail : this.customizeForm.value.uuidEomOrderDetail
                    ,uuidImgCustomize   : this.customizeForm.value.uuidImgCustomize
                    ,uuidFileCustomize  : this.customizeForm.value.uuidFileCustomize
                    ,txtCustomize       : this.customizeForm.value.txtCustomize
                    ,acction            : 'ADD'
                    
                  }
        let tmp = await this._orderService.setOrderCustomize(json)
        this._dialogRef.close({refresh:true});
        //////////////////////////////////////////  
    }else{
        //////////////////////////////////////////     
        //await this.reLoadOrder()           
        //////////////////////////////////////////                
    }
  });
}
//////////////////////////////////////////////////


////////////////////////////////////////////////// 
async uploadFile(): Promise<any> {
  if(!!this.imgToUpload){
  
    let extraData = {}
    const res = await this._catalogService.uploadImg(this.imgToUpload,this.customizeForm.value.uuidEomOrderDetail,extraData);
    if(res.codStatus === "200"){
      this.showImage = true
      this.uuidPsImgUpload = res.data.uuidImg
      this.customizeForm.get('uuidImgCustomize').setValue(this.uuidPsImgUpload);
      await this.addDetailCustomize()
      //this.showButtonUpload = false
    }
  }else{
    this._toast.warning('No se ha seleccionado ninguna imagen');
  }
  }
//###########################################################  
onFileChange(event: any): void {

this.imgToUpload = event.target.files[0];
var reader = new FileReader();
reader.onload = (event:any) => {
  this.previewImageSrc = event.target.result;
}
reader.readAsDataURL(event.target.files[0]);
        
this.showButtonUpload = true

}

//###########################################################
/*
async deleteImg(uuidImg: string,uuidBrand): Promise<any>{
await this._catalogService.deattachImgToProduct(uuidImg, uuidBrand).then(
  (res: any) => {
    if(res.success){
      this._toast.success('Imagen eliminada correctamente');
      this.ngOnInit();
    }
  }
);
}*/
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
}
