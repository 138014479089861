import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
//import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { UserService } from 'app/services/user.service';
import { DialogCreateOrUpdateCasosComponent } from '../../../components/casos/dialog-create-or-update-casos/dialog-create-or-update-casos.component';
import { MatDialog } from '@angular/material/dialog';
import { DetailClientComponent } from '../../../components/clientes/detail-client/detail-client.component'

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: Notification[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    userData : any = {};  
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
       // private _notificationsService: NotificationsService,
        private _userService:UserService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _dialog: MatDialog,
    )
    {
        this.userData = {
            uuidSSO     : JSON.parse(localStorage.getItem('emisorData')).usrData[0].UUID_EMISOR_USR,
            appToken    : localStorage.getItem('appToken')
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     * void
     */
    async ngOnInit()
    {
    
        this.notifications = await this._userService.getNotification(this.userData.uuidSSO,this.userData.appToken);
       
        this.unreadCount = await this.notifications.length;
        this._changeDetectorRef.markForCheck();
       // await this._calculateUnreadCount();
        /*
        // Subscribe to notification changes
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {

                // Load the notifications
                this.notifications = notifications;

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
            */
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void
    {
        this.notifications = [];
        this.unreadCount = 0;
        this._changeDetectorRef.markForCheck();
    }


   async  refreshNotifications(){
        this.notifications = await this._userService.getNotification(this.userData.uuidSSO,this.userData.appToken);
        this.unreadCount = await this.notifications.length;
        this._changeDetectorRef.markForCheck();

    }
    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void
    {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
      //  this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    async delete(notification: Notification)
    {
        this.notifications = await this.notifications.filter(item => item.id != notification.id);
        this.unreadCount = await this.notifications.length;
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withLockedPosition(true)
                                  .withPush(true)
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
async viewCaso(element: any): Promise<any>{
    var e = {
                uuidCrmCustomer               : element.uuidCrmCustomer
               ,UUID_CRM_CUSTOMER             : element.uuidCrmCustomer
               ,UUID_CRM_CUSTOMER_INTERACTION : element.uuid    
            }

    const dialogRef = this._dialog.open(DialogCreateOrUpdateCasosComponent, {
      height: '100%',
      width: '100%',
      data: {
        type: 'update',
        element: e
      }
    }).afterClosed().subscribe(
      async(res: any) => {
        if (res) {
         // this.getList();
        await this.refreshNotifications()
        }
      }
    );
  }
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
//###########################################################
//############################################################
async editOrCreateClient(element: any): Promise<void> {
    var  dummy : Event; 
    const dialogRef = this._dialog.open(DetailClientComponent, {
      
      width: '100%',
      height: '100%',
        //autoFocus: false,
        data: {
            customer: null,
            type: 'view',
            tab:"customer",
            source: 'modal',
            matTabGroupSelected : 4,
            uuidCrmCustomer :  element.uuidCrmCustomer
  
        }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
        await this.refreshNotifications()
    });
  }
  //############################################################
  //###########################################################
}
