import { LOCALE_ID,NgModule   } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeCl from '@angular/common/locales/es-CL';
registerLocaleData(localeCl,'es-CL');
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FormsModule } from '@angular/forms';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { ToastrModule } from 'ngx-toastr';
import { MatNativeDateModule } from '@angular/material/core';
// import { CatalogComponent } from './components/catalog/catalog.component';
// import { RouterModule } from '@angular/router';
import { Error404Component } from './components/error/error404/error404.component';
import { Error500Component } from './components/error/error500/error500.component';
//import { RcComponent } from './components/rc/rc.component';

// import { OmsComponent } from './components/oms/oms.component';
// import { POSComponent } from './components/pos/pos.component';
// import { ClientesComponent } from './components/clientes/clientes.component';
// import { CasosComponent } from './components/casos/casos.component';
// import { ProfileComponent } from './components/profile/profile.component';
// import { SettingsComponent } from './components/settings/settings.component';
// import { UsersComponent } from './components/users/users.component';
// import { ReceptoresComponent } from './components/receptores/receptores.component';
// import {MatAutocompleteModule} from '@angular/material/autocomplete';



const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};



@NgModule({
    declarations: [
        AppComponent,
        Error404Component,
        Error500Component,
       // RcComponent,


        // POSComponent,
        // OmsComponent,
        // CasosComponent,
        // ProfileComponent,
        // ClientesComponent,
        // SettingsComponent,
        // ReceptoresComponent,
        // CatalogComponent,
        // RUTformatPipe
    ],
    imports     : [
        BrowserModule,
     //   CommonModule,
        // ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        FormsModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        // Toastr
        ToastrModule.forRoot(),

        // ANGULAR MATERIAL
        MatNativeDateModule,
        // MatAutocompleteModule
    ],
    providers   : [
         { provide: LOCALE_ID,  useValue:  'es-CL' } 
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
