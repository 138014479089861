<b  class="w-1/2 prose prose-xl"><small>{{nombreFantasia  | titlecase}}</small></b>  &nbsp; &nbsp; &nbsp;


<!-- Button -->
<button
    mat-icon-button
    [matTooltip]="tooltip || 'Toggle Fullscreen'"
    (click)="toggleFullscreen()">
    <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<!-- Default icon -->
<ng-template #defaultIconTpl>
    <mat-icon [svgIcon]="'heroicons_outline:arrows-expand'"></mat-icon>
</ng-template>
