import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//import * as html2canvas from 'html2canvas';
import { DialogBugInfoComponent } from './dialog-bug-info/dialog-bug-info.component';


@Component({
  selector: 'report-bug',
  templateUrl: './report-bug.component.html',
  styleUrls: ['./report-bug.component.scss']
})
export class ReportBugComponent implements OnInit {

  constructor(
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  takeScreenshot() {
    /*
    html2canvas.default(document.body).then(canvas => {
      // Puedes hacer lo que quieras con el canvas aquí
      // Por ejemplo, puedes convertirlo a una imagen y descargarla
      let imgData = canvas.toDataURL('image/png');
      let a = document.createElement('a');
      a.href = imgData;
      a.download = 'screenshot.png';
      a.click();
    });*/
  }

  openDialog() {
    this._dialog.open(DialogBugInfoComponent, {
      width: '400px',
      data: {}
    });
  }
}
