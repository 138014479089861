import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from 'app/services/cliente.service';
import { SelectClientesComponent } from '../../clientes/select-clientes/select-clientes.component'


@Component({
  selector: 'app-dialog-create-or-update-casos',
  templateUrl: './dialog-create-or-update-casos.component.html',
  styleUrls: ['./dialog-create-or-update-casos.component.scss']
})
export class DialogCreateOrUpdateCasosComponent implements OnInit {

  casosForm: UntypedFormGroup;
  flexibleAttributesForm: UntypedFormGroup;
  notasForm: UntypedFormGroup;
  t1List: any[];
  t2List: any[];
  flexibleAttributes: any[];
  flexibleAttributesFiltered: any[]=[];
  flexibleAttributesValue: any[]=[];
  flexibleAttributesToCreate: any[] = [];
  flexibleAttributesValidate: false;
  t2ListFiltered: any[];
  vt2Selected: any;
  status: any[];
  customerList: any[];

  uuidCmEmployee =localStorage.getItem('uuidEmployee');
  /***********/
  // folioCtrl = new FormControl();
  /***********/
  flagShowCustomer = true;
  interaction: any={};
  ////////////////////////////////
  flagShowCustomerName = false;
  customerFancyName = '';
  customerRut = '';
  ///////////////////////////////


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _dialogRef: MatDialogRef<DialogCreateOrUpdateCasosComponent>,
    private _fb: FormBuilder,
    private _clienteService: ClienteService,
    private _dialog: MatDialog,
  ) {
    this.casosForm = this._fb.group({
      t1: [null,[Validators.required]],
      t2: [{value: null, disabled: true}],
      status: [0, [Validators.required]],
      txt: ['', [Validators.required, Validators.maxLength(500)]],
      customer: [1, [Validators.required]]
    });


    this.flexibleAttributesForm = this._fb.group({
      // FolioDoc: [''],
      // folio: [''],
      // refactura: [''],
      // montoReclamado: [''],
      // glosa: [''],
      // fecha: [''],
      // fechaHora: ['']
    });

  }
//######################################################
//######################################################
showSupplier(){
  const dialogRef = this._dialog.open(SelectClientesComponent, {
      
    height: '100%',
    width: '100%',
    data: {
       flagCustomer     : false
      ,flagSupplier     : false
      ,flagEmployee     : false
      ,flagAssociate    : false
      ,flagDistributor  : false
      ,flagDTE          : false
      ,flagALL          : true

    }
});
dialogRef.afterClosed().subscribe(async (result) => {

    this.casosForm.get('customer').setValue(result.UUID_CRM_CUSTOMER);
    this.flagShowCustomerName = true;
    this.customerFancyName    = result.CUSTOMER_FANCY_NAME;
    this.customerRut          = result.RUT;
});
}
//######################################################
//######################################################
  async ngOnInit(): Promise<void>{
    await this.casosForm.reset();;
    await this.getTypeInteractions();
    await this.getCustomers();
    if(this.data.type === 'update'){
      await this.getInteractionByUUID();
      this.casosForm.get('t2').enable();
    }
    //////////////////////
    if(this.data.uuidCrmCustomer ){
      this.casosForm.get('customer').setValue(this.data.uuidCrmCustomer);
      this.flagShowCustomer = false;
    }
    //////////////////////    

  }
//######################################################
async makeFlexibleForm(form,master): Promise<void>{
  const $this = this;
  var flex : any = [];
    return await new Promise (async function(resolve, reject) {
      try {
        //##################################
         for(let i =0; i< master.length; i++){
            flex.push(
                        { 
                             idCmInteractionFlexibleAttributes  : master[i].ID_CM_INTERACTION_FLEXIBLE_ATTRIBUTES
                            ,valueFlexibleAttributes            : form.value[master[i].NAME_PARAM_FORM]
                        }
                    );
         }
         resolve(flex)
        //##################################
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//######################################################
  async create(): Promise<void>{
    let tmp =  (JSON.parse(localStorage.getItem('ssoData'))) 
    //###################################
    let arrFlex = await this.makeFlexibleForm(this.flexibleAttributesForm,this.flexibleAttributesFiltered)
    //###################################    
    const data = {
       uuidCrmCustomer              : this.casosForm.value.customer
      ,uuidCmEmployee               : this.uuidCmEmployee
      ,idCmInteractionT2            : this.vt2Selected.ID_CM_INTERACTION_T2
      ,idCmInteractionStatus        : this.casosForm.value.status
      ,idParentCustomerInteraction  : 0
      ,flexibleAttributes           : arrFlex
      ,jCustomerInteraction         : {
        'txt': this.casosForm.value.txt,
        'date': new Date().toISOString(),
        'by': tmp.user.firstName + ' ' + tmp.user.lastName
      }
    };
    const resp = await this._clienteService.createInteraction(data);
    if(resp){
      this._dialogRef.close(true);
    }
  }
//######################################################
  async update(): Promise<void>{
    const user = ((JSON.parse(localStorage.getItem('emisorData'))).usrData[0]).USER;
    const data = {
       uuidCrmCustomer              : this.data.element.UUID_CRM_CUSTOMER
      ,uuidCrmCustomerInteraction   : this.interaction.UUID_CRM_CUSTOMER_INTERACTION
      ,idCmInteractionStatus        : this.casosForm.value.status
      ,jCustomerInteraction         : {
        'txt': this.casosForm.value.txt,
        'date': new Date().toISOString(),
        'by': user
      }
    } 
    const resp = await this._clienteService.updateInteraction(data);
    if(resp.status){
      this._dialogRef.close(true);
    };
  };
//######################################################
  asingValidators(): void{
    if(this.vt2Selected.FLAG_FLEXIBLE_ATTRIBUTES === 'Y'){
      this.flexibleAttributesFiltered.forEach((fa) => {
        if(fa.FLAG_MANDATORY_PARAM === 'Y'){
          this.flexibleAttributesForm.get(fa.NAME_PARAM_FORM).setValidators([Validators.required]);
        }
      });
    }
  }
//######################################################
  addToFormControl(): void{
    this.flexibleAttributesForm = this._fb.group({});
    this.flexibleAttributesFiltered.forEach((fa) => {
      this.flexibleAttributesForm.addControl(fa.NAME_PARAM_FORM, new FormControl(''));
    });
  }
//######################################################
  t1Selected(): void{
    this.casosForm.get('t2').enable();
    this.t2ListFiltered = this.t2List.filter(t2 => t2.ID_CM_INTERACTION_T1 === this.casosForm.value.t1);
    // this.t2List = this.t2List.map(t2 => { });
  }
//######################################################
  t2Selected(t2: any): void{
    this.vt2Selected = t2;
    // this.flexibleAttributesFiltered = [];
    if(t2.FLAG_FLEXIBLE_ATTRIBUTES === 'Y'){
      this.flexibleAttributesFiltered = this.flexibleAttributes.filter(fa => fa.ID_CM_INTERACTION_T2 === t2.ID_CM_INTERACTION_T2);
      this.flexibleAttributesFiltered.forEach((fa) => {
        fa.LIST_VALUES_PARAM = JSON.parse(fa.LIST_VALUES_PARAM);
      });
      this.addToFormControl();
    }

    this.asingValidators();

  }
//######################################################
  async getInteractionByUUID(): Promise<void>{
    const { interaction, flexibleAttributes } = await this._clienteService.getInteractionByUUID(this.data.element.UUID_CRM_CUSTOMER, this.data.element.UUID_CRM_CUSTOMER_INTERACTION);
    await interaction.forEach((element) => {
      element.J_CUSTOMER_INTERACTION = JSON.parse(element.J_CUSTOMER_INTERACTION);
    });


    this.interaction = interaction[0];
    this.flexibleAttributesValue = flexibleAttributes;

    this.casosForm.get('t1').setValue(interaction[0].T1);
    this.casosForm.get('t2').setValue(interaction[0].T2);
    this.casosForm.get('t2').setValue(interaction[0].T2);
    this.casosForm.get('customer').setValue(this.data.element.UUID_CRM_CUSTOMER);

    let estado = this.status.filter(item => item.ID_CM_INTERACTION_STATUS == interaction[0].ID_CM_INTERACTION_STATUS)[0]
    if(estado.FLAG_END == 'Y'){
      this.casosForm.get('status').setValue(interaction[0].ID_CM_INTERACTION_STATUS);
      this.casosForm.get('status').disable();
    }else{
      this.casosForm.get('status').setValue(interaction[0].ID_CM_INTERACTION_STATUS);
    }

    this.casosForm.get('status').setValue(interaction[0].ID_CM_INTERACTION_STATUS);

  }
//######################################################
  async getTypeInteractions(): Promise<void>{
    const { t1, t2, status, type, flexibleAttributes } = await this._clienteService.getTypeInteractions();
    this.t1List = t1;
    this.t2List = t2;
    this.status = status;
    this.flexibleAttributes = flexibleAttributes;
    let estado = this.status.filter(item => item.FLAG_START == 'Y')[0]
    if(estado.FLAG_START == 'Y') this.casosForm.get('status').setValue(estado.ID_CM_INTERACTION_STATUS);
  }
//######################################################
  async getCustomers(): Promise<void>{
    let tmp = await this._clienteService.getClientes();
   //console.log(data)
   this.customerList = tmp.activo;
  }
//######################################################
closeBtn(): void{
  this._dialogRef.close();
}
//######################################################
}
