<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<div *ngIf="uuidPsServicesCombination == 'add'">
        <!-- ##########################################################  -->
        <!-- ##########################################################  -->
        <div class="bg-white rounded-lg p-4">
            <!-- ##########################################################  -->
            <!-- ##########################################################  -->

            <div class="w-full">
                <!-- ##########################################################  --> 
                <div class="grid grid-cols-10">   
                    <div class="col-span-8">
                        <!-- ## -->
                            <h2 class="prose prose-xl"><b> Crear Combinación Productos </b></h2>                       
                           <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                        <!-- ## -->
                    </div>
                    <div class="col-span-1">
                        <!-- ## -->
                        <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                        <!-- ## -->
                    </div>
                </div>
                <!-- ##########################################################  -->    
        
            </div>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
        <mat-divider class="py-2"></mat-divider>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
                <form [formGroup]="combinationForm">
                    <div>
                        <!--//-->                        
                        <div class="grid grid-cols-3 gap-4">
                            <div class="grid grid-cols-7 gap-4">
                                <div class="col-span-6">
                                    <mat-slide-toggle color="primary" formControlName="enabledPsServices"> Activo </mat-slide-toggle>
                                </div>
                            </div>
                        </div>                     
                        <!--//-->                        
                        <div class="grid grid-cols-3 gap-4">
                            <div class="grid grid-cols-7 gap-4">
                                <div class="col-span-6">
                                    <mat-form-field class="w-full">
                                        <mat-label>Nombre Combinación</mat-label>
                                        <input matInput  placeholder="Nombre del producto" (input)="countCharacter('name')"  formControlName="namePsServices" />
                                        <mat-hint> {{countNameCharacter}} / 70 </mat-hint>
                                        <mat-error *ngIf="combinationForm.get('namePsServices').hasError('required')"> El nombre es requerido </mat-error>
                                        <mat-error *ngIf="combinationForm.get('namePsServices').hasError('maxlength')"> El nombre no puede ser mas de 70 caracteres </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!--//-->
                        <!--                         <div class="grid grid-cols-3 gap-4">
                            <div class="grid grid-cols-7 gap-4">
                                <div class="col-span-6">
                                    <mat-form-field>
                                        <mat-label>Tipo de Combinación</mat-label>
                                        <mat-select formControlName="uuidCombinationProperty">
                                            <mat-option
                                            *ngFor="let a of psCombinationProperty"
                                            [value]="a.UUID_COMBINATION_PROPERTY"
                                            >
                                            {{ a.NAME_COMBINATION_PROPERTY }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('uuidCombinationProperty').hasError('required')"> La Combinación es requerida </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                       -->
                        <!--#################################################################################################-->
                        <!--#################################################################################################-->
                        <div class="w-full">
                            <mat-card class="w-full">
                                <mat-card-title> Atributos del producto </mat-card-title>
                                <mat-card-content>
                                    <!-- ###########################################################  -->
                                    <div class="grid grid-cols-2 w-full" >
                                    <!-- ###########################################################  -->                        
                                        <div>
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->                                
                                            <mat-form-field appearance="fill" class="w-1/2">
                                                <mat-label>Seleccione Grupo de Atributos 1</mat-label>
                                                 <!-- ////////////// -->
                                                    <mat-select    formControlName="uuidPsCombinationProperty_a">
                                                        <ng-container *ngFor="let c of psServicesCombinationPropertyFilter_A">
                                                            <mat-option [value]="c.UUID_COMBINATION_PROPERTY"  (click)="setPsAttribGroup('A',c)">  
                                                                <b>{{ c.NAME_COMBINATION_PROPERTY }}</b> 
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-select>       
                                                 <!-- ////////////// -->                                                                         
                                            </mat-form-field>
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->                                
                                            <!-- // -->
                                            <br>
                                        </div>
                                    <!-- ###########################################################  -->
                                    <!-- ###########################################################  -->  
                                        <div *ngIf="psServicesCombinationPropertyFilter_B.length > 0" >
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->
                                            <mat-form-field appearance="fill" class="w-1/2">
                                                <mat-label>Seleccione Grupo de Atributos 2</mat-label>
                                                 <!-- ////////////// -->
                                                    <mat-select  formControlName="uuidPsCombinationProperty_b" >
                                                        <ng-container *ngFor="let c of psServicesCombinationPropertyFilter_B">
                                                            <mat-option [value]="c.UUID_COMBINATION_PROPERTY"  (click)="setPsAttribGroup('B',c)">  
                                                            
                                                                <b>{{ c.NAME_COMBINATION_PROPERTY }}</b> 
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-select>       
                                                 <!-- ////////////// -->                                                                         
                                            </mat-form-field>
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->
                                        </div>
                                    <!-- ###########################################################  -->
                                    </div>
                                    <!-- ###########################################################  -->                                              
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <!--#################################################################################################-->
                        <!--#################################################################################################-->
                        <!--//-->
                            <!--//-->
                            <div>
                                <div class="grid grid-cols-3 gap-4">
                                    <mat-form-field>
                                        <mat-label>Categoría</mat-label>
                                        <mat-select formControlName="category" (selectionChange)="setGroup($event.value)">
                                            <mat-option
                                            *ngFor="let category of categories"
                                            [value]="category.ID_PS_CATEGORY"
                                            >
                                            {{ category.NAME_PS_CATEGORY }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('category').hasError('required')"> La categoria es requerida </mat-error>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Sub Categoría</mat-label>
                                        <mat-select formControlName="group">
                                            <mat-option
                                            *ngFor="let group of groupsSelected"
                                            [value]="group.ID_PS_GROUP"
                                            >
                                            {{ group.NAME_PS_GROUP }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('group').hasError('required')">La Sub Categoría es requerido </mat-error>
                                    </mat-form-field>                                      
                                    <mat-form-field>
                                        <mat-label>Tipo</mat-label>
                                        <mat-select formControlName="type">
                                            <mat-option
                                            *ngFor="let type of types"
                                            [value]="type.ID_PS_TYPE"
                                            >
                                            {{ type.NAME_PS_TYPE }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('type').hasError('required')"> El tipo es requerido </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--//-->                                                      
                            <!--//-->                                                                                              
                    </div>
                </form>
            <!-- ##########################################################  -->
            <!-- ##########################################################  -->            
        </div>
        <!-- ##########################################################  -->
        <div >
            <button mat-raised-button color="primary" (click)="addCombination('ADD')" [disabled]="combinationForm.invalid"> <mat-icon>add</mat-icon>Crear Combinación</button>
        </div>    
        <!-- ##########################################################  -->
</div>            
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<div *ngIf="uuidPsServicesCombination != 'add'">
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="bg-white rounded-lg p-4">
        <!-- ##########################################################  -->
        <!-- ##########################################################  -->
        <div class="w-full">
            <!-- ##########################################################  --> 
            <div class="grid grid-cols-10">   
                <div class="col-span-8">
                    <!-- ## -->
                        <h2 class="prose prose-xl">Combinación Productos <b> {{combinationForm.get('namePsServices').value}} </b></h2>                       
                       <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
                    <!-- ## -->
                </div>
                <div class="col-span-1">
                    <!-- ## -->
                    <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                    <!-- ## -->
                </div>
            </div>
            <!-- ##########################################################  -->    
    
        </div>
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
    <mat-divider class="py-2"></mat-divider>
    <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->

                <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
                <form [formGroup]="combinationForm">
                    <div>
                        <!--//-->                        
                        <div class="grid grid-cols-3 gap-4">
                            <div class="grid grid-cols-7 gap-4">
                                <div class="col-span-6">
                                    <mat-slide-toggle color="primary" formControlName="enabledPsServices"> Activo </mat-slide-toggle>
                                </div>
                            </div>
                        </div>                     
                        <!--//-->                        
                        <div class="grid grid-cols-3 gap-4">
                            <div class="grid grid-cols-7 gap-4">
                                <div class="col-span-6">
                                    <mat-form-field class="w-full">
                                        <mat-label>Nombre Combinación</mat-label>
                                        <input matInput  placeholder="Nombre del producto" (input)="countCharacter('name')"  formControlName="namePsServices" />
                                        <mat-hint> {{countNameCharacter}} / 70 </mat-hint>
                                        <mat-error *ngIf="combinationForm.get('namePsServices').hasError('required')"> El nombre es requerido </mat-error>
                                        <mat-error *ngIf="combinationForm.get('namePsServices').hasError('maxlength')"> El nombre no puede ser mas de 70 caracteres </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!--//-->
                        <!-- 
                        <div class="grid grid-cols-3 gap-4">
                            <div class="grid grid-cols-7 gap-4">
                                <div class="col-span-6">
                                    <mat-form-field>
                                        <mat-label>Tipo de Combinación</mat-label>
                                        <mat-select formControlName="uuidCombinationProperty">
                                            <mat-option
                                            *ngFor="let a of psCombinationProperty"
                                            [value]="a.UUID_COMBINATION_PROPERTY"
                                            >
                                            {{ a.NAME_COMBINATION_PROPERTY }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('uuidCombinationProperty').hasError('required')"> La Combinación es requerida </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        -->
                        <!--#################################################################################################-->
                        <!--#################################################################################################-->
                        <div class="w-full">
                            <mat-card class="w-full">
                                <mat-card-title> Atributos del producto </mat-card-title>
                                <mat-card-content>
                                    <!-- ###########################################################  -->
                                    <div class="grid grid-cols-2 w-full" >
                                    <!-- ###########################################################  -->                        
                                        <div>
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->                                
                                            <mat-form-field appearance="fill" class="w-1/2">
                                                <mat-label>Seleccione Grupo de Atributos 1</mat-label>
                                                 <!-- ////////////// -->
                                                    <mat-select    formControlName="uuidPsCombinationProperty_a">
                                                        <ng-container *ngFor="let c of psServicesCombinationPropertyFilter_A">
                                                            <mat-option [value]="c.UUID_COMBINATION_PROPERTY"  (click)="setPsAttribGroup('A',c)">  
                                                                <b>{{ c.NAME_COMBINATION_PROPERTY }}</b> 
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-select>       
                                                 <!-- ////////////// -->                                                                         
                                            </mat-form-field>
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->                                
                                            <!-- // -->
                                            <br>
                                        </div>
                                    <!-- ###########################################################  -->
                                    <!-- ###########################################################  -->  
                                        <div *ngIf="psServicesCombinationPropertyFilter_B.length > 0" >
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->
                                            <mat-form-field appearance="fill" class="w-1/2">
                                                <mat-label>Seleccione Grupo de Atributos 2</mat-label>
                                                 <!-- ////////////// -->
                                                    <mat-select  formControlName="uuidPsCombinationProperty_b" >
                                                        <ng-container *ngFor="let c of psServicesCombinationPropertyFilter_B">
                                                            <mat-option [value]="c.UUID_COMBINATION_PROPERTY"  (click)="setPsAttribGroup('B',c)">  
                                                            
                                                                <b>{{ c.NAME_COMBINATION_PROPERTY }}</b> 
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-select>       
                                                 <!-- ////////////// -->                                                                         
                                            </mat-form-field>
                                            <!-- %%%%%%%%%%%%%%%%%%%% -->
                                        </div>
                                    <!-- ###########################################################  -->
                                    </div>
                                    <!-- ###########################################################  -->                                              
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <!--#################################################################################################-->
                        <!--#################################################################################################-->                        
                        <!--//-->
                            <!--//-->
                            <div>
                                <div class="grid grid-cols-3 gap-4">
                                    <mat-form-field>
                                        <mat-label>Categoría</mat-label>
                                        <mat-select formControlName="category" (selectionChange)="setGroup($event.value)">
                                            <mat-option
                                            *ngFor="let category of categories"
                                            [value]="category.ID_PS_CATEGORY"
                                            >
                                            {{ category.NAME_PS_CATEGORY }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('category').hasError('required')"> La categoria es requerida </mat-error>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Sub Categoría</mat-label>
                                        <mat-select formControlName="group">
                                            <mat-option
                                            *ngFor="let group of groupsSelected"
                                            [value]="group.ID_PS_GROUP"
                                            >
                                            {{ group.NAME_PS_GROUP }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('group').hasError('required')"> La Sub Categoría es requerido </mat-error>
                                    </mat-form-field>                                     
                                    <mat-form-field>
                                        <mat-label>Tipo</mat-label>
                                        <mat-select formControlName="type">
                                            <mat-option
                                            *ngFor="let type of types"
                                            [value]="type.ID_PS_TYPE"
                                            >
                                            {{ type.NAME_PS_TYPE }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="combinationForm.get('type').hasError('required')"> El tipo es requerido </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--//-->   
                            <!--//-->   
                                           
                    </div>
                </form>
                <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
        <!-- ##########################################################  -->
        <!-- ##########################################################  -->
    </div>                
        <!-- ##########################################################  -->
                                    <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
                                    <p>Creado:<small>{{this.dataCombination?.EMPLOYEE_DATA_CREATION?.LOGIN || '1-9' |rut:'DOTS_DASH'}}</small><b> {{this.dataCombination?.EMPLOYEE_DATA_CREATION?.FIRST_NAME}} {{this.dataCombination?.EMPLOYEE_DATA_CREATION?.LAST_NAME}} {{this.dataCombination?.DATE_CREATION || '1900-01-01 00:00:00' | date:'dd/MM/yyyy HH:mm'}}</b></p>
                                    <p>Actualizado:<small>{{this.dataCombination?.EMPLOYEE_DATA_UPDATE?.LOGIN|| '1-9'  |rut:'DOTS_DASH'}}</small><b> {{this.dataCombination?.EMPLOYEE_DATA_UPDATE?.FIRST_NAME}} {{this.dataCombination?.EMPLOYEE_DATA_UPDATE?.LAST_NAME}} {{this.dataCombination?.DATE_UPDATE   || '1900-01-01 00:00:00' | date:'dd/MM/yyyy HH:mm'}}</b></p>
                                <!--&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->  
        <div >
            <button mat-raised-button color="primary" (click)="udpCombination('UDP')" [disabled]="combinationForm.invalid"> <mat-icon>add</mat-icon>Actualizar Combinación</button>
        </div>    
        <!-- ##########################################################  -->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<br>
<mat-divider></mat-divider>
<br>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<div>
    <div >
        <button mat-raised-button color="primary" (click)="addPS()"> <mat-icon>add</mat-icon>Agregar Productos</button>
    </div> 
    <div>
        <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/4">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." #input>
            <mat-icon matPrefix> find_in_page </mat-icon> 
        </mat-form-field>
    </div>
    <!-- ================================================================== -->
        <mat-table [dataSource]="tableCombinationDetail" class="mat-elevation-z8">
            <!--//-->                    
            <ng-container matColumnDef="img">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">img</mat-header-cell>
                <mat-cell *matCellDef="let o">
                                                <div class="flex items-center">
                                                    <div class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 rounded overflow-hidden border">
                                                        <img
                                                            class="w-8"
                                                            *ngIf="o.IMG_D !== '0'"
                                                            [alt]="'Product thumbnail image'"
                                                            [src]="urlImg + '/' + o.IMG_D + appImgSizeA">
                                                          
                                                        <div
                                                            class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase"
                                                            *ngIf="o.IMG_D === '0'">
                                                            NO IMG
                                                        </div>
                                                    </div>
                                                </div>
                </mat-cell>
            </ng-container>
            <!--//-->
            <ng-container matColumnDef="SKU">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">SKU/Nombre</mat-header-cell>
                <mat-cell *matCellDef="let o"><small><b (click)="editOrCreateProduct(o.UUID_PS_SERVICES)" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.COD_PS_SERVICES}}</b><br>{{o.NAME_PS_SERVICES}}</small></mat-cell>
            </ng-container>                       
            <!--//-->        
            <ng-container matColumnDef="price">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Neto</mat-header-cell>
                <mat-cell *matCellDef="let o"><b>{{o.PRICE.PRICE |  currency:'CLP':'symbol-narrow'}}</b></mat-cell>
            </ng-container>
            <!--//-->   
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Sub Categoría</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.NAME_PS_GROUP}}</small></mat-cell>
            </ng-container>
            <!--//-->   
            <ng-container matColumnDef="category">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Categoria</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.NAME_PS_CATEGORY}}</small></mat-cell>
            </ng-container>
            <!--//-->   
            <ng-container matColumnDef="tipo">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Tipo</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.NAME_PS_TYPE}}</small></mat-cell>
            </ng-container>
            <!--//--> 
            <ng-container matColumnDef="activo">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">activo</mat-header-cell>
                <mat-cell *matCellDef="let o">
                                                <div *ngIf="o.ENABLED_PS_SERVICES === 'Y' ? true : false"><mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check'"></mat-icon></div>
                                                <div *ngIf="o.ENABLED_PS_SERVICES === 'N' ? true : false"><mat-icon class="text-red-400 icon-size-5"   [svgIcon]="'heroicons_solid:x'"></mat-icon></div>
                </mat-cell>
            </ng-container>
            <!--//-->  
            <!--//--> 
            <ng-container matColumnDef="root">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">Principal</mat-header-cell>
                <mat-cell *matCellDef="let o">
                                                <div *ngIf="o.FLAG_PRIMARY === 'Y' ? true : false"><mat-icon class="text-purple-400 icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon></div>
                                                <!-- <div *ngIf="o.FLAG_PRIMARY === 'N' ? true : false"><mat-icon class="text-red-400 icon-size-5"   [svgIcon]="'heroicons_solid:x'"></mat-icon></div>-->
                </mat-cell>
            </ng-container>
            <!--//-->              
            
        <!--//-->   
        <!--//-->   
                                        <!--//-->   
                                            <!--//-->                                                
                                            <ng-container matColumnDef="combinacion_A">
                                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Combinación 1</mat-header-cell>
                                                <mat-cell *matCellDef="let o">{{o.NAME_COMBINATION_PROPERTY_A}}</mat-cell>
                                            </ng-container>               
                                            <!--//-->   
                                            <ng-container matColumnDef="atributos_A">
                                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Tipo Combinación 1</mat-header-cell>
                                                <mat-cell *matCellDef="let o">
                                                            <div>
                                                                <!--// TYPE_COMBINATION_PROPERTY -->
                                                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_A == 'COLOR'" >
                                                                        <!--//-->
                                                                        <small>
                                                                                {{o.NAME_COMBINATION_PROPERTY_A}}
                                                                                <br>
                                                                                {{o.TYPE_COMBINATION_PROPERTY_A}}   
                                                                        </small>                                                                     
                                                                                <mat-icon   [ngStyle]="{'color': o.VALUE_COMBINATION_PROPERTY_A }"  [svgIcon]="'heroicons_solid:document'"></mat-icon>

                                                                          <small><b>{{ o.VALUE_COMBINATION_PROPERTY_A }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_A }}   </small>
                                                                                                           
                                                                        <!--//-->                                
                                                                    </div>
                                                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_A == 'TXT'  " >    
                                                                        <!--//-->
                                                                        <small>
                                                                                {{o.NAME_COMBINATION_PROPERTY_A}}
                                                                                <br>
                                                                                <b>{{ o.VALUE_COMBINATION_PROPERTY_A }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_A }}                                        
                                                                        </small>
                                                                        <!--//-->                                
                                                                    </div>                                                                  
                                                                <!--//-->
                                                            </div>
                                                    </mat-cell>
                                            </ng-container>                        
                                            <!--//-->   
                                        <!--//-->   
                                            <!--//-->                                                
                                            <ng-container matColumnDef="combinacion_B">
                                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Combinación 2</mat-header-cell>
                                                <mat-cell *matCellDef="let o">{{o.NAME_COMBINATION_PROPERTY_B}}</mat-cell>
                                            </ng-container>               
                                            <!--//-->   
                                            <ng-container matColumnDef="atributos_B">
                                                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Tipo Combinación 2</mat-header-cell>
                                                <mat-cell *matCellDef="let o">
                                                            <div>
                                                                <!--// TYPE_COMBINATION_PROPERTY -->
                                                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_B == 'COLOR'" >
                                                                        <!--//-->
                                                                        <small>
                                                                                {{o.NAME_COMBINATION_PROPERTY_B}}
                                                                                <br>
                                                                                {{o.TYPE_COMBINATION_PROPERTY_B}}
                                                                        </small>
                                                                                <mat-icon   [ngStyle]="{'color': o.VALUE_COMBINATION_PROPERTY_B }"  [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                                                                <small><b>{{ o.VALUE_COMBINATION_PROPERTY_B }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_B }}</small>                                        
                                                                        <!--//-->                                
                                                                    </div>
                                                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_B == 'TXT'  " >    
                                                                        <!--//-->
                                                                        <small>
                                                                                  {{o.NAME_COMBINATION_PROPERTY_B}}
                                                                                  <br>
                                                                                <b>{{ o.VALUE_COMBINATION_PROPERTY_B }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_B }}                                        
                                                                        </small>
                                                                        <!--//-->                                
                                                                    </div>                                                                  
                                                                <!--//-->
                                                            </div>
                                                    </mat-cell> 
                                            </ng-container>                        
                                            <!--//-->     
            <!--//-->                                                          
            <!--//-->              
            <ng-container matColumnDef="acc">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                <mat-cell *matCellDef="let o">
                    <!-- //////////////////////////////////////////////////  -->
                        <div >
                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="delPS(o)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button>
                            <button *ngIf="o.FLAG_PRIMARY === 'N' "class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="setRoot(o)"> <mat-icon class="text-purple-400 icon-size-5" color="prymary" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon> </button>
                        </div>
                    <!-- //////////////////////////////////////////////////  -->                                    
                </mat-cell>
            </ng-container>
            <!--//-->               
            <mat-header-row *matHeaderRowDef="displayedColumnCatalogo" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
            <mat-row *matRowDef="let row;columns: displayedColumnCatalogo" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
            <!--//-->                    
        </mat-table>
            <!--//-->      
            <mat-spinner *ngIf="isLoadingCombination" style="margin:0 auto"></mat-spinner>         
            <mat-paginator
            #pagCombinationDetail
            [pageSize]="pag"
            showFirstLastButtons
            [pageSizeOptions]="pageSizeOptions" 
            aria-label="Select page of periodic elements">
        </mat-paginator>               
        <!--//-->     
    <!-- ================================================================== -->
</div>
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!-- ///////////////////////////////////////////////////////////////////////////-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->     
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
</div>   