import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, of, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { RcService } from  'app/services/rc.service';
import { ClienteService } from 'app/services/cliente.service';
import { environment } from 'environments/environment';
//
import { MatDialog } from '@angular/material/dialog';
import { DialogCreateUpdateInteractionComponent } from '../../clientes/detail-client/dialog-create-update-interaction/dialog-create-update-interaction.component';
import { SelectAttributesComponent } from '../../clientes/select-attributes/select-attributes.component';
import { SelectContactComponent } from '../../clientes/select-contact/select-contact.component';
//
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
//
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';
//
import {ClaimComponent} from '../../ordenes/claim/claim.component'
import { DialogDetailComponent } from '../../ordenes/dialog-detail/dialog-detail.component';
import { OrderService } from 'app/services/orders.service'; 
import { SelectRcProductsComponent } from '../select-rc-products/select-rc-products.component'
//
import { Buffer } from "buffer";
//
@Component({
  selector: 'app-detail-rc',
  templateUrl: './detail-rc.component.html',
  styleUrls: ['./detail-rc.component.scss']
})
export class DetailRcComponent implements OnInit {
  //######################################################
  rcForm: UntypedFormGroup;
  rcAttributesTypeForm: UntypedFormGroup;
  flagInstallments : boolean = false;
  minDateInfo = new Date(2000, 0, 1);
  today = new Date();
  maxDate = new Date(9999, 11, 31)

  months = environment.months
  monthSelected: number = 1;
  yearSelected: number = new Date().getFullYear();
  //today: number = new Date().getTime();
  last5Years: any[] = [];  
  //
  arrDueDate = [
                   {cod:15 ,gls:"15" }
                  ,{cod:30 ,gls:"30" }
                  ,{cod:45 ,gls:"45" }
                  ,{cod:60 ,gls:"60" }
                  ,{cod:90 ,gls:"90" }
                  ,{cod:120,gls:"120"}
              ]

arrmake = [
                {cod:1  ,gls:"01" }
               ,{cod:15 ,gls:"15" }
               ,{cod:30 ,gls:"30" }
           ]    
           
arrIndExe = [
             {cod:"A" ,gls:"Afecto" }  
            ,{cod:"E" ,gls:"Exento" }
            ]               
arrType = [
              {cod:"A" ,gls:"Activo" }  
             ,{cod:"I" ,gls:"Inactivo" }
             ]               

arrOp = [
              {cod:"Y" ,gls:"Si" }  
             ,{cod:"N" ,gls:"No" }
             ]  
//
dataRC :any ={}
customerRelatedContact : any = []
customerRelatedAddress : any = []
//
@ViewChild('pagContact'  ) pagContact  : MatPaginator;    
displayedContact    : string[] = ['type','legalNumber','firstName','lastName','mail','phone','acc']
isLoadingContact    : boolean = false;
dataContact         : any = [];
tableContact        : any = [];
//
@ViewChild('pagPs'  ) pagPs  : MatPaginator;    
displayedPs    : string[] = ['COD_PS_SERVICES','NAME_PS_SERVICES','NAME_CURRENCY','PRICE','DISCOUNT','DATE_DISCOUNT','GLS','QUANTITY_PS_SERVICES','TOTAL','WAREHOUSE','EMPLOYEE_CREATE','EMPLOYEE_UPDATE','DATE_CREATION','DATE_UPDATE','acc']
isLoadingPs    : boolean = false;
dataPs         : any = [];
tablePs        : any = [];
//
@ViewChild('pagPsDel'  ) pagPsDel  : MatPaginator;    
displayedPsDel    : string[] = ['COD_PS_SERVICES','NAME_PS_SERVICES','NAME_CURRENCY','PRICE','DISCOUNT','DATE_DISCOUNT','GLS','QUANTITY_PS_SERVICES','TOTAL','WAREHOUSE','EMPLOYEE_CREATE','EMPLOYEE_UPDATE','DATE_CREATION','DATE_UPDATE']
isLoadingPsDel    : boolean = false;
dataPsDel         : any = [];
tablePsDel        : any = [];
//
@ViewChild('pagAttributes'  ) pagAttributes : MatPaginator;    
displayedAttributes    : string[] = ['FLAG_RECURRENT','VALUE_RC_ATTRIBUTES_TYPE','DATE_CREATE','acc']
isLoadingAttributes    : boolean = false;
dataAttributes         : any = [];
tableAttributes        : any = [];
//
@ViewChild('pagOrder'    ) pagOrder    : MatPaginator;
displayedOrder      : string[] = ['FRIENLY_EOM_ORDER','TOTAL','NAME_COD_REASON_CODE', 'COD_SP','DATE_CREATION','DATE_UPDATE','UUID_EOM_ORDER']
isLoadingOrder      : boolean = false;
dataOrder           : any = [];
tableOrder          : any = [];
//
@ViewChild('pagMessage'    ) pagMessage    : MatPaginator;
displayedMessage      : string[] = ['tipo', 'message','employeeName', 'date'];
isLoadingMessage      : boolean = false;
dataMessage           : any = [];
tableMessage          : any = [];
//
matTabDteSelected = 0;
matTabOOFF        = 0;
//
attributes : any =[]
//
rcAttributesType : any = []
rcAttributesTypeSelected : any = [
                                    {
                                       "ID_RC_ATTRIBUTES_TYPE"    : null
                                      ,"COD_RC_ATTRIBUTES_TYPE"   : null
                                      ,"NAME_RC_ATTRIBUTES_TYPE"  : null
                                      ,"FLAG_RECURRENT"           : null
                                   }
                                  ]
//
@ViewChild('autosize') autosize: CdkTextareaAutosize;
//


//######################################################
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _dialogRef: MatDialogRef<DetailRcComponent>,
    private _fb: FormBuilder,
    private _fuseConfirmationService: FuseConfirmationService,
    private _rc:RcService,
    private ref: ChangeDetectorRef,
    private _clienteService:ClienteService,
    private _dialog: MatDialog,
    private _orderService : OrderService, 
  ) { 
    this.rcForm = this._fb.group({

       uuidRc                : [null]                          
      ,codOoff               : [null]
      ,uuidCrmCustomer       : [null]                                   
      ,dateStart             : [null]                               
      ,dateEnd               : [null]                             
      ,flagMakeOnPayment     : ['C']                                       
      ,dueDate               : [30]                             
      ,recurringDay          : [1]                                
      ,dueDateType           : ['IMT']                                 
      ,remove                : ['N']                                                       
      ,versionCodOoff        : [1]                                  
      ,txtToSend             : ['N/A']                               
      ,flagAtRenew           : ['Y'] 
      // 
      ,crmContact               : [Array]                    
      ,uuidContactoReceptor     : [null,[Validators.required]]
      ,contactoReceptorMail     : [null,[Validators.required]]
      //
      ,tipoDte               : ['A']                             
      ,flagAdicional         : ['N']                                   
      ,glosaAdicional        : ['N/A']                                  
      ,typeInvoice           : ['R']                                 
      ,flagInstallments      : ['Y']                                    
      ,totalInstallments     : [0]                                       
      ,numberInstallments    : [0]                                      
      ,statusRc              : ['I']    
    })

    this.rcAttributesTypeForm = this._fb.group({
       idRcAttributes        : [null]
      ,idRcAttributesType    : [null]
      ,valueRcRttributes_Tpe : [null]
      ,dateRcAttributesType  : [null]
      ,flagRecurrent         : [null]
    })
    this.monthSelected = new Date().getMonth() + 1;
    this.yearSelected = new Date().getFullYear();
    for (let i = 0; i < 5; i++) {
      this.last5Years.push(this.yearSelected - i);
    }   
    //==    
}
//###################################################### 
async  ngOnInit() {
  if(this.data.type == 'add'){
    this.setFlagInstallments('Y')
    await this.getCustomerRelated(this.data.rut ,null)
  }
  else{
        await this.getCustomerRelated(this.data.rut ,null)
        await this.getRcDetail(this.data.uuidRc)
        
        this.rcForm.patchValue({
                                     uuidRc                : this.dataRC.UUID_RC     
                                    ,codOoff               : this.dataRC.COD_OOFF
                                    ,uuidCrmCustomer       : this.dataRC.UUID_CRM_CUSTOMER              
                                    ,dateStart             : this.dataRC.DATE_START_TXT          
                                    ,dateEnd               : this.dataRC.V_DATE_END_TXT        
                                    ,flagMakeOnPayment     : this.dataRC.FLAG_MAKE_ON_PAYMENT                 
                                    ,dueDate               : Number(this.dataRC.DUE_DATE)      
                                    ,recurringDay          : Number(this.dataRC.RECURRING_DAY)        
                                    ,dueDateType           : this.dataRC.DUE_DATE_TYPE             
                                  //  ,remove                : this.dataRC.            flagInstallments
    
                                    ,versionCodOoff        : this.dataRC.VERSION_COD_OOFF          
                                    ,txtToSend             : await Buffer.from(this.dataRC.TXT_TO_SEND, 'base64').toString()           
                                    ,flagAtRenew           : this.dataRC.FLAG_AT_RENEW
                                    ,tipoDte               : this.dataRC.TIPO_DTE       
                                    ,flagAdicional         : this.dataRC.FLAG_ADICIONAL             
                                    ,glosaAdicional        : this.dataRC.GLOSA_ADICIONAL              
                                    ,typeInvoice           : this.dataRC.TYPE_INVOICE           
                                    ,flagInstallments      : this.dataRC.FLAG_INSTALLMENTS              
                                    ,totalInstallments     : this.dataRC.TOTAL_INSTALLMENTS               
                                    ,numberInstallments    : this.dataRC.NUMBER_INSTALLMENTS              
                                    ,statusRc              : this.dataRC.STATUS_RC
                                    //
                                    ,crmContact               : []                    
                                    ,uuidContactoReceptor     : ['a']
                                    ,contactoReceptorMail     : ['a']                                    
          });   
          //
          if(this.dataRC.FLAG_INSTALLMENTS == 'Y') this.flagInstallments= true;  else this.flagInstallments= false;
          await this.loadData('ps',this.dataPs)   
          await this.loadData('psDel',this.dataPsDel)   
          await this.loadData('contact',this.dataContact)    
          //
          await this.enableDisable('flagAdicional',this.dataRC.FLAG_ADICIONAL   )   
          this.rcForm.get('glosaAdicional').setValue(this.dataRC.GLOSA_ADICIONAL);


          await this.getRcAttributesType()
          await this.loadData('attributes',this.dataAttributes)  
          await this.getRcOrder(this.data.uuidRc,this.yearSelected, this.monthSelected)
          await this.loadData('order',this.dataOrder)  
          await this.loadData('message',this.dataMessage)  
          ////////////////////////////////
          this.rcForm.get('dateStart').disable();
          this.rcForm.get('dateEnd').disable();
          this.rcForm.get('recurringDay').disable();
          this.rcForm.get('dueDateType').disable();
          this.rcForm.get('numberInstallments').disable();
          this.rcForm.get('flagInstallments').disable();
          ///////////////////////////////
  }
}
//######################################################wz
setFlagInstallments(e){
  if(e == 'Y'){
    this.flagInstallments = true
    this.rcForm.get('totalInstallments').setValue(0)
    this.rcForm.get('totalInstallments').setValidators( [Validators.required, Validators.min(1),Validators.max(99)]);
    this.rcForm.get('dateStart').setValidators([]);
    this.rcForm.get('dateEnd').setValidators([]);
    this.rcForm.updateValueAndValidity();
  }
  else{
    this.flagInstallments = false
    this.rcForm.get('totalInstallments').setValue(1)
    this.rcForm.get('totalInstallments').setValidators( [Validators.required, Validators.min(0),Validators.max(99)]);
    this.rcForm.get('dateStart').setValidators([Validators.required]);
    this.rcForm.get('dateEnd').setValidators([Validators.required]);
    this.rcForm.updateValueAndValidity();    
  }
}
//######################################################
setRcAttributesType(e){
this.rcAttributesTypeSelected = this.rcAttributesType.filter(i => i.ID_RC_ATTRIBUTES_TYPE == e)[0]
this.dataAttributes = this.attributes.filter(i => i.ID_RC_ATTRIBUTES_TYPE == e)
this.loadData('attributes',this.dataAttributes)   
}
//######################################################
addAttributes(){
  let $this = this;
  //###############################################################
      const dialogRef = this._dialog.open(SelectAttributesComponent, {
        width: '80%',
        height: '60%',
          data: {
              uuidCrmCustomer          : this.dataRC.UUID_CRM_CUSTOMER   
              ,uuidRc                   : this.data.uuidRc
              ,rcAttributesTypeSelected : this.rcAttributesTypeSelected
              ,quantityAttributes       : this.dataAttributes.length
              ,dataAttributes           : this.dataAttributes

          }
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if(!!result){
          await this.getRcDetail(this.data.uuidRc)
          this.dataAttributes = await this.attributes.filter(i => i.ID_RC_ATTRIBUTES_TYPE == result.idRcAttributesType)
          await $this.loadData('attributes',this.dataAttributes)  
        }
      });  
  //###############################################################  
}
//######################################################
deleteAttributes(o){
let $this = this;
var attributes = {};
const dialogRefa = this._fuseConfirmationService.open({
  title: `Eliminar Atributo  ${o.COD_RC_ATTRIBUTES_TYPE} ${o.NAME_RC_ATTRIBUTES_TYPE}`,
  message: `valor: <b>${o.VALUE_RC_ATTRIBUTES_TYPE}<b>`,
  icon: {
    show: true,
    name: 'assignment_turned_in',
    color: 'primary'
  },    
  actions: {
      confirm: {
          label: 'Aceptar',
          color: 'primary',
      },
      cancel: {
           show: true,
          label: 'Cancelar',
      },
  },
});
dialogRefa.afterClosed().subscribe(async (result) => {
  if (result === 'confirmed') {
      //////////////////////////////////////////
        attributes = {
                           appToken                : null
                          ,language                : 'es'
                          ,uuidSSO                 : null
                          ,uuidRc                  :this.data.uuidRc      
                          ,detailRc                : [
                                                        {
                                                           idRcAttributes        : o.ID_RC_ATTRIBUTES
                                                          ,acction               : 'DEL'   
                                                        }
                          ]
                      }
          let j = await await this._rc.setRcAttributes(attributes)
          if(j.codStatus == 200 ){
            //==================================
                await this.getRcDetail(this.data.uuidRc)
                this.dataAttributes = await this.attributes.filter(i => i.ID_RC_ATTRIBUTES_TYPE == o.ID_RC_ATTRIBUTES_TYPE )
                await $this.loadData('attributes',this.dataAttributes) 
            //==================================
          }
      //////////////////////////////////////////                    
  } else {
      return;
  }
});

}
//######################################################
closeBtn(){
  this._dialogRef.close();
}
//######################################################
async getRcDetail(uuidRc): Promise<void> {
  let tmp = await this._rc.getRcDetail(uuidRc);
  this.dataRC      = tmp.dataSet.rc
  this.dataPs      = tmp.dataSet.rcDetail
  this.dataContact = tmp.dataSet.customerContact
  this.dataPsDel   = tmp.dataSet.rcDetailDel
  this.attributes  = tmp.dataSet.attributes
  this.dataMessage = tmp.dataSet.rcMessage
}
//######################################################
async getRcAttributesType(): Promise<void>{
  let tmp = await this._rc.getRcAttributesType()
  this.rcAttributesType = tmp.dataSet.attributesType
}
//######################################################
async getRcOrder(uuidRc,year,month): Promise<void>{
  let tmp = await this._rc.getRcOrder(uuidRc,'null',year,month)
  this.dataOrder = tmp.dataSet.rc
}
//######################################################
async loadData(op,data):Promise<any>{
    //==
    if( op == 'contact' ){
      this.isLoadingContact=true; 
      this.tableContact =  new MatTableDataSource(data);
      this.ref.detectChanges();
      this.tableContact.paginator = this.pagContact;
      this.isLoadingContact=false; 
      }
  //==             
    if( op == 'ps' ){
      this.isLoadingPs=true; 
      this.tablePs =  new MatTableDataSource(data);
      this.ref.detectChanges();
      this.tablePs.paginator = this.pagPs;
      this.isLoadingPs=false; 
      }     
  //==   
  if( op == 'psDel' ){
    this.isLoadingPsDel=true; 
    this.tablePsDel =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tablePsDel.paginator = this.pagPsDel;
    this.isLoadingPsDel=false; 
    }     
  //==   
  if( op == 'attributes' ){
    this.isLoadingAttributes=true; 
    this.tableAttributes =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tableAttributes.paginator = this.pagAttributes;
    this.isLoadingAttributes=false; 
    }     
  //==     
  if( op == 'order' ){
    this.isLoadingOrder=true; 
    this.tableOrder =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tableOrder.paginator = this.pagOrder;
    this.isLoadingOrder=false; 
    }     
  //==     
  if( op == 'message' ){
    this.isLoadingMessage=true; 
    this.tableMessage =  new MatTableDataSource(data);
    this.ref.detectChanges();
    this.tableMessage.paginator = this.pagMessage;
    this.isLoadingMessage=false; 
    }     
  //==               
  }  
//######################################################
async getCustomerRelated(rut: string,uuid: string): Promise<any>{
  var tmp
  tmp = await this._clienteService.customerRelated(rut,uuid);
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    this.customerRelatedContact =tmp.contact
    this.customerRelatedAddress =tmp.address
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$     
}
//###########################################################
setContactoReceptorMail(e){
  var tmp = this.customerRelatedContact.filter(i => i.UUID_CRM_CUSTOMER_CONTACT == e)[0]
  this.rcForm.get('contactoReceptorMail').setValue(tmp.MAIL)
}
//########################################################### 
numericOnly(event): boolean {    
  let patt = /^([0-9])$/;
  let result = patt.test(event.key);
  return result;
}

alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
   let patt = /^([a-zA-Z0-9])$/;
  let result = patt.test(e.key);
  return result;

}

onPaste(e) {
  e.preventDefault();
  return false;
}
//###########################################################
setIndefinido(val){
  if(val == true){
    //#############
    this.rcForm.get('dateStart').setValue(this.today);
    this.rcForm.get('dateEnd').setValue(this.maxDate);
    //#############
  }
  else{
    //#############
    this.rcForm.get('dateStart').setValue(this.today);
    this.rcForm.get('dateEnd').setValue(null);
    //#############            
  }
}
//###########################################################
createContact(){
    const dialogRef = this._dialog.open(DialogCreateUpdateInteractionComponent, {
      height: '100%',
      autoFocus: false,
      width: '800px',
      data: {
          customer: null,
          type: 'create',
          tab:"contact",
          uuidCustomer : this.data.uuidCustomer,
          rut          : this.data.rut,
          contact      : this.customerRelatedContact
      }
      });
      dialogRef.afterClosed().subscribe(async (result) => {
            this.rcForm.get('contactoReceptorMail').setValue('')
            await this.getCustomerRelated(this.data.rut,null)
      });
}
//###########################################################
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('/');
}
//###########################################################
editRcAttributes(){


}
//###########################################################
addOOFF(){
  var arrContact
  let $this = this;
  var ooff = {};
  const dialogRefa = this._fuseConfirmationService.open({
    title: `Se creara Orden de Facturación OOFF`,
    message: `OOFF sera creara como inactiva, ir a editar OOFF para completar la informacion requerida`,
    icon: {
      show: true,
      name: 'assignment_turned_in',
      color: 'primary'
    },    
    actions: {
        confirm: {
            label: 'Aceptar',
            color: 'primary',
        },
        cancel: {
             show: true,
            label: 'Cancelar',
        },
    },
  });

  dialogRefa.afterClosed().subscribe(async (result) => {
    if (result === 'confirmed') {
        //////////////////////////////////////////
          this.rcForm.enable();
          arrContact = [ { uuidCrmCustomerContact: $this.rcForm.value.uuidContactoReceptor,acction:"ADD" }]
          ooff = {
                     appToken              : null
                    ,language              : 'es'
                    ,uuidSSO               : null
                    ,uuidRc                : null                          
                    ,codOoff               : null
                    ,uuidCrmCustomer       : $this.data.uuidCustomer                                   
                    ,dateStart             : $this.formatDate($this.rcForm.value.dateStart)                               
                    ,dateEnd               : $this.formatDate($this.rcForm.value.dateEnd)                        
                    ,flagMakeOnPayment     : $this.rcForm.value.flagMakeOnPayment                                 
                    ,dueDate               : $this.rcForm.value.dueDate                        
                    ,recurringDay          : $this.rcForm.value.recurringDay                       
                    ,dueDateType           : $this.rcForm.value.dueDateType                          
                    ,remove                : $this.rcForm.value.remove                       
                    ,idCurrency            : $this.rcForm.value.idCurrency                          
                    ,versionCodOoff        : $this.rcForm.value.versionCodOoff                             
                    ,txtToSend             : $this.rcForm.value.txtToSend                     
                    ,flagAtRenew           : $this.rcForm.value.flagAtRenew
                    // 
                    ,crmContact               : arrContact              
                    //
                    ,tipoDte               : $this.rcForm.value.tipoDte                        
                    ,flagAdicional         : $this.rcForm.value.flagAdicional                             
                    ,glosaAdicional        : $this.rcForm.value.glosaAdicional                            
                    ,typeInvoice           : $this.rcForm.value.typeInvoice                             
                    ,flagInstallments      : $this.rcForm.value.flagInstallments                         
                    ,totalInstallments     : $this.rcForm.value.totalInstallments                                  
                    ,numberInstallments    : $this.rcForm.value.numberInstallments                                 
                    ,statusRc              : $this.rcForm.value.statusRc    
                    ,acction               : 'ADD'   
                }             
            let j = await await this._rc.setRc(ooff)
            if(j.codStatus == 200 ){
              this._dialogRef.close({reload:true});
            }        
        //////////////////////////////////////////                    
    } else {
        return;
    }
  });
}
//###########################################################
refreshRc(e,o){

}
//###########################################################
enableDisable(form,val){
  //
  if(form == 'flagAdicional'){
    if( val == 'Y' ){
      //==
      this.rcForm.get('glosaAdicional').setValidators([Validators.required]);
      this.rcForm.get('glosaAdicional').enable();
      this.rcForm.get('glosaAdicional').setValue(null);
      this.rcForm.updateValueAndValidity();
      //==            
}
else{
      //==
      this.rcForm.get('glosaAdicional').setValidators([]);
      this.rcForm.get('glosaAdicional').setValue('N/A');
      this.rcForm.get('glosaAdicional').disable();
      this.rcForm.updateValueAndValidity();
      //==
}    
  }
  //
}
//###########################################################
addContact(){
  var contactDup
  var contact
  let $this = this;
  const dialogRef = this._dialog.open(SelectContactComponent, {
    height: '800px',
    autoFocus: false,
    width: '800px',
    data: {
        uuidCustomer : this.data.uuidCustomer,
        rut          : this.data.rut
    }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if(!!result){
        //==========================================================
          contactDup = await this.dataContact.filter(i => i.UUID_CRM_CUSTOMER_CONTACT == result.dataContact.UUID_CRM_CUSTOMER_CONTACT)
          if(contactDup.length > 0){
            //===================================================
                    const dialogRef = this._fuseConfirmationService.open({
                      title: `Contacto Duplicado `,
                      message: `${result.dataContact.FIRST_NAME} ${result.dataContact.LAST_NAME}`,
                      icon: {
                          show: true,
                          name: 'delete',
                          color: 'warn'
                      },
                      actions: {
                          confirm: {
                              label: 'Aceptar',
                              color: 'warn'
                          },
                          cancel: {
                               show:false,
                              label: 'Cancelar',
                          }
                      }
                  });
                  dialogRef.afterClosed().subscribe(async (result) => {
                      return false;
                  });          
            //===================================================
          }
          else{
            contact = {
                         appToken                : null
                        ,language                : 'es'
                        ,uuidSSO                 : null
                        ,uuidRc                  : this.data.uuidRc         
                        ,crmContact              : [ { uuidCrmCustomerContact: result.dataContact.UUID_CRM_CUSTOMER_CONTACT,acction:"ADD" }]
                        
                      }
            let j = await await this._rc.setRcContact(contact)
            if(j.codStatus == 200 ){
              //===========================================
               await $this.getRcDetail($this.data.uuidRc)
               await $this.loadData('contact',$this.dataContact)    
              //===========================================
            }
          }
        //==========================================================
       }
    });
}
//###########################################################
deleteContact(o){
  var contact
  let $this = this;
      const dialogRef = this._fuseConfirmationService.open({
        title: `Eliminar Contacto `,
        message: `${o.FIRST_NAME} ${o.LAST_NAME}`,
        icon: {
            show: true,
            name: 'delete',
            color: 'warn'
        },
        actions: {
            confirm: {
                label: 'Aceptar',
                color: 'warn'
            },
            cancel: {
                show:true,
                label: 'Cancelar',
            }
        }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
        //===========================================
        contact = {
                       appToken                : null
                      ,language                : 'es'
                      ,uuidSSO                 : null
                      ,uuidRc                  : this.data.uuidRc         
                      ,crmContact              : [ { uuidCrmCustomerContact: o.UUID_CRM_CUSTOMER_CONTACT,acction:"DEL" }]
         
                }
          let j = await await this._rc.setRcContact(contact)
          if(j.codStatus == 200 ){
          //===========================================
          await $this.getRcDetail($this.data.uuidRc)
          await $this.loadData('contact',$this.dataContact)    
          //===========================================
          }
        //===========================================        
    });   
}
//###########################################################
refreshOOFF(e,v){

}
//###########################################################
orderDetail(uuidEomOrder: string): void {
  this._orderService.getOrdersDetail(uuidEomOrder).then((res) => {
    // console.log(res);

    const dialogRef =  this._dialog.open(DialogDetailComponent, {
      //width: '1200px',
      //height: '600px',
      width: '100%',
      height: '100%',
      data: res.dataSet
    });
    dialogRef.afterClosed().subscribe(async result => {
     // await this.getOrders(this.yearSelected,this.monthSelected);
       if(!!result){
        this.ngOnInit();
       }
     });    
  })
}
//###########################################################
async returnOrder(UUID_EOM_ORDER : string){ 
  const dialogRef = this._dialog.open(ClaimComponent, {
    width: '100%'
   ,data: {
              UUID_EOM_ORDER: UUID_EOM_ORDER
            ,uuidSpEmployee : null
            ,uuidSp         : null
      }
  });
  dialogRef.afterClosed().subscribe(async result => {
   //await this.getOrders(this.yearSelected,this.monthSelected);
    if(!!result){
  
    }
  });
  };
//###########################################################  
addPs(){
  var contactDup
  var contact
  let $this = this;
  const dialogRef = this._dialog.open(SelectRcProductsComponent, {
    height: '900px',
    autoFocus: false,
    width: '900px',
    data: {
         uuidCustomer : this.data.uuidCustomer
        ,uuidRc       : this.data.uuidRc
        ,ooff         : this.dataRC.COD_OOFF + '-' + this.dataRC.VERSION_COD_OOFF
    }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if(!!result){
        //==========================================================
          if(result.reload == true){
            this.ngOnInit();
          }
        //==========================================================
       }
    });
}
//###########################################################  
delPs(o){
  var dataPS
  let $this = this;
      const dialogRef = this._fuseConfirmationService.open({
        title: `Eliminar Producto `,
        message: `${o.COD_PS_SERVICES} ${o.NAME_PS_SERVICES}`,
        icon: {
            show: true,
            name: 'delete',
            color: 'warn'
        },
        actions: {
            confirm: {
                label: 'Aceptar',
                color: 'warn'
            },
            cancel: {
                show:true,
                label: 'Cancelar',
            }
        }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
        //===========================================
        dataPS = {
                            appToken                : null
                          ,language                : 'es'
                          ,uuidSSO                 : null
                          ,uuidRc                  : this.data.uuidRc      
                          ,detailRc                : [
                                                        {
                                                          uuidRcDetail           : o.UUID_RC_DETAIL                                                        
                                                         ,action                  : 'DEL'   
                                                        }
                                                      ]
                        }
                  let j = await await this._rc.setRcDetail(dataPS)
                  if(j.codStatus == 200 ){
                    //===========================================
                    this.ngOnInit();
                    //===========================================
                  }
        //===========================================        
    });   
}
//###########################################################  
udpOOFF(){
  var ooff
  let $this = this;
      const dialogRef = this._fuseConfirmationService.open({
        title: `Actualizar OOFF `,
        message: `${this.rcForm.value.codOoff}-${this.rcForm.value.versionCodOoff}`,
        icon: {
            show: true,
            name: 'assignment_turned_in',
            color: 'primary'
        },
        actions: {
            confirm: {
                label: 'Aceptar',
                color: 'primary'
            },
            cancel: {
                show:true,
                label: 'Cancelar',
            }
        }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
        //===========================================
        $this.rcForm.get('glosaAdicional').enable();
       
        ooff = {
                   appToken              : null
                  ,language              : 'es'
                  ,uuidSSO               : null
                  ,uuidRc                : this.data.uuidRc                         
                  ,dueDate               : $this.rcForm.value.dueDate                                                
                  ,txtToSend             : await Buffer.from($this.rcForm.value.txtToSend).toString('base64')                     
                  ,flagAtRenew           : $this.rcForm.value.flagAtRenew
                  //
                  ,tipoDte               : $this.rcForm.value.tipoDte                        
                  ,flagAdicional         : $this.rcForm.value.flagAdicional                             
                  ,glosaAdicional        : $this.rcForm.value.glosaAdicional                 
                  ,typeInvoice           : $this.rcForm.value.typeInvoice                             
                  ,statusRc              : $this.rcForm.value.statusRc    
                  ,acction               : 'UDP'   
              }             
          let j = await await this._rc.setRc(ooff)
          $this.rcForm.get('glosaAdicional').disable();
          if(j.codStatus == 200 ){
            //this._dialogRef.close({reload:true});
            this.ngOnInit()
          }  
        //===========================================        
    });  
}
//###########################################################  
}

