


<div class="close">
    <button mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>
    <!-- <button mat-button (click)="test()"> TEST </button> -->
  </div>
<div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
    <!-- Title -->
    <div   class="text-4xl font-extrabold tracking-tight" >{{data.ooff}} </div>
</div>







<form [formGroup]="detalleForm" onsubmit="return false;">
    <div>
        <h1 class="prose">Detalle</h1>
        <br>

        <div *ngIf="spWarehouse.length >1 " class="flex">
            <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                <mat-label> Bodega</mat-label>
                <mat-select (selectionChange)="setSpWarehouse($event.value)" formControlName="uuidWareHouse">
                    <ng-container *ngFor="let wm of spWarehouse">
                        <mat-option [value]="wm.UUID_WAREHOUSE"> {{wm.COD_WAREHOUSE}} - {{wm.NAME_WAREHOUSE}} </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="spWarehouse.length == 1 " class="flex col-span-2">
            Bodega<br />
            {{selectWarehouse.COD_WAREHOUSE}} - {{selectWarehouse.NAME_WAREHOUSE}}
        </div>
        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
            <mat-label>SKU</mat-label>
            <input matInput   formControlName="VlrCodigo" readonly>
            <mat-icon  matSuffix> 123 </mat-icon>
        </mat-form-field>
        <button  mat-icon-button type="button" class="search-button" (click)="showProducts()"> <mat-icon class="icon-size-9"> search </mat-icon> </button>
        <button mat-raised-button color="accent" style="bottom: 17px" (click)="resetProducts()"> Limpiar </button>
    </div>
    <div>
        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
            <mat-label>Producto</mat-label>
            <input matInput  formControlName="NmbItem" readonly>
            <mat-icon  matSuffix> abc </mat-icon>
        </mat-form-field>

            <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                <mat-label *ngIf="flagOverwrite == true" ><b style="color: rgb(0, 122, 61)">Precio Unitario</b></mat-label>
                <mat-label *ngIf="flagOverwrite == false">Precio Unitario</mat-label>

                <b>{{detalleForm.value.symbolCurrency}}</b>
                    <input matInput type="number" min="0" *ngIf="flagOverwrite == true"  formControlName="PrcItem" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)">
                    <input matInput type="number" min="0" *ngIf="flagOverwrite == false" formControlName="PrcItem" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" readonly>
                <b>{{detalleForm.value.codCurrency}}</b>

                <mat-icon *ngIf="flagOverwrite == true"  matSuffix> create </mat-icon>
                <mat-icon *ngIf="flagOverwrite == false" matSuffix> payments </mat-icon>
            </mat-form-field>

        <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
            <mat-label>Cantidad</mat-label>
            <input type="number" min="1" [max]="maxDetalleItem" matInput  formControlName="QtyItem" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)">
            <mat-icon  matSuffix> inventory_2 </mat-icon>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="detalleForm.get('CodImpAdic').value != null" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen w-1/4">
            <mat-label>Impuesto adicional</mat-label>
            <input matInput formControlName="glsImpAdic" readonly>
        </mat-form-field>
        <!-- ###############################################  -->
        <div>
            <mat-form-field appearance="fill" class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet">
                <mat-label>Descuento</mat-label>
                <input matInput type="number" min="0" max="100" formControlName="DescuentoPct" value="0" (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)"> %
                <mat-error *ngIf="detalleForm.get('DescuentoPct').hasError('max')"> El valor no puede ser mayor a 100%</mat-error>
                <mat-icon  matSuffix> money_off </mat-icon>
            </mat-form-field>
        </div>
        <h1 class="prose">Vigencia Descuento</h1>
        <div>
            <section >
                <mat-checkbox class="example-margin" (change)="setIndefinido($event.checked)">Indefinido</mat-checkbox>
            </section>
        </div>  
        <div class="flex">           
            <mat-form-field class="w-1/3">
                    <mat-label>Fecha Inicio Descuento:</mat-label>   
                    <input matInput formControlName="dateStart" [matDatepicker]="emision" [min]="today" [value]="today" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="emision" ></mat-datepicker-toggle>
                    <mat-datepicker #emision ></mat-datepicker>
            </mat-form-field>   
            <mat-form-field class="w-1/3">
                    <mat-label>Fecha Fin Descuento:</mat-label>   
                    <input matInput formControlName="dateEnd" [matDatepicker]="fin" [min]="today" [value]="today" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="fin" ></mat-datepicker-toggle>
                    <mat-datepicker #fin ></mat-datepicker>
            </mat-form-field>                                                 
        </div>
        <!-- #################################################-->
    </div>
    <div>
        <mat-form-field class="fuse-mat-textarea desc">
            <mat-label> Descripción</mat-label>
            <textarea matInput formControlName="DscItem"></textarea>
        </mat-form-field>
    </div>
    <button mat-raised-button (click)="addProduct()" color="accent" class="addButton" [disabled]="detalleForm.invalid"> Añadir </button>
</form> 