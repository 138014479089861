<div class="close">
    <button mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>
  </div>



<div>
<mat-card >
        <mat-card-header >
            <mat-card-title class="text-lg font-semibold">Selecione Contacto</mat-card-title>
        </mat-card-header>
    <mat-card-content>
                <!-- ========================================= -->
                <!-- ========================================= -->
                <mat-table [dataSource]="tableContact" class="mat-elevation-z8">
                    <!--//-->                    
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-ps">Tipo</mat-header-cell>
                        <mat-cell *matCellDef="let o"><b>{{o.GLS_CONTACT_TYPE}}</b></mat-cell>
                    </ng-container>                        
                    <!--//-->                        
                    <ng-container matColumnDef="legalNumber">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Rut</mat-header-cell>
                        <mat-cell *matCellDef="let o"><small>{{o.LEGAL_NUMBER || '1-9' | rut:'DOTS_DASH'}}</small></mat-cell>
                    </ng-container>
                    <!--//-->   
                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Nombre</mat-header-cell>
                        <mat-cell *matCellDef="let o"><small>{{o.FIRST_NAME}} {{o.FIRST_NAME2}}</small></mat-cell>
                    </ng-container>
                    <!--//-->   
                    <ng-container matColumnDef="lastName">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Apellido</mat-header-cell>
                        <mat-cell *matCellDef="let o"><small>{{o.LAST_NAME}} {{o.LAST_NAME2}}</small></mat-cell>
                    </ng-container>
                    <!--//-->   
                    <ng-container matColumnDef="phone">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Telefono</mat-header-cell>
                        <mat-cell *matCellDef="let o"><small>{{o.PHONE}}</small></mat-cell>
                    </ng-container>
                    <!--//-->             
                    <ng-container matColumnDef="mail">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Email</mat-header-cell>
                        <mat-cell *matCellDef="let o">
                                    <div style="color: rgb(9, 9, 132)"><b>{{o.MAIL}}</b></div>
                            </mat-cell>
                    </ng-container>                 
                    <!--//-->                        
                    <ng-container matColumnDef="acc">
                        <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-add"></mat-header-cell>
                        <mat-cell *matCellDef="let o">
                            <!-- //////////////////////////////////////////////////  -->
                                <div><button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="addContact(o)"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'add'"></mat-icon> </button></div>
                            <!-- //////////////////////////////////////////////////  -->                                    
                        </mat-cell>
                    </ng-container>
                    <!--//-->               
                    <mat-header-row *matHeaderRowDef="displayedContact" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                    <mat-row *matRowDef="let row;columns: displayedContact" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    <!--//-->                    
                </mat-table>
                    <!--//-->      
                    <mat-spinner *ngIf="isLoadingContact" style="margin:0 auto"></mat-spinner>         
                    <mat-paginator
                    #pagContact
                    [pageSize]="pag"
                    showFirstLastButtons
                    [pageSizeOptions]="pageSizeOptions" 
                    aria-label="Select page of periodic elements">
                </mat-paginator>               
                <!--//-->    
                <!-- ========================================= -->
                <!-- ========================================= -->
        </mat-card-content>
</mat-card>
</div>