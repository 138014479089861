<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  -->
        <div class="grid grid-cols-3">       
            <div>
                <h2 class="prose prose-xl">Producto: <b>{{data.ps.NAME_PS_SERVICES }}</b></h2>
            </div>
            <div>
                <h2  *ngIf="data.FRIENLY_EOM_ORDER != null" class="prose prose-xl">Orden: <b>{{data.FRIENLY_EOM_ORDER }}</b></h2>
            </div> 
            <!-- ## -->
            <!-- ## -->
            <div>
                <button class="close" mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>         
            </div>
        </div>
        <!-- ##########################################################  -->
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<!-- ================================================================================================================== -->                
<!-- ================================================================================================================== --> 
    <h1><b>Customizar Producto</b></h1>
    <br>
    <!--##-->
    <div *ngIf="data.ps.FLAG_CUSTOMIZE == 'Y' ">
        <!--$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->
            <div *ngIf="data.ps.TYPE_CUSTOMIZE == 'IMG' "> 
                        <!--$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->   
                        <h1><b>{{data.ps.CONFIG_CUSTOMIZE.IMG.title}}</b></h1>             
                            <div class="w-full">
                                <input type="file" (change)="onFileChange($event, 'logo')" id="input-file" name="file" style="display: none" [accept]="data.ps.CONFIG_CUSTOMIZE.IMG.acceptExt" >
                                <button  mat-raised-button  onclick="document.querySelector('#input-file').click()" > <mat-icon>add_a_photo</mat-icon> Seleccionar Imagen</button>
                                
                                <br>
                                <b>Tamaño Mínimo: </b>
                                <span *ngIf="data.ps.CONFIG_CUSTOMIZE.IMG.minWidthPx  > 0">Largo:<b>{{data.ps.CONFIG_CUSTOMIZE.IMG.minWidthPx}} px </b></span>
                                <span *ngIf="data.ps.CONFIG_CUSTOMIZE.IMG.minHeightPx > 0">Ancho:<b>{{data.ps.CONFIG_CUSTOMIZE.IMG.minHeightPx}} px </b></span>
                                <br>
                                <b>Tamaño Máximo: </b>
                                <span *ngIf="data.ps.CONFIG_CUSTOMIZE.IMG.maxWidthPx  > 0">Largo:<b>{{data.ps.CONFIG_CUSTOMIZE.IMG.maxWidthPx}} px </b></span>
                                <span *ngIf="data.ps.CONFIG_CUSTOMIZE.IMG.maxHeightPx > 0">Ancho:<b>{{data.ps.CONFIG_CUSTOMIZE.IMG.maxHeightPx}} px </b></span>
                            </div>


                            <div>
                                <div class="w-52 h-44 border rounded overflow-hidden" >
                                    <ng-container >
                                        <img *ngIf="showButtonUpload == true" [src]="previewImageSrc" alt="" />
                                        <img *ngIf="showButtonUpload == false" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt="" />
                                    </ng-container>
                               </div>                                     
                            </div>
                            <br>
                            <button *ngIf="showButtonUpload == true" mat-raised-button color="primary" (click)="uploadFile()"> <mat-icon>upload</mat-icon>Agregar Imagen </button>
                        <!--$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->                
            </div>
            <!--%%-->
            <div *ngIf="data.ps.TYPE_CUSTOMIZE == 'FILE' ">
                {{data.ps.CONFIG_CUSTOMIZE.FILE.title}}
                Opcion no Disponbile
            </div>
            <!--%%-->
            <div *ngIf="data.ps.TYPE_CUSTOMIZE == 'TXT' ">
                    <form [formGroup]="customizeForm">
                        <!--$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->
                                <!--//-->                                
                                <div>
                                    <mat-form-field class="w-3/4">
                                        <mat-label>{{data.ps.CONFIG_CUSTOMIZE.TXT.title}}</mat-label>
                                        <input 
                                            matInput type="text" 
                                            placeholder="Customización" 
                                            formControlName="txtCustomize" 
                                            [minlength]="data.ps.CONFIG_CUSTOMIZE.TXT.minlength" 
                                            [maxlength]="data.ps.CONFIG_CUSTOMIZE.TXT.maxlength" 
                                        />
                                    </mat-form-field>                                    
                                </div>
                                <!--//-->   
                        <!--$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->  
                    </form>    
                    <button  mat-raised-button color="primary" [disabled]="customizeForm.invalid" (click)="addDetailCustomize()"> <mat-icon>save</mat-icon>Agregar Texto </button>                                                       
            </div>
            <!--%%-->
        <!--$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->
    </div>

<!-- ================================================================================================================== -->                
<!-- ================================================================================================================== --> 
 </div>
 </div>
