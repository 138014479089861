import { Component, Inject, OnInit, ViewChild , HostListener} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogService } from 'app/services/catalog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { split } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectClientesComponent } from '../../../clientes/select-clientes/select-clientes.component'
import { environment } from 'environments/environment';
import { Buffer } from "buffer";
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-dialog-clone',
  templateUrl: './dialog-clone.component.html',
  styleUrls: ['./dialog-clone.component.scss']
})
export class DialogCloneComponent implements OnInit {

  prefixEAN = '000000';
  ean13Generated: boolean = false;
  ean8Generated: boolean = false;

  infoForm: UntypedFormGroup;

  preciosForm: UntypedFormGroup;
  resumeForm : UntypedFormGroup;
  impuestosRetenidos: any[] = [];
  impuestosEspeciales: any[] = [];

  priceActive:any= [];
  percentProfitsMargin = 0;


  regularTax  : number =0;
  precioBruto : number =0;
  precioNetoNew : number =0;
  matTabPriceDetail : number =0;
  minBruto =0

  countNameCharacter: number = 0;
  countNameInvoiceCharacter: number = 0;
  countDescInvoiceCharacter: number = 0;
  countpsServicesDescriptionShort: number = 0;

  formatBarcode: any;
  codProd: any;

  typeBarcode: any[] = []

  editModeBarcode: boolean = false;

flagExternal : boolean = false;
flagExternalDisable : boolean = false;

product: any = {}
isProduct: boolean = false;

barcode: any;

charCodeSelected: any;

lastIdPriceOld: number = 0;
margenPrice = { amount:0,percent:0,txt:'',style:'',amountSalesRecomented:0,amountSalesRecomentedTax:0};


types: any[] = [];
groups: any[] = [];
brands: any[]
categories : any[] = [];
unitMeasurements : any[] = []
saleschannels : any =[]

cuentasContablesArray: any[] = [];

selectedSalesChannel: any[] = [];
//############################################################
codeByKeyboard: any = '';
isCodeByMachine: boolean = false;
focusInputCode: boolean = false;
flagRedLight:Boolean = false;
//############################################################
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
constructor(
  public dialog: MatDialog,
  public _dialogRef: MatDialogRef<DialogCloneComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _catalogService: CatalogService,
  private ref: ChangeDetectorRef,
  private _fb: FormBuilder,
  private _dialog: MatDialog,
  private _fuseConfirmationService: FuseConfirmationService,
  private _toast: ToastrService,

) { 
  this.product = {};
  this.infoForm = this._fb.group({
    name: ['', [Validators.required, Validators.maxLength(70)]],
    category: [null, [Validators.required]],
    group: [null, [Validators.required]],
    type: [null, [Validators.required]],
    SKU:   [null,[Validators.required]],
    barcode: [{ value: '', disabled: true }],
    price: [0, [Validators.required]],
    visible: [false, [Validators.required]],
    typeCod: [null,[Validators.required]],
    description: [null, [Validators.required, Validators.maxLength(5000)]],
    fechaInicio: [new Date()],
    fechaFin: [new Date(9999, 11, 31)],
    uuidBrands: [null,[Validators.required]],
    salesChannel: [Array],
    currentImg: [0],
    charCode:   [null,[Validators.required]],
    nameInvoice: ['', [Validators.maxLength(40), Validators.required]],
    descInvoice: ['', Validators.maxLength(80)],
    idUnitMeasurement :  [null,[Validators.required]],
    flagEntryPsServices: [false],
    flagOutStock:        [false],
    flagDueDate:         [false],
    flagExternal:        [false, Validators.required],
    //
    uuidPsCombinationProperty_a:        [null],
    uuidPsCombinationProperty_b:        [null],      
    uuidPsCombinationPropertyValue_a:   [null],
    uuidPsCombinationPropertyValue_b:   [null],
    psServicesDescriptionShort:         ['', Validators.maxLength(100)],
    //
    flagCustomize    : [null, [Validators.required]],
    typeCustomize    : [null, [Validators.required]],
    flagMandatory    : [null, [Validators.required]],
    configCustomize  : [null, [Validators.required]], 
  });

  

  this.preciosForm = this._fb.group({
    codRetenidoTax: [0],
    activeRetenidoTax: [false],
    codAnticipadoTax: [0],
    activeAnticipadoTax: [false,],
    codTypeService: ['', ],
    codChargeCode: [''],
    psPrice: [null, [Validators.required]],   
    activeDteTax: [true],
    cost: [{value: 0, disabled: false}, [Validators.required]],
    overwrite: [false],
    flagConsignado: [false],
    flagOutStock: [false],     
    
    uuidCrmCustomerConsignado   : ['',[Validators.required]],
    rutCustomerConsignado       : ['',[Validators.required]],
    nameCustoreConsignado       : ['',[Validators.required]],

    psPriceTAX: [null, [Validators.required]],
    //
    parentUUID      : this.data.uuidPsServices,
    flagClonePrice  : [false],
    flagCopyDiscount: [false]
  });
  this.resumeForm = this._fb.group({
                                      name: [''],
                                      categoria: [''],
                                      tipo: [''],
                                      grupo: [''],
                                      marca: [''],
                                      SKU: [''],
                                      unitMeasurement: [0],      
                                      codigoInt: [''],
                                      charCode: [''],
                                      psPrice: [''],
                                      cost: [''],
                                      psPriceTAX: [''],
                                      sobrescribible: [''],
                                      afecto: [''],
                                      codRetenidoTax: [''],
                                      codAnticipadoTax: [''],
                                      //
                                      entryPsServices: [''],
                                      outStock: [''],
                                      dueDate: [''],
                                      external: [''],      
                                    });
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async ngOnInit() {
  let tmp2 = (JSON.parse(localStorage.getItem('emisorData')))
  this.regularTax = tmp2.emisorData[0].IVA;
  this.typeBarcode           = this.data.typeBarcode
  await this.getProductByCode(this.data.uuidPsServices)
  await this.loadFormPrecios()
  
  this.types                 = this.data.types
  this.groups                = this.data.groups
  this.brands                = this.data.brands
  this.categories            = this.data.categories
  this.unitMeasurements      = this.data.unitMeasurements
  this.cuentasContablesArray = this.data.cuentasContablesArray
  this.saleschannels         = this.data.saleschannels
  
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async closeBtn(): Promise<any> {
  this._dialogRef.close({status : false});
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
setPrecioBruto(e,priceF){
  var p1,p2
  var price  :number= Number(priceF)
  p2 = Math.round( price * (1 + (this.regularTax/100))) 
  p1=p2/100 
  ////////////////
  let x = Math.floor(p1)*100
  let y = Math.round(p1) *100
  this.precioNetoNew = Math.round( y / (1 + (this.regularTax/100)))
  this.precioBruto =Math.round( this.precioNetoNew * (1 + (this.regularTax/100))) 
}
//
setPrecioNeto(e,priceF){
  var tmp
  var price  :number= Number(priceF)
  tmp = Math.round( price / (1 + (this.regularTax/100))) 
  this.preciosForm.get('psPrice').setValue(tmp);
  this.setPrecioBruto(e,tmp)

}
//
setFirstPriceSales(e,costo){
  var neto=0,bruto=0;
  var cost :number= Number(costo)


  if(this.data.percentProfitsMargin >= 1){

    neto  =  Math.trunc( (cost * this.data.percentProfitsMargin)+cost)
  }else{
    neto  = Math.trunc((cost)/(1-this.data.percentProfitsMargin)+1)
  }
  bruto = Math.round( neto  * (1 + (this.regularTax/100))) 
  this.minBruto =bruto

  

  this.preciosForm.get('psPriceTAX').setValue(bruto);
  this.preciosForm.get('psPrice').setValue(neto);
  
    
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
showCustomer(){
  const dialogRef = this._dialog.open(SelectClientesComponent, {
      
    height: '100%',
    width: '100%',
    data: {
       flagCustomer     : false
      ,flagSupplier     : true
      ,flagEmployee     : false
      ,flagAssociate    : false
      ,flagDistributor  : false

    }
});
dialogRef.afterClosed().subscribe(async (result) => {
    this.preciosForm.get('uuidCrmCustomerConsignado').setValue(result.UUID_CRM_CUSTOMER      );
    this.preciosForm.get('rutCustomerConsignado').setValue(result.RUT     );
    this.preciosForm.get('nameCustoreConsignado').setValue(result.CUSTOMER_FANCY_NAME     );

});
}
setCustomerDefault(e){
if(e.checked == false){
  this.preciosForm.get('uuidCrmCustomerConsignado').setValue('*');
  this.preciosForm.get('rutCustomerConsignado').setValue('00000000-0');
  this.preciosForm.get('nameCustoreConsignado').setValue('dummy');
}
if(e.checked == true){
  this.preciosForm.get('uuidCrmCustomerConsignado').setValue(null);
  this.preciosForm.get('rutCustomerConsignado').setValue(null);
  this.preciosForm.get('nameCustoreConsignado').setValue(null);
}
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
countCharacter(field: string){
  // //(field)
  if(field == 'nameInvoice'){
    // //(this.infoForm.value.nameInvoice)
    this.countNameInvoiceCharacter = this.infoForm.value.nameInvoice.length;
  }else if(field == 'descInvoice'){
    this.countDescInvoiceCharacter = this.infoForm.value.descInvoice.length;
  }else if(field == 'name'){
    this.countNameCharacter = this.infoForm.value.name.length;
  }else if(field == 'psServicesDescriptionShort'){
    this.countpsServicesDescriptionShort = this.infoForm.value.psServicesDescriptionShort.length;
  }
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async getProductByCode(uuidPsServices: string): Promise<any>{
  this.product = await this._catalogService.getProductByCode(uuidPsServices);  
  var sku,ean13 
  sku   = this.data.groupsSelected.NEW_COD_PS_SERVICES
  ean13 = this.data.EAN13
  this.barcode = ean13

  this.formatBarcode =  this.typeBarcode.filter(i => i.COD_TYPE_BARCODE == this.product.data.COD_TYPE_BARCODE)[0].FORMAT_TYPE_BARCODE;
  //////////////////////////////////////////////////    
  this.flagExternal = this.product.data.FLAG_EXTERNAL  === 'Y' ? true : false
  ///////////////////////////////
  // this.categorySelected = this.product.data.COD_PS_CATEGORY;
  // this.typeSelected = this.product.data.COD_PS_TYPE;
  // this.groupSelected = this.product.data.COD_PS_GROUP;
  let fechaInicio = this.product.data.DATE_START.split('-');
  let fechaFin = this.product.data.DATE_END.split('-');
  this.charCodeSelected = this.product.data.COD_CHARGE_CODE;


  ////////////////////////////////////////////////
  // Filtro por el precio activo
  var priceOK = this.product.price.filter(item => item.ACTIVE_PRICE == 'Y')
  ////////////////////////////////////////////////
  this.infoForm.patchValue({
    name: this.product.data.NAME_PS_SERVICES,
    barcode: ean13,
    SKU: sku,
    uuidPsServices: this.product.data.UUID_PS_SERVICES,
    ////////////////////////////////////////////////
    price: priceOK[0].SALES_PRICE,
    ////////////////////////////////////////////////
    visible:  false,
    category: this.product.data.ID_PS_CATEGORY,
    group: this.product.data.ID_PS_GROUP,
    charCode : this.product.data.COD_CHARGE_CODE,
    type: this.product.data.ID_PS_TYPE,
    typeCod: this.product.data.COD_TYPE_BARCODE,
    fechaInicio: new Date(fechaInicio[0], fechaInicio[1] - 1, fechaInicio[2]),
    fechaFin: new Date(fechaFin[0], fechaFin[1] - 1, fechaFin[2]),
    salesChannel: this.product.salesChannel.map((x: any) => x.ID_EPC_SALES_CHANNEL),
    description:  Buffer.from(this.product.data.PS_SERVICES_DESCRIPTION, 'base64').toString(),
    uuidBrands: this.product.data.UUID_PS_BRANDS,
    nameInvoice: this.product.data.NAME_PS_SERVICES_INVOICE,
    descInvoice: this.product.data.PS_SERVICES_DESCRIPTION_INVOICE,
    //
    idUnitMeasurement: this.product.data.ID_UNIT_MEASUREMENT,
    flagEntryPsServices: this.product.data.FLAG_ENTRY_PS_SERVICES  === 'Y' ? true : false,
    flagOutStock:        this.product.data.FLAG_OUT_STOCK  === 'Y' ? true : false,
    flagDueDate:         this.product.data.FLAG_DUE_DATE  === 'Y' ? true : false,
    flagExternal:        this.product.data.FLAG_EXTERNAL  === 'Y' ? true : false,    
    //
    uuidPsCombinationPropertyValue_a:   this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_A,
    uuidPsCombinationPropertyValue_b:   this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_B,
    psServicesDescriptionShort:         this.product.data.PS_SERVICES_DESCRIPTION_SHORT,
    uuidPsCombinationProperty_a:        this.product.data.UUID_COMBINATION_PROPERTY_A,
    uuidPsCombinationProperty_b:        this.product.data.UUID_COMBINATION_PROPERTY_B,
    //
    flagCustomize                      : this.product.data.FLAG_CUSTOMIZE,
    typeCustomize                      : this.product.data.TYPE_CUSTOMIZE,
    configCustomize                    : this.product.data.CONFIG_CUSTOMIZE,
    flagMandatory                      : this.product.data.FLAG_MANDATORY_CUSTOMIZE
  }); 
  ///////////////////////////////////////////////////
  this.countCharacter('nameInvoice')
  this.countCharacter('descInvoice')
  this.countCharacter('psServicesDescriptionShort')


  this.infoForm.get('SKU').disable();


  
  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  //console.log(this.product.data.FLAG_OVERWRITE)

  this.preciosForm.patchValue({
    activeRetenidoTax           : this.product.data.FLAG_RETENIDO_TAX === 'Y' ? true : false,
    activeAnticipadoTax         : this.product.data.FLAG_ANTICIPADO_TAX === 'Y' ? true : false,
    codRetenidoTax              : this.product.data.COD_RETENIDO_TAX,
    codAnticipadoTax            : this.product.data.COD_ANTICIPADO_TAX,
    overwrite                   : this.product.data.FLAG_OVERWRITE  == 'Y' ? true : false,
    activeDteTax                : this.product.data.COD_DTE_TAX  === '0' ? true : false,
    flagConsignado              : this.product.data.FLAG_CONSIGNADO  === 'Y' ? true : false,
    //
    uuidCrmCustomerConsignado   : this.product.data.UUID_CRM_CUSTOMER_CONSIGNADO,
    rutCustomerConsignado       : this.product.data.RUT_CONSIGNADO,
    nameCustoreConsignado       : this.product.data.CUSTOMER_FANCY_NAME_CONSIGNADO,
  });




};
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async loadFormPrecios(): Promise<any>{
  // //(this.product);
 //margenPrice = { amount:0,percent:0,txt:null}
  let amount = 0;
  let percent=0;
  let margenTxt='';
  let priceRecommended=0;
  ////

  ////
  this.priceActive = await this.product.price.filter(item => item.ACTIVE_PRICE == 'Y');
  if( this.priceActive[0].COST_PRICE === 0 || this.priceActive[0].COST_PRICE == null){
    margenTxt="Margen No Determinado";
    this.percentProfitsMargin = 0
  }
  else{ 
    /////////////////////////////////////////////
    if(this.percentProfitsMargin >= 1){

      //priceRecommended  = Math.trunc((this.priceActive[0].COST_PRICE)/(1+this.percentProfitsMargin)+1)
      priceRecommended  =  Math.trunc( (this.priceActive[0].COST_PRICE * this.percentProfitsMargin)+this.priceActive[0].COST_PRICE)

    }else{
      priceRecommended  = Math.trunc((this.priceActive[0].COST_PRICE)/(1-this.percentProfitsMargin)+1)
      //priceRecommended  =  Math.trunc( (this.priceActive[0].COST_PRICE * this.percentProfitsMargin)+this.priceActive[0].COST_PRICE)
    }
    /////////////////////////////////////////////      
    //percent           = (this.priceActive[0].SALES_PRICE/this.priceActive[0].COST_PRICE)-1
    percent           = (this.priceActive[0].SALES_PRICE-this.priceActive[0].COST_PRICE)/(this.priceActive[0].SALES_PRICE)
    amount            = this.priceActive[0].SALES_PRICE - this.priceActive[0].COST_PRICE;
    /////////////////////////////////////////////
    if( amount < 0) {
        percent = percent *-1;
        margenTxt="Magen Negativo";
    }
  }
  this.margenPrice.amount                   = amount;
  this.margenPrice.percent                  = percent;
  this.margenPrice.txt                      = margenTxt;
  this.margenPrice.style                    = margenTxt;
  this.margenPrice.amountSalesRecomented    = priceRecommended
  this.margenPrice.amountSalesRecomentedTax =  Math.round( priceRecommended * (1 + (this.regularTax/100))) 
  ////////////////////////////////
  this.preciosForm.patchValue({
    psPrice: this.priceActive[0].SALES_PRICE,
    cost: this.priceActive[0].COST_PRICE,

    overwrite: this.product.data.FLAG_OVERWRITE === 'Y' ? true : false,
    activeDteTax: this.product.data.COD_DTE_TAX === "0" ? true : false,
  });
  ////////////////////////////////
  this.setPrecioBruto(null,this.priceActive[0].SALES_PRICE)

  
  let tmpP = Math.round( this.priceActive[0].SALES_PRICE * (1 + (this.regularTax/100))) 
  this.preciosForm.get('psPriceTAX').setValue(tmpP);
  ////////////////////////////////    
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
assingResume(): void{
  // //(this.infoForm.value)
  // //(this.preciosForm.value);
  var unitMeasurements
  var sku   = this.data.groupsSelected.NEW_COD_PS_SERVICES
try{    
  this.resumeForm.enable();
  this.infoForm.get('barcode').enable();
  if(this.flagExternal == false){
    //=====================================    
    unitMeasurements = this.unitMeasurements.find((x: any) => x.ID_UNIT_MEASUREMENT === this.infoForm.value.idUnitMeasurement)    
    this.resumeForm.patchValue({
      name: this.infoForm.value.name,
      categoria: this.categories.find((x: any) => x.ID_PS_CATEGORY === this.infoForm.value.category).NAME_PS_CATEGORY,
      tipo: this.types.find((x: any) => x.ID_PS_TYPE === this.infoForm.value.type).NAME_PS_TYPE,
      grupo: this.groups.find((x: any) => x.ID_PS_GROUP === this.infoForm.value.group).NAME_PS_GROUP,
      marca: this.brands.find((x: any) => x.UUID_PS_BRANDS === this.infoForm.value.uuidBrands).NAME_PS_BRANDS,
      SKU: sku,
      codigoInt: this.infoForm.value.barcode,
      charCode: this.infoForm.value.charCode,
      psPrice: this.preciosForm.value.psPrice,
      psPriceTAX:this.preciosForm.value.psPriceTAX,
      cost:this.preciosForm.value.cost,
      sobrescribible: this.preciosForm.value.overwrite ? 'Si' : 'No',
      afecto: this.preciosForm.value.overwrite ? 'Si' : 'No',
      codRetenidoTax: this.preciosForm.value.codRetenidoTax === 0 ? 'No' : 'Si',
      codAnticipadoTax: this.preciosForm.value.codAnticipadoTax === 0 ? 'No' : 'Si',
      //
      unitMeasurement : unitMeasurements.NAME_UNIT_MEASUREMENT +' '+ unitMeasurements.DTE ,
      consignado      : this.preciosForm.value.flagConsignado   == false ? 'No' : 'Si',
      entryPsServices : this.infoForm.value.flagEntryPsServices == false ? 'No' : 'Si',
      outStock        : this.infoForm.value.flagOutStock        == false ? 'No' : 'Si',
      dueDate         : this.infoForm.value.flagDueDate         == false ? 'No' : 'Si',
      external        : this.infoForm.value.flagExternal        == false ? 'No' : 'Si',      
    });
    this.resumeForm.disable();
    this.infoForm.get('barcode').disable();
    //=====================================      
  }
  else{
    //=====================================
      //************************************************ */
        this.resumeForm.patchValue({
              name: this.infoForm.value.name,
              categoria: this.categories.find((x: any) => x.ID_PS_CATEGORY === this.infoForm.value.category).NAME_PS_CATEGORY,
              tipo: this.types.find((x: any) => x.ID_PS_TYPE === this.infoForm.value.type).NAME_PS_TYPE,
              grupo: this.groups.find((x: any) => x.ID_PS_GROUP === this.infoForm.value.group).NAME_PS_GROUP,
              marca: this.brands.find((x: any) => x.UUID_PS_BRANDS === this.infoForm.value.uuidBrands).NAME_PS_BRANDS,
              SKU: sku,
              codigoInt: this.infoForm.value.barcode,
              charCode: this.infoForm.value.charCode,
              psPrice: 0,
              psPriceTAX:0,
              cost:0,
              //
              external        : 'Si',      
            });
      //************************************************ */        
    this.resumeForm.disable();
    this.infoForm.get('barcode').disable();
    //=====================================      
  }
  

}catch(e){
  console.log(e)
}    
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('/');
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
// checkDigitEAN13(barcode)
chkEAN13(barcode){
  try{
    var barcode12
  var dv 
  var barcodeOK
  barcode12 =  barcode.substring(0, 12)
  dv = this.checkDigitEAN13(barcode12)
  barcodeOK = barcode12 + dv
  if(barcodeOK == barcode)
  return true;
  else
  return false;
  
  }
  catch(e){
    return false;
  }
  
} 
//////////////////////////////////////////////////
//////////////////////////////////////////////////
checkDigitEAN13(barcode) {
    const sum = barcode.split('')
      .map((n, i) => n * (i % 2 ? 3 : 1)) // alternate between multiplying with 3 and 1
      .reduce((sum, n) => sum + n, 0) // sum all values
  
    const roundedUp = Math.ceil(sum / 10) * 10; // round sum to nearest 10
  
    const checkDigit = roundedUp - sum; // subtract round to sum = check digit
    
    return checkDigit;
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async cloneProduct(): Promise<any>{
  const date = new Date();
  const today = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  var glsVisible="";
  if(this.infoForm.value.visible == false) glsVisible=`<b>¡¡Atencion!!<br><b>Producto Creado como no visible</b></b>`; else glsVisible="";

  
  const confirmCreateDialog = this._fuseConfirmationService.open({
    title: 'Crear Producto',
    message: `¿Está seguro de crear el producto ${this.infoForm.value.name}?<br>${glsVisible}`,
    icon: {
      name: 'shopping_cart',
      color: 'success'
    },
    actions: {
      confirm: {
        label: 'Confirmar',
        color: 'primary'
      },
      cancel: {
        label: 'Seguir editando',
      }
    }
  });

  confirmCreateDialog.afterClosed().subscribe(async (result) => {
    // //(result); 
    if(result == 'confirmed'){
      this.infoForm.enable();
      //###############################
      let chk = this.chkEAN13(this.infoForm.value.barcode)
      if(chk == false) {
        this._toast.error('Codigo EAN13 Incorrecto:'+this.infoForm.value.barcode,'Error');
        return false;
      }
    //###############################   
      let tmpA = this.infoForm.value.name.replaceAll('"',"'")
      let tmpB = this.infoForm.value.nameInvoice.replaceAll('"',"'")   
      let tmpC = this.infoForm.value.descInvoice.replaceAll('"',"'")   
    //== 
      const data = {
        "codPsServices": this.infoForm.value.SKU,
        "uuidPsServices": this.infoForm.value.UUID_PS_SERVICES,
        "idPsGroup": this.infoForm.value.group,
        "idPsCategory": this.infoForm.value.category,
        "idPsType": this.infoForm.value.type,
        "codDteTax": this.preciosForm.value.activeDteTax ? "0":"1",
        "idCodType": 900,
        "flagBundle": "N",
        "namePsServices": tmpA,
        "psServicesDescription": await Buffer.from(this.infoForm.value.description).toString('base64') ,
        "orderPsService": 0,
        "dateStart": this.formatDate(this.infoForm.value.fechaInicio),
        "dateEnd": this.formatDate(this.infoForm.value.fechaFin),
        "flagRetenidoTax": this.preciosForm.value.activeRetenidoTax ? 'Y' : 'N',
        "codRetenidoTax": this.preciosForm.value.activeRetenidoTax ? this.preciosForm.value.codRetenidoTax : -99,
        "flagAnticipadoTax": this.preciosForm.value.activeAnticipadoTax ? 'Y' : 'N',
        "codAnticipadoTax": this.preciosForm.value.activeAnticipadoTax ? this.preciosForm.value.codAnticipadoTax : -99,
        "codTypeService": "0",
        "codChargeCode": this.product.data.COD_CHARGE_CODE, 
//REVUSAR DEBEN IR TODFOS""!!!!"          
    //    "codChargeCode": this.chargeCodeSelectDetail[0]?.COD_CHARGE_CODE || 'DUMMY',
        "enabledPsServices":'Y', 
        "codTypePsServices": this.infoForm.value.typeCod,//"EAN13",
        "flagOverwrite": this.preciosForm.value.overwrite ? 'Y' : 'N',
        "uuidBrands": this.infoForm.value.uuidBrands,
        "flagRoot": "Y",
        "visible": this.infoForm.value.visible ? 'Y' : 'N',
        "remove": "N",
        //////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////
        "psPrice": this.preciosForm.value.psPrice,
        "cost"   : this.preciosForm.value.cost | 0,
        //////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////          
        "servicesSalesChannel": [],
   //     "flagDueDate"                   : "N",
        "psServicesDescriptionInvoice"  : tmpC || 'Sin Descripción',
        "namePsServicesInvoice"         : tmpB
        ,"psAttributes"                 :   this.product.data.PS_ATTRIBUTES

      // ,"flagEntryPsServices"           : "N"
        ,"flagShippingMeasure"           : "Y"
        ,"psShippingMeasure"             : this.product.data.PS_SHIPPING_MEASURE
       // ,"flagExternal"                  : "N",
        ,"barcode": this.infoForm.value.barcode
        //
        ,"flagConsignado"             : this.preciosForm.value.flagConsignado ? 'Y' : 'N'
        ,"uuidCrmCustomerConsignado"  : this.preciosForm.value.uuidCrmCustomerConsignado



        ,"idUnitMeasurement"  : this.infoForm.value.idUnitMeasurement
        ,"flagEntryPsServices": this.infoForm.value.flagEntryPsServices ? 'Y' : 'N'
        ,"flagOutStock"       : this.infoForm.value.flagOutStock ? 'Y' : 'N'
        ,"flagDueDate"        : this.infoForm.value.flagDueDate ? 'Y' : 'N'
        ,"flagExternal"       : this.infoForm.value.flagExternal ? 'Y' : 'N'
        //
        ,"psServicesDescriptionShort":         this.infoForm.value.psServicesDescriptionShort
        ////////////////////////////////////////////////////////////
        ,"uuidPsCombinationPropertyValue_a":   this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_A
        ,"uuidPsCombinationPropertyValue_b":   this.product.data.UUID_PS_COMBINATION_PROPERTY_VALUE_B
        ,"uuidPsCombinationProperty_a":        this.product.data.UUID_COMBINATION_PROPERTY_A
        ,"uuidPsCombinationProperty_b":        this.product.data.UUID_COMBINATION_PROPERTY_B
        ////////////////////////////////////////////////////////////
        ,"parentUUID"      : this.preciosForm.value.parentUUID
        ,"flagClonePrice"  : this.preciosForm.value.flagClonePrice ? 'Y' : 'N'
        ,"flagCopyDiscount": this.preciosForm.value.flagCopyDiscount ? 'Y' : 'N'
        ////////////////////////////////////////////////////////////
        //
        ,"flagCustomize"                      : this.infoForm.value.flagCustomize
        ,"typeCustomize"                      : this.infoForm.value.typeCustomize
        ,"flagMandatory"                      : this.infoForm.value.flagMandatory
        ,"configCustomize"                    : this.infoForm.value.configCustomize
        ////////////////////////////////////////////////////////////
      };

    
      data.servicesSalesChannel = []
        this.product.salesChannel.forEach((item: any) => {
            //elimino SalesChannel por defecto
            for(let i=0;i<this.saleschannels.length;i++){
              if(this.saleschannels[i].checked == true && this.saleschannels[i].ID_EPC_SALES_CHANNEL == item.ID_EPC_SALES_CHANNEL ){
                data.servicesSalesChannel.push(item.ID_EPC_SALES_CHANNEL);
              }
              
            }
            
          });
///////////////////

//////////////////
const x = await this._catalogService.updateCreateDeleteProductos(data, true);
if(!!x){
  this._toast.success('Producto creado correctamente');
  //this._router.navigate(['/catalogos']);
  this._dialogRef.close({status : true});
}
      // //(x);
    }
  });
  
}
//////////////////////////////////////////////////
setCopyCost(e){
  if(e.checked == false){
    this.preciosForm.get('cost').enable();
    //this.preciosForm.get('uuidCrmCustomerConsignado').setValue(null);
  
  }
  if(e.checked == true){
    this.preciosForm.get('cost').disable();
  }
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
changeTypeCod(cod: any,type:any): void{
  // //(cod);
  if(cod == 'EAN13'){

    this.infoForm.patchValue({barcode: this.generarCodigoEAN13()})
  }else if(cod == 'EAN8'){
    this.infoForm.patchValue({barcode: this.generarCodigoEAN8()})

  }
  this.formatBarcode = cod;
}


generarCodigoEAN13(): string {
  var  prefix
  var idGroup
  var codigoEAN13

  this.ean13Generated = true;
  if(this.prefixEAN == '000000'){
    idGroup = this.infoForm.get('group').value
    prefix = this.groups.filter(item => item.ID_PS_GROUP == idGroup)[0].PREFIX_BARCODE
  }
  else{
     prefix = this.prefixEAN;
  }

  const randomNumber = Math.floor(Math.random() * 100000); // Generar un número aleatorio de 12 dígitos
  let codigo = randomNumber.toString().padStart(6, '0'); // Rellenar con ceros a la izquierda si es necesario
  codigo = codigo.slice(0, 6);
  codigo = prefix + codigo;
  codigo = codigo.substring(0, 12)

  let digit = this.checkDigitEAN13(codigo)
  // //(codigo.slice(0, 6));
  codigoEAN13 =codigo + digit
  return codigoEAN13;
};


generarCodigoEAN8(): string {
  this.ean8Generated = true;
  let prefix = this.prefixEAN;
  prefix = prefix.slice(0, 2);
  const randomNumber = Math.floor(Math.random() * 100000); // Generar un número aleatorio de 7 dígitos
  let codigo = randomNumber.toString().padStart(5, '0'); // Rellenar con ceros a la izquierda si es necesario
  // //(codigo);
  codigo = codigo.slice(0, 5);
  codigo = prefix + codigo;
  
  let sumaPares = 0;
  let sumaImpares = 0;

  for (let i = 0; i < 7; i++) {
    const digito = Number(codigo.charAt(i));
    if (i % 2 === 0) {
      sumaImpares += digito;
    } else {
      sumaPares += digito;
    }
  }

  const sumaTotal = sumaPares * 3 + sumaImpares;
  const digitoVerificador = 10 - (sumaTotal % 10);

  const codigoEAN8 = codigo + digitoVerificador.toString();

  return codigoEAN8;
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
@HostListener('window:keydown', ['$event'])
async keyEvent(event: KeyboardEvent) {
 // let $this = this;

    var  charTmp : string  =null ,uuidPsServices : string = ''
        if(!this.focusInputCode){
            if(event.code.includes('Digit') && !this.isCodeByMachine){
                this.flagRedLight = true;
                this.isCodeByMachine = true;
                this.codeByKeyboard = '';
            }
            if(this.isCodeByMachine){
                if(event.code === 'Enter'){
                    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
                        console.log(this.codeByKeyboard)
                        if(this.editModeBarcode){
                          this.infoForm.get('barcode').setValue(this.codeByKeyboard);
                        }
                        this.isCodeByMachine = false;
                        this.codeByKeyboard = '';
                        //valido EAN
                        //if(uuidPsServices != '-1')
                        //  this.editOrCreateProduct(uuidPsServices)
                        //else
                        //  this._toast.warning('Producto no Encontrado', 'Alerta');
                    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%                
                }else{
                    this.flagRedLight   = true;
                    charTmp = event.key.replace('Shift','')
                    this.codeByKeyboard = this.codeByKeyboard + charTmp;
                }
            }
        }
  //===========================          
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
toggleEditModeBarcode() {
  this.editModeBarcode = !this.editModeBarcode;
  if (this.editModeBarcode) {
    this.infoForm.get('barcode')?.enable();
  } else {
    this.infoForm.get('barcode')?.disable();
  }
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
}
