<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <div class="bg-white rounded-lg p-4">
        <!-- ##############################################################################################-->
        <!--############################################################-->
            <!-- ##########################################################  -->
            <!-- ##########################################################  -->
            <div class="w-full">
                <!-- ##########################################################  --> 
                <div class="grid grid-cols-10">   
                    <div class="col-span-8">
                        <!-- ## -->
                            <h2 class="prose prose-xl"><b> Crear Descuento</b></h2>                       
                           <!--  #####   -->  
                           <!--  #####   -->
                        <!-- ## -->
                    </div>
                    <div class="col-span-1">
                        <!-- ## -->
                        <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
                        <!-- ## -->
                    </div>
                </div>
                <!-- ##########################################################  -->    
        
            </div>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
        <mat-divider class="py-2"></mat-divider>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
        <div class="w-full">
            <form [formGroup]="discountForm" class="space-y-8">
        <!-- ##############################################################################################-->
        <mat-card class="col-span-1" >
            <mat-card-content class="h-full">
                <!--//-->
                <div class="col-span-5">
                    <mat-form-field class="w-1/6">
                        <mat-label ><b>Nombre Descuento:</b> <small><b  style="color: rgb(232, 7, 7)">   (Máximo 25 caracteres)</b></small></mat-label>
                        <input matInput number formControlName="nameDiscount" maxlength="25"  /> 
                    </mat-form-field>
                   
                </div>
                <!--//--> 
                <div class="col-span-5">
                    <mat-form-field class="w-1/3">
                        <mat-label class="text-xl text-gray-800">Canal de Venta:</mat-label>
                        <mat-select formControlName="idEpcSalesChannel">
                            <mat-option *ngFor="let sc of salesChannels" [value]="sc.ID_EPC_SALES_CHANNEL">
                                {{sc.NAME_SALES_CHANNEL}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--//-->
                <div class="w-full">
                    <mat-form-field class="w-1/5">
                            <mat-label><b>Tipo de Descuento:</b></mat-label>   
                        <mat-select formControlName="typePsServicesDiscount" (selectionChange)="setAmount($event.value,null)">
                            <mat-option value="P">Porcentaje</mat-option>
                            <mat-option value="A">Monto</mat-option>
                        </mat-select>  
                    </mat-form-field>   

                    <mat-form-field class="w-1/5">
                            <mat-label *ngIf="(discountForm.get('typePsServicesDiscount').value)=='A'"><b>Monto Descuento(Neto):</b></mat-label>
                            <mat-label *ngIf="(discountForm.get('typePsServicesDiscount').value)=='P'"><b>Porcentaje Descuento(Aplica sobre Neto):</b></mat-label>
                            <b *ngIf="(discountForm.get('typePsServicesDiscount').value)=='A'">{{chargeCodeSelectDetail.SYMBOL_CURRENCY}}&nbsp;</b>
                            <input matInput number formControlName="discount"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> <b *ngIf="(discountForm.get('typePsServicesDiscount').value)=='P'">%</b>
                    </mat-form-field>

                    <mat-form-field *ngIf="(discountForm.get('typePsServicesDiscount').value)=='A'" class="w-1/5">
                        <mat-label ><b>Monto Descuento(Bruto):</b></mat-label>
                        <b *ngIf="(discountForm.get('typePsServicesDiscount').value)=='A'">{{chargeCodeSelectDetail.SYMBOL_CURRENCY}}&nbsp;</b>
                        <input matInput  value="{{discountForm.get('discount').value * 1.19 | number :'1.0-0'}}" readonly/> 
                        <!-- ## -->
                        
                        <!-- ## -->
                </mat-form-field>                    
                    
                </div>
                <!--//-->   
                <div class="w-full" *ngIf="(discountForm.get('typePsServicesDiscount').value)=='P'">
                    <section >
                        <mat-checkbox class="example-margin" (change)="setMaxFlagAmountLimit($event.checked)">Definir Monto Descuento Máximo  </mat-checkbox>
                    </section>
                    <mat-form-field class="w-1/3" *ngIf="(discountForm.get('flagAmountLimit').value)==true">
                            <mat-label ><b>Monto Máximo Descuento:</b></mat-label>
                            <b>{{chargeCodeSelectDetail.SYMBOL_CURRENCY}}&nbsp;</b>
                            <input matInput number formControlName="amountLimit"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                    </mat-form-field>
                </div>                
                <!--//-->   
                <div class="w-full">
                    <section >
                        <mat-checkbox class="example-margin" (change)="setMaxFlagQuantityLimit($event.checked)">Definir Cantidad Máxima </mat-checkbox>
                    </section>
                    <mat-form-field class="w-1/3" *ngIf="(discountForm.get('flagQuantityLimit').value)==true">
                            <mat-label ><b>Cantidad Máxima:</b></mat-label>
                            <input matInput number formControlName="quantityLimit"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                    </mat-form-field>
                </div>                
                <!--//-->   
                <br>
                <div>
                    <section >
                        <mat-checkbox class="example-margin" formControlName="flagGlobalDiscount"  (change)="setGlobal($event.checked)" >Descuento Global</mat-checkbox>
                    </section>
                </div>
                <br>
                <!--//-->   
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->  
                <div class="w-full" >
                    <mat-card class="w-full mx-auto mt-10 rounded-md shadow-lg">
                        <mat-card-header class="">
                            <mat-card-title>Fecha Vigencia</mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="p-4">                             
                            <div>
                                <section >
                                    <mat-checkbox class="example-margin" (change)="setIndefinido($event.checked)">Indefinido</mat-checkbox>
                                </section>
                            </div>  
                            <div class="flex">
                                <mat-form-field class="w-1/5">
                                        <mat-label>Fecha Inicio:</mat-label>   
                                        <input matInput formControlName="dateStart" [matDatepicker]="emision" [min]="today" [value]="today" />
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="emision" ></mat-datepicker-toggle>
                                        <mat-datepicker #emision ></mat-datepicker>
                                </mat-form-field>   
                                <mat-form-field class="w-1/5">
                                        <mat-label>Fecha Fin:</mat-label>   
                                        <input matInput formControlName="dateEnd" [matDatepicker]="fin" [min]="today" [value]="today" />
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="fin" ></mat-datepicker-toggle>
                                        <mat-datepicker #fin ></mat-datepicker>
                                </mat-form-field>                                                 
                            </div>
                        </mat-card-content>
                    </mat-card>
                 </div>                            
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->                  
                <!--//-->
                <!--//-->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->   
                <div class="w-full" >
                    <mat-card class="w-full mx-auto mt-10 rounded-md shadow-lg">
                        <mat-card-header class="">
                            <mat-card-title>Dias Activo</mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="p-4">
                            <mat-checkbox formControlName="flagMonday">Lunes&nbsp;</mat-checkbox>
                            <mat-checkbox formControlName="flagTuesday">Martes&nbsp;</mat-checkbox>
                            <mat-checkbox formControlName="flagWednesday">Miercoles&nbsp;</mat-checkbox>
                            <mat-checkbox formControlName="flagThursday">Jueves&nbsp;</mat-checkbox>
                            <mat-checkbox formControlName="flagFriday">Viernes&nbsp;</mat-checkbox>
                            <mat-checkbox formControlName="flagSaturday">Sabado&nbsp;</mat-checkbox>
                            <mat-checkbox formControlName="flagSunday">Domingo&nbsp;</mat-checkbox>
                        </mat-card-content>
                   </mat-card>
                </div>
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->                   
                <!--//-->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->  
                <div class="w-full" >
                    <mat-card class="w-full mx-auto mt-10 rounded-md shadow-lg">
                        <mat-card-header class="">
                            <mat-card-title>Horario Activo</mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="p-4">                             
                            <div>
                                <section >
                                    <mat-checkbox class="example-margin" (change)="setHour($event.checked)">Definir Horario</mat-checkbox>
                                </section>
                            </div>  
                            <div *ngIf="(discountForm.get('flagHour').value)==true">
                                    <div class="w-1/3">
                                        <div>Desde:</div>
                                        <div><ngx-timepicker-field [controlOnly]="true" [format]="24" formControlName="hourStart" ></ngx-timepicker-field></div>
                                    </div>
                                    <br>
                                    <div class="w-1/3">
                                        <div>Hasta:</div>
                                        <div><ngx-timepicker-field [controlOnly]="true" [format]="24" formControlName="hourEnd"></ngx-timepicker-field></div>
                                    </div>         
                            </div>
                        </mat-card-content>
                    </mat-card>
                 </div>                            
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->   
                <!--//-->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->  
                <div class="w-full"  *ngIf="(discountForm.get('flagGlobalDiscount').value)==false" >
                    <mat-card class="w-full mx-auto mt-10 rounded-md shadow-lg">
                        <mat-card-header class="">
                            <mat-card-title>Reglas</mat-card-title>
                            <mat-card-subtitle>Al definir reglas, la aplcación del descuento estara sujeta a cumplir criterios por monto o cantdad definindas a continuación. </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content class="p-4">  
                        <!--//-->   
                        <!--//-->                       
                            <div class="w-full">
                                <section >
                                    <mat-checkbox class="example-margin" formControlName="setFlagAmountStart" (change)="setFlagAmountStart($event.checked)">Regla por Monto  </mat-checkbox>
                                </section>
                                <div *ngIf="(discountForm.get('flagAmountStart').value)==true">
                                        <!--//-->
                                        <mat-form-field class="w-1/2">
                                            <mat-label class="text-xl text-gray-800">Comportamiento:</mat-label>
                                            <mat-select formControlName="flagFullFillment" (selectionChange)="setFullFillment($event.value,'amount')">
                                                <mat-option *ngFor="let f of arrFlagFullFillment" [value]="f.FLAG_FULL_FILLMENT">
                                                    {{f.GLS_AMOUNT}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>                                 
                                        <!--//-->
                                        <div class="shadowbox" [innerHtml]="toltipAmount"></div>
                                        <!--//-->                                
                                        <mat-form-field class="w-1/4" *ngIf="flagRangeA == 'Y'">
                                                <mat-label ><b>{{nameRangeAmountA}}:</b></mat-label>
                                                <b>{{chargeCodeSelectDetail.SYMBOL_CURRENCY}}&nbsp;</b>
                                                <input matInput number formControlName="amountStart"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                                        </mat-form-field>
                                        <!--//-->
                                        <mat-form-field class="w-1/4" *ngIf="flagRangeB == 'Y'">
                                            <mat-label ><b>{{nameRangeAmountB}}</b></mat-label>
                                            <b>{{chargeCodeSelectDetail.SYMBOL_CURRENCY}}&nbsp;</b>
                                            <input matInput number formControlName="amountEnd"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                                        </mat-form-field>    
                                        <!--//--> 
                                </div>                                                                                              
                            </div>  
                        <!--//-->                                                       
                        <!--//-->   
                            <div class="w-full">
                                <section >
                                    <mat-checkbox class="example-margin" formControlName="setFlagQuantityStart" (change)="setFlagQuantityStart($event.checked)">Regla por Cantidad  </mat-checkbox>
                                </section>
                                <div *ngIf="(discountForm.get('flagQuantityStart').value)==true">
                                        <!--//-->                              
                                        <mat-form-field class="w-1/2" >
                                            <mat-label class="text-xl text-gray-800">Comportamiento:</mat-label>
                                            <mat-select formControlName="flagFullFillment" (selectionChange)="setFullFillment($event.value,'quantity')">
                                                <mat-option *ngFor="let f of arrFlagFullFillment" [value]="f.FLAG_FULL_FILLMENT">
                                                    {{f.GLS_QUANTITY}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>  
                                        <!--//-->
                                        <div class="shadowbox" [innerHtml]="toltipQuantity"></div>
                                        <!--//-->                                  
                                        <mat-form-field class="w-1/4" *ngIf="flagRangeA == 'Y'">
                                                <mat-label ><b>{{nameRangeQuantityA}}</b></mat-label>
                                                <b>{{unitMeasurements.NAME_UNIT_MEASUREMENT}}&nbsp;</b>
                                                <input matInput number formControlName="quantityStart"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                                        </mat-form-field>
                                        <!--//-->                                
                                        <mat-form-field class="w-1/4" *ngIf="flagRangeB == 'Y'">                                  
                                            <mat-label ><b>{{nameRangeQuantityB}}</b></mat-label>
                                            <b>{{unitMeasurements.NAME_UNIT_MEASUREMENT}}&nbsp;</b>
                                            <input matInput number formControlName="quantityEnd"  (input)="numericOnly($event)"  (keypress)="numericOnly($event)"  (paste)="onPaste($event)" /> 
                                        </mat-form-field>    
                                        <!--//-->
                                </div>                                        
                            </div>                         
                        <!--//-->                           
                        </mat-card-content>
                    </mat-card>
                 </div>                            
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->                                   
             </mat-card-content>
        <!--//-->                                  
        </mat-card>
        <!-- ##############################################################################################-->         
             </form>
        </div>
        <button
            mat-flat-button
            [color]="'primary'"
            (click)="addDiscount()"
            [disabled]="discountForm.invalid">
            Crear
        </button>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->         
         </div>
</div>