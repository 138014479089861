<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- ##########################################################  -->
    <!-- ##########################################################  -->
    <div class="w-full">
        <!-- ##########################################################  -->
        <div class="grid grid-cols-5">       
            <div>
                <h2 class="prose prose-xl">Orden:<b>{{data.order.FRIENLY_EOM_ORDER }}</b></h2>
            </div>
            <div>
                <h2 class="prose prose-xl">Estado:<b>{{data.order.NAME_COD_REASON_CODE }}</b></h2>
            </div>
            <div>
                <!-- ## -->
                <div  *ngIf="data.order.FLAG_PAYMENT == 'Y'">
                    <div *ngIf="data.order.COD_REASON_CODE == 'AO'">
                        <h2 class="prose prose-xl"><mat-icon class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>   Total:<b>{{ data.order.TOTAL  | currency:'CLP':'symbol-narrow'}}</b></h2>
                    </div>  
                    <div *ngIf="data.order.COD_REASON_CODE != 'AO'">
                        <h2 class="prose prose-xl"> <mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>Total:<b>{{ data.order.TOTAL  | currency:'CLP':'symbol-narrow'}}</b></h2>
                    </div>                                                                     
                </div>
                <div  *ngIf="data.order.FLAG_PAYMENT != 'Y'">
                    <h2 class="prose prose-xl"><mat-icon class="text-red-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>Total:<b>{{ data.order.TOTAL  | currency:'CLP':'symbol-narrow'}}</b></h2>
                </div>    
                <!-- ## -->
            </div>  
            <!-- ## -->
            <div class="flex sm:items-center">
                <snap *ngFor="let item of [].constructor(data.order.COD_CM_CUSTOMER_VALUE)">
                    <mat-icon color="primary"  [svgIcon]="'heroicons_outline:star'"></mat-icon>
                </snap>
                
                <div class="ml-6 leading-6">Valor Cliente: <b style="color: rgb(28, 154, 3)">{{data.order?.NAME_CM_CUSTOMER_VALUE}}</b></div>
            </div> 
            <!-- ## -->
            <div>
                <button class="close" mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>         
            </div>
        </div>
        <!-- ##########################################################  -->
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<!-- ================================================================================================================== -->                
<!-- ================================================================================================================== -->                
<mat-tab-group #tabsOrder  [(selectedIndex)]="matTabOrderSelected"  (selectedTabChange)="refreshOrder($event,null)" >
    <!-- ## -->
    <mat-tab label="General" >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
                <!--PAGO/ANULA:INICIO-->
                    <div class="grid grid-cols-2">   
                        <!--//-->                 
                        <div >
                            <!-- -->
                                    <div *ngIf="data.order.FLAG_PAYMENT == 'Y' && data.order.FLAG_DELIVERED == 'N' && data.order.FLAG_SHIPING == 'N' && data.order.COD_REASON_CODE == 'WDS' && data.order.COD_STATUS_DTE != 'OK' ">
                                        <button  mat-raised-button color="warn" (click)="anularOrden(data.order)"> <mat-icon>close</mat-icon>Anular Orden</button>
                                    </div>
                            <div *ngIf="data.order.COD_REASON_CODE != 'AO'">
                                    <!-- ## -->
                                    <div *ngIf="data.order.COD_STATUS_DTE != 'OK' && data.order.FLAG_PAYMENT == 'N' && data.order.FLAG_DELIVERED == 'N' && data.order.FLAG_SHIPING == 'N' &&  (data.order.COD_REASON_CODE == 'OI' || data.order.COD_REASON_CODE == 'FN' || data.order.COD_REASON_CODE == 'OPP' || data.order.COD_REASON_CODE == 'RST' || data.order.COD_REASON_CODE == 'POK' || data.order.COD_REASON_CODE == 'PER' ) ">
                                        <button  mat-raised-button color="warn" (click)="anularOrden(data.order)"> <mat-icon>close</mat-icon>Anular Orden</button>
                                    </div>    
                                    <div *ngIf="data.order.FLAG_PAYMENT == 'N' && ( data.order.COD_REASON_CODE != 'AO' || data.order.COD_REASON_CODE != 'POK')">
                                        <button mat-raised-button class="payBtn" (click)="payOrder(data.order)"> <mat-icon>done</mat-icon>Pagar Orden</button>
                                        <!-- /////////////////////////////////////  -->
                                                    <div class="grid grid-cols-1">   
                                                        <div class="text-gray-700 text-sm mb-1">
                                                                <div class="flex justify-between">
                                                                    <div class=" prose prose-xl">Fecha de Vencimiento:</div>
                                                                    <div class=" prose prose-xl">
                                                                                                    <div *ngIf="data.order.DAYS_DUE_DAY > 0 "  ><b style="color: rgb(169, 8, 8)"  >{{ data.order.DATE_DUE_DAY |  date:'dd-MM-yyyy' }}</b></div> 
                                                                                                    <div *ngIf="data.order.DAYS_DUE_DAY == 0 " ><b style="color: rgb(239, 161, 5)">{{ data.order.DATE_DUE_DAY |  date:'dd-MM-yyyy' }}</b></div> 
                                                                                                    <div *ngIf="data.order.DAYS_DUE_DAY < 0"   ><b style="color: rgb(28, 154, 3)" >{{ data.order.DATE_DUE_DAY |  date:'dd-MM-yyyy' }}</b></div>                                                                                            
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        <div class="text-gray-700 text-sm mb-1">
                                                                <div class="flex justify-between">
                                                                    <div class=" prose prose-xl">Dias Mora:</div>
                                                                    <div class=" prose prose-xl">
                                                                                                    <div *ngIf="data.order.DAYS_DUE_DAY > 0 "  ><b style="color: rgb(169, 8, 8)"  >{{ data.order.DAYS_DUE_DAY  }}</b></div> 
                                                                                                    <div *ngIf="data.order.DAYS_DUE_DAY == 0 " ><b style="color: rgb(239, 161, 5)">{{ data.order.DAYS_DUE_DAY  }}</b></div> 
                                                                                                    <div *ngIf="data.order.DAYS_DUE_DAY < 0"   ><b style="color: rgb(28, 154, 3)" >{{ data.order.DAYS_DUE_DAY  }}</b></div>                                                                                             
                                                                    </div>
                                                                </div>                        
                                                        </div>
                                                </div>
                                        <!-- /////////////////////////////////////  -->
                                    </div>                          
                            </div>
                        </div>
                        <!--//-->              
                    </div>                
                <!--PAGO/ANULA:INICIO-->                        
                <!--TOTALES:INICIO-->
                <div>
                    <div class="grid grid-cols-2">   
                        <div class="text-gray-700 text-sm mb-4">
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Fecha de Creación:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Fecha de Actualización:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.DATE_UPDATE |  date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                                </div>
                        </div>
                        <div class="text-gray-700 text-sm mb-4">
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Fecha de Entrega:</div>
                                    <div class="w-1/2 prose">
                                        <b *ngIf=" data.order.DATE_DELIVERED == '1900-01-01T00:00:00'" style="color: rgb(181, 5, 11)">Pendiente</b>
                                        <b *ngIf=" data.order.DATE_DELIVERED != '1900-01-01T00:00:00'" style="color: rgb(4, 93, 11)"  >{{ data.order.DATE_DELIVERED |  date:'dd-MM-yyyy HH:mm:ss'}}</b>
                                                            
                                                            </div>
                                </div>
                                <div class="flex justify-between" *ngIf="data.order.DATE_SHIPING !='1900-01-01T00:00:00'">
                                    <div class="w-1/2 prose">Fecha de Despacho:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.DATE_SHIPING |  date:'dd-MM-yyyy HH:mm:ss' }}</b></div>
                                </div>                        
                        </div>
                    </div>
                    <!-- // -->
                    <div>
                                    <!--&&&&&&&&&&&&&&&&&&&-->                                            
                                            <div class="w-1/2 prose" *ngIf="data.order.FLAG_PAYMENT == 'Y'">
                                                <div *ngIf="data.order.COD_REASON_CODE == 'AO'">
                                                    <mat-icon class="text-yellow-400 icon-size-5" [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
                                                    Total:<b>{{ data.order.TOTAL  | currency:'CLP':'symbol-narrow'}}</b>  
                                                </div>  
                                                <div *ngIf="data.order.COD_REASON_CODE != 'AO'">
                                                    <mat-icon class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                                                    Total:<b>{{ data.order.TOTAL  | currency:'CLP':'symbol-narrow'}}</b> 
                                                </div>                                                                     
                                            </div>
                                            <div class="w-1/2 prose" *ngIf="data.order.FLAG_PAYMENT != 'Y'">
                                                <mat-icon class="text-red-400 icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
                                                Total:<b>{{ data.order.TOTAL  | currency:'CLP':'symbol-narrow'}}</b> 
                                            </div>                                             
                                    <!--&&&&&&&&&&&&&&&&&&&-->                            
                    </div>
                    <!-- // -->
                    <div class="grid grid-cols-3"> 
                            <!--&&&&&&&&&&&&&&&&&&&-->      
                            <div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Neto:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_NETO  | currency:'CLP':'symbol-narrow' }}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">IVA:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_IVA  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Descuento:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_DESCUENTO  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>                
                            </div>
                            <!--&&&&&&&&&&&&&&&&&&&-->  
                            <div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Exento:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_EXENTO  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Envio:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_ENVIO  | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                            </div>
                            <!--&&&&&&&&&&&&&&&&&&&-->   
                            <div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Donación:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_DONACION   | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Ajuste Sencillo:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_AJUSTE_SENCILLO   | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="w-1/2 prose">Propina:</div>
                                    <div class="w-1/2 prose"><b>{{ data.order.TOTAL_TIP    | currency:'CLP':'symbol-narrow'}}</b></div>
                                </div>
                            </div>
                            <!--&&&&&&&&&&&&&&&&&&&-->                               
                        </div> 
                        <br>
                        <!-- // -->
                        <mat-divider class="py-2"></mat-divider>
                        <div class="grid grid-cols-2"> 
                            <!--&&&&&&&&&&&&&&&&&&&--> 
                            <div>
                                <div *ngIf="data.order?.RUT == '66666666-6' || data.order?.RUT == '55555555-5'" class="prose" >
                                    Cliente:<b>{{data.order?.RUT | rut:'DOTS_DASH'}}  <br> {{ data.order?.RAZON_SOCIAL | titlecase}}</b>
                                    <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button    disabled="true" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button> 
                                </div>  
                                <div *ngIf="data.order?.RUT != '66666666-6' && data.order?.RUT != '55555555-5'" class="prose" >
                                    Cliente:<b>{{data.order?.RUT | rut:'DOTS_DASH'}}<br> {{ data.order?.RAZON_SOCIAL | titlecase}}</b>
                                    <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button                    (click)="editOrCreateClient(data.order?.UUID_CRM_CUSTOMER)" > <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button> 
                                </div>  
                            </div>
                            <div>              
                                <div class="prose" >
                                    Colaborador: <b>{{data.order.EMPLOYEE_LOGIN | rut:'DOTS_DASH'}} {{data.order?.EMPLOYEE_FIRST_NAME}} {{data.order?.EMPLOYEE_LAST_NAME}}</b>
                                </div>     
                                <div class="prose" >
                                    Punto de Venta: <b>{{data.order.COD_SP}}-{{data.order?.NAME_SP}}</b>
                                </div>                                           
                            </div>            
                            <!--&&&&&&&&&&&&&&&&&&&--> 
                        </div>     
                        <!-- // -->        
                            <!-- // -->  
                            <div class="grid grid-cols-2" *ngIf="data.order.CUSTOMER_CONTACT_LEGAL_NUMBER !='1-9'"> 
                                <!--&&&&&&&&&&&&&&&&&&&--> 
                                <div  >
                                    <div class="prose" >
                                        Contacto:<b>
                                                    {{data.order?.CUSTOMER_CONTACT_LEGAL_NUMBER | rut:'DOTS_DASH'}} {{ data.order?.CUSTOMER_CONTACT_FIRST_NAME | titlecase}} {{ data.order?.CUSTOMER_CONTACT_LAST_NAME | titlecase}}
                                        </b>
                                    </div>  
                                </div>
                                <div>    
                                    <div class="prose" >
                                            Cargo:  {{ data.order?.CUSTOMER_CONTACT_GLS_CONTACT_TYPE | titlecase}}<br>
                                            Correo: {{ data.order?.CUSTOMER_CONTACT_MAIL | titlecase}}  
                                    </div>                                    
                                </div>            
                                <!--&&&&&&&&&&&&&&&&&&&--> 
                        </div> 
                        <!-- ================================================================== -->
                        <!-- ================================================================== -->
                        <!-- <div *ngIf="data.orderShiping[0].COD_SHIPING_METHOD == 'FLC' "> -->
                            <!-- /////////////////////////////////////////////////////// -->
                                    
                            <!-- /////////////////////////////////////////////////////// -->
                        <!--</div>-->
                        <!-- ================================================================== -->
                        <!-- ================================================================== -->
        <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->
        <!-- <button  mat-stroked-button  (click)="printZebra()" > <mat-icon class="icon-size-5 plusButton" [svgIcon]="'add'"></mat-icon>   </button> -->
        <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&-->                         
                </div>    
                <!--TOTALES:FIN-->        
        <!--########################################################################-->
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>
   <!-- ## -->
    <mat-tab label="Productos" >
        <mat-divider class="py-2"></mat-divider>
         <!--########################################################################-->
                <!--TABLA PRODUCTOS:INICIO-->
                <div>
                    <mat-table [dataSource]="orderDetail" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="sku">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">SKU/BAR</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">    
                                                                    <div>
                                                                        <b>{{orderDetail.COD_PS_SERVICES}}</b>
                                                                        <br>
                                                                        {{orderDetail.BARCODE}}
                                                                    </div>
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="producto">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Producto</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">{{orderDetail.NAME_PS_SERVICES}}</mat-cell>
                        </ng-container>
                        <!--//-->         
                        <ng-container matColumnDef="wareHouse">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Bodega</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail"> 
                                                                    <!-- ####  -->
                                                                    <div *ngIf="orderDetail.COD_WAREHOUSE_TYPE === 'W'"><mat-icon  class="text-green-700 icon-size-5"> warehouse </mat-icon>   </div> 
                                                                    <div *ngIf="orderDetail.COD_WAREHOUSE_TYPE === 'V'"><mat-icon  class="text-blue-700 icon-size-5"> cloud </mat-icon>        </div> 
                                                                    <div *ngIf="orderDetail.COD_WAREHOUSE_TYPE === 'U'"><mat-icon  class="text-blue-700 icon-size-5"> all_inclusive </mat-icon></div> 
                                                                    <!-- ####  -->                                                                                
                                                                    <small>{{orderDetail.COD_WAREHOUSE}}-{{orderDetail.NAME_WAREHOUSE}}</small>
                            </mat-cell>
                        </ng-container>
                        <!--//-->                                                             
                        <ng-container matColumnDef="cantidad">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Cantidad</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">{{orderDetail.QUANTITY_PS_SERVICES}}</mat-cell>
                        </ng-container>
                        <!--//-->                        
                        <ng-container matColumnDef="precio">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Precio</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">
                                                               <div *ngIf="orderDetail.COD_CURRENCY == 'CLP'">
                                                                    {{orderDetail.NETO  | currency:'CLP':'symbol-narrow'}}
                                                               </div>     
                                                               <div *ngIf="orderDetail.COD_CURRENCY != 'CLP'">
                                                                {{orderDetail.NETO  | currency:'CLP':'symbol-narrow'}}
                                                                <br>
                                                                {{orderDetail.SYMBOL_CURRENCY}}{{orderDetail.SALES_PRICE | number}}
                                                                <br>
                                                                <small>
                                                                    tc: ${{orderDetail.CURRENCY_VALUE | number  }}<br> ({{orderDetail.DATE_CURRENCY | date: 'dd-MM-yy'}})
                                                                </small>                                                                
                                                               </div>                                                                    
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="desc">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Descuento</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">
                                                                    <div *ngIf="orderDetail.DESCUENTO_TOTAL > 0">
                                                                        <!--///////////////////////////-->
                                                                        <div *ngIf="orderDetail.FLAG_FULL_FILLMENT != '00'">
                                                                                <div *ngIf="orderDetail.FLAG_AMOUNT_LIMIT == 'N'">
                                                                                    <span style="color: rgb(217, 11, 11)">{{orderDetail.DESCUENTO_TOTAL | currency:'CLP':'symbol-narrow'}}</span>
                                                                                </div>
                                                                                <div *ngIf="orderDetail.FLAG_AMOUNT_LIMIT == 'Y'">
                                                                                    <span style="color: rgb(217, 11, 11)">{{orderDetail.DESCUENTO_TOTAL | currency:'CLP':'symbol-narrow'}}*</span>
                                                                                    <span style="color: rgb(217, 11, 11)"><small>Monto Máximo</small></span>
                                                                                </div>                                                                        
                                                                                

                                                                                <div *ngIf="orderDetail.TYPE_PS_SERVICES_DISCOUNT == 'A' ">
                                                                                
                                                                                    <span style="color: rgb(8, 49, 164)"> {{orderDetail.NAME_DISCOUNT }}:</span>
                                                                                    <br>
                                                                                    <span *ngIf="orderDetail.FLAG_QUANTITY_LIMIT == 'Y'" style="color: rgb(217, 11, 11)"> {{orderDetail.SYMBOL_CURRENCY}} {{orderDetail.DISCOUNT  | number}} x Q:{{orderDetail.QUANTITY_LIMIT}}</span>
                                                                                    <span *ngIf="orderDetail.FLAG_QUANTITY_LIMIT == 'N'" style="color: rgb(217, 11, 11)"> {{orderDetail.SYMBOL_CURRENCY}} {{orderDetail.DISCOUNT  | number}} x Q:{{orderDetail.QUANTITY_PS_SERVICES}}</span>
                                                                                </div>
                                                                                <!-- // -->
                                                                                <div *ngIf="orderDetail.TYPE_PS_SERVICES_DISCOUNT == 'P' ">
                                                                                    <span style="color: rgb(8, 49, 164)"> {{orderDetail.NAME_DISCOUNT }}:</span>                                                                            
                                                                                    <span *ngIf="orderDetail.FLAG_QUANTITY_LIMIT == 'Y'" style="color: rgb(217, 11, 11)"> {{orderDetail.DISCOUNT  | percent}} (Q:{{orderDetail.QUANTITY_LIMIT}})      </span>
                                                                                    <span *ngIf="orderDetail.FLAG_QUANTITY_LIMIT == 'N'" style="color: rgb(217, 11, 11)"> {{orderDetail.DISCOUNT  | percent}} (Q:{{orderDetail.QUANTITY_PS_SERVICES}})</span>                                                                            
                                                                                </div> 
                                                                                <!-- // -->
                                                                                <div *ngIf="orderDetail.FLAG_FULL_FILLMENT == 'T0'">
                                                                                    <span style="color: rgb(8, 49, 1641)">  <small>{{orderDetail.GLS_AMOUNT}}</small></span>
                                                                                </div>
                                                                                <!-- // -->
                                                                                <div *ngIf="orderDetail.FLAG_FULL_FILLMENT != 'T0'">
                                                                                    <span *ngIf="orderDetail.FLAG_AMOUNT_START   == 'Y'" style="color: rgb(8, 49, 1641)">  <small>{{orderDetail.GLS_AMOUNT}}</small></span>
                                                                                    <span *ngIf="orderDetail.FLAG_QUANTITY_START == 'Y'" style="color: rgb(8, 49, 164)"> <small>{{orderDetail.GLS_QUANTITY}}</small></span>                                                                            
                                                                                </div>  
                                                                        </div>     
                                                                        <div *ngIf="orderDetail.FLAG_FULL_FILLMENT == '00'">
                                                                            <div >
                                                                                <span style="color: rgb(217, 11, 11)">{{orderDetail.DESCUENTO_TOTAL | currency:'CLP':'symbol-narrow'}}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span style="color: rgb(8, 49, 164)"> {{orderDetail.NAME_DISCOUNT }}</span>
                                                                            </div>
                                    
                                                                        </div>                                                                                                                                                                                                                   
                                                                        <!--///////////////////////////-->                                                                        
                                                                    </div>
                                                                    <div *ngIf="orderDetail.DISCOUNT == 0 && orderDetail.DESCUENTO == 0">
                                                                        <span style="color: rgb(217, 11, 11)"> {{orderDetail.DISCOUNT  | currency:'CLP':'symbol-narrow'}}</span>
                                                                    </div>                                                                    
                            </mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="promotion">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Promoción</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">
                                                                    <div *ngIf="orderDetail.DESC_PS_SERVICES_PROMOTION != '0'">{{orderDetail.DESC_PS_SERVICES_PROMOTION}}</div>
                                                                    <div *ngIf="orderDetail.DESC_PS_SERVICES_PROMOTION == '0'">S/Promoción</div>
                                                                    </mat-cell>
                        </ng-container>
                        <!--//-->   
                        <ng-container matColumnDef="total">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Total</mat-header-cell>
                            <mat-cell *matCellDef="let orderDetail">{{orderDetail.TOTAL  | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>              
                        <!--//-->                                                                        
                        <mat-header-row *matHeaderRowDef="columnsDetail" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsDetail" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDetail
                        [pageSize]="orderDetailPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>   
                <!--TABLA PRODUCTOS:FIN-->      
         <!--########################################################################-->
         <mat-divider class="py-2"></mat-divider>
    </mat-tab>
    <!-- ## -->
    <mat-tab label="Documentos" >
        <mat-divider class="py-2"></mat-divider>
         <!--########################################################################-->
                <!--DTE:INICIO-->
                <div>
                    <mat-table [dataSource]="orderDTE" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="dte">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Tipo Doc</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.DESC_COD_REASON_CODE}}</mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <ng-container matColumnDef="folio">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Folio</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                                            <div *ngIf="o.COD_DOC == '9020' || o.COD_DOC == '9010'; else folio ">
                                                                {{o.UUID_DTE}}
                                                            </div>
                                                            <ng-template #folio> {{o.FOLIO | number}}</ng-template>
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="total">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Total</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.TOTAL  | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="Fecha">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Fecha</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.DATE_CREATION |  date:'dd-MM-yyyy'}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="estado">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Estado</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <div *ngIf="o.COD_STATUS_DTE == 'P' && data.order.FLAG_PAYMENT == 'Y' && ( o.COD_DOC == '39' || o.COD_DOC == '41' || o.COD_DOC == '33' || o.COD_DOC == '34'  ) " >  <button mat-raised-button  (click)="reMake(o)"> <mat-icon>autorenew</mat-icon>Emitir DTE</button>   </div>
                                <div *ngIf="o.COD_STATUS_DTE == 'P' && data.order.FLAG_PAYMENT == 'Y' && ( o.COD_DOC == '61'  ) " >  <button mat-raised-button  (click)="reMake(o)"> <mat-icon>autorenew</mat-icon>Emitir NC</button>   </div>
                                <div *ngIf="o.COD_STATUS_DTE == 'PR'"> Proceso  </div>
                                <div *ngIf="o.COD_STATUS_DTE == 'OK'"> Emitida  </div>
                                <div *ngIf="o.COD_STATUS_DTE == 'P' && data.order.FLAG_PAYMENT != 'Y' " >No emitida/{{data.order.NAME_COD_REASON_CODE }}</div>
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="link">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600"></mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                    <div *ngIf="(o.COD_DOC == '33' || o.COD_DOC == '34')  && o.COD_STATUS_DTE == 'OK'">
                                        80mm<mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'"                                        (click)="downloadDte(o.COD_DOC,o.UUID_DTE,'80mm','0')" class="text-yellow-400 icon-size-6" [svgIcon]="'heroicons_solid:document-download'"></mat-icon>
                                        <!--     <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK' && data.order.FLAG_PRINTER_A == 'Y'"    (click)="printPDF(o.COD_DOC,o.UUID_DTE,'80mm','0')"    class="text-blue-400 icon-size-6"   [svgIcon]="'heroicons_solid:printer'"></mat-icon>-->
                                        Carta  <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'"                                        (click)="downloadDte(o.COD_DOC,o.UUID_DTE,'a4','0')"   class="text-yellow-400 icon-size-6" [svgIcon]="'heroicons_solid:document-download'"></mat-icon>
                                        <!--     <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK' && data.order.FLAG_PRINTER_A == 'Y'"    (click)="printPDF(o.COD_DOC,o.UUID_DTE,'a4','0')"      class="text-blue-400 icon-size-6"   [svgIcon]="'heroicons_solid:printer'"></mat-icon>-->
                                     Cedible<mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'"                                        (click)="downloadDte(o.COD_DOC,o.UUID_DTE,'a4','')"    class="text-yellow-400 icon-size-6" [svgIcon]="'heroicons_solid:document-download'"></mat-icon>
                                        <!--     <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK' && data.order.FLAG_PRINTER_A == 'Y'"    (click)="printPDF(o.COD_DOC,o.UUID_DTE,'a4','')"     class="text-blue-400 icon-size-6"   [svgIcon]="'heroicons_solid:printer'"></mat-icon>-->
                                    </div> 
                                    <div *ngIf="(o.COD_DOC == '39' || o.COD_DOC == '41' || o.COD_DOC == '52' || o.COD_DOC == '56' || o.COD_DOC == '61' || o.COD_DOC == '43' || o.COD_DOC == '46' ) && o.COD_DOC != '5001' && o.COD_STATUS_DTE == 'OK'">
                                        80mm<mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'"                                       (click)="downloadDte(o.COD_DOC,o.UUID_DTE,'80mm','0')" class="text-yellow-400 icon-size-6"  [svgIcon]="'heroicons_solid:document-download'"></mat-icon>
                                        <!--     <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'  && data.order.FLAG_PRINTER_A == 'Y'"  (click)="printPDF(o.COD_DOC,o.UUID_DTE,'80mm','0')"    class="text-blue-400 icon-size-6"    [svgIcon]="'heroicons_solid:printer'"></mat-icon>-->
                                        Carta  <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'"                                       (click)="downloadDte(o.COD_DOC,o.UUID_DTE,'a4','0')"   class="text-yellow-400 icon-size-6"  [svgIcon]="'heroicons_solid:document-download'"></mat-icon>
                                        <!--     <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'  && data.order.FLAG_PRINTER_A == 'Y'"  (click)="printPDF(o.COD_DOC,o.UUID_DTE,'a4','0')"      class="text-blue-400 icon-size-6"    [svgIcon]="'heroicons_solid:printer'"></mat-icon>-->
                                    </div>                                      
                                    <div *ngIf="o.COD_DOC == '5001' && o.COD_STATUS_DTE == 'OK' && o.FOLIO > 0">
                                        80mm<mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'"                                       (click)="downloadDte(o.COD_DOC,o.UUID_DTE,'80mm','0')" class="text-yellow-400 icon-size-6"  [svgIcon]="'heroicons_solid:document-download'"></mat-icon>
                                        <!--    <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'  && data.order.FLAG_PRINTER_A == 'Y'"  (click)="printPDF(o.COD_DOC,o.UUID_DTE,'80mm','0')"    class="text-blue-400 icon-size-6"    [svgIcon]="'heroicons_solid:printer'"></mat-icon>-->
                                    </div> 
                                    <div *ngIf="o.COD_DOC == '110' || o.COD_DOC == '111' || o.COD_DOC == '112'">
                                        Carta  <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'"                                       (click)="downloadDte(o.COD_DOC,o.UUID_DTE,'A4','0')"   class="text-yellow-400 icon-size-6"  [svgIcon]="'heroicons_solid:document-download'"></mat-icon>
                                        <!--     <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'  && data.order.FLAG_PRINTER_A == 'Y'"  (click)="printPDF(o.COD_DOC,o.UUID_DTE,'a4','0')"      class="text-blue-400 icon-size-6"    [svgIcon]="'heroicons_solid:printer'"></mat-icon>-->
                                    </div>  
                                    <div *ngIf="o.COD_DOC == '999'">
                                        80mm <mat-icon *ngIf="o.COD_STATUS_DTE == 'OK'  && data.order.FLAG_PRINTER_A == 'Y'"    (click)="printTicketChange()"       class="text-blue-400 icon-size-6"    [svgIcon]="'heroicons_solid:printer'"></mat-icon>                                        
                                    </div> 
                                    <!-- // -->
                                    <div *ngIf="o.COD_DOC == '9020' || o.COD_DOC == '9010' ">
                                        Carta <mat-icon   (click)="movementDetail(o.UUID_EOM_ORDER)"   class="text-blue-400 icon-size-6"  [svgIcon]="'link'"></mat-icon>
                                    </div>                                      
                                    <!-- // -->

                                                                     
                           </mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <mat-header-row *matHeaderRowDef="columnsDTE" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsDTE" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDTE
                        [pageSize]="orderDTEPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>
                <!--DTE:FIN-->           
         <!--########################################################################-->
         <mat-divider class="py-2"></mat-divider>
    </mat-tab>
    <!-- ## -->
    <mat-tab label="Devoluciones" >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
                <!--TABLA DEVOLUCIONES:INICIO-->
                <div>
                    <!-- #### -->
                    <div *ngIf="data.order.COD_REASON_CODE !='AO'"> 
                        <h2 class="mat-h2"><b>Crear Devolución </b>
                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  [disabled]="data.order.COD_REASON_CODE !='FN' && data.order.COD_REASON_CODE != 'OK'" (click)="returnOrder()" > <mat-icon class="icon-size-5" color="warn"    [svgIcon]="'edit'"></mat-icon>   </button>    
                        </h2>
                    </div>
                    <div *ngIf="data.order.COD_REASON_CODE =='AO'">
                        <h2 class="mat-h2"><b>Crear Devolución </b>
                            <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  disabled="true"   (click)="returnOrder()" > <mat-icon class="icon-size-5" color="warn" [svgIcon]="'edit'"></mat-icon> </button>                                        
                        </h2>
                    </div>  
                   <!-- #### -->                                         
                    <mat-table [dataSource]="orderReturn" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="Motivo">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Motivo</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.NAME_COD_REASON_CODE}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="total">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Total</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.TOTAL  | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="fecha">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Fecha</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.DATE_CREATION | date:'dd-MM-yyyy HH:mm:ss'}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="employee">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Colaborador</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.EMPLOYEE_DATA.EMPLOYEE_FIRST_NAME}} {{o.EMPLOYEE_DATA.EMPLOYEE_LAST_NAME}}</mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <ng-container matColumnDef="estado">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Estado</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.COD_STATUS_DTE}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="tipo">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Tipo</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.GLS_TYPE_EOM_ORDER_RETURN}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="sp">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Punto de Venta</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.NAME_SP}}</mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <ng-container matColumnDef="coupon">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Cupon</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.COD_EPC_DISCOUNT_COUPON}}</mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <ng-container matColumnDef="detail">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Detalle</mat-header-cell>
                            <mat-cell *matCellDef="let o"><mat-icon (click)="loadReturnDetail(o.UUID_EOM_ORDER_RETURN)" class="text-green-400 icon-size-5" [svgIcon]="'heroicons_solid:clipboard-list'"></mat-icon></mat-cell>
                        </ng-container>
                        <!--//-->                                       
                        <mat-header-row *matHeaderRowDef="columnsReturn" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsReturn" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagReturn
                        [pageSize]="orderReturnPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>   
                <!--TABLA DEVOLUCIONES:FIN-->      
                <!--TABLA DEVOLUCIONES DETALLE:INICIO-->
                <div>
                    <h2 class="mat-h2">Productos</h2>
                    <mat-table [dataSource]="orderReturnDetail" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="sku">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">SKU</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                                            <div>
                                                                <b>{{o.COD_PS_SERVICES}}</b>
                                                                <br>
                                                                {{o.BARCODE}}
                                                            </div>
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="producto">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Producto</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.NAME_PS_SERVICES}}</mat-cell>
                        </ng-container>
                        <!--//-->         
                        <ng-container matColumnDef="wareHouse">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Bodega</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                                            
                                                                    <!-- ####  -->
                                                                    <div *ngIf="o.COD_WAREHOUSE_TYPE === 'W'"><mat-icon  class="text-green-700 icon-size-5"> warehouse </mat-icon>   </div> 
                                                                    <div *ngIf="o.COD_WAREHOUSE_TYPE === 'V'"><mat-icon  class="text-blue-700 icon-size-5"> cloud </mat-icon>        </div> 
                                                                    <div *ngIf="o.COD_WAREHOUSE_TYPE === 'U'"><mat-icon  class="text-blue-700 icon-size-5"> all_inclusive </mat-icon></div> 
                                                                    <!-- ####  -->                                                                                
                                                                    <small>{{o.COD_WAREHOUSE}}-{{o.NAME_WAREHOUSE}}</small>                                                            
                            </mat-cell>
                        </ng-container>
                        <!--//-->         
                        <ng-container matColumnDef="estado">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Estado</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.NAME_COD_REASON_CODE}}</mat-cell>
                        </ng-container>                    
                        <!--//-->                                   
                        <ng-container matColumnDef="cantidad">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Cantidad</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.QUANTITY_PS_SERVICES}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="precio">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Precio</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.NETO  | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="total">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Total</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.TOTAL  | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <mat-header-row *matHeaderRowDef="columnsReturnDetail" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsReturnDetail" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagReturnDetail
                        [pageSize]="orderReturnDetailPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>
                <!--TABLA DEVOLUCIONES DETALLE:FIN-->                  
        <!--########################################################################-->
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>      
    <!-- ## -->
    <mat-tab label="Pagos" >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
            <!--TABLA ESTADOS:INICIO-->
                <div>
                    <h2 class="mat-h2">Detalles de Pago</h2>
                    <mat-table [dataSource]="orderPayment" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="paymeans">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Medio Pago</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                                        <div *ngIf="o.COD_PM_PAY_MEANS == 'PIT' ">{{o.DATA_ORDER_PAYMENT.cardDetail.cardBrandName}}</div>
                                                        <div *ngIf="o.COD_PM_PAY_MEANS != 'PIT' ">{{o.NAME_PM_PAY_MEANS}}</div>
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Estado</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.DATA_ORDER_PAYMENT.status }}</mat-cell>
                        </ng-container>
                        <!--//-->         
                        <ng-container matColumnDef="total">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Total</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.DATA_ORDER_PAYMENT.amount | currency:'CLP':'symbol-narrow'}}</mat-cell>
                        </ng-container>
                        <!--//-->                                   
                        <ng-container matColumnDef="extra">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Detalle </mat-header-cell>
                                <mat-cell *matCellDef="let o">
                                    <!--//-->
                                        <div *ngIf="o.FLAG_PARAM == 'Y' ">{{o.NAME_PARAM}}:<br><b>{{o.TXT_SC_REL_PM_PAYS_MEANS}}</b></div>
                                        <div *ngIf="o.FLAG_PARAM == 'N' && ( o.COD_PM_PAY_MEANS == 'WP' || o.COD_PM_PAY_MEANS == 'PIT' ) ">
                                                                                                        **** **** **** <b>{{o.DATA_ORDER_PAYMENT.cardDetail.card_number}}</b>
                                                                                                        <br>Cod Autorización {{o.DATA_ORDER_PAYMENT.authorizationCode}}
                                        </div>
                                    <!--//--> 
                                </mat-cell>
                        </ng-container>
                        <!--//-->                
                        <mat-header-row *matHeaderRowDef="columnsPayment" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsPayment" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagPayment
                        [pageSize]="orderPaymentPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>                
                <!--///////////////--> 
                <div>
                    <h2 class="mat-h2">Historial</h2>
                    <mat-table [dataSource]="orderHistory" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="newStatus">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Estado</mat-header-cell>
                            <mat-cell *matCellDef="let history" >
                                            <div [ngStyle]="
                                            {
                                                'background-color':  history.CONFIG_COD_REASON_CODE.colorOrderStatus, 
                                                'color': '#fff',
                                                'font-weight': 'bold',
                                                'text-align': 'center',
                                                'border-radius': '20px',
                                                'vertical-align': 'middle',
                                                'padding': '5px 10px',
                                                'border': '2px solid dotted brown'
                                            }">
                                            <small>{{ history.DATA_EOM_ORDER_STATUS }}</small>
                                            </div>
                          </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Fecha</mat-header-cell>
                            <mat-cell *matCellDef="let history">{{ history.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss' }}</mat-cell>
                        </ng-container>
                        <!--//-->         
                        <ng-container matColumnDef="employee">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Colaborador</mat-header-cell>
                            <mat-cell *matCellDef="let history">{{ history.EMPLOYEE_FIRST_NAME }} {{ history.EMPLOYEE_LAST_NAME }}</mat-cell>
                        </ng-container>
                        <!--//-->                
                        <mat-header-row *matHeaderRowDef="columnsHistory" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsHistory" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                        <!--//-->                    
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagHistory
                        [pageSize]="orderHistoryPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>  
                <!--///////////////--> 
            <!--TABLA ESTADOS:FIN-->        
        <!--########################################################################-->
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>      
    <!-- ## -->
    <mat-tab label="Despacho" *ngIf="data.carrierStatus.length > 0 " >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
        <!--########################################################################-->     
        <mat-tab-group #tabsOrder2    >
            <!-- ## -->
            <mat-tab label="Despacho" >
                <!--########################################################################-->
                            <div *ngIf="data.orderShiping[0].COD_SHIPING_METHOD == 'SHP' ">
                            <!-- /////////////////////////////////////////////////////// -->
                                        <form [formGroup]="carrierForm">
                                        <div class="grid grid-cols-2">
                                            <!-- // -->
                                            <div>
                                                <!-- ## -->
                                                <mat-form-field class="w-3/4">
                                                    <mat-label>Estado de la Orden</mat-label>
                                                    <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
                                                    <mat-select formControlName="uuidStatus">
                                                        <mat-option *ngFor="let o of data.carrierStatus" [value]="o.UUID_EOM_ORDER_STATUS" (click)="chkStatusTrackID()">
                                                        {{ o.DESC_COD_REASON_CODE}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <!-- ## -->
                                            </div>
                                            <div>
                                                <!-- ## -->
                                                <mat-form-field class="w-3/4">
                                                    <mat-label> TrackID </mat-label>
                                                    <input matInput type="text" formControlName="trackID" />
                                                </mat-form-field>
                                                <!-- ## <div *ngIf="createTransportOrders == true  ">-->
                                                <div >
                                                        
                                                        <!--   <button mat-flat-button [color]="'primary'" (click)="newOmsOrderOT()" [disabled]="data.orderShiping[0].flagProd == true && data.orderShiping[0].TRAKING_NUMBER != '00000'"> Crear OT</button>-->  
                                                            <button mat-flat-button *ngIf="data.orderShiping[0].showLabel  == 'true' " [color]="'primary'" (click)="labelOmsOrderOT('download')" [disabled]="data.orderShiping[0].TRAKING_NUMBER == '00000'">Descargar Equiqueta</button>
                                                            <button mat-flat-button *ngIf="data.orderShiping[0].printLabel == 'true' " [color]="'primary'" (click)="labelOmsOrderOT('print')" [disabled]="data.orderShiping[0].TRAKING_NUMBER == '00000'">Imprimir Equiqueta</button>
                                                            <br>
                                                </div>
                                                <div>
                                                    <button mat-flat-button color="warn" (click)="replaceOmsOrderOT()" >Reemplazar OT </button>
                                                    <button mat-flat-button [disabled]="flagReplaceOT == false" color="primary" (click)="saveReplaceOmsOrderOT()" >Actualizar OT </button>
                                                </div>
                                                <!-- ## -->
                                            </div>
                                            <!-- // -->
                                        </div>

                                        </form>                        
                                        <div *ngIf="flagReplaceOT == false">
                                            <!-- ## -->
                                            <button mat-flat-button [color]="'primary'" (click)="updateOrderStatus()" [disabled]="carrierForm.invalid || flagUpdateOrderStatus == false"> Actualizar</button>
                                            <!-- ## -->
                                        </div>
                                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->
                                        <div class="grid grid-cols-2"> 
                                            <!--&&&&&&&&&&&&&&&&&&&-->      
                                            <div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Courier:</div>
                                                    <div class="w-1/2 prose"><b>{{ data.orderShiping[0].NAME_CS_CARRIER}}</b></div>
                                                </div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Tipo Envio</div>
                                                    <div class="w-1/2 prose"><b>{{ data.orderShiping[0].NOTE_SHIPING}}</b></div>
                                                </div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">TrackID:</div>
                                                    <div class="w-1/2 prose">
                                                                            <b *ngIf=" data.orderShiping[0].TRAKING_NUMBER=='00000'" style="color: rgb(181, 5, 11)">Pendiente</b>
                                                                            <b *ngIf=" data.orderShiping[0].TRAKING_NUMBER!='00000'" style="color: rgb(4, 5, 93)" >{{ data.orderShiping[0].TRAKING_NUMBER}}</b>
                                                                        </div>
                                                </div>                
                                            </div>
                                            <!--&&&&&&&&&&&&&&&&&&&-->  
                                            <!--&&&&&&&&&&&&&&&&&&&-->   
                                            <div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Creado:</div>
                                                    <div class="w-1/2 prose"><b>{{ data.orderShiping[0].DATE_CREATION |  date:'dd-MM-yyyy'}}</b></div>
                                                </div>                            
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Fecha Envio:</div>
                                                    <div class="w-1/2 prose">
                                                                                <b *ngIf="data.orderShiping[0].FLAG_SHIPING == 'Y'" style="color: rgb(4, 5, 93)">{{ data.orderShiping[0].DATE_SHIPING |  date:'dd-MM-yyyy'}}</b>
                                                                                <b *ngIf="data.orderShiping[0].FLAG_SHIPING == 'N'" style="color: rgb(181, 5, 11)">Pendiente</b>
                                                    </div>
                                                </div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Fecha de Entrega:</div>
                                                    <div class="w-1/2 prose">
                                                                                <b *ngIf=" data.order.DATE_DELIVERED == '1900-01-01T00:00:00'" style="color: rgb(181, 5, 11)">Pendiente</b>
                                                                                <b *ngIf=" data.order.DATE_DELIVERED != '1900-01-01T00:00:00'" style="color: rgb(4, 93, 11)"  >{{ data.order.DATE_DELIVERED |  date:'dd-MM-yyyy HH:mm:ss'}}</b>
                                                    </div>
                                                </div>

                                            </div>
                                            <!--&&&&&&&&&&&&&&&&&&&-->                               
                                        </div> 
                                        <div class="grid grid-cols-2"> 
                                            <div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Costo Envio:</div>
                                                    <div class="w-1/2 prose"><b>{{ data.orderShiping[0].TOTAL_ENVIO| currency:'CLP':'symbol-narrow'}}</b></div>
                                                </div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Dimenciones:</div>
                                                    <div class="w-1/2 prose">
                                                                            <small>Alto:      <b>{{data.orderShiping[0].SHIPPING_MEASURE.height}}</b> cm&nbsp;</small> x 
                                                                            <small>Ancho:     <b>{{data.orderShiping[0].SHIPPING_MEASURE.width}}</b> cm&nbsp;</small> x
                                                                            <small>Largo:     <b>{{data.orderShiping[0].SHIPPING_MEASURE.length}}</b> cm&nbsp;</small> x
                                                                            <small>Peso:      <b>{{data.orderShiping[0].SHIPPING_MEASURE.weight}}</b> grs&nbsp;</small>
                                                                            <br>
                                                                            <small><b>Peso Final:</b> <b>{{data.orderShiping[0].SHIPPING_MEASURE.finalWeight}} grs</b> &nbsp;</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$--> 
                                <div *ngIf=" data.orderShipingHistory.length > 0">
                                    <h2><b>Dirección de Despacho</b></h2>
                                    <h2>{{ data.orderShipingHistory[0].NAME_ECS_CARRIER_STATUS}}</h2>
                                    <div class="row">
                                        <!-- // -->
                                        <div class="col-lg-4" *ngFor="let o of data.orderShipingHistory; let i = index;">
                                            <div  class="card card-dashboard" >
                                                <div class="card-body">
                                                    <h3 class="card-title"><b>{{o.DATA_EOM_ORDER_SHIPING.ALIAS_CUSTOMER_ADDRESS}}</b></h3>
                                                    <p style="color: rgb(4, 5, 93)"> {{o.DATA_EOM_ORDER_SHIPING.ADDRESS1}}  N°{{o.DATA_EOM_ORDER_SHIPING.ADDRESS2}}
                                                    <span *ngIf="o.DATA_EOM_ORDER_SHIPING.FLAG_ADDRESS_3 == 'Y'"> {{o.DATA_EOM_ORDER_SHIPING.GLS_CUSTOMER_ADDRESS_TYPE}} {{o.DATA_EOM_ORDER_SHIPING.ADDRESS3}}</span><br>
                                                <!--   {{o.ADDRESS2}}<br>-->
                                                    {{o.DATA_EOM_ORDER_SHIPING.GLS_COMUNA}},{{o.DATA_EOM_ORDER_SHIPING.GLS_CIUDAD}}<br>
                                                    {{o.DATA_EOM_ORDER_SHIPING.GLS_REGION}}<br>
                                                    cod Postal:{{o.DATA_EOM_ORDER_SHIPING.POSTAL_CODE}}<br>
                                                    Tel:{{o.DATA_EOM_ORDER_SHIPING.PHONE_NUMBER}}<br>
                                                    <br>
                                                    Notas:{{o.DATA_EOM_ORDER_SHIPING.DELIVERY_NOTES}}<br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>                                                        
                                    </div>
                                </div>                            
                            <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->    
                            </div>              
                        <!-- == -->
                        <!-- == -->
                            <div *ngIf="data.orderShiping[0].COD_SHIPING_METHOD == 'C&C' ">
                                    <!-- /////////////////////////////////////////////////////// -->
                                    <form [formGroup]="carrierForm">
                                        <div class="grid grid-cols-2">
                                            <!-- // -->
                                            <div>
                                                <!-- ## -->
                                                <mat-form-field class="w-3/4">
                                                    <mat-label>Estado de la Orden</mat-label>
                                                    <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
                                                    <mat-select formControlName="uuidStatus">
                                                        <mat-option *ngFor="let o of data.carrierStatus" [value]="o.UUID_EOM_ORDER_STATUS" >
                                                        {{ o.DESC_COD_REASON_CODE}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <!-- ## -->                                
                                            </div>
                                            <!-- // -->
                                            <div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Punto de Retiro:</div>
                                                    <div class="w-1/2 prose"><b>{{ data.orderShiping[0].NAME_WAREHOUSE}}</b></div>
                                                </div>
                                                <div class="flex justify-between">
                                                    <div class="w-1/2 prose">Dirección:</div>
                                                    <div class="w-1/2 prose"><b>{{ data.orderShiping[0].ADDRESS_WAREHOUSE}}</b></div>
                                                </div>
                                                <div>
                                                   <button mat-flat-button [color]="'primary'" *ngIf="data.orderShiping[0].showLabel  == 'true'" (click)="labelOmsOrderOT('download')" >Descargar Equiqueta</button>
                                                   <button mat-flat-button [color]="'primary'" *ngIf="data.orderShiping[0].printLabel == 'true'" (click)="labelOmsOrderOT('print')" >Imprimir Equiqueta</button>
                                                </div>
                                            </div>
                                            <!-- // -->
                                        </div>
                                    </form>
                                    <div>
                                        <!-- ## -->
                                        <button mat-flat-button [color]="'primary'" (click)="updateOrderStatus()" [disabled]="carrierForm.invalid || flagUpdateOrderStatus == false"> Actualizar</button>
                                        <!-- ## -->
                                    </div>                    
                                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->
                                    <h2><b>{{ data.orderShiping[0].NAME_CS_CARRIER}}</b></h2>
                                    <h2></h2>
                                    <div class="grid grid-cols-2"> 
                                        <div>
                                            <div class="flex justify-between">
                                                <div class="w-1/2 prose">Costo Envio:</div>
                                                <div class="w-1/2 prose"><b>{{ data.orderShiping[0].TOTAL_ENVIO| currency:'CLP':'symbol-narrow'}}</b></div>
                                            </div>
                                            <!-- 
                                            <div class="flex justify-between">
                                                <div class="w-1/2 prose">Dimenciones:</div>
                                                <div class="w-1/2 prose">
                                                                        <small>Alto:      <b>{{data.orderShiping[0].SHIPPING_MEASURE.height}}</b> cm&nbsp;</small>
                                                                        <small>Ancho:     <b>{{data.orderShiping[0].SHIPPING_MEASURE.width}}</b> cm&nbsp;</small>
                                                                        <small>Largo:     <b>{{data.orderShiping[0].SHIPPING_MEASURE.length}}</b> cm&nbsp;</small><br>
                                                                        <small>Peso:      <b>{{data.orderShiping[0].SHIPPING_MEASURE.weight}}</b> grs&nbsp;</small>
                                                                        <small>Productos: <b>{{data.orderShiping[0].SHIPPING_MEASURE.SUM_PS}}</b> &nbsp;</small> 
                                                </div>
                                            </div>
                                            -->
                                        </div>
                                    </div>                    
                                <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$-->                
                            </div>
                        <!-- == -->
                        <!-- == -->
                            <div *ngIf="data.orderShiping[0].COD_SHIPING_METHOD == 'FLC' ">
                                    <!-- /////////////////////////////////////////////////////// -->                
                                        <form [formGroup]="carrierForm">
                                            <div class="grid grid-cols-2">
                                                <!-- // -->
                                                <div>
                                                    <!-- ## -->
                                                    <mat-form-field class="w-3/4">
                                                        <mat-label>Estado de la Orden</mat-label>
                                                        <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
                                                        <mat-select formControlName="uuidStatus">
                                                            <mat-option *ngFor="let o of data.carrierStatus" [value]="o.UUID_EOM_ORDER_STATUS" >
                                                            {{ o.DESC_COD_REASON_CODE}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <!-- ## -->                                
                                                </div>
                                                <!-- // -->
                                                <div>
                                                    <div class="flex justify-between">
                                                        <div class="w-1/2 prose">Correo Cliente:</div>
                                                        <div class="w-1/2 prose"><b>{{ data.orderShiping[0].NOTE_SHIPING}}</b></div>
                                                    </div>
                                                </div>
                                                <!-- // -->
                                            </div>
                                        </form>
                                        <div>
                                            <!-- ## -->
                                            <button mat-flat-button [color]="'primary'" (click)="updateOrderStatus()" [disabled]="carrierForm.invalid || flagUpdateOrderStatus == false"> Actualizar</button>
                                            <!-- ## -->
                                        </div>   
                                    <!-- /////////////////////////////////////////////////////// -->                              
                            </div>
                <!--########################################################################-->      
            </mat-tab>
                <!--########################################################################-->      
            <mat-tab label="Seguimiento" >
                <!--########################################################################-->
                <div class="grid grid-cols-2">
                    <!-- // -->
                    <div class="text-gray-700 text-sm mb-4">
                        <div class="flex justify-between">
                            <div class="w-1/2 prose">Estado:</div>
                            <div class="w-1/2 prose"><b>{{orderOTTracking?.transportOrderData?.status}}</b></div>
                        </div>
                        <div class="flex justify-between">
                            <div class="w-1/2 prose">Origen:</div>
                            <div class="w-1/2 prose"><b>{{orderOTTracking?.transportOrderData?.locationStatus}}</b></div>
                        </div>
                    </div>                     
                    <!-- // -->
                </div>
                <div >
                    <mat-table [dataSource]="orderTracking" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Fecha</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.eventDate}} {{o.eventHour}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="description">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Detalle</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.description}}
                            </mat-cell>
                        </ng-container>
                        <!--//-->                                       
                        <mat-header-row *matHeaderRowDef="columnsTracking" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsTracking" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagTracking
                        [pageSize]="orderTrackingPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>   
                </div>
                <!--########################################################################-->                
            </mat-tab>
                <!--########################################################################-->   
        </mat-tab-group>                                                   
        <!--########################################################################-->
        <!--########################################################################-->        
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>      
    <!-- ## -->
    <mat-tab label="Descuentos" >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
                <!--TABLA DESCUENTOS:INICIO-->
                <div>
                    <mat-table [dataSource]="orderDiscountCoupon" class="mat-elevation-z8">
                        <!--//-->                    
                        <ng-container matColumnDef="cod">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Codigo</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.COD_EPC_DISCOUNT_COUPON}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="amount">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Monto</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <div *ngIf="o.TYPE_EPC_DISCOUNT_COUPON == 'A'"     > <b ><span style="color: rgb(217, 11, 11)">{{o.AMOUNT_DISCOUNT_COUPON  | currency:'CLP':'symbol-narrow'}}</span></b> </div>
                                <div *ngIf="o.TYPE_EPC_DISCOUNT_COUPON == 'P'"     > <b>{{o.AMOUNT_DISCOUNT_COUPON  | percent }} <span style="color: rgb(217, 11, 11)">{{o.AMOUNT_DISCOUNT_COUPON_ORDER | currency:'CLP':'symbol-narrow'}}</span></b> </div>                                                                 
                                                            
                            </mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="paymean">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Aplica</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                    <div *ngIf="o.FLAG_PM_PAY_MEANS == 'Y'"     > {{o.NAME_PM_PAY_MEANS}} </div>
                                    <div *ngIf="o.FLAG_EPC_SALES_CHANNEL == 'Y'"> {{o.NAME_SALES_CHANNEL}}</div>
                                    <div *ngIf="o.FLAG_PM_PAY_MEANS == 'N'"     > Todo Medio Pago </div>
                                    <div *ngIf="o.FLAG_EPC_SALES_CHANNEL == 'N'"> Todos los canales de Venta</div>                                    
                            </mat-cell>
                        </ng-container>
                        <!--//-->                    
                        <ng-container matColumnDef="reason">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Razon</mat-header-cell>
                            <mat-cell *matCellDef="let o">{{o.NAME_COD_REASON_CODE}}</mat-cell>
                        </ng-container>
                        <!--//-->
                        <ng-container matColumnDef="obs">
                            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Detalle</mat-header-cell>
                            <mat-cell *matCellDef="let o">
                                <div *ngIf="o.OBS != '0' " > {{o.OBS}}</div>
                                <div *ngIf="o.OBS == '0' " > s/i</div>
                            </mat-cell>
                        </ng-container>
                        <!--//-->                                       
                        <mat-header-row *matHeaderRowDef="columnsDiscountCoupon" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                        <mat-row *matRowDef="let row;columns: columnsDiscountCoupon" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
                    </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagDiscountCoupon
                        [pageSize]="orderDiscountCouponPag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>               
                        <!--//-->                
                </div>  
                <!--TABLA DESCUENTOS:FIN-->        
        <!--########################################################################-->
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>      
    <!-- ## -->
    <mat-tab label="Notas" >
        <mat-divider class="py-2"></mat-divider>
        <!--########################################################################-->
                <!--NOTAS:INICIO--> 
                <div>
                     <button mat-raised-button color="primary" (click)="openAddOrderNote(data.order)"> <mat-icon>add</mat-icon>Agregar </button>
                        <mat-table [dataSource]="orderMessage" class="mat-elevation-z8">
                        <!--//-->  
                            <ng-container matColumnDef="employeeName">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600">Colaborador </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.EMPLOYEE_DATA.EMPLOYEE_FIRST_NAME}} {{element.EMPLOYEE_DATA.EMPLOYEE_LAST_NAME}} </mat-cell>
                            </ng-container>
                        <!--//-->                      
                            <ng-container matColumnDef="message">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Mensaje </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.TXT_ORDER_MESSAGE}} </mat-cell>
                            </ng-container>
                        <!--//-->                          
                            <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Fecha </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.DATE_CREATION |  date:'dd-MM-yyyy HH:mm:ss'}} </mat-cell>
                            </ng-container>
                        <!--//-->  
                        <ng-container matColumnDef="tipo">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600"> Tipo </mat-header-cell>
                            <mat-cell *matCellDef="let element"> 
                                <div *ngIf=" element.FLAG_EOM_ORDER_MESSAGE_PUBLIC == 'Y' ">Publica</div>
                                <div *ngIf=" element.FLAG_EOM_ORDER_MESSAGE_PUBLIC == 'N' ">Privada</div>
                            </mat-cell>
                            </ng-container>                    
                        <!--//-->  
                        <ng-container matColumnDef="acctionBotton">
                            <mat-header-cell *matHeaderCellDef  class="font-bold uppercase text-sm text-gray-600">  </mat-header-cell>
                            <mat-cell *matCellDef="let element"> 
                                <div *ngIf=" element.ID_EMISOR_CONTACTO == 0 || element.ID_EMISOR_REPECTOR == 0 || element.ID_EMISOR_USR == 0">
                                    <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button  (click)="delMessage(element)"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'delete'"></mat-icon> </button>
                                </div>
                            </mat-cell>
                            </ng-container>                    
                        <!--//-->                                                                 
                            <!-- Columnas de la tabla -->
                            <mat-header-row *matHeaderRowDef="columnsMessage" class="bg-gray-100 border-b border-gray-200" ></mat-header-row>
                            <mat-row *matRowDef="let row;columns: columnsMessage" class="hover:bg-gray-100 border-b border-gray-200"></mat-row> 
                        </mat-table>
                        <!--//-->      
                        <mat-spinner *ngIf="isLoading" style="margin:0 auto"></mat-spinner>         
                        <mat-paginator
                        #pagMessage
                        [pageSize]="orderMessagePag"
                        showFirstLastButtons
                        [pageSizeOptions]="pageSizeOptions" 
                        aria-label="Select page of periodic elements">
                    </mat-paginator>  
                </div>
                <!--NOTAS:FIN-->        
        <!--########################################################################-->
        <mat-divider class="py-2"></mat-divider>
   </mat-tab>    
    <!-- ## -->    
</mat-tab-group>
<!-- ================================================================================================================== -->                
<!-- ================================================================================================================== -->                
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->                                              
<!-- ##########################################################  -->
<!-- ##########################################################  -->
</div>
<!-- ##########################################################  -->
<!-- ##########################################################  -->
</div>