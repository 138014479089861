import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: HttpClient,
  ) { }

  async getAllRegiones(){
    const $this = this;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.location); 
        res.subscribe(
          (data) => {
            // console.log(data.dataSet.region)
            data = data.dataSet.region
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  }

  async getAllComunas(){
    const $this = this;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.location);
        res.subscribe(
          (data) => {
            // console.log(data)
            data = data.dataSet.comuna
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  }

  async getAllCiudades(): Promise<any> {
    const $this = this;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.location);
        res.subscribe(
          (data) => {
            // console.log(data)
            data = data.dataSet.ciudad
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  }

  async getAllCountrys(){
    const $this = this;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>( environment.endPoints.location);
        res.subscribe(
          (data) => {
            // console.log(data)
            data = data.dataSet.pais
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  }
}
