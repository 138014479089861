import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
//
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { SalesPointService } from 'app/services/salesPoint.service';
//
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
//
import { ToastrService } from 'ngx-toastr';
//
import { DetailClientComponent } from '../detail-client/detail-client.component'

@Component({
  selector: 'app-ctz-detail',
  templateUrl: './ctz-detail.component.html',
  styleUrls: ['./ctz-detail.component.scss']
})
//############################################################
//############################################################
export class CtzDetailComponent implements OnInit {
  @ViewChild('pagMessage'       ) pagMessage        : MatPaginator;
  @ViewChild('pagHistory'       ) pagHistory        : MatPaginator;
  @ViewChild('pagDetail'        ) pagDetail         : MatPaginator;
  @ViewChild('pagDTE'           ) pagDTE            : MatPaginator;
  @ViewChild('pagReturn'        ) pagReturn         : MatPaginator;
  @ViewChild('pagReturnDetail'  ) pagReturnDetail   : MatPaginator;
  @ViewChild('pagPayment'       ) pagPayment        : MatPaginator;
  @ViewChild('pagDiscountCoupon') pagDiscountCoupon : MatPaginator;
  @ViewChild('pagShiping'       ) pagShiping        : MatPaginator;
  page : number = 0;

  isLoadingHistory :boolean = false;

  //
  order               : any ;
  orderDetail         : any ;
  orderHistory        : any ;
  orderMessage        : any ;
  orderDiscountCoupon : any ;

  columnsDetail         : any[] = ['sku','producto','wareHouse', 'cantidad', 'precio','desc','promotion', 'total']
  columnsHistory        : any[] = ['newStatus', 'date', 'employee'];
  columnsMessage        : any[] = ['tipo', 'message','employeeName', 'date'];
  columnsDiscountCoupon : any[] = ['cod', 'amount', 'paymean','reason','obs'];

  orderDetailPag         : number = 10;
  orderHistoryPag        : number = 3;
  orderMessagePag        : number = 3;
  orderDiscountCouponPag : number = 3;

  pageSizeOptions : any = [3, 5 ,10, 50, 100];

  isLoading : boolean = false;

  flagShow=false
  //
  codDoc=33;
  authDTE    : any = {}
  //
  emisorData :any = {};
    //
    userData =  {
                   login      : ''
                  ,firstName  : ''
                  ,lastName   : ''
                  ,pin        : ''
                  ,envirometDTE : ''
                  ,envirometBOL : ''
                  ,envirometTKT : ''                     
                  }
    //
    matTabOrderSelected = 0
    //
//############################################################
//############################################################
constructor(
            private _dialog: MatDialog,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private _dialogRef: MatDialogRef<CtzDetailComponent>, 
            private ref: ChangeDetectorRef,
            private _fuseConfirmationService: FuseConfirmationService,
            private _salesPointService : SalesPointService,
            //
            private _datePipe:DatePipe,
            private _currencyPipe:CurrencyPipe,
            private _toastr: ToastrService,
            ) { 
              this.emisorData = JSON.parse(localStorage.getItem('emisorData'));
              var tmp;
              tmp =  (JSON.parse(localStorage.getItem('ssoData')))
              this.userData = {
                                   login        : tmp.user.login
                                  ,firstName    : tmp.user.firstName
                                  ,lastName     : tmp.user.lastName
                                  ,pin          : this.emisorData.usrData[0].PIN
                                  ,envirometDTE : this.emisorData.emisorData[0].COD_ENVIROMENT_DTE
                                  ,envirometBOL : this.emisorData.emisorData[0].COD_ENVIROMENT_BOL
                                  ,envirometTKT : this.emisorData.emisorData[0].COD_ENVIROMENT_TKT
                              }


}
//############################################################
//############################################################
refreshOrder(e,data){
  //console.log(e)
  }
  //############################################################
  //############################################################
async ngOnInit(): Promise<any>{
  await this.loadOrderHistory();
  await this.loadOrderMessage();
  await this.loadDetail();
  await this.loadDiscountCoupon();
  this.codDoc = this.data.ctz.COD_DOC_MAKE_ORDER
  this.authDTE  = await this.makeAuthInvoice(this.codDoc)
}
//############################################################
//############################################################
async anularCtz(ctz,acc){
const dialogRefa = this._fuseConfirmationService.open({
  title: `La Cotizacion ${ctz.FRIENLY_EOM_CTZ}, sera anulada, Monto Total: <b>${this._currencyPipe.transform(ctz.TOTAL, 'CLP', 'symbol-narrow')}</b>`,
  message: `Esta operación no es reversible`,
  icon: {
    show: true,
    name: 'delete',
    color: 'warn'
  },    
  actions: {
      confirm: {
          label: 'Aceptar',
          color: 'warn',
      },
      cancel: {
           show: true,
          label: 'Cancelar',
      },
  },
  dismissible: false   
});

dialogRefa.afterClosed().subscribe(async (result) => {
  if (result === 'confirmed') {
      //////////////////////////////////////////
            let udpCtz={
              "language"      :"es"
              ,"appToken"     : null
              ,"uuidSSO"      : null
              ,"uuidEomCtz"   : ctz.UUID_EOM_CTZ
              ,"codDoc"       : null
              ,"uuidDte"      : null
              ,"codStatusDte" : null
              ,"uuidEomOrder" : null
              ,"codCtzStatus" : acc
          }
          let orderCtz = await this._salesPointService.ctzUdp(udpCtz);
          this._toastr.success('Cotizacion  Anulada', 'Exito');
          this._dialogRef.close();
      //////////////////////////////////////////                    
  } else {
      return;
  }
});

}
//############################################################
//############################################################
async makeOrder(ctz,acc){
  var index:number=0, dte:any,order:any,placeOrder:any,save:any,makeDte:any,udpDte:any,orderDte:any
  var ts_hms = new Date();
  var fechaRef = ts_hms.getFullYear() + '-' + ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + ("0" + (ts_hms.getDate())).slice(-2) 
const dialogRefa = this._fuseConfirmationService.open({
  title: `Cotizacion ${ctz.FRIENLY_EOM_CTZ}, sera facturada Monto Total: <b>${this._currencyPipe.transform(ctz.TOTAL, 'CLP', 'symbol-narrow')}</b>`,
  message: `Sera Creada una Orden, pendiente de pago`,
  icon: {
    show: true,
    name: 'assignment_turned_in',
    color: 'warning'
  },    
  actions: {
      confirm: {
          label: 'Aceptar',
          color: 'primary',
      },
      cancel: {
           show: true,
          label: 'Cancelar',
      },
  },
  dismissible: false   
});

dialogRefa.afterClosed().subscribe(async (result) => {
  if (result === 'confirmed') {
      ////////////////////////////////////////// 
      placeOrder = {
                         "language"     :"es"
                        ,"uuidSSO"      : null
                        ,"appToken"     : null
                        ,"uuidEomCtz"   : ctz.UUID_EOM_CTZ
                      }
       order = await this._salesPointService.ctzPlaceOrder(placeOrder); 
       if(order.status == true){
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
                this._toastr.success(order.glsStatus, 'Exito');
                dte =  await this._salesPointService.getCtz2Dte(ctz.UUID_DTE,this.codDoc)
                index= Number(dte.dataSet.Referencia.length) + Number(1)
                dte.dataSet.Referencia.push({
                                              NroLinRef : index
                                              ,TpoDocRef : 'GLS'
                                              ,IndGlobal : 1
                                              ,FolioRef  : 0
                                              ,FchRef    : fechaRef
                                              ,RazonRef  : "Orden:" + order.order.FRIENLY_EOM_ORDER
                                            })

                save = await this.orderDteSave(order.order.UUID_EOM_ORDER,0,dte.dataSet) 
                makeDte = await this.makeDte(dte.dataSet)
                //##
                if(save.status == true && makeDte.caratula.status == true){
                   udpDte={
                                "language"  :"es"
                                ,"appToken"  : null
                                ,"uuidSSO"   : null
                                ,"order":{
                                
                                            "uuidEomOrder"     : order.order.UUID_EOM_ORDER
                                            ,"codDoc"          : makeDte.dte[0].codDoc
                                            ,"uuidDte"         : makeDte.dte[0].uuidDTE
                                            ,"codStatusDte"    : 'OK'
                                            ,"uuidCrmCustomer" : ''     
                                      }
                            }
                    orderDte = await this._salesPointService.orderDte(udpDte);
                    this._toastr.success('DTE Emitido', 'Exito'); 
                    this._dialogRef.close();
                 }
                 else{
                  this._toastr.error('DTE no emitido', 'Error');
                  this._dialogRef.close();
                 }               
                //##                    
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
       
       }
      //////////////////////////////////////////                    
  } else {
      return;
  }
});
}
//############################################################
//############################################################
show(){
  if(this.flagShow == false) this.flagShow = false;
  if(this.flagShow == true ) this.flagShow = true;
}
//############################################################
//############################################################
  async loadOrderHistory(): Promise<any>{
    this.isLoading=true; 
    this.orderHistory = new MatTableDataSource(this.data.orderHistory);
    this.ref.detectChanges();
    this.orderHistory.paginator = this.pagHistory;
    this.isLoading=false; 
  };
//############################################################
//############################################################
  async loadOrderMessage(): Promise<any>{
    this.isLoading=true; 
    this.orderMessage = new MatTableDataSource(this.data.ctzMessage);
    this.ref.detectChanges();
    this.orderMessage.paginator = this.pagMessage;
    this.isLoading=false; 
  };
//############################################################
//############################################################
async loadDetail (): Promise<any>{
  this.isLoading=true; 
  this.orderDetail = new MatTableDataSource(this.data.ctzDetail);
  this.ref.detectChanges();
  this.orderDetail.paginator = this.pagDetail;
  this.isLoading=false; 
};
//############################################################
//############################################################
async loadDiscountCoupon  (): Promise<any>{
  this.isLoading=true; 
  this.orderDiscountCoupon = new MatTableDataSource(this.data.ctzDiscountCoupon );
  this.ref.detectChanges();
  this.orderDiscountCoupon.paginator = this.pagDiscountCoupon;
  this.isLoading=false; 
};
//############################################################
//############################################################
  async closeBtn(): Promise<any> {
    this._dialogRef.close();
}
//############################################################
//############################################################
async makeAuthInvoice(TipoDTE): Promise<any>{  
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
      var auth,token
      token = localStorage.getItem('appToken')
        //////////////////////////////////////////// 
        if(TipoDTE == 39 || TipoDTE == 41){
              auth = {"appToken":token,"language":"es","environment": $this.userData.envirometBOL,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
        }
        else if(TipoDTE == 5001 || TipoDTE == 5100  ){ // hacer clase para tratar los tkt
              auth = {"appToken":token,"language":"es","environment": $this.userData.envirometTKT,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
       }
         else{
              auth = {"appToken":token,"language":"es","environment": $this.userData.envirometDTE,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
       }
              ////////////////////////////////////////////  
       resolve(auth)
      }); 
}
//############################################################
//############################################################
async orderDteSave(uuidEomOrder,uuidEomOrderReturn,jsonDte): Promise<any>{  
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
      var auth,token
      token = localStorage.getItem('appToken')
        //////////////////////////////////////////// 
        let json = {
                           "language" :"es"
                          ,"appToken" : null
                          ,"uuidSSO"  : null
                          ,"orderDTE":{
                          
                                      "uuidEomOrder"         : uuidEomOrder
                                      ,"uuidEomOrderReturn"  : uuidEomOrderReturn
                                      ,"dteData"             : jsonDte
                              }
                              
                          }
        let save = await $this._salesPointService.orderDteSave(json);
        ////////////////////////////////////////////  
       resolve(save)
      }); 
}
//############################################################
//############################################################
async makeDte(dte){
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
        ////////////////////////////////////////////
        try{
            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°    
              var make,data
              data = [dte]
              if($this.codDoc == 5001 || $this.codDoc == 5100){
                //make = await $this._salesPointService.makeTkt( $this.auth ,data)
              }
              else{
                make = await $this._salesPointService.makeDte( $this.authDTE ,data)
              }
              resolve(make)
            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
          }
          catch(e)
          {
               console.log(e)
              reject({success:false,error:e,jsonDte:null})
        }
   });             
} 
//############################################################
//############################################################
//############################################################
//############################################################
async editOrCreateClient(uuidCrmCustomer): Promise<void> {
  const dialogRef = this._dialog.open(DetailClientComponent, {
    
    width: '100%',
    height: '100%',
      //autoFocus: false,
      data: {
          customer: null,
          type: 'view',
          tab:"customer",
          source: 'modal',
          uuidCrmCustomer : uuidCrmCustomer

      }
  });
  dialogRef.afterClosed().subscribe(async (result) => {
     // this.ngOnInit();
  });
}
//############################################################
//############################################################

}
