/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EmisorService {

  //rutEmpresa = localStorage.getItem('rutEmpresa');
  // appToken = 'CCA067D69814CADAE0536B01A8C00150';
  // appToken = '804ff3684dd74764b048aa8d7e129ff4';
  appToken //= localStorage.getItem('appToken');//environment.appTokenUser; 


  constructor(
    private _http: HttpClient,
    private _toastr: ToastrService,
  ) { }

  async getLogo(): Promise<any>{
    const $this = this;
    const rutEmisor = localStorage.getItem('rutEmpresa');
    const url = `${environment.endPoints.logoEmisor}${rutEmisor}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  }

  async getInfoEmisor(): Promise<any> {
    const $this = this;
    const rutEmisor = localStorage.getItem('rutEmpresa');
    const url = `${environment.endPoints.emisorInfo}${rutEmisor}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async getInfoContactosEmisor(): Promise<any>{
    const $this = this;
    const rutEmisor = localStorage.getItem('rutEmpresa');
    const url = `${environment.endPoints.emisorContactosInfo}${rutEmisor}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  }

  async getCertificados(): Promise<any>{
    const $this = this;
    const rutEmisor = localStorage.getItem('rutEmpresa');
    const url = `${environment.endPoints.emisorCertificados}${rutEmisor}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async getSucursales(): Promise<any>{
    const $this = this;
    const rutEmisor = localStorage.getItem('rutEmpresa');
    const url = `${environment.endPoints.emisorSucursales}${rutEmisor}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async getUsers(): Promise<any>{
    const $this = this;
    const rutEmisor = localStorage.getItem('rutEmpresa');
    const url = `${environment.endPoints.emisorUsers}${rutEmisor}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async uploadCertificado(file: any, pass: string): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    ////////////////////////////////////////
    var tmp2 = (JSON.parse(localStorage.getItem('emisorData')))
    var uuidEmisor = tmp2.emisorData[0].UUID_EMISOR
    /////////////////////////////////////////    
    const url = `${environment.endPoints.emisorCertificados}`;
    const formData = new FormData();
    const data = {
      uuidEmisor: uuidEmisor,
      password: pass
    };
    formData.append('data', JSON.stringify(data));
    formData.append('cert', file);
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.post<any>(url, formData);
        res.subscribe(
          (resp) => {
            resolve(resp);
          },
          (error) => {
            console.log(error.error.detail.error)
            $this._toastr.error(`${error.error.detail.error}`, 'Error');
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async deleteCertificado(idCert: number): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    ////////////////////////////////////////
    var tmp2 = (JSON.parse(localStorage.getItem('emisorData')))
    var uuidEmisor = tmp2.emisorData[0].UUID_EMISOR
    /////////////////////////////////////////
    const data = {
      "uuidEmisor": uuidEmisor
      ,"idEmisorCert": idCert
    }
    console.log(data)
    return await new Promise (async function(resolve, reject) {
      try {
        const pet = await $this._http.delete<any>(`${environment.endPoints.emisorCertificados}`, {
          body: {
            data: JSON.stringify(data)
          }
        });

        pet.subscribe(
          (resp) => {
            resolve(resp);
          },
          (error) => {
            reject(error);
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  async uploadLogo(file: any): Promise<any> {
    const $this = this;
    const url = `${environment.endPoints.logoEmisor}`;
    const formData = new FormData();
    const data = {
      'rutEmisor': localStorage.getItem('rutEmpresa'),
    };
    formData.append('data', JSON.stringify(data));
    formData.append('img', file);
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.post<any>(url, formData);
        res.subscribe(
          (resp) => {
            resolve(resp);
          },
          (error) => {
            resolve(error.error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async getWarehouses(): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const rutEmisor = localStorage.getItem('rutEmpresa');
    const url = `${environment.endPoints.warehouse}${this.appToken}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async getWarehouseTypes(): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.warehouseType}${this.appToken}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/*
async crearCostos(cost: any): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.costs}`;
      const data = {
        "appToken": $this.appToken,
        "language":"es",
        'cost': [cost]
      };

      const res = await $this.http.post<any>(url, {
        'data': JSON.stringify(data)
      });
      res.subscribe(
        (subResp: any) => {
          subResp = subResp.dataSet;
          resolve(subResp);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
}*/
async getReasonCodesFilter(data): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.reasonCodes}`;
  return await new Promise (async function(resolve, reject) {   
    try {
      data.appToken=$this.appToken
      const res = await $this._http.post<any>(url, {
        'data': JSON.stringify(data)
      });
      res.subscribe(
        (data) => {
          data = data.dataSet;
          data.data.forEach((element) => {
            element.CONFIG_COD_REASON_CODE = JSON.parse(element.CONFIG_COD_REASON_CODE);
          });
          // console.log(data.data);
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    }
    catch (err) {
      reject(err);
    }
  });
};
async getReasonCodesAll(): Promise<any> {
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  const url = `${environment.endPoints.reasonCodes}${this.appToken}`;
  return await new Promise (async function(resolve, reject) {
    try {
      const res = await $this._http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          data.data.forEach((element) => {
            element.CONFIG_COD_REASON_CODE = JSON.parse(element.CONFIG_COD_REASON_CODE);
          });
          // console.log(data.data);
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    }
    catch (err) {
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////


  async getAllStatus(): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const url = `${environment.endPoints.statusCodes}${this.appToken}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  };

  async getCAF(){
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    let rutEmpresa = localStorage.getItem('rutEmpresa');
        //
        let emisorData = (JSON.parse(localStorage.getItem('emisorData')))
        let env        = emisorData.emisorData[0].GROUP_ENVIROMENT_DTE
        //
    const url = `${environment.endPoints.caf}${env}/${rutEmpresa}`;
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this._http.get<any>(url);
        res.subscribe(
          (data) => {
            resolve(data.dataSet);
          },
          (error) => {
            reject(error);
          }
        );
      }
      catch (err) {
        reject(err);
      }
    });
  }

}
