import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ClienteService } from 'app/services/cliente.service';
import { DocsService } from 'app/services/docs.service';
import { Observable, of } from 'rxjs';
import { DialogCreateUpdateInteractionComponent } from './dialog-create-update-interaction/dialog-create-update-interaction.component';
import { CtzDetailComponent } from '../ctz-detail/ctz-detail.component';

import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OrderService } from 'app/services/orders.service';
import { DialogDetailComponent } from '../../ordenes/dialog-detail/dialog-detail.component';
import {ClaimComponent} from '../../ordenes/claim/claim.component'
import { DialogCreateOrUpdateCasosComponent } from '../../casos/dialog-create-or-update-casos/dialog-create-or-update-casos.component';
import { DialogPdfviewerComponent } from 'app/shared/components/dialog-pdfviewer/dialog-pdfviewer.component';
import { Router } from '@angular/router';
import { DialogDeclineOrAceptComponent } from '../../docs/docs-recibidos/dialog-decline-or-acept/dialog-decline-or-acept.component';
import { RcService } from  'app/services/rc.service';
import { DetailRcComponent } from '../detail-rc/detail-rc.component'
import { SalesPointService } from 'app/services/salesPoint.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-detail-client',
    templateUrl: './detail-client.component.html',
    styleUrls: ['./detail-client.component.scss'],
})
export class DetailClientComponent implements OnInit {
    /////////////////////////////////////////////////////////////
    @ViewChild('tabsDocs') tabsDocs: any | undefined;


    @ViewChild('pagCasos'    ) pagCasos    : MatPaginator;
    displayedCasos      : string[] = ['iconSLA', 'cod','t1t2', 'assignedTo', 'createdDate','updateDate', 'status', 'acc']
    isLoadingCasos      : boolean = false;
    dataCasos           : any = [];
    tableCasos          : any = [];
    searchCasos         : any = '';
    tmpSearchCasos      : any;

    @ViewChild('pagContact'  ) pagContact  : MatPaginator;    
    displayedContact    : string[] = ['type','legalNumber','firstName','lastName','mail','phone','birthday','acc']
    isLoadingContact    : boolean = false;
    dataContact         : any = [];
    tableContact        : any = [];
    searchContact       : any = '';
    tmpSearchContact    : any;   

    @ViewChild('pagDireccion') pagDireccion: MatPaginator;
    displayedDireccion  : string[] = [ 'alias','address1','region','ciudad','comuna','telefono','typeAddress','acc'] // [ 'alias','default','address1','adrress2','region','ciudad','comuna','telefono','typeAddress','acc']
    isLoadingDireccion  : boolean = false;
    dataDireccion       : any = [];
    tableDireccion      : any = [];
    searchDireccion     : any = '';
    tmpSearchDireccion  : any;   

    @ViewChild('pagNotas'    ) pagNotas    : MatPaginator;
    displayedNotas      : string[] = ['createdBy','createdAt','txt','scheduleAt','reminder','view']
    isLoadingNotas      : boolean = false;
    dataNotas           : any = [];
    tableNotas          : any = [];
    searchNotas         : any = '';
    tmpSearchNotas      : any;    

    @ViewChild('pagRc'    ) pagRc    : MatPaginator;
    displayedRC      : string[] = ['cod_ooff','recurring_day','status_rc','date','count_child','date_creation','date_update','acc']
    isLoadingRc      : boolean = false;
    dataRc           : any = [];
    tableRc          : any = [];   
    searchRc         : any = '';
    tmpSearchRc      : any;     

    @ViewChild('pagOrder'    ) pagOrder    : MatPaginator;
    displayedOrder      : string[] = ['FRIENLY_EOM_ORDER','TOTAL','NAME_COD_REASON_CODE', 'COD_SP','DATE_CREATION','DATE_UPDATE','EMPLOYEE','UUID_EOM_ORDER']
    isLoadingOrder      : boolean = false;
    dataOrder           : any = [];
    tableOrder          : any = [];
    searchOrder         : any = '';
    tmpSearchOrder      : any;    

    @ViewChild('pagDte'      ) pagDte      : MatPaginator;    
    displayedDte        : string[] =['folio','fechaEmision', 'typeDoc', 'emitedTo', 'rutRecep', 'tlt','actions'];
    isLoadingDte        : boolean = false;
    dataDte             : any = [];
    tableDte            : any = [];
    searchDte           : any = '';
    tmpSearchDte        : any;    

    @ViewChild('pagCtz'      ) pagCtz      : MatPaginator;    
    displayedCtz        : string[] = ['folio','fechaEmision', 'typeDoc', 'emitedTo', 'rutRecep', 'tlt','actions'];
    isLoadingCtz        : boolean = false;
    dataCtz             : any = [];
    tableCtz            : any = [];
    searchCtz           : any = '';
    tmpSearchCtz        : any;    

    @ViewChild('pagTkt'      ) pagTkt      : MatPaginator;    
    displayedTkt        : string[] = ['folio','fechaEmision', 'typeDoc', 'emitedTo', 'rutRecep', 'tlt','actions'];
    isLoadingTkt        : boolean = false;
    dataTkt             : any = [];
    tableTkt            : any = [];    
    searchTkt           : any = '';
    tmpSearchTkt        : any; 

    @ViewChild('pagReceivedDTE'      ) pagReceivedDTE      : MatPaginator;    
    displayedReceivedDTE: string[] = ['folio','fechaEmision', 'typeDoc', 'emitedTo', 'rutRecep', 'tlt','actions','actions2'];
    isLoadingReceivedDTE: boolean = false;
    dataReceivedDTE     : any = [];
    tableReceivedDTE    : any = [];    
    searchReceivedDTE   : any = '';
    tmpSearchReceivedDTE: any;        

    @ViewChild('pagReceivedBOL'      ) pagReceivedBOL      : MatPaginator;    
    displayedReceivedBOL: string[] = ['folio','fechaEmision', 'typeDoc', 'emitedTo', 'rutRecep', 'tlt','actions2'];
    isLoadingReceivedBOL: boolean = false;
    dataReceivedBOL     : any = [];
    tableReceivedBOL    : any = [];    
    
      
    
    @ViewChild('pagSummary'      ) pagSummary      : MatPaginator;    
    displayedSummary        : string[] =['year','count','total'];
    isLoadingSummary        : boolean = false;
    dataSummary             : any = [];
    tableSummary            : any = [];      

    @ViewChild('pagReturn'      ) pagReturn      : MatPaginator;    
    displayedReturn        : string[] =['year','count','total'];
    isLoadingReturn        : boolean = false;
    dataReturn             : any = [];
    tableReturn            : any = [];       

    @ViewChild('pagOrderProducts'      ) pagOrderProducts     : MatPaginator;    
    displayedOrderProducts        : string[] = ['cod','ean','name', 'quantity', 'order', 'date','acc'];
    isLoadingOrderProducts        : boolean = false;
    dataOrderProducts             : any = [];
    tableOrderProducts            : any = []; 
    searchOrderProducts           : any = '';
    tmpSearchOrderProducts        : any;       

    @ViewChild('pagDueSummary'      ) pagDueSummary      : MatPaginator;    
    displayedDueSummary        : string[] =['year','count','total'];
    isLoadingDueSummary        : boolean = false;
    dataDueSummary             : any = [];
    tableDueSummary            : any = [];  

    @ViewChild('pagToDueSummary'      ) pagToDueSummary      : MatPaginator;    
    displayedToDueSummary        : string[] =['year','count','total'];
    isLoadingToDueSummary        : boolean = false;
    dataDueToSummary             : any = [];
    tableToDueSummary            : any = [];      

    @ViewChild('pagOrderDue'    ) pagOrderDue    : MatPaginator;
    displayedOrderDue      : string[] = ['FRIENLY_EOM_ORDER','TOTAL','NAME_COD_REASON_CODE', 'COD_SP','DATE_CREATION','DATE_UPDATE','DUE_DATE','DUE_DATE_DAYS','EMPLOYEE','UUID_EOM_ORDER']
    isLoadingOrderDue      : boolean = false;
    dataOrderDue           : any = [];
    tableOrderDue          : any = [];
    searchOrderDue         : any = '';
    tmpSearchOrderDue      : any;       

    @ViewChild('pagOrderCtz'    ) pagOrderCtz    : MatPaginator;
    displayedOrderCtz      : string[] = ['FRIENLY_EOM_CTZ','TOTAL','GLS_EOM_CTZ_STATUS', 'CUSTOMER','DATE_CREATION','DUE_DATE','EMPLOYEE','UUID_EOM_CTZ']       
    isLoadingOrderCtz      : boolean = false;
    dataOrderCtz           : any = [];
    tableOrderCtz          : any = [];
    searchOrderCtz         : any = '';
    tmpSearchOrderCtz      : any;       

    /////////////////////////////////////////////////////////////
    pag                 : number = 20;
    pageSizeOptions     : any    = [20, 50, 100,150,200];

    pagHome             : number = 3;
    pageSizeOptionsHome : any    = [6, 12, 18,24,48];
    /////////////////////////////////////////////////////////////
    months = environment.months
    monthSelected: number = 1;
    yearSelected: number = new Date().getFullYear();
    today: number = new Date().getTime();
    last5Years: any[] = [];  
    /////////////////////////////////////////////////////////////
    matTabGroupSelected :number = 0
    matTabDteSelected   :number = 0
    uuidCliente
    customerData : any = {};
    rutEmisor;   
    stars: number[] = [1, 2, 3, 4, 5];
    selectedValue: number;
    /////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
    constructor(
        private _fb: FormBuilder,
        private _clienteService: ClienteService,
        private _docsService: DocsService,
        private _dialog: MatDialog,
        private _fuseConfirmationService: FuseConfirmationService,
        private ref: ChangeDetectorRef,
        private _toast: ToastrService,    
        private _orderService : OrderService,  
        private _router: Router, 
        private _docsServ: DocsService,
        private _rc:RcService,
        private _salesPointService : SalesPointService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _dialogRef: MatDialogRef<DetailClientComponent>, 
    ) {
        this.monthSelected = new Date().getMonth() + 1;
        this.yearSelected = new Date().getFullYear();
        for (let i = 0; i < 5; i++) {
          this.last5Years.push(this.yearSelected - i);
        }   
        this.rutEmisor = localStorage.getItem('rutEmpresa');
        //==        
    }
//////////////////////////////////////////////////
async ngAfterViewInit(){
  if(this.data.matTabGroupSelected != undefined){
    if(this.data.matTabGroupSelected > 0){
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.matTabGroupSelected = this.data.matTabGroupSelected
    }
     
  }
}
//////////////////////////////////////////////////
async ngOnInit(): Promise<void> {
    let codProd: any = window.location.pathname;
    var task :any = []
    var  dummy : Event; 
    //codProd = codProd.split('clientes')[1].split('/')[1];
    //this.uuidCliente = codProd;
    this.uuidCliente = this.data.uuidCrmCustomer;
    await this.geDataClient(this.uuidCliente);
    ////////////////////////////
    ////////////////////////////
    task.push(this.geDataInteractionList(this.uuidCliente))
    task.push(this.geDataOrder(this.uuidCliente,this.yearSelected, this.monthSelected))
    task.push(this.getDocumentsByCliente(this.rutEmisor,this.customerData.RUT,this.yearSelected, this.monthSelected))
    task.push(this.getDocumentsReceived(this.rutEmisor,this.customerData.RUT,this.yearSelected, this.monthSelected))
    task.push(this.getRc(this.uuidCliente))
    task.push(this.geDataCtz(this.uuidCliente,this.yearSelected, this.monthSelected))
    ////////////////////////////
        //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Promise.all(task)    
        .then(async result => {
            // ----------------------------------------------------------------
                await this.loadData('notas',this.dataNotas)
                await this.loadData('order',this.dataOrder)
                await this.loadData('direccion',this.dataDireccion)
                await this.loadData('casos',this.dataCasos)
                await this.loadData('dte',this.dataDte)
                await this.loadData('ctz',this.dataCtz)
                await this.loadData('tkt',this.dataTkt)
                await this.loadData('contact',this.dataContact)
                await this.loadData('summary',this.dataSummary)
                await this.loadData('return',this.dataReturn)
                await this.loadData('products',this.dataOrderProducts)
                await this.loadData('dueSummary',this.dataDueSummary)
                await this.loadData('orderDue',this.dataOrderDue)
                await this.loadData('orderToDue',this.dataDueToSummary)
                await this.loadData('receivedDTE',this.dataReceivedDTE)
                await this.loadData('receivedBOL',this.dataReceivedBOL)
                await this.loadData('rc',this.dataRc)
                await this.loadData('orderCtz',this.dataOrderCtz)
                //--
                if( this.tmpSearchDireccion    != undefined  || this.tmpSearchDireccion    != null) this.applyFilter(dummy,'direcciones'  ,this.tmpSearchDireccion)
                if( this.tmpSearchOrderProducts!= undefined  || this.tmpSearchOrderProducts!= null) this.applyFilter(dummy,'products'     ,this.tmpSearchOrderProducts)
                if( this.tmpSearchCasos        != undefined  || this.tmpSearchCasos        != null) this.applyFilter(dummy,'casos'        ,this.tmpSearchCasos)
                if( this.tmpSearchNotas        != undefined  || this.tmpSearchNotas        != null) this.applyFilter(dummy,'notas'        ,this.tmpSearchNotas)
                if( this.tmpSearchOrderCtz     != undefined  || this.tmpSearchOrderCtz     != null) this.applyFilter(dummy,'orderCtz'     ,this.tmpSearchOrderCtz)
                if( this.tmpSearchRc           != undefined  || this.tmpSearchRc           != null) this.applyFilter(dummy,'rc'           ,this.tmpSearchRc)
                if( this.tmpSearchOrder        != undefined  || this.tmpSearchOrder        != null) this.applyFilter(dummy,'order'        ,this.tmpSearchOrder)

                if( this.tmpSearchDte          != undefined  || this.tmpSearchDte          != null) this.applyFilter(dummy,'dte'        ,this.tmpSearchDte)
                //tableOrderDue

            // ----------------------------------------------------------------                                        
        })
        .catch(error => 
            {
            // ----------------------------------------------------------------
               this._toast.error('Error al cargar los datos', '¡Alerta!');
            // ----------------------------------------------------------------                                                                 
            }
        );
        //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++     
    //////////////////////////// 

    ////////////////////////////    
}
//////////////////////////////////////////////////
/*
btnBack(){
    this._router.navigateByUrl('clientes');
  }*/
  closeBtn(){
    // Unsuscribe to all observables
    this._dialogRef.close();
  };
//////////////////////////////////////////////////
async geDataClient(uuid): Promise<void> {
    let tmp = await this._clienteService.getClienteByUUID(uuid,this.yearSelected, this.monthSelected)
    this.customerData   = tmp.customer[0]
    this.dataContact    = tmp.contact
    this.dataDireccion  = tmp.address
    this.dataNotas      = tmp.notes
    this.dataSummary    = tmp.orderSummary
    this.dataReturn    = tmp.orderReturn
    this.dataOrderProducts = tmp.orderProducts
    this.dataDueSummary = tmp.orderDueSummary
    this.dataOrderDue = tmp.orderDue
    this.dataDueToSummary = tmp.orderToDueSummary
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async geDataInteractionList(uuid): Promise<void> {
    let tmp = await this._clienteService.getInteractionList(uuid);
    //let tmp = await this._clienteService.getInteractionList();
    this.dataCasos = tmp.interaction
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
/*
async getTypeInteractions(): Promise<any> {
    const { t1, t2, status } = await this._clienteService.getTypeInteractions();
    this.t1s = t1;
    this.t2s = t2;
    this.statusArray = status;
}*/
async getDocumentsByCliente(rutEmisor,rutReceptor,year, month): Promise<void> {
   //:environment/:rutEmisor/:rutReceptor/:month/:year 
    const { dte } = await this._docsService.listDocsByType(rutEmisor,rutReceptor, month, year, 'DTE');
    const { bol } = await this._docsService.listDocsByType(rutEmisor,rutReceptor, month, year, 'BOL');
    const { tkt } = await this._docsService.listDocsByType(rutEmisor,rutReceptor, month, year, 'TKT');
    const { ctz } = await this._docsService.listDocsByType(rutEmisor,rutReceptor, month, year, 'CTZ');    
    //const { dte } = await this._docsService.listDocsByType(rutEmpresa, month, year, 'DTE');
    //const { bol } = await this._docsService.listDocsByType(rutEmpresa, month, year, 'BOL');
    this.dataDte = [...dte, ...bol];
    this.dataCtz = ctz
    this.dataTkt = tkt
    //console.log(tkt)
    //console.log(ctz)

}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async getDocumentsReceived(rutEmisor,rutReceptor,year, month): Promise<void> {
     let tmp = await this._docsService.getReceivedDocs(rutEmisor,rutReceptor, month, year);
     this.dataReceivedDTE = tmp.dte
     this.dataReceivedBOL = []
 }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async getRc(uuidCrmCustomer): Promise<void> {
  let tmp = await this._rc.getRc(uuidCrmCustomer);
  this.dataRc = tmp.dataSet.rc

}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async geDataOrder(uuid,year, month): Promise<void> {
    //var tmp = await this._orderService.getOrders('0',uuid,year, month);
    var tmp = await this._orderService.getOrders('0',uuid,year, month);
    this.dataOrder = tmp.dataSet.order;
    //console.log(tmp)
  };
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async geDataCtz(uuid,year, month): Promise<void> {
  //var tmp = await this._orderService.getOrders('0',uuid,year, month);
  var tmp = await this._salesPointService.getCtz(uuid,year, month);
  this.dataOrderCtz = tmp.dataSet.ctz;
  //console.log(tmp)
};
//////////////////////////////////////////////////
//////////////////////////////////////////////////
/*
    @ViewChild('pagOrderCtz'    ) pagOrderCtz    : MatPaginator;
    displayedOrderCtz      : string[] = ['FRIENLY_EOM_ORDER','TOTAL','NAME_COD_REASON_CODE', 'CUSTOMER','DATE_CREATION','DATE_UPDATE','EMPLOYEE','UUID_EOM_ORDER']
    isLoadingOrderCtz      : boolean = false;
    dataOrderCtz           : any = [];
    tableOrderCtz          : any = [];

async getData(op): Promise<void> {
var tmp
    if( op == 'casos' ){
        tmp = await this._clienteService.getClientes();
        this.dataCasos = tmp 
    }
    //==
    if( op == 'contact' ){
        tmp = await this._clienteService.getClientes();
        this.dataContact = tmp 
    }
    //==
    if( op == 'direccion' ){
        tmp = await this._clienteService.getClientes();
        this.dataDireccion = tmp 
    }
    //==
    if( op == 'notas' ){
        tmp = await this._clienteService.getClientes();
        this.dataNotas = tmp 
    }
    //==
    if( op == 'rc' ){
        tmp = await this._clienteService.getClientes();
        this.dataOrder = tmp 
    }
    //==
    if( op == 'order' ){
        tmp = await this._clienteService.getClientes();
        this.dataOrder = tmp 
    }
    //==
    if( op == 'dte' ){
        tmp = await this._clienteService.getClientes();
        this.dataDte = tmp 
    }
    //==   
    if( op == 'tkt' ){
        tmp = await this._clienteService.getClientes();
        this.dataDte = tmp 
    }
    //==  
    if( op == 'ctz' ){
        tmp = await this._clienteService.getClientes();
        this.dataDte = tmp 
    }
    //==                           
  };
  */
////////////////////////////////////////////////// 
//////////////////////////////////////////////////
async loadData(op,data):Promise<any>{
    if( op == 'casos' ){
        this.isLoadingCasos=true; 
        this.tableCasos =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableCasos.paginator = this.pagCasos;
        this.isLoadingCasos=false; 
        }
    //==
    if( op == 'contact' ){
        this.isLoadingContact=true; 
        this.tableContact =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableContact.paginator = this.pagContact;
        this.isLoadingContact=false; 
        }
    //==
    if( op == 'direccion' ){
        this.isLoadingDireccion=true; 
        this.tableDireccion =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableDireccion.paginator = this.pagDireccion;
        this.isLoadingDireccion=false; 
        }
    //==
    if( op == 'notas' ){
        this.isLoadingNotas=true; 
        this.tableNotas =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableNotas.paginator = this.pagNotas;
        this.isLoadingNotas=false; 
        }
    //==
    if( op == 'rc' ){
        this.isLoadingRc=true; 
        this.tableRc =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableRc.paginator = this.pagRc;
        this.isLoadingRc=false; 
    }
    //==
    if( op == 'order' ){
        this.isLoadingOrder=true; 
        this.tableOrder =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableOrder.paginator = this.pagOrder;
        this.isLoadingOrder=false; 
        }
    //==
    if( op == 'dte' ){
        this.isLoadingDte=true; 
        this.tableDte =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableDte.paginator = this.pagDte;
        this.isLoadingDte=false; 
        }
    //==     
    if( op == 'ctz' ){
        this.isLoadingCtz=true; 
        this.tableCtz =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableCtz.paginator = this.pagCtz;
        this.isLoadingCtz=false; 
        }
    //==   
    if( op == 'tkt' ){
        this.isLoadingTkt=true; 
        this.tableTkt =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableTkt.paginator = this.pagTkt;
        this.isLoadingTkt=false; 
        }
    //==                
    if( op == 'summary' ){
        this.isLoadingSummary=true; 
        this.tableSummary =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableSummary.paginator = this.pagSummary;
        this.isLoadingSummary=false; 
        }
    //==                
    if( op == 'return' ){
        this.isLoadingReturn=true; 
        this.tableReturn =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableReturn.paginator = this.pagReturn;
        this.isLoadingReturn=false; 
        }     
    //==                
    if( op == 'products' ){
        this.isLoadingOrderProducts=true; 
        this.tableOrderProducts =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableOrderProducts.paginator = this.pagOrderProducts;
        this.isLoadingOrderProducts=false; 
        }     
    //==                
    if( op == 'dueSummary' ){
        this.isLoadingDueSummary=true; 
        this.tableDueSummary =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableDueSummary.paginator = this.pagDueSummary;
        this.isLoadingDueSummary=false; 
        }     
    //==                
    if( op == 'orderDue' ){
        this.isLoadingOrderDue=true; 
        this.tableOrderDue =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableOrderDue.paginator = this.pagOrderDue ;
        this.isLoadingOrderDue=false; 
        }     
    //==                
    if( op == 'orderToDue' ){
        this.isLoadingToDueSummary=true; 
        this.tableToDueSummary =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableToDueSummary.paginator = this.pagToDueSummary;
        this.isLoadingToDueSummary=false; 
        
        }     
    //==   
    //==                
    if( op == 'receivedDTE' ){
        this.isLoadingReceivedDTE=true; 
        this.tableReceivedDTE =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableReceivedDTE.paginator = this.pagReceivedDTE;
        this.isLoadingReceivedDTE=false; 
        }     
    //==                
    if( op == 'receivedBOL' ){
        this.isLoadingReceivedBOL=true; 
        this.tableReceivedBOL =  new MatTableDataSource(data);
        this.ref.detectChanges();
        this.tableReceivedBOL.paginator = this.pagReceivedBOL;
        this.isLoadingReceivedBOL=false; 
        }     
    //==                
    if( op == 'orderCtz' ){
      this.isLoadingOrderCtz=true; 
      this.tableOrderCtz =  new MatTableDataSource(data);
      this.ref.detectChanges();
      this.tableOrderCtz.paginator = this.pagOrderCtz;
      this.isLoadingOrderCtz=false; 
      }     
  //==                   
  }  
//////////////////////////////////////////////////   
//////////////////////////////////////////////////
applyFilter(event: Event,op,txt : string) {
    if( op == 'casos' ){
        if(txt != null  ){
          this.tmpSearchCasos = txt
          this.tableCasos.filter  = txt.trim().toLowerCase();
        this.ref.detectChanges();
        }
        else{
          const filterValue = (event.target as HTMLInputElement).value;
          this.tmpSearchCasos = filterValue
          this.tableCasos.filter  = filterValue.trim().toLowerCase();
          this.ref.detectChanges();
        }
        }
    //==
    if( op == 'contact' ){
        const filterValue = (event.target as HTMLInputElement).value;
        this.tableContact.filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
        }
    //==
    if( op == 'direcciones' ){
          if(txt != null  ){
            this.tmpSearchDireccion = txt
            this.tableDireccion.filter  = txt.trim().toLowerCase();
            this.ref.detectChanges();
          }
          else{
            const filterValue = (event.target as HTMLInputElement).value;
            this.tmpSearchDireccion = filterValue
            this.tableDireccion.filter  = filterValue.trim().toLowerCase();
            this.ref.detectChanges();
          }
    }
    //==
    if( op == 'notas' ){
      if(txt != null  ){
        this.tmpSearchNotas = txt
        this.tableNotas.filter  = txt.trim().toLowerCase();
        this.ref.detectChanges();
      }
      else{
        const filterValue = (event.target as HTMLInputElement).value;
        this.tmpSearchNotas = filterValue
        this.tableNotas.filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
      }      

     }
    //==
    if( op == 'rc' ){
      if(txt != null  ){
        this.tmpSearchRc = txt
        this.tableRc .filter  = txt.trim().toLowerCase();
        this.ref.detectChanges();
      }
      else{
        const filterValue = (event.target as HTMLInputElement).value;
        this.tmpSearchRc = filterValue
        this.tableRc .filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
      }         
    }
    //==
    if( op == 'order' ){
      if(txt != null  ){
        this.tmpSearchOrder = txt
        this.tableOrder.filter    = txt.trim().toLowerCase();
        this.tableOrderDue.filter = txt.trim().toLowerCase();
        this.ref.detectChanges();
      }
      else{
        const filterValue = (event.target as HTMLInputElement).value;
        this.tmpSearchOrder = filterValue
        this.tableOrder.filter    = filterValue.trim().toLowerCase();
        this.tableOrderDue.filter = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
      }       

    }
    //==
    if( op == 'dte' ){

      if(txt != null  ){
        this.tmpSearchDte = txt
        this.tableDte.filter         = txt.trim().toLowerCase();
        this.tableCtz.filter         = txt.trim().toLowerCase();
        this.tableTkt.filter         = txt.trim().toLowerCase();
        this.tableReceivedDTE.filter = txt.trim().toLowerCase();
        this.dataReceivedBOL.filter  = txt.trim().toLowerCase();

        this.ref.detectChanges();
      }
      else{
        const filterValue = (event.target as HTMLInputElement).value;
        this.tmpSearchDte = filterValue
        this.tableDte.filter         = filterValue.trim().toLowerCase();
        this.tableCtz.filter         = filterValue.trim().toLowerCase();
        this.tableTkt.filter         = filterValue.trim().toLowerCase();
        this.tableReceivedDTE.filter = filterValue.trim().toLowerCase();
        this.dataReceivedBOL.filter  = filterValue.trim().toLowerCase();        
        this.ref.detectChanges();
      }         


        }
    //==
    /*
    if( op == 'tkt' ){
        const filterValue = (event.target as HTMLInputElement).value;
        this.tableTkt.filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
        }
    //==
    if( op == 'ctz' ){
        const filterValue = (event.target as HTMLInputElement).value;
        this.tableCtz.filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
        }
    //==
    */
    if( op == 'summary' ){
        const filterValue = (event.target as HTMLInputElement).value;
        this.tableSummary.filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
        }
    //==
    if( op == 'products' ){
        if(txt != null  ){
          this.tmpSearchOrderProducts = txt
          this.tableOrderProducts.filter  = txt.trim().toLowerCase();
          this.ref.detectChanges();
        }
        else{
          const filterValue = (event.target as HTMLInputElement).value;
          this.tmpSearchOrderProducts = filterValue
          this.tableOrderProducts.filter  = filterValue.trim().toLowerCase();
          this.ref.detectChanges();
        }      
    }
    //== 
    //==
    /*
    if( op == 'receivedDTE' ){
        const filterValue = (event.target as HTMLInputElement).value;
        this.tableReceivedDTE.filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
        }
    //==
    if( op == 'receivedBOL' ){
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataReceivedBOL.filter  = filterValue.trim().toLowerCase();
        this.ref.detectChanges();
        } 
    */
    //==
      if( op == 'orderCtz' ){
        if(txt != null  ){
          this.tmpSearchOrderCtz = txt
          this.tableOrderCtz.filter  = txt.trim().toLowerCase();
          this.ref.detectChanges();
        }
        else{
          const filterValue = (event.target as HTMLInputElement).value;
          this.tmpSearchOrderCtz = filterValue
          this.tableOrderCtz.filter  = filterValue.trim().toLowerCase();
          this.ref.detectChanges();
        }          

        }
    //==      
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
refresh(e,o){

}
refreshDTE(e,o){

}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async createOrUpdateComponent(tab: string, type: string, element?: any): Promise<void> {
  var  dummy : Event; 
  console.log( this.customerData)
    const dialogRef = this._dialog.open(DialogCreateUpdateInteractionComponent, {
      width: '100%',
      height: '100%',
        data: {
            customer: this.customerData,
            type: type,
            tab,
            element
        }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
        await this.ngOnInit();
    });
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async deleteMethod(tab: string, o): Promise<void>{
const dialogRef = this._fuseConfirmationService.open({
    title: `Eliminar ${tab}`,
    message: `¿Está seguro que desea eliminar ${tab}?`,
    icon: {
        show: true,
        name: 'delete',
        color: 'warn'
    },
    actions: {
        confirm: {
            label: 'Eliminar',
            color: 'warn'
        },
        cancel: {
            label: 'Cancelar',
        }
    }
});
dialogRef.afterClosed().subscribe(async (result) => {
    if(result === 'confirmed'){
        if(tab == 'nota') { 
                await this._clienteService.deleteNote(this.customerData.UUID_CRM_CUSTOMER, o.UUID_CRM_CUSTOMER_NOTES)
            }
        if(tab == 'direccion'){
              await  this._clienteService.deleteAddress(this.customerData.UUID_CRM_CUSTOMER, o.UUID_CRM_CUSTOMER_ADDRESS);
            }
      await  this.ngOnInit();
    }
});
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
orderDetail(uuidEomOrder: string): void {
  var  dummy : Event; 
    this._orderService.getOrdersDetail(uuidEomOrder).then((res) => {
      // console.log(res);
  
      const dialogRef =  this._dialog.open(DialogDetailComponent, {
        //width: '1200px',
        //height: '600px',
        width: '100%',
        height: '100%',
        data: res.dataSet
      });
      dialogRef.afterClosed().subscribe(async result => {
       // await this.getOrders(this.yearSelected,this.monthSelected);
       //await this.geDataOrder(this.uuidCliente,this.yearSelected, this.monthSelected)
       //await this.geDataClient(this.uuidCliente)
       //this.loadData('order',this.dataOrder)
       //this.loadData('summary',this.dataSummary)
       //this.loadData('return',this.dataReturn)
       //this.loadData('dueSummary',this.dataDueSummary)
       //this.loadData('orderDue',this.dataOrderDue)
       //this.loadData('orderToDue',this.dataDueToSummary)  
       await this.ngOnInit()
       });    
    })
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async returnOrder(UUID_EOM_ORDER : string){ 
  var  dummy : Event; 
    const dialogRef = this._dialog.open(ClaimComponent, {
      width: '100%'
     ,data: {
                UUID_EOM_ORDER: UUID_EOM_ORDER
              ,uuidSpEmployee : null
              ,uuidSp         : null
        }
    });
    dialogRef.afterClosed().subscribe(async result => {
     //await this.getOrders(this.yearSelected,this.monthSelected);
     //await this.geDataOrder(this.uuidCliente,this.yearSelected, this.monthSelected)
     //await this.geDataClient(this.uuidCliente)
     //this.loadData('order',this.dataOrder)
     //this.loadData('summary',this.dataSummary)
     //this.loadData('return',this.dataReturn)
     //this.loadData('dueSummary',this.dataDueSummary)
     //this.loadData('orderDue',this.dataOrderDue)
     //this.loadData('orderToDue',this.dataDueToSummary)  
     await this.ngOnInit()
     
    });
    };
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async create(): Promise<any>{
  var  dummy : Event; 
    const dialogRef = this._dialog.open(DialogCreateOrUpdateCasosComponent, {
      width: '800px',
      data: {
        type: 'create',
        uuidCrmCustomer : this.uuidCliente
      }
    }).afterClosed().subscribe(
      async (res: any) => {
        if (res) {
            await this.geDataInteractionList(this.uuidCliente);
            await this.loadData('casos',this.dataCasos)
            if( this.tmpSearchCasos        != undefined  || this.tmpSearchCasos        != null) this.applyFilter(dummy,'casos'        ,this.tmpSearchCasos)
        }
      }
    );
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
  async update(element: any): Promise<any>{
    var  dummy : Event; 
    const dialogRef = this._dialog.open(DialogCreateOrUpdateCasosComponent, {
      width: '800px',
      data: {
        type: 'update',
        element: element
      }
    }).afterClosed().subscribe(
      async (res: any) => {
        if (res) {
          await this.geDataInteractionList(this.uuidCliente);
          await this.loadData('casos',this.dataCasos)
          if( this.tmpSearchCasos        != undefined  || this.tmpSearchCasos        != null) this.applyFilter(dummy,'casos'        ,this.tmpSearchCasos)
        }
      }
    );
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////  
downloadFile(uuid: string,typeDoc): void{
    let urlPDF = '';


    if(typeDoc === 'DTE'){
      urlPDF = `${environment.endPoints.pdf}${uuid}`;
    }else if(typeDoc === 'BOL'){
      urlPDF = `${environment.endPoints.pdf}${uuid}`;
    }else if(typeDoc === 'TKT'){
      urlPDF = `${environment.endPoints.pdfTkt}${uuid}`;
    }else if(typeDoc === 'CTZ'){
      urlPDF = `${environment.endPoints.pdfCtz}${uuid}`;

    }

    window.open(urlPDF, '_blank');

}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
openPdf(uuid: string,typeDoc): void {

      const dialogRef = this._dialog.open(DialogPdfviewerComponent, {
        width: 'auto',
        data: {
          uuid,
          typeDoc: typeDoc
        }
    });
  }
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
openPdfExt(uuid): void {
    const dialogRef = this._dialog.open(DialogPdfviewerComponent, {
      width: 'auto',
      data: {
        uuid,
        typeDoc: 'DTEEXT'
      }
    });
  }
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
createContact(o){

  const dialogRef = this._dialog.open(DialogCreateUpdateInteractionComponent, {
      
    height: '100%',
    autoFocus: false,
 
    width: '800px',
    data: {
        customer: null,
        type: 'create',
        tab:"contact",
        uuidCustomer : this.customerData.UUID_CRM_CUSTOMER,
        rut          : this.customerData.RUT,
        contact      : this.dataContact

    }
});
dialogRef.afterClosed().subscribe(async (result) => {
    this.ngOnInit();
});

}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
updateContact(o){
  const dialogRef = this._dialog.open(DialogCreateUpdateInteractionComponent, {
      
    height: '100%',
    autoFocus: false, 
 
    width: '800px',
    data: {
        customer: null,
        type: 'edit',
        tab:"contact",
        uuidCustomer : this.customerData.UUID_CRM_CUSTOMER,
        rut          : this.customerData.RUT,
        contactDetail: o

    }
});
dialogRef.afterClosed().subscribe(async (result) => {
    this.ngOnInit();
});
}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
deleteContact(o){
  const dialogRef = this._fuseConfirmationService.open({
    title: `Eliminar Contacto? `,
    message: `Contacto <b>${o.FIRST_NAME} ${o.LAST_NAME}</b>, tipo: <b>${o.GLS_CONTACT_TYPE}</b>`,
    icon: {
        show: true,
        name: 'delete',
        color: 'warn'
    },
    actions: {
        confirm: {
            label: 'Aceptar',
            color: 'warn'
        },
        cancel: {
            show : true,
            label : "Cancel"
          }
    },
    dismissible: true    
});
dialogRef.afterClosed().subscribe(async (result) => {
  //==
  let arr ={
               appToken               : null
              ,language               : 'es'     
              ,uuidCustomer           : this.customerData.UUID_CRM_CUSTOMER
              ,uuidCrmCustomerContact : o.UUID_CRM_CUSTOMER_CONTACT
              ,remove                 : 'Y'
       
          }
  let resp = await this._clienteService.udpCustomerContact(arr);
      if(resp.success == true){
        this.ngOnInit(); 
        }
  //==  
});

}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
async editOrCreateCliente(acc): Promise<void> {
    const dialogRef = this._dialog.open(DialogCreateUpdateInteractionComponent, {
      
        height: '100%',
        autoFocus: false,
     
        width: '800px',
        data: {
            customer: null,
            type: 'edit',
            tab:"customer",
            uuidCustomer : this.customerData.UUID_CRM_CUSTOMER,
            rut          : this.customerData.RUT

        }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
        this.ngOnInit();
    });
}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
async confirmReception(dte: any): Promise<void> {
    if(dte.ORIGEN_DTE_GET !== 'Sii'){
      const dialogRef = this._dialog.open(DialogDeclineOrAceptComponent, {
        width: '60%',
        data: {
          confirm: true,
          declineOrAccept: false
        }
      });
      // this._toast.success('Se ha confirmado la recepción del documento');
      // return;
    }else{
      this._toast.warning('Este documento no se puede confirmar, ya que no cuenta con el XML en su correo');
      return;
    }
  }
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
declineOrAccept(): void{
    const dialogRef = this._dialog.open(DialogDeclineOrAceptComponent, {
      width: '60%',
      data: {
        confirm: false,
        declineOrAccept: true
      }
    });
  }
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
downloadPDFFile(uuid: string): void{

    let urlPDF = '';
      urlPDF = `${environment.endPoints.pdfExt}${uuid}`;
  
    window.open(urlPDF, '_blank');
    // console.log(uuid);
    // console.log(file);
  }
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
  downloadXMLFile(uuid: string): void{
    let urlPDF = '';
    urlPDF = `${environment.endPoints.xmlExt}${uuid}`;
    window.open(urlPDF, '_blank');
  }
  downloadXMLFileEmit(uuid: string): void{
    let urlPDF = '';
  
      urlPDF = `${environment.endPoints.xml}${uuid}`;
  
  
    window.open(urlPDF, '_blank');
    // console.log(uuid);
    // console.log(file);
  }  
//////////////////////////////////////////////////
//////////////////////////////////////////////////
createOOFF(){

  const dialogRef = this._dialog.open(DetailRcComponent, {
    height: '100%',
    autoFocus: false,
    width: '800px',
    data: {
        uuidRc: null,
        type: 'add',
        tab:"rc",
        uuidCustomer : this.customerData.UUID_CRM_CUSTOMER,
        rut          : this.customerData.RUT
    }
  });
  dialogRef.afterClosed().subscribe(async (result) => {
    this.ngOnInit();
  });
}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
viewOOFF(uuidRc,uuidCustomer,rut,name){
  const dialogRef = this._dialog.open(DetailRcComponent, {
    width: '100%',
    height: '100%',
    data: {
        uuidRc: uuidRc,
        type: 'edit',
        tab:"rc",
        uuidCustomer : this.customerData.UUID_CRM_CUSTOMER,
        rut          : this.customerData.RUT,
        name         : name

    }
  });
  dialogRef.afterClosed().subscribe(async (result) => {
    this.ngOnInit();
  });
}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
ctzDetail(uuidEomCtz: string): void {
  const $this = this;
  var  dummy : Event; 
  this._salesPointService.getCtzDetail(uuidEomCtz).then((res) => {
    const dialogRef =  this._dialog.open(CtzDetailComponent, {
      //width: '1200px',
      //height: '600px',
      width: '100%',
      height: '100%',
      data: res.dataSet
    });
    dialogRef.afterClosed().subscribe(async result => {

        await $this.geDataCtz($this.uuidCliente,$this.yearSelected, $this.monthSelected)
        await $this.loadData('orderCtz',$this.dataOrderCtz)
        if( $this.tmpSearchOrderCtz     != undefined  || $this.tmpSearchOrderCtz     != null) $this.applyFilter(dummy,'orderCtz'     ,$this.tmpSearchOrderCtz)
        
       
     });    
  })
}
//////////////////////////////////////////////////
////////////////////////////////////////////////// 
}
