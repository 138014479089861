<div class="flex justify-end">
    <button mat-button (click)="closePage()"> <mat-icon>close</mat-icon></button>
  </div>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
<div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
    <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/2">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." #input>
        <mat-icon matPrefix> find_in_page </mat-icon> 
    </mat-form-field>

</div>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->  
  <div> 
    <div class="w-full">
      <!--[dataSource]="(dataSourceFiltered$ | async) | filter: searchInput"   
    [dataSource]="(dataSource | async) | filter: searchInput"
    -->
      <table mat-table [dataSource]="tableClientes" class="w-full">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- Position Column --> 

        <ng-container matColumnDef="rut">
          <th mat-header-cell *matHeaderCellDef> Rut </th>
          <td mat-cell *matCellDef="let element"><b>{{element.RUT | rut:'DOTS_DASH' }}</b> </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="razon">
          <th mat-header-cell *matHeaderCellDef> Razon Social </th>
          <td mat-cell *matCellDef="let element">  
                                                <div *ngIf="element.RAZON_SOCIAL == element.CUSTOMER_FANCY_NAME" style="color: rgb(9, 9, 132)"><b>{{element.RAZON_SOCIAL  }}        </b> </div>
                                                <div *ngIf="element.RAZON_SOCIAL != element.CUSTOMER_FANCY_NAME" style="color: rgb(9, 9, 132)"><b>{{element.CUSTOMER_FANCY_NAME  }} </b> </div>
          </td>
        </ng-container>    
    
        <ng-container matColumnDef="giro">
          <th mat-header-cell *matHeaderCellDef> Giro </th>
          <td mat-cell *matCellDef="let element"> {{element.GIRO_IMPRESO}} </td>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.EMAIL_DTE}} </td>
        </ng-container>


        <ng-container matColumnDef="acc">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> 
                          <div *ngIf="element.GIRO_IMPRESO != 'null'">
                            <button   (click)="addReceptor(element)" mat-button color="primary" > <mat-icon>playlist_add</mat-icon> </button> 
                          </div>
                          
            </td>
        </ng-container>
     

        <tr mat-header-row *matHeaderRowDef="displayedColumnClientes"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnClientes;"></tr>
      </table>
    </div>
    <mat-spinner *ngIf="isLoadingClientes" style="margin:0 auto"></mat-spinner>
    <mat-paginator
        #pagClientes
        [pageSize]="pag"
        showFirstLastButtons
        [pageSizeOptions]="pageSizeOptions" 
        aria-label="Select page of periodic elements">
    </mat-paginator>    
  </div>
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
<!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->  