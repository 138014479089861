import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import * as rut from '@fdograph/rut-utilities';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ActivatedRoute, Router } from '@angular/router';
import { DocsService } from 'app/services/docs.service';
import { fuseAnimations } from '@fuse/animations';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepicker } from '@angular/material/datepicker';
import { split } from 'lodash';
import { SalesPointService } from 'app/services/salesPoint.service';

@Component({
  selector: 'app-ware-house-product',
  templateUrl: './ware-house-product.component.html',
  styleUrls: ['./ware-house-product.component.scss']
})
export class WareHouseProductComponent implements OnInit {
  storageTotal = [];
  displayedColumns: string[] = ['NAME_WAREHOUSE_TYPE','NAME_WAREHOUSE', 'QUANTITY_AVAILABLE','QUANTITY_BOOKING', 'QUANTITY_HOLD', 'QUANTITY_REMOVE','QUANTITY_SALES','DATE_UPDATE'];

  constructor(
    private _salesPointService:SalesPointService,
    private _fb: FormBuilder,
    private _toast: ToastrService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _docsService: DocsService,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
//###########################################################################
async ngOnInit(): Promise<any>{
    await this.spPsServicesStorageTotal(this.data.COD_PS_SERVICES)
  }
//###########################################################################
async spPsServicesStorageTotal(codPsServices : string): Promise<any>{
  let tmp = await this._salesPointService.spPsServicesStorageTotal(codPsServices);
  this.storageTotal = tmp.storageTotal;
  console.log(this.storageTotal)
}
//###########################################################################

//###########################################################################
}
