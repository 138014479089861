import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { products } from 'app/mock-api/apps/ecommerce/inventory/data';
import { ToastrService } from 'ngx-toastr';
import { psServicesTable } from '../model/psServices';
import { labelPriceTable } from '../model/labelPrice';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  appToken = localStorage.getItem('appToken');;//environment.appTokenUser;
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

  constructor(
    private http: HttpClient,
    private _toast: ToastrService
  ) { } 
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/* ELIMINAR Y CAMBIAR POR  getGroups*/
  // Obtener familias del catalogo
  async getFamilys(all? : string): Promise<any>{
    const $this = this;
    var url;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        if(!all ) {
          url=`${environment.endPoints.groups}${$this.appToken}`
        }
        else{
                url=`${environment.endPoints.groups}${$this.appToken}/${all}`
              }

        
        const res = await $this.http.get(url);
        res.subscribe(
          (data: any) => {
            resolve(data.dataSet);
            // console.log(data)
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
        // resolve(res);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  // Obtener familias del catalogo
  async groupsDetail(idPsCategory : number): Promise<any>{
    const $this = this;
    var url;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        url=`${environment.endPoints.groupsDetail}/${$this.appToken}/${idPsCategory}`
        const res = await $this.http.get(url);
        res.subscribe(
          (data: any) => {
            resolve(data.dataSet);
            // console.log(data)
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
        // resolve(res);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getCategorias(type? : boolean): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    var url;
    return await new Promise (async function(resolve, reject) {
      try {
        if(type == true)
            url = await $this.http.get(`${environment.endPoints.categorias}${$this.appToken}/Y`);
        else
            url = await $this.http.get(`${environment.endPoints.categorias}${$this.appToken}`);
        url.subscribe(
          (data: any) => {
            resolve(data.dataSet);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  // Obtener familias del catalogo
  async getGroups(type? : boolean): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    var url;
    return await new Promise (async function(resolve, reject) {
      try {
        if(type == true)
            url = await $this.http.get(`${environment.endPoints.groups}${$this.appToken}/Y`);
        else
            url = await $this.http.get(`${environment.endPoints.groups}${$this.appToken}`);
        url.subscribe(
          (data: any) => {
            resolve(data.dataSet);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////   
  // Obtener familias del catalogo
  async getGroupsList(): Promise<any>{
    
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    var url;
    return await new Promise (async function(resolve, reject) {
      try {
            url = await $this.http.get(`${environment.endPoints.groupsList}/${$this.appToken}`);
        url.subscribe(
          (data: any) => {
            resolve(data.dataSet);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  } 
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getTypes(type? : boolean): Promise<any>{
    const $this = this;
    var all 
    if(type) all = 'Y'; else all = 'N';
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get(`${environment.endPoints.types}${$this.appToken}/${all}`);
        res.subscribe(
          (data: any) => {
            resolve(data.dataSet);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getUnitMeasurement(type? : boolean): Promise<any>{
  const $this = this;
  var all 
  if(type) all = 'Y'; else all = 'N';
  this.appToken = localStorage.getItem('appToken');
  const year = new Date().getFullYear();
  return await new Promise (async function(resolve, reject) {
    try {
      const res = await $this.http.get<any>(`${environment.endPoints.unitMeasurement}${$this.appToken}/${all}`);
      res.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getCurrency(month: number): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const year = new Date().getFullYear();
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get<any>(`${environment.endPoints.currency}${$this.appToken}/${year}/${month}`);
        res.subscribe(
          (data: any) => {
            resolve(data.dataSet);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getAllProductsByDate(date: Date): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return await new Promise (async function(resolve, reject) {
      try {
        const res = await $this.http.get(`${environment.endPoints.getAllCatalog}${$this.appToken}/ALL/${year}/${month}/${day}`);
        res.subscribe(
          (data: any) => {
            console.log(data.dataSet.data);
            data = data.dataSet;
            data.data.forEach((element: any) => {
              // console.log(element)
              element.J_ANTICIPADO_TAX = JSON.parse(element.J_ANTICIPADO_TAX);
              element.J_RETENIDO_TAX = JSON.parse(element.J_RETENIDO_TAX);
              element.J_TAX = JSON.parse(element.J_TAX);
              element.PRICE = JSON.parse(element.PRICE);
            });
            resolve(data);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async delpsServices (uuidPsServices: string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const res = await $this.http.delete(`${environment.endPoints.delpsServices}/${$this.appToken}/${uuidPsServices}`);
      res.subscribe(
        (data: any) => {
          console  
          resolve(data);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  // Obtener todos productos del catalogo
  async getAllFilteredProducts(): Promise<any>{
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const day = new Date().getDate();
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getFilteredCatalog}${$this.appToken}/ALL/${year}/${month}/${day}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          async (data) => {
            console.log(data);
            data = data.dataSet;
            data.data.forEach((element: any) => {
              // console.log(element)
              element.J_ANTICIPADO_TAX = JSON.parse(element.J_ANTICIPADO_TAX);
              element.J_RETENIDO_TAX = JSON.parse(element.J_RETENIDO_TAX);
              element.J_TAX = JSON.parse(element.J_TAX);
              element.PRICE = JSON.parse(element.PRICE);
            });
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getAllProducts(): Promise<any>{
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const day = new Date().getDate();
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getAllCatalog}${$this.appToken}/ALL/${year}/${month}/${day}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          async (data) => {
            data = data.dataSet;
            
            data.data.forEach((element: any) => {
              element.PRICE = JSON.parse(element.PRICE);
            });
            data.delete.forEach((element: any) => {
              element.PRICE = JSON.parse(element.PRICE);
            });            
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  // Obtener producto por codigo
  async getProductByCode(uuidPsServices: string): Promise<any>{ 
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getFilteredCatalog}${$this.appToken}/${uuidPsServices}`; 
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            data = data.dataSet;
            //
            data.price.forEach(e => {
              e.EMPLOYEE_CREATION = JSON.parse(e.EMPLOYEE_CREATION)
            });    
            //  
            data.eslTag.forEach(e => {
              e.ESL_DATA = JSON.parse(e.ESL_DATA)
            });
            //       
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  // Obtener todos los productos especificando charcode
  async getAllProductsByCharCode(charCode: string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const day = new Date().getDate();
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getCatalogByCharCode}${$this.appToken}/ALL/${charCode}/${year}/${month}/${day}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            data.data.forEach((element: any) => {
              // console.log(element)
              element.J_ANTICIPADO_TAX = JSON.parse(element.J_ANTICIPADO_TAX);
              element.J_RETENIDO_TAX = JSON.parse(element.J_RETENIDO_TAX);
              element.J_TAX = JSON.parse(element.J_TAX);
              element.PRICE = JSON.parse(element.PRICE);
            });
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  //Obtener Costos de un producto
  async getCostsByProduct(uuidPsServices: string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.costs}${$this.appToken}/${uuidPsServices}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            data = data.dataSet;
            data.priceOld.forEach(e => {
                e.EMPLOYEE_CREATION = JSON.parse(e.EMPLOYEE_CREATION)
              });
              resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  //Obtener items de costos
  async getCostItemsByProduct(uuidPsServices: string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getCostItems}${$this.appToken}/${uuidPsServices}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            // data.data.forEach((element: any) => {
            //   // console.log(element)
            //   element.J_ANTICIPADO_TAX = JSON.parse(element.J_ANTICIPADO_TAX);
            //   element.J_RETENIDO_TAX = JSON.parse(element.J_RETENIDO_TAX);
            //   element.J_TAX = JSON.parse(element.J_TAX);
            //   element.PRICE = JSON.parse(element.PRICE);
            // });
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async crearCostos(cost: any): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.costs}`;
        const data = {
          "appToken": $this.appToken,
          "uuidSSO" : null,
          "device"  : null,
          "language":"es",
          'cost': [cost]
        };

        const res = await $this.http.post<any>(url, {
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (subResp: any) => {
            subResp = subResp.dataSet;
            resolve(subResp);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async updateCostos(cost: any): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.costs}`;
        const data = {
          "appToken": $this.appToken,
          "language":"es",
          "uuidSSO" : null,
          "device"  : null,
          'cost': [cost]
        };

        const res = await $this.http.patch<any>(url, {
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (subResp: any) => {
            // console.log(data);
            subResp = subResp.dataSet;
            resolve(subResp);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async deleteCosto(cost: any): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.costs}`;
        const costUpdateForm = {
          "idPsServicesCost": cost.ID_PS_SERVICES_COST,
          "idSupplier": cost.ID_SUPPLIER,
          "idPsServicesCostItem": cost.ID_PS_SERVICES_COST_ITEM,
          "idPsServicesPrice": cost.ID_PS_SERVICES_PRICE,
          "nameServicesCost": cost.NAME_SERVICES_COST,
          "costServicesCost": cost.COST_SERVICES_COST,
          "activeServicesCost": cost.ACTIVE_SERVICES_COST,
          "extraDataServicesCost":{},
          "remove": "Y"
        };
        const data = {
          "appToken": $this.appToken,
          "language":"es",
          'cost': [costUpdateForm]
        };
        const res = await $this.http.patch<any>(url, {
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (subResp: any) => {
            // console.log(data);
            subResp = subResp.dataSet;
            resolve(subResp);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getAllSalesChannels(type? : boolean): Promise<any>{
    const $this = this;
    var all 
    if(type) all = 'Y'; else all = 'N';
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.salesChannel}${$this.appToken}/${all}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async updateCreateDeleteProductos(product: any, create?: boolean): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    let res: any;
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getFilteredCatalog}`;
        const data = {
          "appToken": $this.appToken,
          "language":"es",
          "uuidSSO" : null,
          "device"  : null,
          'psServices': [product]
        };

        if(create){
          res = await $this.http.post<any>(url, {
            'data': JSON.stringify(data)
          });
        }else{
          res = await $this.http.patch<any>(url, {
            'data': JSON.stringify(data)
          });
        }

        res.subscribe(
          (subResp: any) => {
            // console.log(data);
            subResp = subResp.dataSet;
            resolve(subResp);
          },
          (error) => {
            console.log(error.error.dataSet.glsStatus     );
            $this._toast.error(error.error.dataSet.glsStatus,'No Actualizado');
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  //Obtener Impuestos
  async getAllTaxes(): Promise<any> {
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.taxes}${$this.appToken}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            // console.log(data);
            data = data.dataSet;
            resolve(data);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async updatePrice(price: number, uuidPsServices: any): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    const date = new Date();
    const today = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.price}`;
        const data = {
          "appToken": $this.appToken,
          "language":"es",
          "uuidPsServices" : uuidPsServices,
          "psPrice": price,
          "dateStart": today,
          "dateEnd":"9999/12/31",
          "uuidSSO" : null,
          "device"  : null,
          "keepDiscount":"Y"
       };

        const res = await $this.http.post<any>(url, {
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (subResp: any) => {
            // console.log(data);
            subResp = subResp.dataSet;
            resolve(subResp);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getAllChargeCodes(): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.chargeCode}${$this.appToken}`;
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            let x = data.dataSet.data;
            x.forEach((element: any) => {
              // console.log(element);
              element.ATTRIBUTE_CHARGE_CODE = JSON.parse(element.ATTRIBUTE_CHARGE_CODE);
            });
            // console.log(x);
            resolve(x);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async uploadImg(file: File,uuidPsServices : string,extraData: any): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.uploadImg}`;
        const formData = new FormData();
        const data = {
          uuid              : null
         ,appToken          : environment.appTokenImg
         ,language          : null
         ,externalParentUUID  : $this.appToken
         ,externalChildUUID   : uuidPsServices
         ,externalData      : extraData
         ,nameImg           : file.name
         ,dateExpired       : "9999/12/31"
         ,uuidSSO           : null
         ,device            : null         
        };
        formData.append('img', file);
        formData.append('data', JSON.stringify(data));
        // let uuidIMG = '';
        const res = await $this.http.post<any>(url, formData);
        await res.subscribe(
          (resData) => {
            // console.log(data);
            resData = resData.dataSet;
            // uuidIMG = resData.data.uuidImg;
            // console.log(uuidIMG);
            resolve(resData);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async attacheImgToProduct(uuidImg: string, uuidPsServices: any): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.addImgToProduct}`;
        const data = {
          "appToken": $this.appToken,
          "language":"es",
          "uuidPsServices": uuidPsServices,
          "uuidImg": uuidImg,
          "typeImg":"R",
          uuidSSO  : null,
          device   : null            
          
       };
        const res = await $this.http.post<any>(url, {
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (subResp: any) => {
            // console.log(data);
            subResp = subResp.dataSet;
            resolve(subResp);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async updateOrRemoveImgProduct(uuidImg: string, uuidPsServices: string, newPosition?: number, typeImg?: string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.addImgToProduct}`;
        const data = {
          "appToken": $this.appToken,
          "language":"es",
          "uuidPsServices": uuidPsServices,
          "uuidImg": uuidImg,
          "orderImg": newPosition,
          "typeImg":typeImg,
          uuidSSO           : null,
          device            : null            
        };
        // console.log(data);
        const res = await $this.http.patch<any>(url, {
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (subResp: any) => {
            // console.log(data);
            subResp = subResp.dataSet;
            resolve(subResp);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async deattachImgToProduct(uuidImg, uuidPsServices): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.addImgToProduct}`;
        const data = {
          "appToken": $this.appToken,
          "language":"es",
          "uuidPsServices": uuidPsServices,
          "uuidImg": uuidImg,
          "orderImg": 1,
          "typeImg":"R",
          "remove": "Y",
          uidSSO  : null ,
          device  : null            
        };
        const res = await $this.http.patch<any>(url, {
          'data': JSON.stringify(data)
        });
        res.subscribe(
          (subResp: any) => {
            // console.log(data);
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
             $this.removeImg(uuidImg)
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            subResp = subResp.dataSet;
            resolve(subResp);
          });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async removeImg(uuid): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.uploadImg}/${environment.appTokenImg}/${uuid}`;
      const res = await $this.http.delete<any>(url);
      await res.subscribe(
        (resData) => {
          // console.log(data);
          resData = resData.dataSet;
          // uuidIMG = resData.data.uuidImg;
          // console.log(uuidIMG);
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
////////////////////////////////////////////////////////////////// 
//////////////////////////////////////////////////////////////////
async postPsServicesLabelPrice(label): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psServicesLabelPrice}`
      label.appToken = $this.appToken
      label.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(label)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Etiquetas Creadas.Se inicia descarga del archivo pdf, verifique carpeta descargas', 'Éxito');
          resolve(res);
        },
        (error) => {
          //this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Etiquetas', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async postPsServicesCombination(ps): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psCombination}`
      ps.appToken = $this.appToken
      ps.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(ps)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Combinacion', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async postPsServicesCombinationDetail(ps): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psCombinationDetail}`
      ps.appToken = $this.appToken
      ps.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(ps)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          //this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Combinacion', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async postPsServicesCombinationProperty(ps): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psCombinationProperty}`
      ps.appToken = $this.appToken
      ps.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(ps)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Propiedades', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async postPsServicesCombinationPropertyValue(ps): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psCombinationPropertyValue}`
      ps.appToken = $this.appToken
      ps.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(ps)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getPsServicesCombination(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psCombination}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
         
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet.psCombination;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getPsServicesCombinationDetail(uuidPsCombination: string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psCombinationDetail}/${$this.appToken}/${uuidPsCombination}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
   
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getPsServicesCombinationProperty(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psCombinationProperty}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async psCombinationPs(uuidPsCombination,codPsGroup,idPsType): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken'); 
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psCombinationPs}/${$this.appToken}/${uuidPsCombination}/${codPsGroup}/${idPsType}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
          resData.dataSet.ps.forEach(element => {
            element.PRICE   = JSON.parse(element.PRICE);
          });            
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet.ps;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getPsServicesCombinationPropertyValue(uuidPsServicesProperty): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psCombinationPropertyValue}/${$this.appToken}/${uuidPsServicesProperty}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getPsServicesCombinationPropertyValueAll(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psCombinationPropertyValue}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async postCategory(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.categorias}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async updateOrDeleteCategory(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.categorias}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.patch(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async postGroup(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.groups}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async updateOrDeleteGroup(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.groups}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.patch(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getEpcEsl(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.getEpcEsl}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
          resData.dataSet.eslTagUsed.forEach(element => {
            element.ESL_DATA = JSON.parse(element.ESL_DATA);
          });       
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getEpcEslDetail(uuidPsServicesEslTag): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.getEpcEslDetail}/${$this.appToken}/${uuidPsServicesEslTag}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
          resData.dataSet.tagQueue.forEach(element => {
            element.MESSAGE = JSON.parse(element.MESSAGE);
          });       
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async epcSetTag(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.epcSetTag}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async epcDelTag (uuidPsServicesEslTag: string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const res = await $this.http.delete(`${environment.endPoints.epcDelTag}/${$this.appToken}/${uuidPsServicesEslTag}`);
      res.subscribe(
        (data: any) => {
          console  
          resolve(data);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getEpcEslData(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.getEpcEslData}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
        //  resData.dataSet.tagQueue.forEach(element => {
        //   element.MESSAGE = JSON.parse(element.MESSAGE);
        //  });       
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getEpcEslpsServices(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.getEpcEslpsServices}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
        //  resData.dataSet.tagQueue.forEach(element => {
        //   element.MESSAGE = JSON.parse(element.MESSAGE);
        //  });       
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getBrands(type? : boolean): Promise<any>{
  const $this = this;
  var all 
  if(type) all = 'Y'; else all = 'N';
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psbrands}${$this.appToken}/${all}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          // console.log(data);
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setBrands(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psbrands}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async udpBrands(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psbrands}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.patch(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          $this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          $this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async delBrands(uuidPsBrands : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psbrands}${$this.appToken}/${uuidPsBrands}`;
      const res = await $this.http.delete<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          $this._toast.success(data.glsStatus, 'Éxito');
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async psbrand(uuidPsBrands : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psbrand}${$this.appToken}/${uuidPsBrands}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getpsFlexibleAttributesType(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.getpsFlexibleAttributesType}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getpsFlexibleAttributesCategory(idPsCategory : number): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.getpsFlexibleAttributesCategory}/${$this.appToken}/${idPsCategory}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          ////////////////////////////////////////////////////////////////////
          data.data.forEach(element => {
            element.LIST_VALUES_PARAM   = JSON.parse(element.LIST_VALUES_PARAM);
          });            
          ///////////////////////////////////////////////////////////////////          
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async postpsFlexibleAttributesCategory(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.postpsFlexibleAttributesCategory}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async customerValueAll(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.customerValueAll}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async interactionT1(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionT1}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          ////////////////////////////////////////////////////////////////////
          data.t1.forEach(e => {
            e.EMPLOYEE_CREATION = JSON.parse(e.EMPLOYEE_CREATE)
            e.EMPLOYEE_UPDATE = JSON.parse(e.EMPLOYEE_UPDATE)
          });            
          ///////////////////////////////////////////////////////////////////             
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async interactionT2(uuidCmInteractionT1 : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionT2}/${$this.appToken}/${uuidCmInteractionT1}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          ////////////////////////////////////////////////////////////////////
          data.t2.forEach(e => {
            e.EMPLOYEE_CREATE = JSON.parse(e.EMPLOYEE_CREATE)
            e.EMPLOYEE_UPDATE = JSON.parse(e.EMPLOYEE_UPDATE)
          });            
          ///////////////////////////////////////////////////////////////////      
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async interactionRule(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionRule}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async interactionQueue(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionQueue}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async newcustomerValueAll(json): Promise<any>{

  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.customerValueAll}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async udpcustomerValueAll(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.customerValueAll}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.patch(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async newInteractionT1(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.interactionT1}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async udpInteractionT1(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.interactionT1}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.patch(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async interactionType(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionType}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async newInteractionT2(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.interactionT2}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async udpInteractionT2(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.interactionT2}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.patch(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getInteractionFlexibleAttributes(uuidCmInteractionT2 : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionFlexibleAttributes}/${$this.appToken}/${uuidCmInteractionT2}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          ////////////////////////////////////////////////////////////////////           
          ///////////////////////////////////////////////////////////////////      
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setInteractionFlexibleAttributes(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.interactionFlexibleAttributes}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async interactionAnswerT2(uuidCmInteractionT2 : string): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionAnswerT2}/${$this.appToken}/${uuidCmInteractionT2}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          ////////////////////////////////////////////////////////////////////           
          ///////////////////////////////////////////////////////////////////      
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setInteractionQueue(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.setInteractionQueue}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setInteractionQueueEmployee(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.setInteractionQueueEmployee}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getInteractionQueueEmployee(idCmInteractionQueue : number): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.interactionQueueEmployee}/${$this.appToken}/${idCmInteractionQueue}`;
      const res = await $this.http.get<any>(url);
      res.subscribe(
        (data) => {
          data = data.dataSet;
          ////////////////////////////////////////////////////////////////////           
          ///////////////////////////////////////////////////////////////////      
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setInteractionRule(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.setInteractionRule}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Valores', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
public getpsServicesPagination(
  codCategory        : string
 ,codGroup           : string 
 ,remove             : string
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
 ,uuidBrands         : string
): Observable<psServicesTable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + codCategory
 + '/' + codGroup	
 + '/' + remove
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy
 + '/' + uuidBrands;
url = `${environment.endPoints.getAllCatalogPagination}/${url}`;

 return this.http.get<psServicesTable>(url);
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  // Obtener producto por codigo
  async getProductByBarcode(barcode: string): Promise<any>{ 
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.getPsBarcode}/${$this.appToken}/${barcode}`; 
        const res = await $this.http.get<any>(url);
        res.subscribe(
          (data) => {
            //       
            resolve(data.dataSet.barcodePsServices);
          },
          (error) => {
            console.log(error);
            resolve('-1');
          }
        );
      }
      catch (err) {
        console.log(err);
        resolve('-1');
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
public getPsServicesLabelPricePagination(
  codCategory        : string  
 ,codGroup           : string  

 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<labelPriceTable> {
 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + codCategory  
 + '/' + codGroup    
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.psServicesLabelPricePagination}/${url}`;

 return this.http.get<labelPriceTable>(url);
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getPsServicesLabelPriceTypeList(): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.psServicesLabelPriceTypeList}/${$this.appToken}`;
      const res = await $this.http.get<any>(url);
      await res.subscribe(
        (resData) => {
          ////////////////////////////////////////////////////////////////////
          resData.dataSet.labelPrice.forEach(element => {
            element.CONF_LABEL_PRICE       = JSON.parse(element.CONF_LABEL_PRICE);
          });         
          ///////////////////////////////////////////////////////////////////
          resData = resData.dataSet;
          resolve(resData);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setPsServicesExternal(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psServicesExternal}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Links', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setPsReview(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psReview}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Links', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
  async getPsServicesTypeBarcode(): Promise<any>{
    
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    var url;
    return await new Promise (async function(resolve, reject) {
      try {
            url = await $this.http.get(`${environment.endPoints.getPsServicesTypeBarcode}/${$this.appToken}`);
        url.subscribe(
          (data: any) => {
            resolve(data.dataSet);
          },
          (err) => {
            console.log(err);
            reject(err);
          }
        );
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getPsServicesDiscount(uuidPsServices : string): Promise<any>{
    
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  var url;
  return await new Promise (async function(resolve, reject) {
    try {
          url = await $this.http.get(`${environment.endPoints.psServicesDiscount}/${$this.appToken}/${uuidPsServices}`);
      url.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setPsServicesDiscount(json): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.psServicesDiscount}`
      json.appToken = $this.appToken
      json.language = "es"
      
      const data = await $this.http.post(url,{
                                                'data': JSON.stringify(json)
                                              })
      data.subscribe(
        (res: any) => {
          this._toast.success(res.dataSet.glsStatus, 'Éxito');
          resolve(res);
        },
        (error) => {
          console.log(error.error.dataSet.glsStatus)
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Links', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getpsDiscountFullfillment(): Promise<any>{
    
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  var url;
  return await new Promise (async function(resolve, reject) {
    try {
          url = await $this.http.get(`${environment.endPoints.psDiscountFullfillment}/${$this.appToken}`);
      url.subscribe(
        (data: any) => {
          resolve(data.dataSet);
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
};
