import { ChangeDetectionStrategy, Component, HostListener, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { map, Observable, of, startWith } from 'rxjs';
//
//import {ReceptoresComponent} from '../../home/receptores/receptores.component'
import { SalesPointService } from 'app/services/salesPoint.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { EmisorService } from 'app/services/emisor.service';
import * as rut from '@fdograph/rut-utilities';

import { HttpClient } from '@angular/common/http';
import { OrderService } from 'app/services/orders.service';
import { CurrencyPipe } from '@angular/common';
//===================================================================
import * as qz from 'qz-tray';
import { sha256 } from 'js-sha256';
import { round } from 'lodash';
//===================================================================
import { FunctionsService }  from 'app/services/functions.service';
//===================================================================
import { DOCUMENT } from '@angular/common'; 
/*
Si la orden ya tiene una rebaja parcial NO de puede anular
Bloquear los produtos ya rebajados

*/
import { SelectClientesComponent } from '../../clientes/select-clientes/select-clientes.component'
import { DialogCreateUpdateInteractionComponent } from '../../clientes/detail-client/dialog-create-update-interaction/dialog-create-update-interaction.component';


//===================================================================
@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {

  appTokenImg = environment.appTokenImg;
  //auth     :any = {}

  /////////////////////////////////////////////////////
  qzDataA: any = {}
  qzDataB : any = {}
  /////////////////////////////////////////////////////
  ivaF       :number = 0
  ivaP       :number = 0
  /////////////////////////////////////////////////////
  totales :any={

    MntNeto       : 0     
   ,MntExe        : 0   
   ,IVA           : 0 
   ,MntTotal      : 0     
   ,MntoNF        : 0   
   ,descTotal     : 0
   ,descPror      : 0

}
//
  uuidEomOrder : string = ''
  orderDetail  :any = {
     order                : {}
    ,orderDetail          : []
    ,orderDiscountCoupon  : []
    ,orderMessage         : []
    ,orderPayment         : []
    ,orderReturn          : []
    ,orderReturnDetail    : []
    ,orderShiping         : []
    ,orderShipingBundle   : []
    ,orderHistory         : []


  } 
//
flagAnula = true
ctrlAnula = true
flagActiva = false;
//
reasonReturn   : any = [];
reasonWareHouse: any = [];
reasonReturnDefault :any = {COD_REASON_CODE:'RS',DESC_COD_REASON_CODE:'Listo para Vender'}
reasonWareHouseSelected:any = {}
reasonReturnSelected =null;// = {COD_REASON_CODE:'SG',DESC_COD_REASON_CODE:'Satisfaccion Garantizada'}
typeReturn : any = 'C';
//
  products: any[] = [];
  /*
  userData =  {
    login      : ''
   ,firstName  : ''
   ,lastName   : ''
   ,rutEmpresa : ''
   ,logoEmpresa: ''
   ,pin        : ''
   ,envirometDTE : ''
   ,envirometBOL : ''
   ,envirometTKT : ''      
   }*/
    //
    summary :any={ quantity:0,products:0}
    mnt     :any={MntDsc:0,MntRcg:0}
    //
    spListEmployee : any = [];
    uuidSpEmployee : string = null;
    spDetail        : any = {};
    spPayMeans      : any = [];
    spWarehouse     : any = [];
    //
    defaultPayMeans    : any = {};
    defaultWarehouse   : any = {};  
    selectedPayMeans   : any = {};
    selectedWarehouse  : any = {};        
    //
    receptor = {
      RUT          : ''//'66666666-6'
     ,RAZON_SOCIAL : ''//'CLIENTE GENERICO'
     }
    //
    flagReceptor = true;
    //
    status           :boolean  = true; // gloval error
    errorSalesPoint  : any = []; //{codErr:0,glsErr:'todo mal'}
    //
    flagProcess : boolean = false   
    //
    TcCLP : number =0;
    glsCurrency : string ='DOLAR USA'
  //////////////////////////////////////
///////////////////////////////////////////////////////////////////
  constructor(
    private _dialog: MatDialog,
    private _toastr: ToastrService,
    private _salesPointService : SalesPointService,
    private _emisorService: EmisorService,
    private _orderService : OrderService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _functions : FunctionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClaimComponent>,
    @Inject(DOCUMENT) document: Document,
    private currencyPipe:CurrencyPipe,
    private _toast: ToastrService

  ) { 
    var tmp,tmp2,tmp3;
    
    tmp =  (JSON.parse(localStorage.getItem('ssoData')))
    tmp2 = (JSON.parse(localStorage.getItem('emisorData')))
    tmp3 =tmp2.emisorData[0].IVA
    this.ivaF       = tmp3/100
    this.ivaP       = tmp3

  }
//########################################################################################################################
//########################################################################################################################
async ngOnInit(): Promise<any>{
       /////////////////////////////////////////////
       this.uuidEomOrder = this.data.UUID_EOM_ORDER;
       await this.getReasonCode();
       await this.getOrderDetail();
       this.launchQZ();
       /////////////////////////////////////////////
       //var tmp,tmp2;
       //tmp =  (JSON.parse(localStorage.getItem('ssoData')))
       //tmp2 = (JSON.parse(localStorage.getItem('emisorData')))
       ////////////////////////////          
        //$$$$$$$$$$$$$$$$$$$$$$$$$
       ///////////////////////////
       /*
       const { logo } = await this._emisorService.getLogo()
       this.userData = {
            login      : tmp.user.login
           ,firstName  : tmp.user.firstName
           ,lastName   : tmp.user.lastName
           ,pin        : tmp2.usrData[0].PIN
           ,rutEmpresa : localStorage.getItem('rutEmpresa')
           ,logoEmpresa: logo
           ,envirometDTE : tmp2.emisorData[0].COD_ENVIROMENT_DTE
           ,envirometBOL : tmp2.emisorData[0].COD_ENVIROMENT_BOL
           ,envirometTKT : tmp2.emisorData[0].COD_ENVIROMENT_TKT           
       }*/
       /////////////////////////////////////////////
       await this.getSpListEmployee();
       /////////////////////////////////////////////  
       /////////////////////////////////////////////   
  }
//############################################################
//############################################################
async setSpWarehouse(arr): Promise<any>{
  this.selectedWarehouse = arr;
}
//############################################################
//############################################################
async getSpListEmployee(): Promise<any>{
  var tmp2;
  let tmp = await this._salesPointService.getSpListEmployee();
  this.spListEmployee = tmp.dataSet.sp;
  
  if(tmp.dataSet.sp.length == 1){
      this.uuidSpEmployee = tmp.dataSet.sp[0].UUID_SP;
      await this.spEployee(); 
  }
  else{
      tmp2=this.spListEmployee.filter(item => item.FLAG_DEFAULT == 'Y')
      if(tmp2.length > 0 ){
          this.uuidSpEmployee = tmp2[0].UUID_SP;
          await this.spEployee();
      }
      else{
          this.uuidSpEmployee =  tmp.dataSet.sp[0].UUID_SP;
          await this.spEployee();
      }
  }
}
//############################################################
//############################################################
async setSpEmployee(uuidSP): Promise<any>{
  this.uuidSpEmployee = uuidSP;
  await this.spEployee();
}
//############################################################
//############################################################
async spEployee(): Promise<any>{
  let tmp = await this._salesPointService.spEployee(this.uuidSpEmployee);
  var def;
 this.spDetail            = tmp.sp
 this.spPayMeans          = tmp.pmPaysMeans 
 this.spWarehouse         = tmp.wmWarehouse.filter(item => item.COD_WAREHOUSE_TYPE == 'W')
 this.typeReturn          = tmp.sp.TYPE_RETURN
 //
 if(tmp.pmPaysMeans.length > 0 && tmp.wmWarehouse.length > 0){
      def = tmp.wmWarehouse.filter(item => item.FLAG_DEFAULT_WAREHOUSE == 'Y')
      if(def.length >= 1 ){
          this.defaultWarehouse  = def[0]
          this.selectedWarehouse = def[0]
      }
      else{
          this.defaultWarehouse = tmp.wmWarehouse[0]
          this.selectedWarehouse= tmp.wmWarehouse[0]
      }   
  }
  else{
      this.status=false;
      if(tmp.pmPaysMeans.length > 0) this.errorSalesPoint.push({codErr:'PM',glsErr:'Sin medios de Pago'})
      if(tmp.wmWarehouse.length > 0) this.errorSalesPoint.push({codErr:'WM',glsErr:'Sin Bodegas'})
      if(!tmp.sp) this.errorSalesPoint.push({codErr:'SP',glsErr:'Punto de Venta no disponible'})
  } 
  //////////////////////////
  await this.initQZ()    
  //////////////////////////
}
//############################################################
//############################################################
showSupplier(){
  const dialogRef = this._dialog.open(SelectClientesComponent, {
      
    height: '100%',
    width: '100%',
    data: {
       flagCustomer     : false
      ,flagSupplier     : false
      ,flagEmployee     : false
      ,flagAssociate    : false
      ,flagDistributor  : false
      ,flagDTE          : true

    }
});
dialogRef.afterClosed().subscribe(async (result) => {
  if(!!result){
    this.receptor.RUT          =  result.RUT
    this.receptor.RAZON_SOCIAL =  result.RAZON_SOCIAL
  }
});
}

async editOrCreateCliente(acc): Promise<void> {
  const dialogRef = this._dialog.open(DialogCreateUpdateInteractionComponent, {
    
      height: '100%',
      autoFocus: false,
   
      width: '800px',
      data: {
          customer: null,
          type: 'create',
          tab:"customer",
          source:"claim"

      }
  });
  dialogRef.afterClosed().subscribe(async (result) => {
    // 24/02this.ngOnInit();
    if(!!result){
      this.receptor.RUT          =  result.RUT
      this.receptor.RAZON_SOCIAL =  result.RAZON_SOCIAL
    }
    else{
      this.ngOnInit();
    }
});
}
/*
openSearchReceptor(): void {

  const dialogRef = this._dialog.open(ReceptoresComponent, {
      width: '100%'
     ,data: {
           uuidSp: this.uuidSpEmployee
          ,uuidWh: this.selectedWarehouse.UUID_WAREHOUSE
          ,date  : 'now'
          ,souce : 'claim'
        }
  });
  dialogRef.afterClosed().subscribe(result => {
      if(result){

              this.receptor.RUT          =  result.RUT
              this.receptor.RAZON_SOCIAL =  result.RAZON_SOCIAL
              }
  });
};
*/
//############################################################
//############################################################
defaultReceptor(): void {
  this.receptor = {
                       RUT          : '66666666-6'
                      ,RAZON_SOCIAL : 'Cliente Generico'
                  }
}
//############################################################
//############################################################  
async getOrderDetail(): Promise<void> {
  var tmp= await this._orderService.getOrdersDetail(this.uuidEomOrder);
  this.orderDetail = tmp.dataSet
  //////////////////////////////
  if(this.orderDetail.order.COD_DOC == 110){
      var json= await this._salesPointService.getJson(this.orderDetail.order.UUID_DTE);
      this.TcCLP       = json.dataSet.Encabezado.OtraMoneda.TpoCambio
      this.glsCurrency = json.dataSet.Encabezado.Totales.TpoMoneda
  }
  //////////////////////////////
  if(this.orderDetail.order.RUT == '66666666-6' || this.orderDetail.order.RUT == '55555555-5' || this.orderDetail.order.RUT == '11111111-1'){
    this.flagReceptor = true
    this.receptor = {
                      RUT          : ''//this.orderDetail.order.RUT
                     ,RAZON_SOCIAL : ''//this.orderDetail.order.RAZON_SOCIAL
                    }    
  }
  else{
    this.flagReceptor = false
    this.receptor = {
                      RUT          : this.orderDetail.order.RUT
                     ,RAZON_SOCIAL : this.orderDetail.order.RAZON_SOCIAL
                    }
  }
  //////////////////////////////
  this.flagActiva = this.orderDetail.order.FLAG_RETURN
  if(this.flagActiva == false) this.flagAnula = false;
  //
  this.udpTotal()
  //this.total();
  //////////////////////////////
};
//############################################################
//############################################################  
async getReasonCode(): Promise<void> {
  //////////////////////////////
  var tmp= await this._salesPointService.getReasonCode();
  this.reasonReturn    = tmp.data.filter(item => item.ID_DOC_TYPE == 19)
  this.reasonWareHouse = tmp.data.filter(item => item.ID_DOC_TYPE == 20)
  //////////////////////////////
};
//############################################################
//############################################################ 
async close(): Promise<void>{
  var tmp= await this._orderService.getOrdersDetail(this.uuidEomOrder);
  this.orderDetail = tmp.dataSet
  this.dialogRef.close(this.orderDetail);
}
//############################################################
//############################################################ 
async restore(): Promise<void> {
if(this.flagAnula == true)  {
  for(let i=0;i<this.orderDetail.orderDetail.length;i++ ){
    ////////////////////////////
      this.orderDetail.orderDetail[i].TOTAL                    = this.orderDetail.orderDetail[i].TOTAL_ORI 
      this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW = this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES
      this.orderDetail.orderDetail[i].COD_REASON_CODE          = "RS"
    ////////////////////////////
  }
  this.total();
}
}
//############################################################
//############################################################
setReasonWareHouse(product,reason){
  for(let i=0;i<this.orderDetail.orderDetail.length;i++ ){
    ////////////////////////////
    if(this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL == product.UUID_EOM_ORDER_DETAIL){
      this.orderDetail.orderDetail[i].COD_REASON_CODE          = reason
    }
    ////////////////////////////
  }
}
//############################################################
//############################################################
udpTotal(){
  for(let i=0;i<this.orderDetail.orderDetail.length;i++ ){
    ////////////////////////////
      this.orderDetail.orderDetail[i].TOTAL = round((this.orderDetail.orderDetail[i].TOTAL_ORI / this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES) * this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_RETURN_AVAILABLE,0)
      this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW=this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_RETURN_AVAILABLE
    ////////////////////////////
  }
  this.total();
}
//############################################################
//############################################################
subTotal(product,quantity){
  for(let i=0;i<this.orderDetail.orderDetail.length;i++ ){
    ////////////////////////////
    if(this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL == product.UUID_EOM_ORDER_DETAIL){
      this.orderDetail.orderDetail[i].TOTAL = round((this.orderDetail.orderDetail[i].TOTAL_ORI / this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES) * quantity ,0)
      this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW=quantity*1
    }
    ////////////////////////////
  }
  this.total();
}
//############################################################
//############################################################ 
total(){ // cosiderar descuento global y prorrotear 
  var countDesc  = 0;
  var countTotal = 0;
  var countProducts = 0;

  this.totales.MntNeto   =  0;
  this.totales.MntExe    =  0;
  this.totales.MntTotal  =  0;  
  this.totales.IVA       =  0;
  this.totales.MntoNF    =  0;
  this.totales.descTotal =  0;
  this.totales.descPror  =  0;
  
  for(let i=0;i<this.orderDetail.orderDetail.length;i++ ){
    ////////////////////////////
        if(this.orderDetail.orderDetail[i].TOTAL   > 0 ) { 
          countDesc=countDesc+this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW*1
          countProducts = countProducts+1;
        }
        countTotal = countTotal+this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES*1
        //
        if(this.orderDetail.order.COD_DOC == 33 ){
          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
           this.totales.MntNeto   +=  this.orderDetail.orderDetail[i].TOTAL    
           this.totales.MntExe    +=  this.orderDetail.orderDetail[i].EXENTO      
           this.totales.MntTotal  +=  round(this.orderDetail.orderDetail[i].TOTAL * (this.ivaF+1),0)  
         //  this.totales.IVA       +=  this.totales.MntTotal  -    this.totales.MntNeto  
           this.totales.MntoNF    +=  0  
           
        }
          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                    
        else if(this.orderDetail.order.COD_DOC == 39 ) {
          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
          this.totales.MntNeto   +=  round(this.orderDetail.orderDetail[i].TOTAL / (this.ivaF+1),0)    
          this.totales.MntExe    +=  this.orderDetail.orderDetail[i].EXENTO        
          this.totales.MntTotal  +=  this.orderDetail.orderDetail[i].TOTAL    
         // this.totales.IVA       +=  this.totales.MntTotal  -    this.totales.MntNeto      
          this.totales.MntoNF    +=  0  
          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                   
        }
          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                    
          else if(this.orderDetail.order.COD_DOC == 110 || this.orderDetail.order.COD_DOC == 34 || this.orderDetail.order.COD_DOC == 41) {
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
              this.totales.MntNeto   +=  0
              this.totales.MntExe    +=  this.orderDetail.orderDetail[i].TOTAL        
              this.totales.MntTotal  +=  this.orderDetail.orderDetail[i].TOTAL    
              this.totales.IVA       +=  0
              this.totales.MntoNF    +=  0  
            //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                   
          }        
        else{
          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            this.totales.MntTotal  +=  this.orderDetail.orderDetail[i].TOTAL    
            //this.totales.MntNeto   +=  round(this.orderDetail.orderDetail[i].TOTAL / (this.ivaF+1),0)        
            this.totales.MntoNF    +=  0  
          //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                    
        }
    ////////////////////////////
  }


  //
  if(this.orderDetail.order.COD_DOC == 5001){
    this.ctrlAnula = true
    this.summary.products = countProducts
    this.summary.quantity = countDesc
    this.totales.descTotal = this.orderDetail.order.TOTAL_DESCUENTO   //this.orderDetail.order.TOTAL_DESCUENTO * 1
    this.totales.descPror = (this.totales.descTotal /  countTotal)*countDesc
    this.totales.IVA      = 0
    this.totales.MntNeto  = 0
  }
  else if(this.orderDetail.order.COD_DOC == 39 ) {
      this.ctrlAnula = false
      this.summary.products = countProducts
      this.summary.quantity = countDesc
      this.totales.descTotal = this.orderDetail.order.TOTAL_DESCUENTO//round(this.orderDetail.order.TOTAL_DESCUENTO / (this.ivaF+1),0)    //this.orderDetail.order.TOTAL_DESCUENTO * 1
      this.totales.descPror = (this.totales.descTotal /  countTotal)*countDesc
      this.totales.MntNeto  = this.totales.MntNeto - this.totales.descPror
      this.totales.IVA      =  round(((this.totales.MntNeto   )* this.ivaF)+0.15,0)    
      this.totales.MntTotal =  this.totales.IVA + this.totales.MntNeto   
  }
  else if(this.orderDetail.order.COD_DOC == 110 || this.orderDetail.order.COD_DOC == 34 || this.orderDetail.order.COD_DOC == 41) {
    this.ctrlAnula = true
    this.summary.products = countProducts
    this.summary.quantity = countDesc
    this.totales.descTotal = this.orderDetail.order.TOTAL_DESCUENTO     //this.orderDetail.order.TOTAL_DESCUENTO * 1
    this.totales.descPror = (this.totales.descTotal /  countTotal)*countDesc
    this.totales.MntNeto  = 0
    this.totales.MntExe   = this.totales.MntExe - this.totales.descPror
    this.totales.IVA      =  0    
    this.totales.MntTotal =  this.totales.MntExe
}  
  else{
    this.ctrlAnula = false
    this.summary.products = countProducts
    this.summary.quantity = countDesc
    this.totales.descTotal = this.orderDetail.order.TOTAL_DESCUENTO     //this.orderDetail.order.TOTAL_DESCUENTO * 1
    this.totales.descPror = (this.totales.descTotal /  countTotal)*countDesc
    this.totales.MntNeto  = this.totales.MntNeto - this.totales.descPror
    this.totales.IVA      =  round(((this.totales.MntNeto)* this.ivaF)+0.15,0)    
    this.totales.MntTotal =  this.totales.IVA + this.totales.MntNeto   
    
  }
  /////////////
  
  //if(countProducts > 1 || countDesc > 1){
    
  //}
  /////////////
}
//############################################################
//############################################################ 
async calcularTKT(): Promise<void> {
  console.log("Anula TKT")
  // validar formulario antes de emitir
  let $this = this;
  var pdf,url
  var title,dte,nc,jsonReturn,orderReturnPlace,orderReturnClose,dataFile;
  title='Anula Venta';
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
const dialogRef = this._fuseConfirmationService.open({
  title: title,
  message: `Debe ingresar los productos a Bodega. <br><b>Esta operarion no es reversible.</b> <br>¿Desea continuar?`,
  icon: {
      show: true,
      name: 'assignment_return',
      color: 'primary'
  },
  actions: {
      confirm: {
          label: 'Aceptar',
          color: 'primary'
      },
  }
});
dialogRef.afterClosed().subscribe(async (result) => {
    if(this.reasonReturnSelected == null){
      
    }
    //
    if(result == 'confirmed' && this.reasonReturnSelected){
      //################################
        try{
            // preparar json cuando es parcial ojo que tengo que actualizar las cantidades y eliminar los productos ya anulados
            jsonReturn = await this.prepararOrderReturnPlaceTKT({})
            orderReturnPlace = await this._salesPointService.orderReturnPlace(jsonReturn.jsonReturn);
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                    orderReturnClose = await $this.prepararOrderReturnCloseTKT(orderReturnPlace,this.totales)
                    if(orderReturnClose.success == true ){
                            if($this.spDetail.TYPE_RETURN == 'C' && orderReturnClose.orderClose.order.COD_EPC_DISCOUNT_COUPON!='-99'){
                            //imprimo cupon
                            console.log("Imprime Cupon:")
                            console.log(orderReturnClose)
                              await $this.printCoupon(orderReturnClose.orderClose.order.FRIENLY_EOM_ORDER,orderReturnClose.orderClose.order.COD_EPC_DISCOUNT_COUPON,this.totales.MntTotal)
                            }
                        //Anulo OK
                        this._toast.success(title + ' OK', 'Correcto');
                        $this.close()
                        //$this.dialogRef.close();
                    }
                    else{
                      //Error!!!
                      this._toast.error('Ha ocurrido un error', 'Error');
                    }              
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""              

        }
        catch(e){
          console.log(e)
          this._toast.error('Ha ocurrido un error', 'Error');
        }
      //################################
    }
});
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  
}
//############################################################
//############################################################ 
async calcular(): Promise<void> {
  // validar formulario antes de emitir
  let $this = this;
  var pdf,url
  var tipo,title,dte,nc,jsonReturn,orderReturnPlace,orderReturnClose,dataFile;
  if(this.flagAnula == true) {tipo='ANULA';title='Anula Venta'; }else {tipo = 'DEVOLUCION';title='Devolucion de Productos';}
  var jsonNC= await this._salesPointService.getJsonNC(this.orderDetail.order.UUID_DTE,tipo);
  dte = jsonNC.dataSet
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  //Pinto receptor
  dte.Encabezado.Receptor.RUTRecep    = this.receptor.RUT
  dte.Encabezado.Receptor.RznSocRecep = this.receptor.RAZON_SOCIAL
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
const dialogRef = this._fuseConfirmationService.open({
  title: title,
  message: `Debe ingresar los productos a Bodega. <br><b>Esta operarion no es reversible.</b> <br>¿Desea continuar?`,
  icon: {
      show: true,
      name: 'assignment_return',
      color: 'primary'
  },
  actions: {
      confirm: {
          label: 'Aceptar',
          color: 'primary'
      },
  }
});
dialogRef.afterClosed().subscribe(async (result) => {
    if(this.reasonReturnSelected == null){
      
    }
    //
    if(result == 'confirmed' && this.reasonReturnSelected){
      //################################
        try{
            // preparar json cuando es parcial ojo que tengo que actualizar las cantidades y eliminar los productos ya anulados
            jsonReturn = await this.prepararOrderReturnPlace(dte)
            jsonReturn.jsonReturn.dte = jsonReturn.dte
            orderReturnPlace = await this._salesPointService.orderReturnPlace(jsonReturn.jsonReturn);

////////////////////////////////////////////////////////////////
nc  = await $this.makeDteAT('0',orderReturnPlace.order.UUID_EOM_ORDER_RETURN,this.spDetail.V_DATA_PRINTER_A.paperSize,jsonReturn.dte.Encabezado.IdDoc.TipoDTE)
///////////////////////////////////////////////////////////////
            if(nc.success == true){
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
              this.flagProcess = true

                    orderReturnClose = await $this.prepararOrderReturnClose(orderReturnPlace,nc.dte)
                    if(orderReturnClose.success == true && nc.success == true){
                        //imprimo NC
                        if($this.spDetail.FLAG_PRINTER_A == 'Y'){
                          pdf = nc.dte.fileB64
                          url = nc.dte.urlPDF
                        }
                        else{
                          pdf = nc.dte.fileB64
                          url = nc.dte.urlPDF
                        }
                        //##
                        dataFile = {
                                      pdf:pdf
                                      ,url:url
                                    }
                       //   if(nc.dte.fileB64 == null){
                       //     console.log("Imprime thermal")
                       //     await $this.printLowLevelPOS(nc.dte.dteJson,nc.dte.ted)
                       //   }else{
                            console.log("Imprime pdfb64")
                            await $this.printx64(dataFile,{})
                       //   }
                                     
                            if($this.spDetail.TYPE_RETURN == 'C' && orderReturnClose.orderClose.order.COD_EPC_DISCOUNT_COUPON!='-99'){
                            //imprimo cupon
                            console.log("Imprime Cupon:")
                              await $this.printCoupon(orderReturnClose.orderClose.order.FRIENLY_EOM_ORDER,orderReturnClose.orderClose.order.COD_EPC_DISCOUNT_COUPON,nc.dte.dteJson.Encabezado.Totales.MntTotal)
                            }
                        //Anulo OK
                        this._toast.success(title + ' OK', 'Correcto');
                        $this.close()
                        //$this.dialogRef.close();
                    }
                    else{
                      //Error!!!
                      this._toast.error('Ha ocurrido un error', 'Error');
                    }              
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""              
            }
            else{
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                  //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°               
                      //Error
                      const dialogRef = $this._fuseConfirmationService.open({
                        title: 'Error: No es posible emitir Nota de Credito',
                        message: `Reintentar en detalle de Orden`,
                        icon: {
                            show: true,
                            name: 'local_atm',
                            color: 'error'
                        },
                        "actions": {
                          "confirm": {
                            "show": true,
                            "label": "Aceptar",
                            "color": "primary"
                          },
                          "cancel": {
                            "show": false,
                            "label": "Cancel"
                          }
                        },
                        "dismissible": true      
                      });
                      dialogRef.afterClosed().subscribe(async (result) => {
                          //
                          $this.close()
                          //$this.dialogRef.close();
                          //
                        });
                  //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°   
                
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""                            
            }
        }
        catch(e){
          if(e.dataSet?.codStatus == '409' && e.dataSet?.glsStatus == 'Punto de Venta debe estar Abierto' ){
            $this.flagProcess = false
          }else if(e != false){
            $this.flagProcess = true
            $this._toast.error('Error al anular orden', 'Error');
          }
        }
      //################################
    }
});
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

}
//############################################################
//############################################################ 
async calcularExp(): Promise<void> {
  // validar formulario antes de emitir
  let $this = this;
  var pdf,url
  var tipo,title,dte,nc,jsonReturn,orderReturnPlace,orderReturnClose,dataFile;
  if(this.flagAnula == true) {tipo='ANULA';title='Anula Venta'; }else {tipo = 'DEVOLUCION';title='Devolucion de Productos';}
  var jsonNC= await this._salesPointService.getJsonNC(this.orderDetail.order.UUID_DTE,tipo);
  dte = jsonNC.dataSet
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
const dialogRef = this._fuseConfirmationService.open({
  title: title,
  message: `Debe ingresar los productos a Bodega. <br><b>Esta operarion no es reversible.</b> <br>¿Desea continuar?`,
  icon: {
      show: true,
      name: 'assignment_return',
      color: 'primary'
  },
  actions: {
      confirm: {
          label: 'Aceptar',
          color: 'primary'
      },
  }
});
dialogRef.afterClosed().subscribe(async (result) => {
    if(this.reasonReturnSelected == null){
      
    }
    //
    if(result == 'confirmed' && this.reasonReturnSelected){
      //################################
      try{
        // preparar json cuando es parcial ojo que tengo que actualizar las cantidades y eliminar los productos ya anulados
        jsonReturn = await this.prepararOrderReturnPlace(dte)
        jsonReturn.jsonReturn.dte = jsonReturn.dte
        orderReturnPlace = await this._salesPointService.orderReturnPlace(jsonReturn.jsonReturn);
     //  this.auth = await this.makeAuthInvoice(112);
        // agregar referencia de pedido
     //   nc        = await this.makeDte(orderReturnPlace.order.UUID_EOM_ORDER_RETURN,jsonReturn.dte)
////////////////////////////////////////////////////////////////
nc = await $this.makeDteAT('0',orderReturnPlace.order.UUID_EOM_ORDER_RETURN,this.spDetail.V_DATA_PRINTER_A.paperSize,jsonReturn.dte.Encabezado.IdDoc.TipoDTE)

///////////////////////////////////////////////////////////////

        if(nc.success == true){
          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                orderReturnClose = await $this.prepararOrderReturnClose(orderReturnPlace,nc.dte)
                if(orderReturnClose.success == true && nc.success == true){
                    //imprimo NC
                    if($this.spDetail.FLAG_PRINTER_A == 'Y'){
                      pdf = nc.dte.fileB64
                      url = nc.dte.urlPDF
                    }
                    else{
                      pdf = nc.dte.fileB64
                      url =  nc.dte.urlPDF
                    }
                    //##
                    dataFile = {
                                  pdf:pdf
                                  ,url:url
                                }
                             //   if(nc.dte.fileB64 == null){
                             //     console.log("imprime thermal")
                             //     await $this.printLowLevelPOS(nc.dte.dteJson,nc.dte.ted)
                             //   }else{
                                  await $this.printx64(dataFile,{})
                             //   }
                        if($this.spDetail.TYPE_RETURN == 'C' && orderReturnClose.orderClose.order.COD_EPC_DISCOUNT_COUPON!='-99'){
                        //imprimo cupon
                        //console.log("Imprime Cupon:")
                        //  await $this.printCoupon(orderReturnClose.orderClose.order.FRIENLY_EOM_ORDER,orderReturnClose.orderClose.order.COD_EPC_DISCOUNT_COUPON,nc.dte.dteJson.Encabezado.Totales.MntTotal)
                        }
                        //Anulo OK
                        this._toast.success(title + ' OK', 'Correcto');
                        $this.close()
                        //$this.dialogRef.close();
                    }
                    else{
                      //Error!!!
                      this._toast.error('Ha ocurrido un error', 'Error');
                    }              
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""              
            }
            else{
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                  //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°               
                      //Error
                      const dialogRef = $this._fuseConfirmationService.open({
                        title: 'Error: No es posible emitir Nota de Credito',
                        message: `Reintentar en detalle de Orden`,
                        icon: {
                            show: true,
                            name: 'local_atm',
                            color: 'error'
                        },
                        "actions": {
                          "confirm": {
                            "show": true,
                            "label": "Aceptar",
                            "color": "primary"
                          },
                          "cancel": {
                            "show": false,
                            "label": "Cancel"
                          }
                        },
                        "dismissible": true      
                      });
                      dialogRef.afterClosed().subscribe(async (result) => {
                          //
                          $this.close()
                          //this.dialogRef.close();
                          //
                        });
                  //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°   
                
              //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""                            
            }
        }
        catch(e){
          console.log(e)
          this._toast.error('Ha ocurrido un error', 'Error');
        }
      //################################
    }
});
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

}
//############################################################
//############################################################
async placeReturn(){
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
        ////////////////////////////////////////////
        var placeOrder;
        try{
            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  

                        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                         // if(payOrder.status == true && payOrder.codStatus == '200'){
  

            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
          }
          catch(e)
          {
               console.log(e)  
              reject({success:false,error:e,jsonDte:null})
        }
   });             
}
//############################################################
//############################################################
/*
si es anulacion no cambio el Json de NC... solo acualiso el json para ingresar el return
si es parcial:
              cambio solo el quantity
              actualizo totales en pantalla
              modifico el descuento gloval al prorateado, una vez modificado lo envio a precheck para que generere totales y montos pos item
              con esto aseguro la cuadratura entre el DTE/NC y la informacion Contable
*/
//############################################################ 
async  prepararOrderReturnPlace(dte){
  let $this = this;
return await new Promise(
  async function (resolve, reject) {
      ////////////////////////////////////////////  
      var orderReturn = {};
      var orderDetail = [];
      var tmp         = {};
      var tmpDetail   = [];
      var jsonReturn  = {};
      var totalesDte  :any = {};
      var typeEomOrderReturn = null;
      //
      if($this.flagAnula == true) typeEomOrderReturn='A'; else typeEomOrderReturn='P';
      //
      orderReturn = {              
                       uuidEomOrder       : $this.orderDetail.order.UUID_EOM_ORDER              
                      ,uuidSp             : $this.uuidSpEmployee
                      ,uuidEomOrderStatus : null        
                      ,uuidCmEmployeeAuth : ''                
                      ,uuidEmisorReceptor : '0'
                      ,rutEmisorReceptor  : $this.receptor.RUT
                      ,uuidCrmCustomer    : 0 
                      ,uuidRelWarehouse   : $this.selectedWarehouse.UUID_SP_REL_WM_WAREHOUSE  
                      ,codReasonCode      : $this.reasonReturnSelected.COD_REASON_CODE 
                      ,typeEomOrderReturn : typeEomOrderReturn                                        
                  }
      for(let i=0;i<$this.orderDetail.orderDetail.length;i++ ){
              ////////////////////////////
                if($this.flagAnula == true){
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 
                      tmp = {
                                 "uuidEomOrderDetail"  : $this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL
                                ,"quantityDetail"      : $this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW //QUANTITY_PS_SERVICES
                                ,"netoDetail"          : $this.orderDetail.orderDetail[i].NETO
                                ,"exentoDetail"        : $this.orderDetail.orderDetail[i].EXENTO
                                ,"ivaDetail"           : $this.orderDetail.orderDetail[i].IVA
                                ,"totalDetail"         : $this.orderDetail.orderDetail[i].TOTAL           
                                ,"codReasonCodeDetail" : $this.orderDetail.orderDetail[i].COD_REASON_CODE
                                ,"uuidWarehouseOri"    : $this.orderDetail.orderDetail[i].UUID_WAREHOUSE
                            }
                      orderDetail.push(tmp)
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%                  
                }
                else{
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
                  //  try{
                        // modificar json DTE
                        for(let j=0;j<dte.Detalle.length;j++ ){
                          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                            try{
                                //======================================================================================
                               
                                if( dte.Detalle[j].CdgItem[0].VlrCodigo == $this.orderDetail.orderDetail[i].COD_PS_SERVICES && dte.Detalle[j].CdgItem[1].VlrCodigo == $this.orderDetail.orderDetail[i].COD_WAREHOUSE){
                                    //%%
                                            if($this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_RETURN_AVAILABLE == 0){
                                              dte.Detalle[j].QtyItem   = 0
                                            }
                                            else{
                                              dte.Detalle[j].QtyItem   = $this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW
                                            }
                                            //==
                                              //dte.Detalle[j].MontoItem = (dte.Detalle[j].PrcItem*$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW).toFixed(4)
                                              dte.Detalle[j].PrcItem  = ((dte.Detalle[j].MontoItem/$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES) ).toFixed(4)
                                              dte.Detalle[j].MontoItem=  Math.round(
                                                                            (dte.Detalle[j].MontoItem/$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES)*$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW
                                                                          )
                                              dte.Detalle[j].DescuentoMonto = null
                                              dte.Detalle[j].DescuentoPct   = null
                                              dte.Detalle[j].RecargoMonto   = null
                                              dte.Detalle[j].RecargoPct     = null
                                            //==
                                            tmp = {
                                                     "uuidEomOrderDetail"  : $this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL
                                                    ,"quantityDetail"      : $this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW
                                                    ,"netoDetail"          : dte.Detalle[j].PrcItem
                                                    ,"exentoDetail"        : $this.orderDetail.orderDetail[i].EXENTO
                                                    ,"ivaDetail"           : $this.orderDetail.orderDetail[i].IVA
                                                    ,"totalDetail"         : (dte.Detalle[j].MontoItem * ( Number(1 + $this.ivaF))).toFixed(4)
                                                    ,"codReasonCodeDetail" : $this.orderDetail.orderDetail[i].COD_REASON_CODE
                                                    ,"uuidWarehouseOri"    : $this.orderDetail.orderDetail[i].UUID_WAREHOUSE
                                                  } 
                                            orderDetail = orderDetail.filter(item => item.uuidEomOrderDetail != $this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL)
                                            if($this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW > 0) orderDetail.push(tmp) ;
                                }
                                //======================================================================================                                
                            }
                            catch(e){
                                //======================================================================================
                                  console.log(e)
                                   if( dte.Detalle[j].NmbItem == $this.orderDetail.orderDetail[i].NAME_PS_SERVICES_INVOICE ){
                                        //%%
                                                dte.Detalle[j].QtyItem   = $this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW
                                                //dte.Detalle[j].MontoItem = (dte.Detalle[j].PrcItem*$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW).toFixed(4)
                                            //==
                                              //dte.Detalle[j].MontoItem = (dte.Detalle[j].PrcItem*$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW).toFixed(4)
                                              dte.Detalle[j].PrcItem  = ((dte.Detalle[j].MontoItem/$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES) ).toFixed(4)
                                              dte.Detalle[j].MontoItem=  Math.round(
                                                                            (dte.Detalle[j].MontoItem/$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES)*$this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW
                                                                          )
                                              dte.Detalle[j].DescuentoMonto = null
                                              dte.Detalle[j].DescuentoPct   = null
                                              dte.Detalle[j].RecargoMonto   = null
                                              dte.Detalle[j].RecargoPct     = null
                                            //==                                                
                                                tmp = {
                                                         "uuidEomOrderDetail"  : $this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL
                                                        ,"quantityDetail"      : $this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW
                                                        ,"netoDetail"          : dte.Detalle[j].PrcItem
                                                        ,"exentoDetail"        : $this.orderDetail.orderDetail[i].EXENTO
                                                        ,"ivaDetail"           : $this.orderDetail.orderDetail[i].IVA
                                                        ,"totalDetail"         : (dte.Detalle[j].MontoItem * ( Number(1 + $this.ivaF))).toFixed(4)
                                                        ,"codReasonCodeDetail" : $this.orderDetail.orderDetail[i].COD_REASON_CODE
                                                        ,"uuidWarehouseOri"    : $this.orderDetail.orderDetail[i].UUID_WAREHOUSE
                                                      } 
                                                 orderDetail = orderDetail.filter(item => item.uuidEomOrderDetail != $this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL)
                                                if($this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW > 0) orderDetail.push(tmp) ;
                                    }  
                                                                  
                                //======================================================================================                                
                            }
                          //""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""                          
                          //%%
                        }
                        //##                                  
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%                  
                }
              ////////////////////////////
        }
        //########################################################
        if($this.flagAnula == true ){
                  tmpDetail = JSON.parse(JSON.stringify(dte.Detalle))
                  delete dte.Detalle
                  dte.Detalle = []
                 // dte.Encabezado.Totales = null
                  for(let j=0;j<tmpDetail.length;j++ ){
                    if(tmpDetail[j].QtyItem > 0 ){
                      if($this.orderDetail.order.COD_DOC == 110){
                        tmpDetail[j].MontoItem =(tmpDetail[j].MontoItem).toFixed(3)
                      }else{
                        tmpDetail[j].MontoItem =Math.ceil(tmpDetail[j].MontoItem)
                      }                      
                      dte.Detalle.push(tmpDetail[j])
                    }
                  }
                  //////////////////////////////////////////////
                  /*
                  if($this.orderDetail.order.COD_DOC == 39){
                    if($this.totales.descTotal > 0){
                        for(let z=0;z<dte.DscRcgGlobal.length;z++){
                          dte.DscRcgGlobal[0].ValorDR  = dte.DscRcgGlobal[0].ValorDR//round((Number(dte.DscRcgGlobal[0].ValorDR) / ($this.ivaF+1))+0.01,0)
                        }
                    }                      
                  }    
                    */                
                  //////////////////////////////////////////////
        }
        else
        {
                tmpDetail = JSON.parse(JSON.stringify(dte.Detalle))
                delete dte.Detalle
                dte.Detalle = []
                dte.Encabezado.Totales = null
                for(let j=0;j<tmpDetail.length;j++ ){
                  if(tmpDetail[j].QtyItem > 0 ){
                    if($this.orderDetail.order.COD_DOC == 110){
                      tmpDetail[j].MontoItem =(tmpDetail[j].MontoItem).toFixed(3)
                    }else{
                      tmpDetail[j].MontoItem =Math.ceil(tmpDetail[j].MontoItem)
                    }
                    //tmpDetail[j].MontoItem =Math.round(tmpDetail[j].MontoItem)
                    dte.Detalle.push(tmpDetail[j])
                  }
                }
                //########################################################   
                // INCLUIR DESCUENTO GLOVAL.....   dteformat.js linea 1013 
                dte.DscRcgGlobal= []
                if($this.totales.descTotal > 0){
                  if($this.orderDetail.order.COD_DOC == 110 || $this.orderDetail.order.COD_DOC == 111 || $this.orderDetail.order.COD_DOC == 112 ){
                    //##################################################
                        dte.DscRcgGlobal.push(
                                                {
                                                  
                                                   NroLinDR : 1
                                                  ,TpoMov   : "D"
                                                  ,GlosaDR  : "Proporcional"
                                                  ,TpoValor : "$"
                                                  ,ValorDR  : $this.totales.descPror
                                                  ,ValorDROtrMnda : $this.totales.descPror
                                                  ,IndExeDR : 1
                                                }
                                              )
                    //##################################################
                  } if( $this.orderDetail.order.COD_DOC == 34 || $this.orderDetail.order.COD_DOC == 41 ){
                    //##################################################
                        dte.DscRcgGlobal.push(
                                                {
                                                  
                                                   NroLinDR : 1
                                                  ,TpoMov   : "D"
                                                  ,GlosaDR  : "Proporcional"
                                                  ,TpoValor : "$"
                                                  ,ValorDR  : $this.totales.descPror
                                                  ,IndExeDR : 1
                                                }
                                              )
                    //##################################################
                  }
                  else{
                    //##################################################
                        dte.DscRcgGlobal.push(
                                                {
                                                  
                                                   NroLinDR : 1
                                                  ,TpoMov   : "D"
                                                  ,GlosaDR  : "Proporcional"
                                                  ,TpoValor : "$"
                                                  ,ValorDR  : $this.totales.descPror
                                                }
                                              )
                    //##################################################
                  }
                  
                }   
         }            
        //########################################################
        jsonReturn = {
                          appToken    : null
                        ,uuidSSO     : null
                        ,orderReturn : orderReturn
                        ,orderDetail  : orderDetail
                      }
      ////////////////////////////////////////////
      for(let i=0;i<dte.Detalle.length;i++ ){
        dte.Detalle[i].NroLinDet = i+1
      }
      ////////////////////////////////////////////      
      //CALCULA TOTALES
      totalesDte = await $this.makeTotalesDte(dte) 
      dte.Encabezado.Totales =  totalesDte.totales  
      ////////////////////////////////////////////
      resolve({status:true,jsonReturn:jsonReturn,dte:dte}) 
    });      
}
//############################################################
//############################################################ 
//############################################################ 
async  prepararOrderReturnPlaceTKT(dte){
  let $this = this;
return await new Promise(
  async function (resolve, reject) {
      ////////////////////////////////////////////  
      var orderReturn = {};
      var orderDetail = [];
      var tmp         = {};
      var tmpDetail   = [];
      var jsonReturn  = {};
      var totalesDte  :any = {};
      var typeEomOrderReturn = null;
      //
      if($this.flagAnula == true) typeEomOrderReturn='A'; else typeEomOrderReturn='P';
      //
      orderReturn = {              
                       uuidEomOrder       : $this.orderDetail.order.UUID_EOM_ORDER              
                      ,uuidSp             : $this.uuidSpEmployee
                      ,uuidEomOrderStatus : null        
                      ,uuidCmEmployeeAuth : ''                
                      ,uuidEmisorReceptor : '0'
                      ,rutEmisorReceptor  : $this.receptor.RUT
                      ,uuidCrmCustomer    : 0 
                      ,uuidRelWarehouse   : $this.selectedWarehouse.UUID_SP_REL_WM_WAREHOUSE  
                      ,codReasonCode      : $this.reasonReturnSelected.COD_REASON_CODE 
                      ,typeEomOrderReturn : typeEomOrderReturn                                        
                  }
      for(let i=0;i<$this.orderDetail.orderDetail.length;i++ ){
              ////////////////////////////
                if($this.flagAnula == true){
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% 
                      tmp = {
                                 "uuidEomOrderDetail"  : $this.orderDetail.orderDetail[i].UUID_EOM_ORDER_DETAIL
                                ,"quantityDetail"      : $this.orderDetail.orderDetail[i].QUANTITY_PS_SERVICES_NEW //QUANTITY_PS_SERVICES
                                ,"netoDetail"          : $this.orderDetail.orderDetail[i].NETO
                                ,"exentoDetail"        : $this.orderDetail.orderDetail[i].EXENTO
                                ,"ivaDetail"           : $this.orderDetail.orderDetail[i].IVA
                                ,"totalDetail"         : $this.orderDetail.orderDetail[i].TOTAL           
                                ,"codReasonCodeDetail" : $this.orderDetail.orderDetail[i].COD_REASON_CODE
                                ,"uuidWarehouseOri"    : $this.orderDetail.orderDetail[i].UUID_WAREHOUSE
                            }
                      orderDetail.push(tmp)
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%                  
                }
                else{
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
                    //Devoluciones Parciales                                
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%                  
                }
              ////////////////////////////
        }
        //########################################################          
        //########################################################
        jsonReturn = {
                          appToken    : null
                        ,uuidSSO     : null
                        ,orderReturn : orderReturn
                        ,orderDetail  : orderDetail
                      }
      ////////////////////////////////////////////
      //CALCULA TOTALES
      totalesDte = await $this.makeTotalesDte(dte) 
      ////////////////////////////////////////////
      resolve({status:true,jsonReturn:jsonReturn,dte:null}) 
    });      
}
//############################################################ 
//############################################################ 
async makeTotalesDte(dte): Promise<any>{  
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
        ////////////////////////////////////////////
        var totalDTE = {
                           TpoMoneda     : null                 
                          ,MntNeto       : null                 
                          ,MntExe        : null                 
                          ,MntBase       : null                 
                          ,MntMargenCom  : null                         
                          ,TasaIVA       : null                 
                          ,IVA           : null             
                          ,IVAProp       : null                 
                          ,IVATerc       : null                 
                          ,ImptoReten    : null                         
                          ,IVANoRet      : null                     
                          ,CredEC        : null                     
                          ,GrntDep       : null                     
                          ,Comisiones    : null                             
                          ,MntTotal      : null                     
                          ,MontoNF       : null                     
                          ,MontoPeriodo  : null                             
                          ,SaldoAnterior : null                         
                          ,VlrPagar      : null                          
                    };
        var totalTKT = {
 
                           MntDesc   : null
                          ,MntRcrg   : null
                          ,MntStotal : null                          
                          ,MntTotal  : null                               
                    };
        var trimsNull;
        try{
            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°° 
            if($this.orderDetail.order.COD_DOC == 5001){
              //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                totalTKT.MntTotal  =  $this.totales.MntTotal    
                totalTKT.MntDesc   = 0
                totalTKT.MntRcrg   = 0
                totalTKT.MntStotal =  $this.totales.MntTotal
                trimsNull = await $this._trimNulls(totalTKT)
                resolve({success:true,error:null,totales:trimsNull.data})                
              //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$          
            }                        
            else{
              //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
              if( $this.orderDetail.order.COD_DOC == 110 || $this.orderDetail.order.COD_DOC == 111 || $this.orderDetail.order.COD_DOC == 112 ){
                //==================================================
                    totalDTE.MntNeto  = $this.totales.MntNeto 
                    totalDTE.TasaIVA  = $this.ivaP
                    totalDTE.IVA      = $this.totales.IVA
                    totalDTE.MntTotal = Number($this.totales.MntNeto)+Number($this.totales.MntExe)+Number($this.totales.IVA)//-Number($this.totales.descPror)
                    if($this.totales.MntExe > 0 )  totalDTE.MntExe =   $this.totales.MntExe 
                    totalDTE.MntTotal = (totalDTE.MntTotal / $this.TcCLP ).toFixed(3)   
                    totalDTE.MntExe   = (totalDTE.MntExe   / $this.TcCLP ).toFixed(3)   

                      totalDTE.TpoMoneda = dte.Encabezado.Totales.TpoMoneda
                      totalDTE.MntNeto = null
                      totalDTE.TasaIVA = null
                      totalDTE.IVA     = null

                    trimsNull = await $this._trimNulls(totalDTE)
                    resolve({success:true,error:null,totales:trimsNull.data})
                //==================================================                
              }else{
                //==================================================
                    totalDTE.MntNeto  = $this.totales.MntNeto 
                    totalDTE.TasaIVA  = $this.ivaP
                    totalDTE.IVA      = $this.totales.IVA
                    totalDTE.MntTotal = Number($this.totales.MntNeto)+Number($this.totales.MntExe)+Number($this.totales.IVA)//-Number($this.totales.descPror)
                    if($this.totales.MntExe > 0 )  totalDTE.MntExe =   $this.totales.MntExe 
                    if( $this.orderDetail.order.COD_DOC == 110 || $this.orderDetail.order.COD_DOC == 111 || $this.orderDetail.order.COD_DOC == 112 ){
                      totalDTE.TpoMoneda = dte.Encabezado.Totales.TpoMoneda
                      totalDTE.MntNeto = null
                      totalDTE.TasaIVA = null
                      totalDTE.IVA     = null
                    }
                    if($this.orderDetail.order.COD_DOC == 34 || $this.orderDetail.order.COD_DOC == 41){
                      totalDTE.MntNeto = null
                    }                
                    trimsNull = await $this._trimNulls(totalDTE)
                    resolve({success:true,error:null,totales:trimsNull.data})
                //================================================== 
              }

              //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$                    
            }
            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
          }
          catch(e)
          {
               console.log(e)
               resolve({success:true,error:e,totales:null})
        }
   });             
}
//############################################################
//############################################################ 
async  _trimNulls(data) {
  let $this = this;
  return await new Promise(
      async function (resolve, reject) {
         ////////////////////////////////////////////
         var y;
         try{
                  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                      for (var x in data) {
                      y = data[x];
                      if (y==="null" || y===null || y==="" || y===false || typeof y === "undefined" || (y instanceof Object && Object.keys(y).length == 0)) {
                          await delete data[x];
                      }
                      if (y instanceof Object) y = $this._trimNulls(y);
                      }
                      resolve({"status":true,"data":data}); 
                   //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                  }
                  catch(err)
                  {
                    console.log(err);
                    reject({"status":false,"data":err}); 
                }
          });                                                           
}
//############################################################
//############################################################ 
/*
async makeDte(uuidEomOrderReturn,dte){
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
        ////////////////////////////////////////////
        try{
            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°° 
            var make;
            var ts_hms = new Date();
            let fechaRef = ts_hms.getFullYear() + '-' + ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + ("0" + (ts_hms.getDate())).slice(-2) 
            var i =  dte.Referencia.length +1 
            //
            dte.Referencia.push({
                          NroLinRef:i
                          ,TpoDocRef : 'GLS'
                          ,IndGlobal : 1
                          ,FolioRef  : 0
                          ,FchRef    : fechaRef
                          ,RazonRef  : "Orden:" + $this.orderDetail.order.FRIENLY_EOM_ORDER
                          })      
              i=i+1
              dte.Referencia.push({
                           NroLinRef:i
                          ,TpoDocRef : 'GLS'
                          ,IndGlobal : 1
                          ,FolioRef  : 0
                          ,FchRef    : fechaRef
                          ,RazonRef : "CodVndor:" + $this.spDetail.COD_CM_EMPLOYEE + ' CodCaja:' + $this.spDetail.COD_SP
                          })  
              i=i+1
              dte.Referencia.push({
                              NroLinRef:i
                            ,TpoDocRef : 'GLS'
                            ,IndGlobal : 1
                            ,FolioRef  : 0
                            ,FchRef    : fechaRef
                            ,RazonRef : "Atendido por:" +$this.userData.firstName +' '+ $this.userData.lastName
                            }) 
              ////////////////////////////////////////////////////////////
              //delete dte.Referencia
              var tmpReferencia = JSON.parse(JSON.stringify(dte.Referencia))
              dte.Referencia = []
               for(i=0;i<tmpReferencia.length;i++){
                  //=====================================
                    if(tmpReferencia[i].TpoDocRef == 39 || tmpReferencia[i].TpoDocRef == 41 || tmpReferencia[i].TpoDocRef == 33 || tmpReferencia[i].TpoDocRef == 34  || tmpReferencia[i].TpoDocRef == 110){
                        if(tmpReferencia[i].CodRef == 1 || tmpReferencia[i].CodRef == 2 || tmpReferencia[i].CodRef == 3){
                          //""""""""""""""""""""""""""""""""""""""""""""""""""""
                          dte.Referencia.push({
                                                 NroLinRef : i+1
                                                ,TpoDocRef : tmpReferencia[i].TpoDocRef
                                                ,FolioRef  : tmpReferencia[i].FolioRef 
                                                ,FchRef    : tmpReferencia[i].FchRef   
                                                ,CodRef    : tmpReferencia[i].CodRef
                                                ,RazonRef  : tmpReferencia[i].RazonRef 
                                              })                                                 
                          //""""""""""""""""""""""""""""""""""""""""""""""""""""
                        }

                    }
                    else{
                      //""""""""""""""""""""""""""""""""""
                      dte.Referencia.push({
                                             NroLinRef : i+1
                                            ,TpoDocRef : tmpReferencia[i].TpoDocRef
                                            ,IndGlobal : tmpReferencia[i].IndGlobal
                                            ,FolioRef  : tmpReferencia[i].FolioRef 
                                            ,FchRef    : tmpReferencia[i].FchRef   
                                            ,RazonRef  : tmpReferencia[i].RazonRef 
                                          })                       
                      //""""""""""""""""""""""""""""""""""
                    }
                  //=====================================
               }
              ////////////////////////////////////////////////////////////
              let save : any = await $this.orderDteSave(0,uuidEomOrderReturn,dte) 
              let data = [dte]
              //if($this.flagAnula == true){
                make = await $this._salesPointService.makeDte( $this.auth ,data)
              //}
              //else{
              //  make = await $this._salesPointService.makeDteFull( $this.auth ,data)
             // }
              resolve({success:true,dteOK:make,json:dte})
            //°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°  
          }
          catch(e)
          {
               console.log(e)
              resolve({success:false,error:e,dteOK:null,json:dte})
        }
   });             
}
//############################################################
//############################################################ 
async makeAuthInvoice(TipoDTE): Promise<any>{  
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
      var auth,token
      token = localStorage.getItem('appToken')
        //////////////////////////////////////////// 
          if(TipoDTE == 39 || TipoDTE == 41){
            auth = {"appToken":token,"language":"es","environment": $this.userData.envirometBOL,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
            }
            else if(TipoDTE == 5001 ){ // hacer clase para tratar los tkt
                  auth = {"appToken":token,"language":"es","environment": $this.userData.envirometTKT,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
          }
            else{
                  auth = {"appToken":token,"language":"es","environment": $this.userData.envirometDTE,"user":$this.userData.login, "passwd":null, "pin":$this.userData.pin,"log":false};
          }
       //////////////////////////////////////////// 
       resolve(auth)
      }); 
}
*/
//############################################################
//############################################################ 
/*
async  prepararOrderReturnClose(orderReturn,nc){
  let $this = this;
return await new Promise(
  async function (resolve, reject) {
      ////////////////////////////////////////////  
      var jsonClose,orderClose;
      jsonClose = {
                      language  : "es"
                     ,appToken  : null
                     ,uuidSSO   : null
                     ,orderReturn:{              
                                     uuidEomOrderReturn : orderReturn.order.UUID_EOM_ORDER_RETURN                            
                                    ,totalNeto          : nc.dte[0].totales.MntNeto
                                    ,totalIva           : nc.dte[0].totales.IVA
                                    ,totalExento        : nc.dte[0].totales.MntExe
                                    ,total              : nc.dte[0].totales.MntTotal
                                    ,codDoc             : nc.dte[0].codDoc
                                    ,uuidDte            : nc.dte[0].uuidDTE
                                    ,codStatusDte       : 'OK'                            
                                }
                  
                  }
      //////////////////////////////////////////// 
      orderClose= await $this._salesPointService.orderReturnClose(jsonClose)
      //////////////////////////////////////////// 
      resolve({success:true,orderClose:orderClose})
    });      
}*/
async  prepararOrderReturnClose(orderReturn,nc){
  let $this = this;
return await new Promise(
  async function (resolve, reject) {
      ////////////////////////////////////////////  
      var jsonClose,orderClose;
      if( $this.orderDetail.order.COD_DOC == 110 || $this.orderDetail.order.COD_DOC == 111 || $this.orderDetail.order.COD_DOC == 112 )
        //=====================================================
        {
              jsonClose = {
                              language  : "es"
                            ,appToken  : null
                            ,uuidSSO   : null
                            ,orderReturn:{              
                                            uuidEomOrderReturn : orderReturn.order.UUID_EOM_ORDER_RETURN                            
                                            ,totalNeto          : 0 
                                            ,totalIva           : 0
                                            ,totalExento        : $this.totales.MntExe 
                                            ,total              : $this.totales.MntTotal
                                            ,codDoc             : nc.codDoc
                                            ,uuidDte            : nc.uuidDte 
                                            ,codStatusDte       : 'OK'                            
                                        }
                          }
         }
        //=====================================================
        else
        //=====================================================
        {
          jsonClose = {
                            language  : "es"
                          ,appToken  : null
                          ,uuidSSO   : null
                          ,orderReturn:{              
                                          uuidEomOrderReturn : orderReturn.order.UUID_EOM_ORDER_RETURN                            
                                          ,totalNeto          : nc.dteJson.Encabezado.Totales.MntNeto |0 
                                          ,totalIva           : nc.dteJson.Encabezado.Totales.IVA     |0
                                          ,totalExento        : nc.dteJson.Encabezado.Totales.MntExe  |0 
                                          ,total              : nc.dteJson.Encabezado.Totales.MntTotal|0
                                          ,codDoc             : nc.codDoc
                                          ,uuidDte            : nc.uuidDte 
                                          ,codStatusDte       : 'OK'                            
                                      }
                        }          
        }
        //=====================================================              
      //////////////////////////////////////////// 
      orderClose= await $this._salesPointService.orderReturnClose(jsonClose)
      //////////////////////////////////////////// 
      resolve({success:true,orderClose:orderClose})
    });      
}
//############################################################
//############################################################ 
async  prepararOrderReturnCloseTKT(orderReturn,totales){
  let $this = this;
return await new Promise(
  async function (resolve, reject) {
      ////////////////////////////////////////////  
      var jsonClose,orderClose;
      jsonClose = {
                      language  : "es"
                     ,appToken  : null
                     ,uuidSSO   : null
                     ,orderReturn:{              
                                     uuidEomOrderReturn : orderReturn.order.UUID_EOM_ORDER_RETURN                            
                                    ,totalNeto          : totales.MntNeto
                                    ,totalIva           : totales.IVA
                                    ,totalExento        : totales.MntExe
                                    ,total              : totales.MntTotal
                                    ,codDoc             : '5001'
                                    ,uuidDte            : '0'
                                    ,codStatusDte       : 'OK'                            
                                }
                  
                  }
      //////////////////////////////////////////// 
      orderClose= await $this._salesPointService.orderReturnClose(jsonClose)
      //////////////////////////////////////////// 
      resolve({success:true,orderClose:orderClose})
    });      
}
//############################################################
//############################################################ 
//############################################################
//############################################################
/*async orderDteSave(uuidEomOrder,uuidEomOrderReturn,jsonDte): Promise<any>{  
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
      var auth,token
      token = localStorage.getItem('appToken')
        //////////////////////////////////////////// uuidEomOrderReturn
        let json = {
                           "language" :"es"
                          ,"appToken" : null
                          ,"uuidSSO"  : null
                          ,"orderDTE":{
                          
                                      "uuidEomOrder"         : uuidEomOrder
                                      ,"uuidEomOrderReturn"  : uuidEomOrderReturn
                                      ,"dteData"             : jsonDte
                              }
                              
                          }
        let save = await $this._salesPointService.orderDteSave(json);
        ////////////////////////////////////////////  
       resolve(save)
      }); 
}
*/
//############################################################
//############################################################ 
//########################################################################################################################
//########################################################################################################################
/*
{
     "typeConnection" : "Device"  | Default
    ,"startConnection": { "retries": 5, "delay": 1 }
    ,"connection"     : "Epson_TM-T80"
    ,"typePrint"      : "pdf"
    ,"paperSize"      : "80mm"
    ,"namePrinter"    : "Epson TM-T80"
    ,"quantityCopies" : "1"
    ,"flagActive"     :true
    ,"content"        : "dte"
}
             certQZ      : environment.qzTrayDigitalKey
            ,printer     : null
            ,err         : null
            ,status      : false
            ,typePrinter : false
            ,quantityCopies : 1
*/

  /*
  async initQZ() {
      let $this=this;
      return await new Promise(
        async function (resolve, reject) {
            ////////////////////////////////////////////
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      if (qz.websocket.isActive()) await qz.websocket.disconnect();
      //if($this.spDetail.FLAG_PRINTER_A == 'Y' && !qz.websocket.isActive())
      if($this.spDetail.FLAG_PRINTER_A == 'Y' )
      {
          qz.security.setCertificatePromise(function(resolve, reject) {resolve(environment.qzTrayDigitalKey)});
          qz.security.setSignatureAlgorithm("SHA256"); // Since 2.1
          if($this.spDetail.V_DATA_PRINTER_A.typeConnection == 'Device'){
                  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                  try{
                          qz.websocket.connect()
                          .then(qz.printers.getDefault)
                          .then(async  function(printer) { 
                              //=================================================
                                  $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                                  $this.qzDataA.printer    = printer
                                  $this.qzDataA.flagActive = true;
                                  $this.qzDataA.err        = false;
                                  console.log("A-Conectado a: " + printer)
                                  $this.setPrint_B();
                                  resolve(true)
                                  
                              //=================================================                    
                          })
                          .catch(async function(err) { 
                              //=================================================                
                              $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                              $this.qzDataA.printer    = false;
                              $this.qzDataA.flagActive = false;
                              $this.qzDataA.err        = err;
                              console.log("A-Error al conectar " + err)
                              resolve(true)
                              //=================================================  
                          })
                          
                  }
                  catch(e){
                      $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                      $this.qzDataA.printer    = false;
                      $this.qzDataA.flagActive = false;
                      $this.qzDataA.err        = e;
                      console.log("A-Error: " + e)
                      resolve(true)
                  }              
                  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!            
              }
              else if ($this.spDetail.V_DATA_PRINTER_A.typeConnection == 'Default'){
                  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 
                  try{ 
                      qz.websocket.connect()
                      .then(qz.printers.getDefault)
                      .then(async  function(printer) { 
                          //=================================================
                              $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                              $this.qzDataA.printer    = printer;
                              $this.qzDataA.flagActive = true;
                              $this.qzDataA.err        = false;
                              console.log("A-Impresora predeterinada -> Conectado a: " + printer)
                              $this.setPrint_B();
                              resolve(true)
                          //=================================================                    
                      })
                      .catch(async function(err) { 
                          //=================================================                
                          $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                          $this.qzDataA.printer    = false;
                          $this.qzDataA.flagActive = false;
                          $this.qzDataA.err        = err;
                          console.log("A-Error al conectar en impesora predeterinada " + err)
                          resolve(true)
                          //=================================================  
                      })
                  }
                  catch(e){
                  $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                  $this.qzDataA.printer    = false;
                  $this.qzDataA.flagActive = false;
                  $this.qzDataA.err        = e;
                  console.log("A-Error n impesora predeterinada: " + e)
                  resolve(true)
                  } 
                  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!                                 
              }
              else{
                  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 
                    console.log("A-Sin impresora")
                    resolve(true)
                  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!                                 
              }
      }
      else{
          resolve(true)
      }
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  });
  }

  */
  launchQZ() {
    //    if (!qzA.websocket.isActive()) {
    //        window.location.assign("qz:launch");
    //        //Retry 5 times, pausing 1 second between each attempt
    //       startConnection({ retries: 5, delay: 1 });
    //    }
    }
    async initQZ() {
        let $this=this;
        return await new Promise(
          async function (resolve, reject) {
              ////////////////////////////////////////////
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        if (qz.websocket.isActive()) await qz.websocket.disconnect();
        //if($this.spDetail.FLAG_PRINTER_A == 'Y' && !qz.websocket.isActive())
        if($this.spDetail.FLAG_PRINTER_A == 'Y' )
        {
            qz.security.setCertificatePromise(function(resolve, reject) {resolve(environment.qzTrayDigitalKey)});
            qz.security.setSignatureAlgorithm("SHA256"); // Since 2.1
            if($this.spDetail.V_DATA_PRINTER_A.typeConnection == 'Device'){
                    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    try{
                            qz.websocket.connect()
                            .then(qz.printers.getDefault)
                            .then(async  function(printer) { 
                                //=================================================
                                    $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                                    $this.qzDataA.printer    = printer
                                    $this.qzDataA.flagActive = true;
                                    $this.qzDataA.err        = false;
                                    console.log("A-Conectado a: " + printer)
                                    $this.setPrint_B();
                                    resolve(true)
                                //=================================================                    
                            })
                            .catch(async function(err) { 
                                //=================================================             
                                $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                                $this.qzDataA.printer    = false;
                                $this.qzDataA.flagActive = false;
                                $this.qzDataA.err        = err;
                                console.log("A-Error al conectar " + err)
                                resolve(true)
                                //=================================================  
                            })
                            
                    }
                    catch(e){
                        $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                        $this.qzDataA.printer    = false;
                        $this.qzDataA.flagActive = false;
                        $this.qzDataA.err        = e;
                        console.log("A-Error: " + e)
                        resolve(true)
                    }              
                    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!            
                }
                else if ($this.spDetail.V_DATA_PRINTER_A.typeConnection == 'Default'){
                    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 
                    try{ 
                        qz.websocket.connect()
                        .then(qz.printers.getDefault)
                        .then(async  function(printer) { 
                            //=================================================
                                $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                                $this.qzDataA.printer    = printer;
                                $this.qzDataA.flagActive = true;
                                $this.qzDataA.err        = false;
                                console.log("A-Impresora predeterinada -> Conectado a: " + printer)
                                $this.setPrint_B();
                                resolve(true)
                            //=================================================                    
                        })
                        .catch(async function(err) { 
                            //=================================================                
                            $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                            $this.qzDataA.printer    = false;
                            $this.qzDataA.flagActive = false;
                            $this.qzDataA.err        = err;
                            console.log("A-Error al conectar en impesora predeterinada " + err)
                            resolve(true)
                            //=================================================  
                        })
                    }
                    catch(e){
                    $this.qzDataA            = $this.spDetail.V_DATA_PRINTER_A
                    $this.qzDataA.printer    = false;
                    $this.qzDataA.flagActive = false;
                    $this.qzDataA.err        = e;
                    console.log("A-Error n impesora predeterinada: " + e)
                    resolve(true)
                    } 
                    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!                                 
                }
                else{
                    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 
                      console.log("A-Sin impresora")
                      resolve(true)
                    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!                                 
                }
        }
        else{
            resolve(true)
        }
        //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    });
    }
  //############################################################
  //############################################################
  async setPrint_B() {
      let $this=this;
      return await new Promise(
        async function (resolve, reject) {
              //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
              if($this.spDetail.FLAG_PRINTER_B == 'Y')
              {
                  //=================================================
                      $this.qzDataB            = $this.spDetail.V_DATA_PRINTER_B
                      $this.qzDataB.printer    = $this.spDetail.V_DATA_PRINTER_B.connection
                      $this.qzDataB.flagActive = true;
                      $this.qzDataB.err        = false;
                      console.log("B-Conectado a: " + $this.spDetail.V_DATA_PRINTER_B.connection)
                      resolve(true)
                  //=================================================   
              }   
              else{
                  resolve(true)
              }              
              //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  });
  }
  //############################################################
  //############################################################
  async printx64(dataFile,options={}){
      let $this=this;
      return await new Promise(
        async function (resolve, reject) {
            ////////////////////////////////////////////
            //{ jobName: "Receipt #123456" }
      var i=1;
      var printData = [
                          {
                          type: 'PDF', format: 'base64'
                          ,data: dataFile.pdf
                          ,options: options
                          }
                      ];
 
      if($this.qzDataA.flagActive == true && $this.spDetail.FLAG_PRINTER_A=='Y'){   
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
          if($this.qzDataA.typeConnection == 'Default'){
              /////////////////////////////
              var config = await qz.configs.create($this.qzDataA.printer);  
              for(i=1;i<=$this.qzDataA.quantityCopies;i++){
                  await qz.print(config, printData).then(function() {
                          console.log("Sent NC data to:"+$this.qzDataA.printer);
                          resolve(true)
                      })
                      .catch(async function(err) { 
                        //=================================================             
                        console.log("A-Error al conectar " + err)
                        resolve(true)
                        //=================================================  
                    })

                  }
              /////////////////////////////              
          }
          if($this.qzDataA.typeConnection == 'Device'){
              /////////////////////////////
              var config = qz.configs.create($this.qzDataA.connection);  
              for(i=1;i<=$this.qzDataA.quantityCopies;i++){
                  await qz.print(config, printData).then(function() {
                          console.log("Sent NC data to:"+$this.qzDataA.connection);
                          resolve(true)
                      });
                  }
              /////////////////////////////              
          }          
      //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%             
      }
      if($this.qzDataB.flagActive == true && $this.spDetail.FLAG_PRINTER_B =='Y' ){ 
          var config = qz.configs.create($this.qzDataB.printer);    
          /////////////////////////////
          for(i=1;i<=$this.qzDataB.quantityCopies;i++){
              await qz.print(config, printData).then(function() {
                      console.log("Sent NC data to:"+$this.qzDataB.printer);
                      resolve(true)
                  });
              }
          /////////////////////////////            
      }   
      //###########################################################
        if($this.spDetail.FLAG_PRINTER_A =='N'){
          window.open(dataFile.url, "_blank");
          resolve(true)
        }
      //###########################################################    
      resolve(true)
  });
  }
  //############################################################
  //############################################################
  async printCoupon(codOrder,codCoupon,amount){
      let $this=this;
      var printData
      return await new Promise(
        async function (resolve, reject) {
            ////////////////////////////////////////////
              //###########################################################
              if($this.qzDataA.typePrint != 'LASER' && $this.qzDataA.flagActive == true){ //typePrint
                  printData = await $this.parceThermal($this.spDetail.RETURN_COUPON,codOrder,codCoupon,amount)
                  var config = qz.configs.create($this.qzDataA.printer); 
                    await  qz.print(config, printData).then(function() {
                              console.log("Send Coupon data to:"+$this.qzDataA.printer);
                              resolve(true)
                          });
               }
               else{
                  resolve(true)
               }
              //########################################################### 
  });
  }
  //############################################################
  //############################################################
  async parceThermal(data,codOrder,codCoupon,amount){
      let $this=this;
      return await new Promise(
         function (resolve, reject) {
          //###########################################################

          var dataPrint=[];
          var txt
          if($this.qzDataA.typePrint = 'ESC/POS'){
              //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
                //head
                dataPrint.push( '\x1B' + '\x40' )
                dataPrint.push( '\x1B' + '\x61' + '\x31')  // center align
                dataPrint.push('\x0A' )
                //==
                if(data.logo64){
                  dataPrint.push( {
                                          type: 'raw',
                                          format: 'image',
                                          data: data.logo64,
                                          options: { language: "ESCPOS", dotDensity: 'double' }
                                          }
                  )
               }
               //==
               if(data.url){
                  dataPrint.push('\x0A' )
                  dataPrint.push( '\x1B' + '\x61' + '\x31')
                  dataPrint.push('\x0A' )
                  dataPrint.push(data.url + '\x0A' )
               } 
               //==
               if(data.title){ 
                  dataPrint.push('\x1B' + '\x61' + '\x31' )// center align
                  dataPrint.push('\x1B' + '\x21' + '\x30' )// em mode on
                  dataPrint.push(data.title + '\x0A' )
                  if(data.subTitle)dataPrint.push(data.subTitle+ '\x0A' )
                  dataPrint.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A') // em mode off
                  dataPrint.push(' '+ '\x0A' )
                  dataPrint.push('\x0A' )
                  dataPrint.push('\x0A' )               
               }
               //==
               // dataPrint.push( 'Donacion........................' + $this.currencyPipe.transform(dataFile.amountDonacion) + '\x0A', )// texto
               //==
                  for(let i=0;i<data.body.length;i++){
                      //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                        txt = data.body[i].txt 
                        if(data.body[i].bold        == true) dataPrint.push('\x1B' + '\x45' + '\x0D' )// bold on
                        if(data.body[i].em          == true) dataPrint.push('\x1B' + '\x21' + '\x30' )// em mode on
                        if(data.body[i].small       == true) dataPrint.push('\x1B' + '\x4D' + '\x31' )// small text
                        if(data.body[i].normal      == true) dataPrint.push('\x1B' + '\x4D' + '\x30' )// normal text
                        if(data.body[i].centerAlign == true) dataPrint.push('\x1B' + '\x61' + '\x31' )// center align
                        if(data.body[i].leftAlign   == true) dataPrint.push('\x1B' + '\x61' + '\x30' )// left align
                        if(data.body[i].rightAlign  == true) dataPrint.push('\x1B' + '\x61' + '\x32' )// right align

                        if(data.body[i].replace     == true) txt = txt.replace('%_REPLACE_COD_ORDER_%',codOrder)
                        if(data.body[i].replace     == true) txt = txt.replace('%_REPLACE_COD_COUPON_%',codCoupon)

                        dataPrint.push( txt + '\x0A', )// texto
                        if(data.body[i].bold        == true) dataPrint.push('\x1B' + '\x45' + '\x0A')// bold off
                        if(data.body[i].em          == true) dataPrint.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A' )// em mode off
                        if(data.body[i].small       == true) dataPrint.push('\x1B' + '\x4D' + '\x30')// small text off
                      //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                  }
               //==   
               dataPrint.push('\x1B' + '\x45' + '\x0D' ) // bold on
               dataPrint.push('\x1B' + '\x4D' + '\x30' )// normal text
               dataPrint.push( 'Total Cupon...................' + $this.currencyPipe.transform(amount) + '\x0A', )// texto
               
               dataPrint.push('\x1B' + '\x45' + '\x0A' ) // bold off 
               //==             
               if(data.QR){ 
                  dataPrint.push( '\x1B' + '\x61' + '\x31')  // center align
                  dataPrint.push('\x0A' )
                  dataPrint.push('\x0A' )  
                  dataPrint.push( {
                      type: 'raw',
                      format: 'image',
                      data: data.QR,
                      options: { language: "ESCPOS", dotDensity: 'double' }
                      }
                      )
                      dataPrint.push(' '+ '\x0A' )
                      dataPrint.push('\x0A' )
                      dataPrint.push('\x0A' )
               }
               //==
               dataPrint.push('\x1B' + '\x69' ) // corto papel
              //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  
          resolve(dataPrint)     
          }
          else{
              resolve(dataPrint) 
          }
          //########################################################### 
  });
  }
  //########################################################################################################################
  //########################################################################################################################
//########################################################################################################################
//########################################################################################################################  
//########################################################################################################################
//######################################################################################################################## 
//############################################################
//############################################################
/*
{
   "language":"es",
   "appToken":"5e99141d-a4ec-40b6-8f3d-3df23e018845",
   "uuidSSO":"42468202-2100-7477-4043-424682022100",
   "uuidEomOrder":"13884202-4250-4021-1745-16E4E7C4E591",
   "uuidEomOrderReturn":"0",
   "userInvoice":"14383206-6",
   "type":"USR",
   "format":"80mm",
   "dteB64":true
}
*/
async makeDteAT(uuidEomOrder,uuidEomOrderReturn,paper,codDoc){ 
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
        var tmp,typeUser,user,makeDte,data;
        var dtePrinterFast,getDte64
        dtePrinterFast = $this.spDetail.V_DATA_PRINTER_A.dtePrinterFast 
        if(codDoc)
        if(dtePrinterFast == true) {getDte64 = false;} else {getDte64 = true;}
        if(getDte64 == false){
          let tmp = await $this._functions._arrIn(environment.arrDTEFast,[codDoc])
          if(tmp == false) { 
                getDte64 = true
                paper    = 'A4'
          }
        }
        //////////////////////////////////////////// 
        try{
            tmp       = (JSON.parse(localStorage.getItem('emisorData')))
            typeUser  = tmp.usrData[0].TYPE_USER
            user      = tmp.usrData[0].USER
            data = {
                     language           :"es"
                    ,appToken           : null
                    ,uuidSSO            : null
                    ,uuidEomOrder       : uuidEomOrder
                    ,uuidEomOrderReturn : uuidEomOrderReturn
                    ,userInvoice        : user
                    ,type               : typeUser
                    ,format             : paper
                    ,dteB64             : getDte64 
                    }
            makeDte = await $this._salesPointService.makeOmsOrderDTE(data); 
            resolve(makeDte)
        }catch(e){
          resolve(makeDte)
        }
        ////////////////////////////////////////////  

      }); 
}
//############################################################
//############################################################
}
