import { AfterViewInit, ChangeDetectionStrategy, Inject,ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { debounceTime, map, merge, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CatalogService } from 'app/services/catalog.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

import { SelectCombinationComponent } from '../select-combination/select-combination.component'
// import { InventoryService } from 'app/modules/admin/apps/ecommerce/inventory/inventory.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import {ModalImgComponent} from '../modal-img/modal-img.component'
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { DetailComponent } from '../detail/detail.component'


@Component({
  selector: 'app-detail-combination',
  templateUrl: './detail-combination.component.html',
  styleUrls: ['./detail-combination.component.scss'],
  animations     : fuseAnimations
})
export class DetailCombinationComponent implements OnInit {
  @ViewChild('pagCombinationDetail' ) pagCombinationDetail : MatPaginator;

  appTokenImg = environment.appTokenImg;
  appImgURL   = environment.appImgURL;
  appImgSizeA = environment.appImgSizeA;
  appImgSizeB = environment.appImgSizeB;
  imgs: any[] = [];
  currentImg: any;
  imgSrc: any;
  imgToUpload: File;

  displayedColumnCatalogo  : string[] = ['img','SKU','price','tipo','activo','root','atributos_A','atributos_B','acc'];

  pag                     : number = 10;
  pageSizeOptions         : any    = [10, 50, 100];

  isLoadingCombination    : boolean = false;
  dataCombinationDetail   : any = [];
  tableCombinationDetail  : any = [];
  dataCombination         : any = {};
  dataCombinationImg      : any = [];
  urlImg                  : any =  environment.appImgURL + environment.appTokenImg
  familys      : any[] = [];
  searchSelect : any = '';
  uuidPsServicesCombination :string = ''
  combinationForm: UntypedFormGroup;
  countNameCharacter: number = 0;
  psCombinationProperty : any = [];
  categories            : any = [];
  groups                 : any = [];
  groupsSelected         : any = [];
  types                  : any = [];

  showButtonUpload : boolean = false
  //
  previewImageSrc:any;
  //
  psServicesCombinationProperty         : any = []
  psServicesCombinationPropertyValueAll : any = []

  psServicesCombinationPropertyFilter_A : any = []
  psServicesCombinationPropertyFilter_B : any = []

  psServicesCombinationPropertyType_A : any =''
  psServicesCombinationPropertyType_B : any =''

  psServicesCombinationPropertyValueFilter_A : any = []
  psServicesCombinationPropertyValueFilter_B : any = []

  psServicesCombinationPropertyValueColor_A : any = ''
  psServicesCombinationPropertyValueColor_B : any = ''


  arrPrimary : any = [
    {val:'Y',txt:'Primario'  ,default:false}
   ,{val:'N',txt:'Secundario',default:true}
  ]
//###########################################################  
constructor(
  private _changeDetectorRef: ChangeDetectorRef,
  private _fuseConfirmationService: FuseConfirmationService,
  private _formBuilder: UntypedFormBuilder,
  private _catalogService: CatalogService,
  private _router: Router,
  private ref: ChangeDetectorRef,
  private _dialog: MatDialog,
  private _toast: ToastrService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<DetailCombinationComponent>,
)
{
  var task1 :any= []
  task1.push(this.getAllCategories());                                  // 0
  task1.push(this.getAllGroups());                                      // 1
  task1.push(this.getAllTypes());                                       // 3
  task1.push(this.getPsServicesCombinationProperty());                  // 4

  Promise.all(task1).then(async (result) => {
    //======================================================
        this.categories = result[0].data;
        this.groups     = result[1].data;
        this.types      = result[2].data;
    //======================================================    
    }, (err) => { console.log(err); });

}
//###########################################################  
async ngOnInit(): Promise<any>{
  var tmp;
  //let tmp: any = window.location.pathname;
  //tmp = tmp.split('/')[3];
  this.uuidPsServicesCombination = this.data.uuidPsServicesCombination
  this.combinationForm = this._formBuilder.group({
                                                    uuidPsCombination:         [null],
                                                    namePsServices:            ['', Validators.required],
                                                    enabledPsServices:         [true, Validators.required],
                                                    //
                                                    uuidPsCombinationProperty_a:   ['', Validators.required],
                                                    uuidPsCombinationProperty_b:   ['0', Validators.required],
                                                    //
                                                    category:                  ['', Validators.required],
                                                    group:                     ['', Validators.required],
                                                    type:                      ['', Validators.required],                                                   

                                                  });
    //==
    var tmp2 = await this.getPsServicesCombinationProperty()
    var tmp3 = await this.getPsServicesCombinationPropertyValueAll()

    this.psServicesCombinationProperty         = tmp2.data
    this.psServicesCombinationPropertyValueAll = tmp3.data
    this.psServicesCombinationPropertyFilter_A = this.psServicesCombinationProperty;  
    //==
    //==    
  if(this.uuidPsServicesCombination !='add'){
    await this.getCombinationDetail(this.uuidPsServicesCombination)    
    await this.loadCombinationDetail();  
    this.countCharacter('name') 
  }
  else{
    this.combinationForm.get('uuidPsCombination').setValidators( []);
   // this.combinationForm.get('uuidPsCombination').setValidators( []);
 
  }
  /////////////////////////////////////////////////
  /////////////////////////////////////////////////

  }  
//###########################################################
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async getPsServicesCombinationProperty(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      let tmp = await this._catalogService.getPsServicesCombinationProperty();
      resolve({status: true, data: tmp.psCombinationProperty});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
async getPsServicesCombinationPropertyValueAll(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      let tmp = await this._catalogService.getPsServicesCombinationPropertyValueAll();
      resolve({status: true, data: tmp.psCombinationPropertyValue});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//###########################################################
async setPsAttribGroup(type,c){
  if(type == 'A'){
    if( this.combinationForm.value.uuidPsCombinationProperty_a == this.combinationForm.value.uuidPsCombinationProperty_b ){
      this.psServicesCombinationPropertyValueFilter_B = []
    }   

    this.psServicesCombinationPropertyValueFilter_A = await this.psServicesCombinationPropertyValueAll.filter(i => i.UUID_COMBINATION_PROPERTY == c.UUID_COMBINATION_PROPERTY)
    this.psServicesCombinationPropertyFilter_B      = await this.psServicesCombinationPropertyFilter_A.filter(i => i.UUID_COMBINATION_PROPERTY != c.UUID_COMBINATION_PROPERTY )    
    this.psServicesCombinationPropertyType_A = c.TYPE_COMBINATION_PROPERTY    
  
  }
  if(type == 'B'){ 
    this.psServicesCombinationPropertyValueFilter_B = this.psServicesCombinationPropertyValueAll.filter(i => i.UUID_COMBINATION_PROPERTY == c.UUID_COMBINATION_PROPERTY)
    this.psServicesCombinationPropertyType_B = c.TYPE_COMBINATION_PROPERTY    
  }
  }
//###########################################################
async setPsAttrib(type,c){
  if(type == 'A'){
    if(this.psServicesCombinationPropertyType_A == 'COLOR') this.psServicesCombinationPropertyValueColor_A = c.VALUE_COMBINATION_PROPERTY
    this.combinationForm.get('uuidPsCombinationPropertyValue_a').setValue(c.UUID_PS_COMBINATION_PROPERTY_VALUE);
  }
  if(type == 'B'){
    if(this.psServicesCombinationPropertyType_B == 'COLOR') this.psServicesCombinationPropertyValueColor_B= c.VALUE_COMBINATION_PROPERTY 
    this.combinationForm.get('uuidPsCombinationPropertyValue_b').setValue(c.UUID_PS_COMBINATION_PROPERTY_VALUE);
  }
}
//###########################################################
//###########################################################
async getCombinationDetail(uuidPsCombination): Promise<void> {
  let tmp = await this._catalogService.getPsServicesCombinationDetail(uuidPsCombination);
  this.dataCombination       = tmp.psCombination
  this.dataCombinationImg    = tmp.psCombinationImg
  ////////////////////////////////////////////
  this.combinationForm.patchValue({
                                       uuidPsCombination:           this.dataCombination.UUID_PS_COMBINATION
                                      ,namePsServices:              this.dataCombination.NAME_COMBINATION
                                      ,enabledPsServices:           this.dataCombination.VISIBLE == 'Y' ? true : false
                                      ,category:                    this.dataCombination.ID_PS_CATEGORY
                                      ,group:                       this.dataCombination.ID_PS_GROUP
                                      ,type:                        this.dataCombination.ID_PS_TYPE      
                                      ,uuidPsCombinationProperty_a: this.dataCombination.UUID_COMBINATION_PROPERTY_A
                                      ,uuidPsCombinationProperty_b: this.dataCombination.UUID_COMBINATION_PROPERTY_B                                
      });
  ////////////////////////////////////////////
  this.setGroup(this.dataCombination.ID_PS_CATEGORY)
  await this.setPsAttribGroup('A',{UUID_COMBINATION_PROPERTY:this.dataCombination.UUID_COMBINATION_PROPERTY_A ,TYPE_COMBINATION_PROPERTY:this.dataCombination.TYPE_COMBINATION_PROPERTY_A })
  await this.setPsAttribGroup('B',{UUID_COMBINATION_PROPERTY:this.dataCombination.UUID_COMBINATION_PROPERTY_B ,TYPE_COMBINATION_PROPERTY:this.dataCombination.TYPE_COMBINATION_PROPERTY_B })
  //==
  this.dataCombinationDetail = tmp.psCombinationDetail
  if(this.dataCombinationDetail.length > 0){
    this.combinationForm.get('category').disable();
    this.combinationForm.get('group').disable();
    this.combinationForm.get('type').disable();
    this.combinationForm.get('uuidPsCombinationProperty_a').disable();
    this.combinationForm.get('uuidPsCombinationProperty_b').disable();
  }
  //==  
////////////////////////////////////////////  
}
//###########################################################
setGroup(idPsCategory){
  var chk
    this.groupsSelected =  this.groups.filter(item => item.ID_PS_CATEGORY == idPsCategory)
    if( this.groupsSelected.length == 0){
      this.combinationForm.get('group').setValue(null);
      this.combinationForm.get('group').disable();
    }
    else{
      this.combinationForm.get('group').enable();
      chk = this.groupsSelected.filter(item => item.ID_PS_GROUP == this.dataCombination.ID_PS_GROUP)
      if(chk.length > 0){}
      else{
        this.combinationForm.get('group').setValue(null);
      }
    }
      
  
  //this.infoForm.get('category').disable();
  
  }
//###########################################################
async loadCombinationDetail():Promise<any>{
  this.isLoadingCombination=true; 
  this.tableCombinationDetail =  new MatTableDataSource(this.dataCombinationDetail);
  this.ref.detectChanges();
  this.tableCombinationDetail.paginator = this.pagCombinationDetail;
  this.isLoadingCombination=false; 
}  
//###########################################################
async resetFilters():Promise<any>{
  this.searchSelect ='';
  await this.getCombinationDetail(this.uuidPsServicesCombination)
  await this.loadCombinationDetail()
}
//###########################################################
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.tableCombinationDetail.filter  = filterValue.trim().toLowerCase();
  this.ref.detectChanges();
}
//###########################################################
filterByFamily() {
  let a = this.dataCombination.filter(item => item.COD_PS_GROUP == this.searchSelect)  
  //////////////////////////////////////////
  this.isLoadingCombination=true; 
  this.tableCombinationDetail =  new MatTableDataSource(a);
  this.ref.detectChanges();
  this.tableCombinationDetail.paginator = this.pagCombinationDetail;
  this.isLoadingCombination=false; 
  //////////////////////////////////////////
  this.ref.detectChanges();
}
//###########################################################
btnBack(){
  this._router.navigateByUrl('catalogos/combination');
}
//###########################################################
countCharacter(field: string){
  // //(field)
if(field == 'name'){
    this.countNameCharacter = this.combinationForm.value.namePsServices.length;
  }
}
//###########################################################
  async addPS(): Promise<any>{
    this._dialog.open(SelectCombinationComponent, {
      width: '100%',
      height: '100%',
      data: {
         dataCombination            : this.dataCombination
        ,psCombinationProperty      : this.psCombinationProperty      
        ,codPsCategory              : this.dataCombination.COD_PS_CATEGORY
        ,codPsGroup                 : this.dataCombination.COD_PS_GROUP  
        ,codPsType                  : this.dataCombination.COD_PS_TYPE       
        ,dataCombinationDetail      : this.dataCombinationDetail    
      }
    }).afterClosed().subscribe( async (res: any) => {
        // //(res)
        if(res){
          await this.addNewPs(res.data); 
          await this.ngOnInit();
        }
      });
}
//##########################################################
async addNewPs(data){
var arr = {
  appToken                : null
 ,language                : 'es'
 ,uuidSSO                 : null
 ,uuidPsCombination       : this.dataCombination.UUID_PS_COMBINATION
 ,combinationDetail       : [{
                                uuidPsCombinationDetail:'0',uuidPsServices : data.UUID_PS_SERVICES,flagPrimary:'N',action:"ADD"
                              }]
 }
let addPS = await this._catalogService.postPsServicesCombinationDetail(arr)
}
//###########################################################
delPS(o){
  this._fuseConfirmationService.open({
    title: `Eliminar Producto <b>${o.NAME_PS_SERVICES}</b>`,
    message: `¿Está seguro de eliminar Combinación?`,
    icon: {
      name: 'delete',
      color: 'warn'
    },
    actions: {
      confirm: {
        label: 'Eliminar',
      },
      cancel: {
        label: 'Cancelar',
      }
    }
  }).afterClosed().subscribe(
    async (res: any) => {
      // //(res)
      if(res === 'confirmed'){
          await this.delNewPs(o)
      }
    }
  );
}
//###########################################################
async delNewPs(o){
  var arr = {
    appToken                : null
   ,language                : 'es'
   ,uuidSSO                 : null
   ,uuidPsCombination       : this.dataCombination.UUID_PS_COMBINATION
   ,combinationDetail       : [{
                                  uuidPsCombinationDetail:o.UUID_PS_COMBINATION_DETAIL,uuidPsServices : o.UUID_PS_SERVICES,flagPrimary:'N',action:"DEL"
                                }]
   }
  let delPS = await this._catalogService.postPsServicesCombinationDetail(arr)
  await this.ngOnInit();
  
  }
//###########################################################
async addCombination(type){
  var arr = {
    appToken                         : null
   ,language                         : 'es'
   ,uuidSSO                          : null
  ,idPsGroup                         : this.combinationForm.value.group
  ,idPsCategory                      : this.combinationForm.value.category
  ,idPsType                          : this.combinationForm.value.type
  ,namePsServices                    : this.combinationForm.value.namePsServices
  ,visible                           : this.combinationForm.value.enabledPsServices ? 'Y' : 'N'
  ,action                            : type
  //,uuidPsCombination                 : this.dataCombination.UUID_PS_COMBINATION        
  ,uuidPsCombinationProperty_a       : this.combinationForm.value.uuidPsCombinationProperty_a      
  ,uuidPsCombinationProperty_b       : this.combinationForm.value.uuidPsCombinationProperty_b      

   }
  let addPS = await this._catalogService.postPsServicesCombination(arr)
  this.closeBtn()
  }
//########################################################### 
async udpCombination(type){
this.combinationForm.get('category').enable();
this.combinationForm.get('group').enable();
this.combinationForm.get('type').enable(); 
this.combinationForm.get('uuidPsCombinationProperty_a').enable();
this.combinationForm.get('uuidPsCombinationProperty_b').enable();


  var arr = {
    appToken              : null
   ,language              : 'es'
   ,uuidSSO               : null
  ,idPsGroup              : this.combinationForm.value.group
  ,idPsCategory           : this.combinationForm.value.category
  ,idPsType               : this.combinationForm.value.type
  ,namePsServices         : this.combinationForm.value.namePsServices
  ,visible                : this.combinationForm.value.enabledPsServices ? 'Y' : 'N'
  ,action                 : type
  ,uuidPsCombination      : this.dataCombination.UUID_PS_COMBINATION   
  ,uuidPsCombinationProperty_a       : this.combinationForm.value.uuidPsCombinationProperty_a      
  ,uuidPsCombinationProperty_b       : this.combinationForm.value.uuidPsCombinationProperty_b        
   
   }
  let addPS = await this._catalogService.postPsServicesCombination(arr)
  await this.getCombinationDetail(this.uuidPsServicesCombination)    
  await this.loadCombinationDetail();  
  }
//###########################################################
async setRoot(o){
  this._fuseConfirmationService.open({
    title: `Establecer como principal Producto <b>${o.NAME_PS_SERVICES}</b>`,
    message: `¿Cambiara el producto principal de la combinación?`,
    icon: {

      name: 'shopping_cart',
       color: 'success'
   },
   actions: {
     confirm: {
       label: 'Agregar',
       color: 'primary'
     },
     cancel: {
       show : true,
       label: 'Cancelar',
     }
    }
  }).afterClosed().subscribe(
    async (res: any) => {
      // //(res)
      if(res === 'confirmed'){
        //==================================================
        var arr = {
          appToken                : null
         ,language                : 'es'
         ,uuidSSO                 : null
         ,uuidPsCombination       : this.dataCombination.UUID_PS_COMBINATION
         ,combinationDetail       : [{
                                        uuidPsCombinationDetail:o.UUID_PS_COMBINATION_DETAIL,uuidPsServices : o.UUID_PS_SERVICES,flagPrimary:'Y',action:"UDP"
                                      }]
         }
        let delPS = await this._catalogService.postPsServicesCombinationDetail(arr)
        await this.ngOnInit();
        //==================================================
      }
    }
  );


}
//###########################################################
  async getAllTypes(): Promise<any> {
    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const resp = await $this._catalogService.getTypes();
        resolve({status: true, data: resp.data});
      } catch (err) {
        reject({status: false, error: err});
      }
    });
  }
//###########################################################
//###########################################################
async getAllCategories(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      const resp = await $this._catalogService.getCategorias();
      resolve({status: true, data: resp.data});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
}
//###########################################################
//###########################################################
async getAllGroups(): Promise<any>{
  const $this = this;
  return await new Promise(async (resolve, reject) => {
    try {
      const resp = await $this._catalogService.getFamilys();
      resolve({status: true, data: resp.data});
    } catch (err) {
      reject({status: false, error: err});
    }
  });
};
//###########################################################
//###########################################################
  /////////////////////////////////////////////////////////////////////////////
  async openImg(type:string,urlImg:string, uuidImg: string,imgB64 : string): Promise<any>{
    const dialogRef = this._dialog.open(ModalImgComponent, {
              height:'100%'
              ,width: '100%'
              ,data: {
                         type      : type
                        ,urlImg    : urlImg
                        ,uuidImg   : uuidImg
                        ,imgB64    : imgB64
                        ,imgHeight : '800px'
                        ,imgWidth  : '600px'
                        ,rotate    : null
                        ,title     : this.dataCombination.NAME_PS_SERVICES
              }
          });
          dialogRef.afterClosed().subscribe(async (result) => {
              //this.ngOnInit();
          });
  }


/////////////////////////////////////////////////////////////////////////////
//###########################################################
//////////////////////////////////////////////////
//////////////////////////////////////////////////
closeBtn(){
  this.dialogRef.close({status:true});
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//########################################################### 
//########################################################### 
async editOrCreateProduct(uuidPsServices: string): Promise<void> {

  var  dummy : Event; 
 const dialogRef = this._dialog.open(DetailComponent, {
 
   width: '100%',
   height: '100%',
     //autoFocus: false,
     data: {
         customer: null,
         type: 'view',
         tab:"customer",
         source: 'modal',
         uuidPsServices : uuidPsServices
     }
 });
 dialogRef.afterClosed().subscribe(async (result) => {
  //
  await this.getCombinationDetail(this.uuidPsServicesCombination)    
  await this.loadCombinationDetail();  
  this.countCharacter('name') 
  //
 });
}
//########################################################### 
//########################################################### 
}