import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { SharedModule } from 'app/shared/shared.module';

// ANGULAR MATERIAL
// FUSE
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { NgxBarcodeModule } from '@greatcloak/ngx-barcode';
// ANGULAR MATERIAL
import { MatSidenavModule } from '@angular/material/sidenav';
import { FuseFindByKeyPipeModule } from '@fuse/pipes/find-by-key';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatListModule } from '@angular/material/list';

@NgModule({
    declarations: [
        NotificationsComponent,
        
    ],
    imports     : [
        RouterModule,
        OverlayModule,
        PortalModule,
        SharedModule,
            // FUSE
    FuseConfirmationModule,

    // ANGULAR MATERIAL
    MatTabsModule,
    MatCardModule,
    MatDatepickerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTooltipModule,
    MatSortModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatChipsModule,
    MatAutocompleteModule,
    DragDropModule,
    MatCardModule,
    MatToolbarModule,
    MatStepperModule,
    MatAutocompleteModule,  
    NgxMatSelectSearchModule,
    MatSidenavModule,
    MatListModule
    ], 
    exports     : [
        NotificationsComponent
    ]
})
export class NotificationsModule
{
}
