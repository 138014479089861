import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { defaultNavigation} from 'app/mock-api/common/navigation/data';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root' 
})
export class NavigationMockApi {   
    // customNavigation: FuseNavigationItem[] = [];
    // private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private  _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    // private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    // private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _fuseMockApiService: FuseMockApiService,
        private _userService: UserService,
        private _router: Router,
        )
    {
         this.getNavigation();
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    async getNavigation(appToken?:string,uuidEmployee?:string): Promise<any> {
        //////////////////////////////////
        var _appToken,_uuidEmployee
        _appToken     = localStorage.getItem('appToken');
        _uuidEmployee = localStorage.getItem('uuidEmployee');
        //////////////////////////////////
        appToken     = appToken     || _appToken
        uuidEmployee = uuidEmployee || _uuidEmployee
        //////////////////////////////////
        const { menu } = await this._userService.getMenu(appToken,uuidEmployee);
        this._defaultNavigation = menu;
    }

    /**
     * Register Mock API handlers
     */
    async  registerHandlers(appToken?:string,uuidEmployee?:string): Promise<any> {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply ( ()  => {

                // Fill compact navigation children using the default navigation
                // this._compactNavigation.forEach((compactNavItem) => {
                //     this._defaultNavigation.forEach((defaultNavItem) => {
                //         if ( defaultNavItem.id === compactNavItem.id )
                //         {
                //             compactNavItem.children = cloneDeep(defaultNavItem.children);
                //         }
                //     });
                // });

                // Fill futuristic navigation children using the default navigation
                // this._futuristicNavigation.forEach((futuristicNavItem) => {
                //     this._defaultNavigation.forEach((defaultNavItem) => { 
                //         if ( defaultNavItem.id === futuristicNavItem.id )
                //         {
                //             futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                //         }
                //     });
                // });

                // Fill horizontal navigation children using the default navigation
                // this._horizontalNavigation.forEach((horizontalNavItem) => {
                //     this._defaultNavigation.forEach((defaultNavItem) => {
                //         if ( defaultNavItem.id === horizontalNavItem.id )
                //         {
                //             horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                //         }
                //     });
                // });

                // Return the response
                const $this = this;
                try{
                if(this._defaultNavigation.length == 0 || this._defaultNavigation == undefined  ){ 
                    //appToken    = localStorage.getItem('appToken');
                    //uuidEmployee= localStorage.getItem('uuidEmployee');
                    this.getNavigation(appToken,uuidEmployee).then((value) => {
                        return [
                            200,
                            {
                                // compact   : cloneDeep(this._compactNavigation),
                                default   : cloneDeep(this._defaultNavigation)
                                // futuristic: cloneDeep(this._futuristicNavigation),
                                // horizontal: cloneDeep(this._horizontalNavigation)
                            }
                        ];
                        // Expected output: "Success!"
                      });

                    //##
                }
                else{
                        //

                        return [
                            200,
                            {
                                // compact   : cloneDeep(this._compactNavigation),
                                default   : cloneDeep(this._defaultNavigation)
                                // futuristic: cloneDeep(this._futuristicNavigation),
                                // horizontal: cloneDeep(this._horizontalNavigation)
                            }
                        ];

                        //
                }
                }
                catch(e){
                    const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/sign-out';
                    localStorage.removeItem('A1');
                    localStorage.removeItem('A2');
                    localStorage.removeItem('A3');
                    localStorage.removeItem('A4');
                    this._router.navigateByUrl(redirectURL);                   
                }

            });
            
    }
}
