import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CatalogService } from 'app/services/catalog.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { split } from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
import { DetailComponent } from '../detail/detail.component'
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
  selector: 'app-select-combination',
  templateUrl: './select-combination.component.html',
  styleUrls: ['./select-combination.component.scss']
})
export class SelectCombinationComponent implements OnInit {
  @ViewChild('pagPS') pagPS  : MatPaginator;
  
  psColums        : string[] = ['img', 'SKU', 'namePS','price','combinacion_A','atributos_A','combinacion_B','atributos_B','acc'];
  isLoadingPs     : boolean = false;
  psData          : any     = [];
  psTable         : any     = [];
  psPag           : number  = 10;
  pageSizeOptions : any     = [10, 50, 100];


  appTokenImg = environment.appTokenImg;
  appImgURL   = environment.appImgURL;
  appImgSizeA = environment.appImgSizeA;
  appImgSizeB = environment.appImgSizeB;

  glsMovements : string = '';
  tmpAddInventory :any = []    


  searchInput: any;
  searchSelect: any = '';
  selectedReasonCodes :any ;

  psCombinationProperty      : any = [];
  psCombinationPropertyValue : any = [];
  dataCombination            : any = [];
  dataCombinationSelect      : any = [];
  arrCombinationDetail       : any = [];


  arrCombinationSelect: any = [];
//////////////////////////////////////////////////
//////////////////////////////////////////////////
  constructor(
    public dialog: MatDialog,
    public _dialogRef: MatDialogRef<SelectCombinationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _catalogService: CatalogService,
    private ref: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _fuseConfirmationService: FuseConfirmationService,
  ) { }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
  async ngOnInit(): Promise<any> {
    //####################################################
    await this.getPs();
    await this.loadPs();
    //////////////////////////////
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface, @typescript-eslint/explicit-function-return-type
  ngAfterViewInit(): void {

  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
addCombinationDetail(o)
{
  var msg='',msg2='';
  var flagOK=true;

if(this.data.dataCombination.UUID_COMBINATION_PROPERTY_A != o.UUID_COMBINATION_PROPERTY_A){
  msg= `Atributo del producto no es igual al de la combinación `
  msg2= `Combinación:<b>${this.data.dataCombination.NAME_COMBINATION_PROPERTY_A}</><br>Producto:<b>${o.NAME_COMBINATION_PROPERTY_A}</b> `
  flagOK = false
}
if(this.data.dataCombination.UUID_COMBINATION_PROPERTY_B != o.UUID_COMBINATION_PROPERTY_B){
  msg= msg + `Atributo del producto no es igual al de la combinación`
  msg2= msg2+ `Combinación:<b>${this.data.dataCombination.NAME_COMBINATION_PROPERTY_B}</b><br>Producto:<b>${o.NAME_COMBINATION_PROPERTY_B}</b> `
  flagOK = false
}
if(flagOK == false){
  //==================================================
    this._fuseConfirmationService.open({
      title: `${msg}`,
      message: `${msg2}`,
      icon: {
        show: true,
        name: 'delete',
        color: 'warn'
      },
      actions: {
        confirm: {
            label: 'Aceptar', 
            color: 'warn'
        },
        cancel: {
          show : false,
          label: 'Cancelar',
        }
      }
    }).afterClosed().subscribe(
      async (res: any) => {
        // //(res)
        if(res === 'confirmed'){
          //#########################################################################
          //this._dialogRef.close({reload:true,data:o});
          //#########################################################################
        }
      }
    );  
  //==================================================  
}else{
  //==================================================
  this._fuseConfirmationService.open({
    title: `Agregar Producto a combinación <b>${o.NAME_PS_SERVICES}</b>`,
    message: `Combinación: <b>${o.NAME_COMBINATION_PROPERTY_A}/${o.NAME_COMBINATION_PROPERTY_B}</b>`,
    icon: {

       name: 'shopping_cart',
        color: 'success'
    },
    actions: {
      confirm: {
        label: 'Agregar',
        color: 'primary'
      },
      cancel: {
        show : true,
        label: 'Cancelar',
      }
    }
  }).afterClosed().subscribe(
    async (res: any) => {
      // //(res)
      if(res === 'confirmed'){
        //#########################################################################
        this._dialogRef.close({reload:true,data:o});
        //#########################################################################
      }
    }
  );  
  //==================================================    
}



}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
async getPs(): Promise<any> {
  let tmp = await this._catalogService.psCombinationPs(this.data.dataCombination.UUID_PS_COMBINATION,this.data.codPsGroup,this.data.dataCombination.ID_PS_TYPE     )
  this.psData = tmp
}  
//////////////////////////////////////////////////
//////////////////////////////////////////////////
closeBtn(){
  // Unsuscribe to all observables
  this._dialogRef.close({reload:false,data:null});
};
///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
async loadPs(): Promise<any>{
  this.isLoadingPs=true; 
  this.psTable =  new MatTableDataSource(this.psData);
  this.ref.detectChanges();
  this.psTable.paginator = this.pagPS;
  this.isLoadingPs=false; 
};
//////////////////////////////////////////////////
//////////////////////////////////////////////////
closeAndAdd(): void{

this._dialogRef.close(this.arrCombinationDetail);
}
//////////////////////////////////////////////////
//////////////////////////////////////////////////
  filterByFamily(): void{
    this.psTable.filter = this.searchSelect;
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
  applyFilter(event: Event) {
    if(event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.psTable.filter = filterValue.trim().toLowerCase();
    }
  }
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//########################################################### 
//########################################################### 
async editOrCreateProduct(uuidPsServices: string): Promise<void> {

  var  dummy : Event; 
 const dialogRef = this._dialog.open(DetailComponent, {
 
   width: '100%',
   height: '100%',
     //autoFocus: false,
     data: {
         customer: null,
         type: 'view',
         tab:"customer",
         source: 'modal',
         uuidPsServices : uuidPsServices
     }
 });
 dialogRef.afterClosed().subscribe(async (result) => {
  //
  await this.getPs();
  await this.loadPs();
  //
 });
}
//########################################################### 
//########################################################### 

}
