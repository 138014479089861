<div class="w-full bg-gray-400">
    <!-- <div class="p-1"></div> -->
    <div class="p-2">
        <mat-card>
            <mat-card-content>
                <div class="">

                    <div class="flex">
                        <h1 class="prose prose-xl"> Orden: <b>{{orderDetail.order.FRIENLY_EOM_ORDER}}</b>&nbsp;</h1>
                        <p class="prose prose-xl" > Estado:<b>{{orderDetail.order.DESC_COD_REASON_CODE}}</b></p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button mat-button (click)="close()"> <mat-icon>close</mat-icon></button>

                    </div>

                </div> 
            </mat-card-content>
        </mat-card>
    </div>
    <div class="grid grid-cols-4 gap-4 px-2">
        <mat-card>
            <mat-card-content>
                <div>
                    <div class="flex" *ngIf="spListEmployee.length >1 " >
                        <mat-form-field class="w-3/4">
                            <mat-label> Punto de Venta </mat-label>
                            <mat-select (selectionChange)="setSpEmployee($event.value)" [(ngModel)]="uuidSpEmployee">
                                <ng-container *ngFor="let sp of spListEmployee">
                                    <mat-option [value]="sp.UUID_SP">
                                        {{sp.COD_SP}} - {{sp.NAME_SP}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div  *ngIf="spListEmployee.length == 1 " class="flex" >
                        Punto de Venta<br> 
                        {{spListEmployee[0].COD_SP}} - {{spListEmployee[0].NAME_SP}}
                    </div> 
                </div>
                <div  *ngIf="true" class="flex" >
                    <mat-form-field class="w-3/4" >
                        <mat-label> Bodega</mat-label>
                        <mat-select 
                            (selectionChange)="setSpWarehouse($event.value)" 
                            [(ngModel)]="selectedWarehouse"
                            [disabled]="!spWarehouse.length > 1"
                            >
                            <ng-container *ngFor="let wm of spWarehouse">
                                <mat-option [value]="wm">
                                    {{wm.COD_WAREHOUSE}} - {{wm.NAME_WAREHOUSE}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>

                <div>
                    <span *ngIf="receptor.RUT != ''" style="color: rgb(18, 2, 140)"><b>Cliente</b></span>
                    <span *ngIf="receptor.RUT == ''" style="color: red"><b>Selecionar Cliente</b></span>                    
                    <mat-form-field class="w-full">
                        <input matInput type="text"  [disabled]="true" [(ngModel)]="receptor.RUT">
                        <mat-icon matPrefix> person </mat-icon>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <input matInput type="text"  [disabled]="true" [(ngModel)]="receptor.RAZON_SOCIAL">
                        <mat-icon matPrefix> person </mat-icon>
                    </mat-form-field>  
                    <div>
                        <!--
                        <button *ngIf="flagReceptor == true" class="ml-5 bottom-4" color="primary" type="button" mat-raised-button (click)="openSearchReceptor()">
                            <mat-icon> search </mat-icon> 
                        </button>
                        -->
                        <!--/////////////////////////////////////////////////////////////-->
                        <button *ngIf="flagReceptor == true" class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="editOrCreateCliente('add')"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'add'"></mat-icon> </button>
                        <button *ngIf="flagReceptor == true" class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="showSupplier()"> <mat-icon class="icon-size-5" color="primary" [svgIcon]="'search'"></mat-icon> </button>
                        <button *ngIf="flagReceptor == true" class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button (click)="defaultReceptor()"> <mat-icon class="icon-size-5" color="warn" [svgIcon]="'refresh'"></mat-icon> </button>
                        <!--/////////////////////////////////////////////////////////////-->
                  
                    </div>                        

                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="col-span-2">
            <mat-card-content>
                <div>  
                    <b> Forma de Devolución: </b>
                    <span class="w-1/2 prose prose-xl font-bold" *ngIf=" typeReturn == 'C'">Cupón de Compra</span>
                    <span class="w-1/2 prose prose-xl font-bold" *ngIf=" typeReturn == 'E'">Efectivo</span>
                    <span class="w-1/2 prose prose-xl font-bold" *ngIf=" typeReturn == 'O'">Medio de Pago de la orden</span>
                    </div>
                <div  *ngIf="spWarehouse.length == 1 " class="flex col-span-2" >
                    Bodega<br>
                    {{selectedWarehouse.COD_WAREHOUSE}} - {{selectedWarehouse.NAME_WAREHOUSE}}
                </div>
                <div>
                    <div  *ngIf="reasonReturn.length >1 " class="flex" >
                        <mat-form-field class="w-3/4">
                            <mat-label> 
                                <span *ngIf="reasonReturnSelected != null" style="color: rgb(18, 2, 140)"><b>Motivo Devolución</b></span>
                                <span *ngIf="reasonReturnSelected == null" style="color: red"><b>Motivo Devolución</b></span>
                            </mat-label>
                            <mat-select [(ngModel)]="reasonReturnSelected" required>
                                <ng-container *ngFor="let wm of reasonReturn">
                                    <mat-option [value]="wm">
                                        {{wm.COD_REASON_CODE}} - {{wm.DESC_COD_REASON_CODE}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>

                        </mat-form-field>
                    </div>
                    <!--
                    <div  *ngIf="reasonWareHouse.length >1 " class="flex" >
                        <mat-form-field class="w-3/4">
                            <mat-label> Estado Mercaderias:</mat-label>
                            <mat-select [(ngModel)]="reasonWareHouseSelected">
                                <ng-container *ngFor="let wm of reasonWareHouse">
                                    <mat-option [value]="wm">
                                        {{wm.COD_REASON_CODE}} - {{wm.DESC_COD_REASON_CODE}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                -->
                <div *ngIf="flagActiva==true">
                    <h3 class="w-1/2 prose prose-xl font-bold"><input type="checkbox" (change)="restore()" [(disabled)]="ctrlAnula"  [(ngModel)]="flagAnula" >Anula Venta </h3> 
                </div>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="w-full py-2 px-1">
        <mat-card>
            <mat-card-content>
                <div class="flex flex-auto overflow-hidden">
                    <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
                    <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
                    <div class="flex flex-auto overflow-hidden">
                        <!-- Products list -->
                        <div class="flex flex-col flex-auto sm:mb-10 overflow-hidden sm:overflow-y-auto">
                            <ng-container *ngIf="orderDetail.orderDetail as products ;let index = index;">
                                <ng-container *ngIf="products.length > 0; else noProducts">
        
                                    <div class="grid">
                                        <!-- Header -->
                                        <div
                                            class="inventory-grid z-9 sticky top-0 grid gap-4 py-4 px-6 md:px-7 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                            matSort
                                            matSortDisableClear>
                                            <!-- <div>
        
                                        </div>
                                        <div
                                        class="hidden md:block"
                                        [mat-sort-header]="'COD_WAREHOUSE'">
                                        ID
                                    </div> -->
                                            <div
                                                class="hidden md:block"
                                                [mat-sort-header]="'COD_PS_SERVICES'">
                                                SKU
                                            </div>
        
                                            <div class="hidden sm:block" [mat-sort-header]="'NAME_PS_SERVICES'">
                                                Nombre
                                            </div>
                                            <div class="hidden sm:block" [mat-sort-header]="'NAME_PS_SERVICES'">
                                                Estado Mercaderias
                                            </div>                                            
                                            <div
                                                class="hidden sm:block"
                                                [mat-sort-header]="'NETO'">
                                                NETO 
                                            </div>
                                   
                                            <div
                                                class="hidden lg:block"
                                                [mat-sort-header]="'QUANTITY_PS_SERVICES'">
                                                Cantidad
                                            </div>
                                            <div class="hidden sm:block">SubTotal</div>
        
                                        </div>
                                        <!-- Rows -->
                                        <ng-container *ngIf="orderDetail.orderDetail  as products; else noProducts">
                                            <ng-container *ngFor="let product of orderDetail.orderDetail; ; let i = index   ">
                                                <div class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">
                                                    <!-- SKU -->
                                                    <div class="hidden md:block truncate">
                                                        {{product.COD_PS_SERVICES}}
                                                    </div>
                
                                                    <!-- Name -->
                                                    <div class="truncate">
                                                        <b>{{product.NAME_PS_SERVICES}}</b> 
                                                        <br>{{product.COD_WAREHOUSE}}-{{product.NAME_WAREHOUSE}}
                                                    </div>
                                                    <!-- Name <div *ngIf="flagAnula == false">  <mat-select [(ngModel)]="product.COD_REASON_CODE" [disabled]="flagAnula"  (selectionChange)="setReasonWareHouse(product,inputWH.value)" #inputWH>-->
                                                            <div >
                                                                <div  *ngIf="reasonWareHouse.length >1 " class="flex" >
                                                                    <mat-form-field class="w-3/4">
                                                                        <mat-select [(ngModel)]="product.COD_REASON_CODE"  (selectionChange)="setReasonWareHouse(product,inputWH.value)" #inputWH [disabled]="product.COD_WAREHOUSE_TYPE=='U'">
                                                                            <ng-container *ngFor="let wm of reasonWareHouse">
                                                                                <mat-option [value]="wm.COD_REASON_CODE">
                                                                                    {{wm.COD_REASON_CODE}} - {{wm.DESC_COD_REASON_CODE}}
                                                                                </mat-option>
                                                                            </ng-container>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                            <!-- 
                                                            <div *ngIf="flagAnula == true"> 
                                                                {{reasonReturnDefault.COD_REASON_CODE}} - {{reasonReturnDefault.DESC_COD_REASON_CODE}}
                                                            </div>
                                                            -->
                                                    <!-- Price -->
                                                    <div class="hidden sm:block">
                                                        <div *ngIf="orderDetail.order.COD_DOC != 110"> {{product.NETO | currency:'CLP':'symbol-narrow'}}</div>
                                                        <div *ngIf="orderDetail.order.COD_DOC == 110"> {{(product.NETO / TcCLP) | number : '1.2-2'}}</div>
                                                       
                                                    </div>
                                                    <!-- Active (change)="addToCart(product,inputQTY.value)" -->
                                                    <div class="hidden lg:block" *ngIf="flagAnula == false">
                                                            <mat-form-field class="w-2/3">
                                                                <!-- ///////////////////////////////////////////////////////////////////////   -->
                                                                <mat-select   (selectionChange)="subTotal(product,inputQTY.value)" #inputQTY [disabled]="product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE=='0'" [value]="product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE">
                                                                    <ng-container *ngFor="let q of [].constructor(product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE+1); let i = index">
                                                                        <mat-option [value]="i">  
                                                                        <!--   <mat-option value="0">    -->
                                                                            {{i}}
                                                                        </mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                            <!-- ///////////////////////////////////////////////////////////////////////   -->                                                               
                                                                <!-- 
                                                                <input
                                                                matInput
                                                                #inputQTY
                                                                type="number"
                                                                [(value)]="product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE"
                                                                min="0"  [value]="product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE"
                                                                [max]="product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE"
                                                                (change)="subTotal(product,inputQTY.value)"
                                                                [disabled]="flagAnula"> 
-->
                                                        </mat-form-field>
                                                                <!-- ///////////////////////////////////////////////////////////////////////   -->
                                                                
                                                        
                                                            /{{product.QUANTITY_PS_SERVICES}}
                                                            <!--
                                                            <mat-form-field class="w-2/3">
                                                                <input
                                                                matInput
                                                                #inputQTY
                                                                type="number"
                                                                [(value)]="product.QUANTITY_PS_SERVICES"
                                                                [disabled]="true">
                                                            </mat-form-field>
                                                         -->
                                                    </div>
                                                    <div class="hidden lg:block" *ngIf=" flagAnula == true">
                                                        {{product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE}}/{{product.QUANTITY_PS_SERVICES}}
                                                    </div>
                                                    <!-- Total (click)="delToCart(product.NroLinDet)" -->
                                                    <div>
                                                       
                                                       <div *ngIf="orderDetail.order.COD_DOC != 110"><b>{{product.TOTAL | currency:'CLP':'symbol-narrow'}} </b></div>
                                                       <div *ngIf="orderDetail.order.COD_DOC == 110"> {{(product.TOTAL / TcCLP) | number : '1.2-2'}}</div>
                                                     <!--   {{product.NETO * product.QUANTITY_PS_SERVICES_RETURN_AVAILABLE | currency:'CLP':'symbol-narrow'}} --> 
                                                    </div>
                                                </div>
                                                <div class="grid">
                                                    <!-- 
                                                    <ng-container *ngIf="selectedProduct?.COD_PS_SERVICES === product.COD_PS_SERVICES">
                                                        <ng-container *ngTemplateOutlet="rowDetailsTemplate; context: {$implicit: product}"></ng-container>
                                                    </ng-container>
                                                    -->
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- <ng-component *ngIf="errorProducts">
                                <h1>HOOLA</h1>
                            </ng-component> -->
                            <ng-template #noProducts>
                                <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center"></div>
                            </ng-template>
                
                        </div>
                    </div>
                    <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
                    <!--%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
                </div>
                <div class="grid justify-items-end">
                    
                    <div class="">
                        <div class="flex">
                            <h1 > <b>Totales</b></h1>
                        </div>
                        <div class="flex">
                            <h1 > Articulos: <b>{{summary.quantity}}</b>/Productos: <b>{{summary.products}}</b></h1>
                        </div>

                        <!-- /////////////-->
                        <section *ngIf="orderDetail.order.COD_DOC != 110 && orderDetail.order.COD_DOC != 5001" class="grid grid-cols-2 justify-items-end" >
                            <h1>  Total Neto: </h1>
                            <h3 class="slashed-zero lining-nums">  {{totales.MntNeto | currency:'CLP':'symbol-narrow'}}</h3>
                        </section>
                        <section class="grid grid-cols-2 justify-items-end" *ngIf="orderDetail.order.COD_DOC != 5001">
                            <h1 *ngIf="orderDetail.order.COD_DOC != 110"> Total Exento : </h1>
                            <h1 *ngIf="orderDetail.order.COD_DOC == 110"> Total Exento <small>{{glsCurrency}}</small> : </h1>
                            <h3 *ngIf="orderDetail.order.COD_DOC != 110" class="slashed-zero lining-nums">  {{totales.MntExe | currency:'CLP':'symbol-narrow'}}</h3>
                            <h3 *ngIf="orderDetail.order.COD_DOC == 110" class="slashed-zero lining-nums">  {{(totales.MntExe / TcCLP) | number : '1.2-2'}}</h3>
                        </section>
                        <section *ngIf="orderDetail.order.COD_DOC != 110 && orderDetail.order.COD_DOC != 5001" class="grid grid-cols-2 justify-items-end" >
                            <h1> IVA({{ivaP}}%): </h1>
                            <h3 class="slashed-zero lining-nums">  {{totales.IVA | currency:'CLP':'symbol-narrow'}} </h3>
                        </section>
                        <!--
                        <section class="grid grid-cols-2 justify-items-end">
                            <h1> Monto Total: </h1>
                            <h3 class="slashed-zero lining-nums">  {{(totales.MntNeto+totales.MntExe+totales.IVA) | currency:'CLP':'symbol-narrow'}} </h3>
                        </section>   
                        -->
                        <!-- 
                        <section class="grid grid-cols-2 justify-items-end">
                            <h1> Descuento Total: </h1>
                            <h3 class="slashed-zero lining-nums">  {{totales.descTotal | currency:'CLP':'symbol-narrow'}} </h3>
                        </section>
                        -->
                        <section class="grid grid-cols-2 justify-items-end" *ngIf="orderDetail.order.COD_DOC != 110 && orderDetail.order.COD_DOC != 5001">
                            <h1> Descuento Neto: </h1>
                            <h3 class="slashed-zero lining-nums">  {{totales.descPror | currency:'CLP':'symbol-narrow'}} </h3>
                        </section>   
                        <section class="grid grid-cols-2 justify-items-end" *ngIf="orderDetail.order.COD_DOC != 110 && orderDetail.order.COD_DOC != 5001">
                            <h1> Descuento : </h1>
                            <h3 class="slashed-zero lining-nums">  {{totales.descPror | currency:'CLP':'symbol-narrow'}} </h3>
                        </section>                          
                        <section class="grid grid-cols-2 justify-items-end">
                            <h1 *ngIf="orderDetail.order.COD_DOC != 110"> <b>Monto Total:</b> </h1>
                            <h1 *ngIf="orderDetail.order.COD_DOC == 110"> <b>Monto Total <small>{{glsCurrency}}</small>:</b> </h1>
                            <h2 class="slashed-zero lining-nums"> 
                                                                 <!-- <div *ngIf="orderDetail.order.COD_DOC != 5001"><b>{{(totales.MntNeto+totales.MntExe+totales.IVA-this.totales.descPror) | currency:'CLP':'symbol-narrow'}}</b></div> -->
                                                            <div *ngIf="orderDetail.order.COD_DOC != 110">
                                                                 <div *ngIf="orderDetail.order.COD_DOC != 5001"><b>{{(totales.MntNeto+totales.MntExe+totales.IVA) | currency:'CLP':'symbol-narrow'}}</b></div>
                                                                 <div *ngIf="orderDetail.order.COD_DOC == 5001"><b>{{(totales.MntTotal-this.totales.descPror) | currency:'CLP':'symbol-narrow'}}</b></div>
                                                            </div>
                                                            <div *ngIf="orderDetail.order.COD_DOC == 110">
                                                                <div *ngIf="orderDetail.order.COD_DOC != 5001"><b>{{(totales.MntNeto+totales.MntExe+totales.IVA)/ TcCLP  | number : '1.2-2'}}</b></div>
                                                           </div>                                                            
                                                                </h2>
                        </section>   
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div *ngIf="flagProcess == false">
                    <button *ngIf="orderDetail.order.COD_DOC == 39 || orderDetail.order.COD_DOC == 41 || orderDetail.order.COD_DOC == 33 || orderDetail.order.COD_DOC == 34" mat-raised-button class="payBtn" [disabled]="(totales.MntTotal<=0 || !reasonReturnSelected ||  receptor.RUT.length == 0 )" (click)="calcular()">Calcular</button>
                    <button *ngIf="orderDetail.order.COD_DOC == 5001" mat-raised-button class="payBtn" [disabled]="(totales.MntTotal<=0 || !reasonReturnSelected ||  receptor.RUT.length == 0 )" (click)="calcularTKT()">Calcular</button>
                    <button *ngIf="orderDetail.order.COD_DOC == 110" mat-raised-button class="payBtn" [disabled]="(totales.MntTotal<=0 || !reasonReturnSelected ||  receptor.RUT.length == 0 )" (click)="calcularExp()">Calcular Exp</button>                    
                   
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
</div>