<button mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>
<div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
<form [formGroup]="infoForm" class="w-full">

    <mat-card >
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Crear Nota {{data.order.FRIENLY_EOM_ORDER }}</mat-card-title>
        </mat-card-header>
<mat-card-content>

    <div class="flex space-x-5">

        <mat-form-field class="w-3/4">
            <mat-label> Tipo </mat-label>
                                    <!--//-->                            
                                    <mat-select formControlName="tipoMessage">
                                        <ng-container *ngFor="let t of tipoMessageList">
                                            <mat-option [value]="t.tipo">
                                                {{t.gls}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <!--//-->   
        </mat-form-field>
</div>
<div>
        <mat-form-field class="w-1/2">
            <mat-label>Nota</mat-label>
            <textarea matInput formControlName="txtMessage"  (input)="countCharacter('txtMessage')" 
            maxlength="50"
            cdkAutosizeMinRows="10"
            cdkAutosizeMaxRows="10"
            ></textarea>
            <mat-hint> {{countMessage}} / 50 </mat-hint>     
            <mat-error *ngIf="infoForm.get('txtMessage').hasError('required')"> El nombre es requerido </mat-error>
            <mat-error *ngIf="infoForm.get('txtMessage').hasError('maxlength')"> El nombre no puede ser mas de 50 caracteres </mat-error>   
</mat-form-field>
</div>

</mat-card-content>
</mat-card >
<button mat-raised-button color="primary" (click)="addMessage()" [disabled]="infoForm.invalid && countMessage ==0" ><mat-icon>add</mat-icon>Agregar Nota</button>
</form>      
<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
</div>