import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef } from '@angular/core';
import { environment } from 'environments/environment';
//
import { SalesPointService } from 'app/services/salesPoint.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
//
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
//
import { PaymentsComponent } from '../payments/payments.component'
//
import { EmisorService } from 'app/services/emisor.service';
//
import { DetailClientComponent } from '../../clientes/detail-client/detail-client.component'
import { FormBuilder, UntypedFormGroup, Validators,FormControl } from '@angular/forms';
import { OrderService } from 'app/services/orders.service';
//
import { DialogUpdateMovimientosComponent } from '../../inventario/dialog-update-movimientos/dialog-update-movimientos.component';
//
import { WarehouseService } from 'app/services/warehouse.service';
//
import {ClaimComponent} from '../claim/claim.component'
//
import {LabelPrinterService} from  'app/services/labelPrinter.service';
//
@Component({
  selector: 'app-dialog-detail',
  templateUrl: './dialog-detail.component.html',
  styleUrls: ['./dialog-detail.component.scss']
})
export class DialogDetailComponent implements OnInit {


  @ViewChild('pagMessage'       ) pagMessage        : MatPaginator;
  @ViewChild('pagHistory'       ) pagHistory        : MatPaginator;
  @ViewChild('pagDetail'        ) pagDetail         : MatPaginator;
  @ViewChild('pagDTE'           ) pagDTE            : MatPaginator;
  @ViewChild('pagReturn'        ) pagReturn         : MatPaginator;
  @ViewChild('pagReturnDetail'  ) pagReturnDetail   : MatPaginator;
  @ViewChild('pagPayment'       ) pagPayment        : MatPaginator;
  @ViewChild('pagDiscountCoupon') pagDiscountCoupon : MatPaginator;
  @ViewChild('pagShiping'       ) pagShiping        : MatPaginator;

  @ViewChild('pagTracking'       ) pagTracking        : MatPaginator;
  page : number = 0;

  isLoadingHistory :boolean = false;

  //
  order               : any ;
  orderDetail         : any ;
  orderHistory        : any ;
  orderMessage        : any ;
  orderDTE            : any ;
  orderReturn         : any ; 
  orderReturnDetail   : any ; 
  orderPayment        : any ;
  orderDiscountCoupon : any ;
  orderShiping        : any ; 
  orderTracking       : any ;

  columnsDetail         : any[] = ['sku','producto','wareHouse', 'cantidad', 'precio','desc','promotion', 'total']
  columnsHistory        : any[] = ['newStatus', 'date', 'employee'];
  columnsMessage        : any[] = ['employeeName', 'date','tipo', 'message','acctionBotton'];
  columnsDTE            : any[] = ['dte','folio','total','Fecha', 'estado','link'];
  columnsReturn         : any[] = ['Motivo','tipo','fecha', 'total','employee','sp','coupon','estado','detail']; 
  columnsReturnDetail   : any[] = ['sku','producto','wareHouse','estado', 'cantidad', 'precio', 'total']
  columnsPayment        : any[] = ['paymeans', 'status', 'total','extra']; 

  columnsDiscountCoupon : any[] = ['cod', 'amount', 'paymean','reason','obs'];

  columnsShiping        : any[] = ['orden', 'Fecha', 'Total']; 

  columnsTracking        : any[] = ['date', 'description']; 


  orderDetailPag         : number = 10;
  orderHistoryPag        : number = 3;
  orderMessagePag        : number = 10;
  orderDTEPag            : number = 5;
  orderReturnPag         : number = 10; 
  orderReturnDetailPag   : number = 10; 
  orderPaymentPag        : number = 3;   
  orderShipingPag        : number = 3; 
  orderDiscountCouponPag : number = 3;
  orderTrackingPag       : number = 3;

  pageSizeOptions : any = [3, 5 ,10, 50, 100];

  isLoading : boolean = false;

  flagShow=false
//
    //
    userData =  {
                   login      : ''
                  ,firstName  : ''
                  ,lastName   : ''
                  ,rutEmpresa : ''
                  ,logoEmpresa: ''
                  ,pin        : ''
                  ,envirometDTE : ''
                  ,envirometBOL : ''
                  ,envirometTKT : ''                     
                  }
    //
//
posCOD :any = []
posTC  :any = []
//
matTabOrderSelected = 0
//
codOrderShiping       = ''
codShipingMethod      = ''
createTransportOrders : boolean = false
flagProd              : boolean = false
//
flagReplaceOT         : boolean = false
//
orderOTTracking       : any =  {
  addressData: {
       destinationCoverageCode : ""
      ,originCoverageCode      : ""
      ,address                 : ""
      ,latitude                : ""
      ,longitude               : ""
  },
   deliveryData : {
       receptorRut      : ""
      ,receptorName     : ""
      ,deliveryDate     : ""
      ,deliveryHour     : ""
      ,deliveryDateTime : ""
  },
  trackingEvents: [],
  internationalData: {
       country        : ""
      ,areaGuide      : ""
      ,trackingNumber : ""
      ,courier        : ""
      ,zipCode        : ""
  },
  transportOrderData: {
       transportOrderNumber : ""
      ,certificateNumber    : ""
      ,reference            : ""
      ,product              : ""
      ,service              : ""
      ,dimensions           : ""
      ,weight               : ""
      ,status               : ""
      ,locationStatus       : ""
  }
}
//###############################
carrierForm: UntypedFormGroup;
flagUpdateOrderStatus : boolean = true;
//###############################
labelPrint : LabelPrinterService
//###############################
  constructor(
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DialogDetailComponent>, 
    private ref: ChangeDetectorRef,
    private _salesPointService : SalesPointService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _emisorService: EmisorService,
    private _fb: FormBuilder,
    private _orderService : OrderService,
    private _warehouseService: WarehouseService,
  ) { 

    this.carrierForm = this._fb.group({
      uuidStatus: [null,[Validators.required]],
      trackID   : [null,[Validators.required]]    
    });

  }
//############################################################
//############################################################
refreshOrder(e,data){
  //console.log(e)
  }
//############################################################
//############################################################
async reLoadOrder(){

  let tmp = await this._orderService.getOrdersDetail(this.data.order.UUID_EOM_ORDER)
  this.data = tmp.dataSet
  await  this.ngOnInit()

}
//############################################################
//############################################################
  async ngOnInit(): Promise<any>{
    //
    //this.matTabOrderSelected = this.data.tabID
    //this.data = this.data.data
    //console.log(this.data)
    //
    var tmp1,tmp2
    await this.getPosData()
    
    //
    this.data.orderPayment.forEach(element => {
        
      if(element.COD_PM_PAY_MEANS == 'PIT'){
        //==
          tmp1 = this.posTC.filter(i => i.tc  ==  element.DATA_ORDER_PAYMENT.cardDetail.cardBrand)
          tmp2 =this.posCOD.filter(i => i.cod == element.DATA_ORDER_PAYMENT.responseCode        )
          //////////////////////////////
          if(tmp1.length > 0) element.DATA_ORDER_PAYMENT.cardDetail.cardBrandName = tmp1[0].gls
                         else element.DATA_ORDER_PAYMENT.cardDetail.cardBrandName = 'NA'
           
          if(tmp2.length > 0) element.DATA_ORDER_PAYMENT.responseName = tmp2[0].gls
                         else element.DATA_ORDER_PAYMENT.responseName = 'NA'
        //==
      }
    });
    /*
    //##
    this.data.orderHistory.forEach(element => {
      element.CONFIG_COD_REASON_CODE = JSON.parse(element.CONFIG_COD_REASON_CODE);
    });
    //##
    this.data.orderMessage.forEach(element => {
      element.CUSTOMER_DATA = JSON.parse(element.CUSTOMER_DATA);
      element.EMPLOYEE_DATA = JSON.parse(element.EMPLOYEE_DATA);
    });
    //##
    this.data.orderReturn.forEach(element => {
      element.EMPLOYEE_DATA = JSON.parse(element.EMPLOYEE_DATA);
    });
    //##
    this.data.orderShipingHistory.forEach(element => {
      element.DATA_EOM_ORDER_SHIPING = JSON.parse(element.DATA_EOM_ORDER_SHIPING);
    });
    this.data.carrierStatus.forEach(element => {
      element.CONFIG_COD_REASON_CODE = JSON.parse(element.CONFIG_COD_REASON_CODE);
    });
    */
    //##
    await this.loadOrderHistory();
    await this.loadOrderMessage();
    await this.loadDetail();
    await this.loadDTE();
    await this.loadReturn ();
    await this.loadPayment();
    await this.loadDiscountCoupon();
    var tmp,tmp2;
    tmp =  (JSON.parse(localStorage.getItem('ssoData')))
    tmp2 = (JSON.parse(localStorage.getItem('emisorData')))
     this.userData = {
        login      : tmp.user.login
        ,firstName  : tmp.user.firstName
        ,lastName   : tmp.user.lastName
        ,pin        : tmp2.usrData[0].PIN
        ,rutEmpresa : localStorage.getItem('rutEmpresa')
        ,logoEmpresa: null
        ,envirometDTE : tmp2.emisorData[0].COD_ENVIROMENT_DTE
        ,envirometBOL : tmp2.emisorData[0].COD_ENVIROMENT_BOL
        ,envirometTKT : tmp2.emisorData[0].COD_ENVIROMENT_TKT                
    }
    //########################################################
    if(this.data.carrierStatus.length > 0){
      this.carrierForm.get('uuidStatus').setValue(this.data.order.UUID_EOM_ORDER_STATUS);
      if(this.data.orderShiping[0].COD_SHIPING_METHOD == 'C&C'){
        this.carrierForm.get('trackID').setValue('00000');
      }
      if(this.data.orderShiping[0].COD_SHIPING_METHOD == 'SHP'){
        if(this.data.orderShiping[0].TRAKING_NUMBER != '00000'){
          this.carrierForm.get('trackID').setValue(this.data.orderShiping[0].TRAKING_NUMBER);
        }
        this.carrierForm.get('trackID').disable();
      }
      if(this.data.orderShiping[0].COD_SHIPING_METHOD == 'FLC'){
        this.carrierForm.get('trackID').setValue('00000');
      }      
      if(this.data.order.COD_REASON_CODE == 'FN'){
        this.carrierForm.get('trackID').disable();
        this.carrierForm.get('uuidStatus').disable();
        this.flagUpdateOrderStatus = false;
      }
      //==
      let tmp = this.data.carrierStatus.filter(i => i.UUID_EOM_ORDER_STATUS == this.data.order.UUID_EOM_ORDER_STATUS)
      this.codShipingMethod = this.data.orderShiping[0].COD_SHIPING_METHOD
      if(tmp.length > 0 ) this.codOrderShiping  = tmp[0].COD_REASON_CODE; else this.codOrderShiping = null;
      
      this.createTransportOrders = (this.data.orderShiping[0].createTransportOrders === 'true')
      this.flagProd              = (this.data.orderShiping[0].flagProd === 'true')
      //==        
    }
    //########################################################
    await this.getOmsOrderOTTracking()
  };
//############################################################
//############################################################
async getPosData(): Promise<void> {
  var reasonCodesFilter
  let dataRS = {
                   appToken    : null 
                  ,uuid        : null
                  ,codDocType  : ['PI']
                }
  reasonCodesFilter   = await this._emisorService.getReasonCodesFilter(dataRS);
  this.posCOD = reasonCodesFilter.data.filter(i => i.COD_REASON_CODE == 'COD')[0].CONFIG_COD_REASON_CODE
  this.posTC  = reasonCodesFilter.data.filter(i => i.COD_REASON_CODE == 'TC' )[0].CONFIG_COD_REASON_CODE
}
//############################################################
//############################################################
show(){
  if(this.flagShow == false) this.flagShow = false;
  if(this.flagShow == true ) this.flagShow = true;
}
//############################################################
//############################################################
async loadDTE (): Promise<any>{
  this.isLoading=true; 
  this.orderDTE = new MatTableDataSource(this.data.orderDTE);
  this.ref.detectChanges();
  this.orderDTE.paginator = this.pagDTE;
  this.isLoading=false; 
};
//############################################################
//############################################################
  async loadOrderHistory(): Promise<any>{
    this.isLoading=true; 
    this.orderHistory = new MatTableDataSource(this.data.orderHistory);
    this.ref.detectChanges();
    this.orderHistory.paginator = this.pagHistory;
    this.isLoading=false; 
  };
//############################################################
//############################################################
  async loadOrderMessage(): Promise<any>{
    this.isLoading=true; 
    this.orderMessage = new MatTableDataSource(this.data.orderMessage);
    this.ref.detectChanges();
    this.orderMessage.paginator = this.pagMessage;
    this.isLoading=false; 
  };
//############################################################
//############################################################
async loadDetail (): Promise<any>{
  this.isLoading=true; 
  this.orderDetail = new MatTableDataSource(this.data.orderDetail);
  this.ref.detectChanges();
  this.orderDetail.paginator = this.pagDetail;
  this.isLoading=false; 
};
//############################################################
//############################################################
async loadReturn (): Promise<any>{
  this.isLoading=true; 
  this.orderReturn = new MatTableDataSource(this.data.orderReturn);
  this.ref.detectChanges();
  this.orderReturn.paginator = this.pagReturn;
  this.isLoading=false; 
};
//############################################################
//############################################################
async loadReturnDetail (UUID_EOM_ORDER_RETURN): Promise<any>{
  this.isLoading=true; 
  this.orderReturnDetail = new MatTableDataSource(this.data.orderReturnDetail.filter(item => item.UUID_EOM_ORDER_RETURN == UUID_EOM_ORDER_RETURN));
  this.ref.detectChanges();
  this.orderReturnDetail.paginator = this.pagReturnDetail;
  this.isLoading=false; 
};
//############################################################
//############################################################
async loadPayment  (): Promise<any>{
  this.isLoading=true; 
  this.orderPayment = new MatTableDataSource(this.data.orderPayment);
  this.ref.detectChanges();
  this.orderPayment.paginator = this.pagPayment;
  this.isLoading=false; 
};
//############################################################
//############################################################
async loadDiscountCoupon  (): Promise<any>{
  this.isLoading=true; 
  this.orderDiscountCoupon = new MatTableDataSource(this.data.orderDiscountCoupon);
  this.ref.detectChanges();
  this.orderDiscountCoupon.paginator = this.pagDiscountCoupon;
  this.isLoading=false; 
};
//############################################################
//############################################################
  async btnClose(): Promise<any> {
    this._dialogRef.close();
}
//############################################################
//############################################################
async downloadDte(codDoc: string,uuid : string,size: string,op: string){

  var urlDte = `${environment.endPoints.pdf}${uuid}`;
  var urlTkt = `${environment.endPoints.pdfTkt}${uuid}`;

  if( codDoc == '5001'){
    urlTkt = urlTkt
    window.open(urlTkt, "_blank");
  }
  else{
    if(size == '80mm' && op == '0' ) urlDte = urlDte+'/'+size
    if(size == 'a4'   && op == '0' ) urlDte = urlDte+'/'+size
    if(size == 'a4'   && op != '0' ) urlDte = urlDte+'/a4/cedible'    


    window.open(urlDte, "_blank");
  }
  
}
//############################################################
//############################################################

async reMake(data): Promise<any> {
  let $this = this;
  var dte : any
  
  if(data.COD_DOC == 61){
    //=====================================================
     dte = await $this.makeDteAT('0',data.UUID_EOM_ORDER)
        if(dte.success == true ){
          const dialogRef = $this._fuseConfirmationService.open({
            title: 'Dte Emitido Correctamente',
            message: `${dte.gls}`,
            icon: {
                show: true,
                name: 'local_atm',
                color: 'primary'
            },
            "actions": {
              "confirm": {
                "show": true,
                "label": "Aceptar",
                "color": "primary"
              },
              "cancel": {
                "show": false,
                "label": "Cancel"
              }
            },
            "dismissible": true      
          });
          dialogRef.afterClosed().subscribe(async (result) => {
              //
              this._orderService.getOrdersDetail(data.UUID_EOM_ORDER).then(async (res) => {
                $this.data = res.dataSet
                await $this.ngOnInit()
            })
              //
            });
        }
        else{
          const dialogRef = $this._fuseConfirmationService.open({
            title: 'Error: No es posible emitir DTE',
            message: `${dte.gls}`,
            icon: {
                show: true,
                name: 'local_atm',
                color: 'error'
            },
            "actions": {
              "confirm": {
                "show": true,
                "label": "Aceptar",
                "color": "primary"
              },
              "cancel": {
                "show": false,
                "label": "Cancel"
              }
            },
            "dismissible": true      
          });
          dialogRef.afterClosed().subscribe(async (result) => {
              //
              
              //
            });
        }
    //=====================================================
  }
  else{
    //=====================================================
    dte = await this.makeDteAT(data.UUID_EOM_ORDER,'0')
       if(dte.success == true ){
         const dialogRef = $this._fuseConfirmationService.open({
           title: 'Dte Emitido Correctamente',
           message: `${dte.gls}`,
           icon: {
               show: true,
               name: 'local_atm',
               color: 'primary'
           },
           "actions": {
             "confirm": {
               "show": true,
               "label": "Aceptar",
               "color": "primary"
             },
             "cancel": {
               "show": false,
               "label": "Cancel"
             }
           },
           "dismissible": true      
         });
         dialogRef.afterClosed().subscribe(async (result) => {
             //
             this._orderService.getOrdersDetail(data.UUID_EOM_ORDER).then(async (res) => {
               $this.data = res.dataSet
               await $this.ngOnInit()
           })
             //
           });
       }
       else{
         const dialogRef = $this._fuseConfirmationService.open({
           title: 'Error: No es posible emitir DTE',
           message: `${dte.gls}`,
           icon: {
               show: true,
               name: 'local_atm',
               color: 'error'
           },
           "actions": {
             "confirm": {
               "show": true,
               "label": "Aceptar",
               "color": "primary"
             },
             "cancel": {
               "show": false,
               "label": "Cancel"
             }
           },
           "dismissible": true      
         });
         dialogRef.afterClosed().subscribe(async (result) => {
             //
             
             //
           });
       }
   //=====================================================     
  }
}
//############################################################
//############################################################ 
async anularOrden(order): Promise<any> {
  let $this = this;
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
            const dialogRef = this._fuseConfirmationService.open({
              title: `Anular Orden:`+ order.FRIENLY_EOM_ORDER,
              message: `Esta operarion no es reversible.</b> <br>¿Desea continuar?`,
              icon: {
                  show: true,
                  name: 'delete',
                  color: 'warn'
              },
              actions: {
                  confirm: {
                      label: 'Anular',
                      color: 'warn'
                  },
                  cancel: {
                      label: 'Cancelar',
                  }
              }
          });
          dialogRef.afterClosed().subscribe(async (result) => {
              if(result === 'confirmed'){
                //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
                if( order.UUID_DTE != 'OK'   ){ 
                    await $this.accanularOrden(order)
                   }
                   else{
                    const dialogRef = $this._fuseConfirmationService.open({
                      title:'No es posible anular orden',
                      message: `Debe realizarlo desde editar orden`,
                      icon: {
                          show: true,
                          name: 'local_atm',
                          color: 'warn'
                      },
                      "actions": {
                        "confirm": {
                          "show": true,
                          "label": "warn",
                          "color": "primary"
                        },
                        "cancel": {
                          "show": false,
                          "label": "Cancel"
                        }
                      },
                      "dismissible": true      
                    });
                    dialogRef.afterClosed().subscribe(async (result) => {
                        //
                        
                        //
                      });
                   }
                //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""          
              }
      //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
      //////////////////////////////////////////// 
    });      
}
//############################################################
//############################################################ 
async accanularOrden(order): Promise<any> {
  let $this = this;
return await new Promise(
  async function (resolve, reject) {
      ////////////////////////////////////////////  
      var jsonAnula,orderAnula;
      jsonAnula = {
                     language     :"es"
                    ,appToken     : null
                    ,uuidSSO      : null
                    ,uuidEomOrder : order.UUID_EOM_ORDER
                  
                  }
      //////////////////////////////////////////// 
      orderAnula= await $this._salesPointService.orderCancel(jsonAnula)
      //////////////////////////////////////////// 
      if(orderAnula.status == true )
      {
        if(orderAnula.codStatus == '200'){
          //#########################################################
            const dialogRef = $this._fuseConfirmationService.open({
              title: orderAnula.glsStatus,
              message: `Productos reversados a Bodega`,
              icon: {
                  show: true,
                  name: 'local_atm',
                  color: 'primary'
              },
              "actions": {
                "confirm": {
                  "show": true,
                  "label": "Aceptar",
                  "color": "primary"
                },
                "cancel": {
                  "show": false,
                  "label": "Cancel"
                }
              },
              "dismissible": true      
            });
            dialogRef.afterClosed().subscribe(async (result) => {
                //
                  $this._orderService.getOrdersDetail(order.UUID_EOM_ORDER).then(async (res) => {
                    $this.data = res.dataSet
                    await $this.ngOnInit()
                  //
                });
                //
              });
          //#########################################################
        }
        else{
          //#########################################################
            const dialogRef = $this._fuseConfirmationService.open({
              title: orderAnula.glsStatus,
              message: `Productos en estado retenidos, mover via inventario`,
              icon: {
                  show: true,
                  name: 'local_atm',
                  color: 'error'
              },
              "actions": {
                "confirm": {
                  "show": true,
                  "label": "Aceptar",
                  "color": "primary"
                },
                "cancel": {
                  "show": false,
                  "label": "Cancel"
                }
              },
              "dismissible": true      
            });
            dialogRef.afterClosed().subscribe(async (result) => {
                //
                $this._orderService.getOrdersDetail(order.UUID_EOM_ORDER).then(async (res) => {
                  $this.data = res.dataSet
                  await $this.ngOnInit()
              })
                //
              });
          //#########################################################                    
        }
      }
      //resolve({success:true,orderClose:orderAnula})
    });      
}
//############################################################
//############################################################ 
openAddOrderNote(order): void {
  /*
  pasar parametros para consultar catalogo ... bodega punto de venta etc
  */
  let $this = this;
  const dialogRef = this._dialog.open(DialogMessageComponent, {
      width: '100%'
     ,data: {
                order: order
            }
  });
  dialogRef.afterClosed().subscribe(async result => {
      if(!!result){
        //========================================================
        this.data.orderMessage = result.orderMessage;
        this.data.orderMessage.forEach(element => {
          element.CUSTOMER_DATA = JSON.parse(element.CUSTOMER_DATA);
          element.EMPLOYEE_DATA = JSON.parse(element.EMPLOYEE_DATA);
        });
        await $this.loadOrderMessage()
        //========================================================
      }
  });
};
//############################################################
//############################################################ 
async delMessage(o): Promise<any> {
  let $this = this;
  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
      const dialogRef = this._fuseConfirmationService.open({
        title: `Eliminar Nota`,
        message: `¿Está seguro que desea eliminar Nota?`,
        icon: {
            show: true,
            name: 'delete',
            color: 'warn'
        },
        actions: {
            confirm: {
                label: 'Eliminar',
                color: 'warn'
            },
            cancel: {
                label: 'Cancelar',
            }
        }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
        if(result === 'confirmed'){
          //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""
           var del = await $this._salesPointService.delorderMessage($this.data.order.UUID_EOM_ORDER,o.UUID_EOM_ORDER_MESSAGE);
            //========================================================
            this.data.orderMessage = del.orderMessage;
            this.data.orderMessage.forEach(element => {
                                                        element.CUSTOMER_DATA = JSON.parse(element.CUSTOMER_DATA);
                                                        element.EMPLOYEE_DATA = JSON.parse(element.EMPLOYEE_DATA);
                                                      });
            await $this.loadOrderMessage()
            //========================================================           
          //"""""""""""""""""""""""""""""""""""""""""""""""""""""""""""          
        }
    });
  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
} 
//############################################################
//############################################################ 
async  movementDetail(uuidWarehouseMovement: any): Promise<any>{
  const detail = await this._warehouseService.getWarehouseMovementDetail(uuidWarehouseMovement);
 const dialogRef = this._dialog.open(DialogUpdateMovimientosComponent, {
  width: '100%',
  height: '100%',
   data: {
      movement       : detail.movements
     ,detailMovement : detail.detail
   }
 });

 dialogRef.afterClosed().subscribe(async result => {
   if (result) {
 
   }
 });
};
//########################################################### 
//###########################################################
payOrder(order): void {
  /*
  pasar parametros para consultar catalogo ... bodega punto de venta etc
  */
  let $this = this;
  const dialogRef = this._dialog.open(PaymentsComponent, {
      width: '100%'
     ,data: {
                order: order
            }
  });
  dialogRef.afterClosed().subscribe(async result => {
        //========================================================
            $this._orderService.getOrdersDetail(order.UUID_EOM_ORDER).then(async (res) => {
              $this.data = res.dataSet
              await $this.ngOnInit()
          })
        //========================================================
      
  });
};
//############################################################
//############################################################ 
closeBtn(){
  // Unsuscribe to all observables
  this._dialogRef.close(this.data);
}; 
//############################################################
//############################################################
async editOrCreateClient(uuidCrmCustomer): Promise<void> {
  const dialogRef = this._dialog.open(DetailClientComponent, {
    
    width: '100%',
    height: '100%',
      //autoFocus: false,
      data: {
          customer: null,
          type: 'view',
          tab:"customer",
          source: 'modal',
          uuidCrmCustomer : uuidCrmCustomer

      }
  });
  dialogRef.afterClosed().subscribe(async (result) => {
     // this.ngOnInit();
  });
}
//############################################################
//############################################################
async updateOrderStatus(){
/////////////////////
var glsDoc, msg
var data = {}
/////////////////////
let $this = this;
let tmp = this.data.carrierStatus.filter(i => i.UUID_EOM_ORDER_STATUS == this.carrierForm.value.uuidStatus)[0]
/////////////////////////
if(tmp == undefined){
  const dialogRef = this._fuseConfirmationService.open({
    title: `No se ha selecionado estado de la Orden`,
    message: `Orden: <b>${this.data.order.FRIENLY_EOM_ORDER}</b>`,
    icon: {
        show: true,
        name: 'local_atm',
        color: 'primary'
    },
    "actions": {
      "confirm": {
        "show": true,
        "label": "Aceptar",
        "color": "primary"
      },
      "cancel": {
        "show": false,
        "label": "Cancel"
      }
    },
    "dismissible": false      
  });
  return false 
}
/////////////////////////
if(this.data.order.UUID_EOM_ORDER_STATUS == this.carrierForm.value.uuidStatus){
  const dialogRef = this._fuseConfirmationService.open({
    title: `No es posible cambiar estado de la Orden`,
    message: `Ya se encuenta en estado <b>${tmp.DESC_COD_REASON_CODE}</b>`,
    icon: {
        show: true,
        name: 'local_atm',
        color: 'warn'
    },
    "actions": {
      "confirm": {
        "show": true,
        "label": "Aceptar",
        "color": "warn"
      },
      "cancel": {
        "show": false,
        "label": "Cancel"
      }
    },
    "dismissible": false      
  });
  return false
}
/////////////////////
/////////////////////

if(this.data.order.COD_DOC == 39 || this.data.order.COD_DOC == 41){
  glsDoc = 'la Boleta';
}
else if(this.data.order.COD_DOC == 5001){
  glsDoc = 'el Ticket';
}
else{
  glsDoc = 'la Factura';
}
/////////////////////////
if(tmp.COD_REASON_CODE == 'OMP' ){
  msg= `Se notificara al cliente el avance de la orden`
}
/////////////////////////
if(tmp.COD_REASON_CODE == 'OWC' || tmp.COD_REASON_CODE == 'OTS'){
  msg= `Se emitira y enviara ${glsDoc} al cliente `
}
/////////////////////////
if(tmp.COD_REASON_CODE == 'RFC' ){
  msg= `Se notificara al cliente para que retire la orden`
}
/////////////////////////
if(tmp.COD_REASON_CODE == 'OOR' ){
  msg= `Se notificara al cliente numero de seguimiento`
}
/////////////////////////
if(tmp.COD_REASON_CODE == 'EN' ){
  msg= `Se notificara al cliente la entrega de la orden`
}
/////////////////////////
if(tmp.COD_REASON_CODE == 'EN' && this.data.orderShiping[0].COD_SHIPING_METHOD == 'FLC'){
  msg= `Se emitira y enviara ${glsDoc} al cliente `
}
/////////////////////////
const dialogRef = this._fuseConfirmationService.open({
  title: `Cambiar estado de la Orden a <br><b>${tmp.DESC_COD_REASON_CODE}</b>`,
  message: msg,
  icon: {
      show: true,
      name: 'local_atm',
      color: 'primary'
  },
  "actions": {
    "confirm": {
      "show": true,
      "label": "Aceptar",
      "color": "primary"
    },
    "cancel": {
      "show": false,
      "label": "Cancel"
    }
  },
  "dismissible": true      
});
dialogRef.afterClosed().subscribe(async (result) => {
    //
    if(result == 'confirmed'){
        ///////////////////////////////////
        data = {
                     language           :"es"
                    ,appToken           : null
                    ,uuidSSO            : null
                    ,uuidEomOrder       : this.data.order.UUID_EOM_ORDER
                    ,uuidEomOrderStatus : this.carrierForm.value.uuidStatus
                  }

        //console.log(data)
        //console.log(tmp.COD_REASON_CODE)
        //return true
        let udpOrder = await this._salesPointService.setOmsOrderForwardCarrier(data);
        if(udpOrder.status == true){
          if(tmp.COD_REASON_CODE == 'OWC'){
            //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
            if(this.data.orderShiping[0].TRAKING_NUMBER == '00000'){
                  data = {
                            language            : "es"
                            ,appToken            : null
                            ,uuidSSO             : null
                            ,uuidEomOrder        : this.data.order.UUID_EOM_ORDER
                            ,uuidEomOrderShiping : this.data.orderShiping[0].UUID_EOM_ORDER_SHIPING
                            ,trakingNumber       : this.carrierForm.value.trackID
                          }
                  let udpTrackID = await this._salesPointService.setOmsOrderSetTrackID(data);
              }
            //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
          }
          //##################################################
          /*
          if(tmp.COD_REASON_CODE == 'OWC' || tmp.COD_REASON_CODE == 'OTS'){
            let dte = await $this.makeDteAT(this.data.order.UUID_EOM_ORDER,'0');
          }
          //##################################################
          if(tmp.COD_REASON_CODE == 'EN' && this.data.orderShiping[0].COD_SHIPING_METHOD == 'FLC'){
            let dte = await $this.makeDteAT(this.data.order.UUID_EOM_ORDER,'0');
          } */         
          //##################################################
          this._orderService.getOrdersDetail(this.data.order.UUID_EOM_ORDER).then(async (res) => {
              $this.data = res.dataSet
              await $this.ngOnInit()
          })
          
        }else{
            this.carrierForm.get('uuidStatus').setValue(this.data.order.UUID_EOM_ORDER_STATUS);
        }
        //////////////////////////////////
    }
    else{
      return false
    }
    //
  }); 


}
//############################################################
//############################################################
chkStatusTrackID(){
  let tmp = this.data.carrierStatus.filter(i => i.UUID_EOM_ORDER_STATUS == this.carrierForm.value.uuidStatus)[0]
  if(this.data.order.UUID_EOM_ORDER_STATUS == this.carrierForm.value.uuidStatus){
    return false
  }
  else{
    if(tmp.COD_REASON_CODE == 'OWC'){
      //======================================================
      if(this.createTransportOrders == true && this.codShipingMethod == 'SHP')
        {
        //================================================ 
        if(this.data.orderShiping[0].TRAKING_NUMBER == '00000'){
                const dialogRef = this._fuseConfirmationService.open({
                  title: `Se creara de forma automatica OT, Precione Aceptar para continuar`,
                  message: `El cliente sera notificado para realizar seguimiento al despacho`,
                  icon: {
                      show: true,
                      name: 'local_atm',
                      color: 'warn'
                  },
                  "actions": {
                    "confirm": {
                      "show": true,
                      "label": "Aceptar",
                      "color": "warn"
                    },
                    "cancel": {
                      "show": true,
                      "label": "Cancel"
                    }
                  },
                  "dismissible": false      
                });
                dialogRef.afterClosed().subscribe(async (result) => {
                  if (result === 'confirmed') {
                      ////////////////////////////////////////// 
                        await this.newOmsOrderOT();
                        await this.reLoadOrder()

                      //////////////////////////////////////////  
                  }else{
                      //////////////////////////////////////////     
                      await this.reLoadOrder()           
                      return false
                      //////////////////////////////////////////                
                  }
                });
              }
          //=============================================
               
          }else{
          //==============================================
            this.carrierForm.get('trackID').enable();
            this.carrierForm.get('trackID').setValue(null);
            
                  const dialogRef = this._fuseConfirmationService.open({
                    title: `Debe indicar numero de seguimiento TrackID del Courier`,
                    message: `El cliente sera notificado para realizar seguimiento al despacho`,
                    icon: {
                        show: true,
                        name: 'local_atm',
                        color: 'warn'
                    },
                    "actions": {
                      "confirm": {
                        "show": true,
                        "label": "Aceptar",
                        "color": "warn"
                      },
                      "cancel": {
                        "show": false,
                        "label": "Cancel"
                      }
                    },
                    "dismissible": false      
                  });
                  return false       
          //==============================================
          }
      //======================================================
    }else{
      this.carrierForm.get('trackID').disable();
    }
  }

}
//############################################################
//############################################################
/*
{
   "language":"es",
   "appToken":"5e99141d-a4ec-40b6-8f3d-3df23e018845",
   "uuidSSO":"42468202-2100-7477-4043-424682022100",
   "uuidEomOrder":"13884202-4250-4021-1745-16E4E7C4E591",
   "uuidEomOrderReturn":"0",
   "userInvoice":"14383206-6",
   "type":"USR",
   "format":"80mm",
   "dteB64":true
}
*/
async makeDteAT(uuidEomOrder,uuidEomOrderReturn){
  let $this = this;
  return await new Promise(
    async function (resolve, reject) {
        var tmp,typeUser,user,makeDte,data;
        //////////////////////////////////////////// 
        try{
            tmp       = (JSON.parse(localStorage.getItem('emisorData')))
            typeUser  = tmp.usrData[0].TYPE_USER
            user      = tmp.usrData[0].USER
            data = {
                     language           :"es"
                    ,appToken           : null
                    ,uuidSSO            : null
                    ,uuidEomOrder       : uuidEomOrder
                    ,uuidEomOrderReturn : uuidEomOrderReturn
                    ,userInvoice        : user
                    ,type               : typeUser
                    ,format             :"80mm"
                    ,dteB64             :false
                      
                    }
            makeDte = await $this._salesPointService.makeOmsOrderDTE(data); 
            resolve(makeDte)
        }catch(e){
          resolve(makeDte)
        }
        ////////////////////////////////////////////  

      }); 
}
//############################################################
//############################################################
//############################################################
//############################################################
async returnOrder(){ 
  const dialogRef = this._dialog.open(ClaimComponent, {
    width: '100%',
    height: '100%',
    disableClose : true
   ,data: {
             UUID_EOM_ORDER: this.data.order.UUID_EOM_ORDER
            ,uuidSpEmployee : null//this.uuidSpEmployee
            ,uuidSp         : null//this.uuidSp
      }
  });
  dialogRef.afterClosed().subscribe(async result => {
    await this.reLoadOrder()
    //await this.resetFilters()
   //await this.getOrders(this.yearSelected,this.monthSelected);
   /*
    if(!!result){
      await this.ngOnInit()
    }*/
  });
  };
//############################################################
//############################################################
async newOmsOrderOT(){
  var json  ={
                 language     : "es"
                ,appToken     : null
                ,uuidEomOrder : this.data.order.UUID_EOM_ORDER
              }
              
  let tmp = await this._orderService.newOmsOrderOT(json)



this.carrierForm.get('trackID').setValue(tmp.data.data.detail[0].transportOrderNumber);
this.data.orderShiping[0].TRAKING_NUMBER = tmp.data.data.detail[0].transportOrderNumber
var data = {
  language            : "es"
  ,appToken            : null
  ,uuidSSO             : null
  ,uuidEomOrder        : this.data.order.UUID_EOM_ORDER
  ,uuidEomOrderShiping : this.data.orderShiping[0].UUID_EOM_ORDER_SHIPING
  ,trakingNumber       : tmp.data.data.detail[0].transportOrderNumber
}
let udpTrackID = await this._salesPointService.setOmsOrderSetTrackID(data);



  if(tmp.dataSet.status == false){
        const dialogRef = this._fuseConfirmationService.open({
          title: `Error al crear Orden de Transporte`,
          message: `Por favor intentelo en unos minutos`,
          icon: {
              show: true,
              name: 'local_atm',
              color: 'warn'
          },
          "actions": {
            "confirm": {
              "show": true,
              "label": "Aceptar",
              "color": "warn"
            },
            "cancel": {
              "show": false,
              "label": "Cancel"
            }
          },
          "dismissible": false      
        })
  }else{
    
  }
 return true

}
//############################################################
//############################################################
async labelOmsOrderOT(type){
var address,countPS
  if(this.data.orderShiping[0].COD_SHIPING_METHOD == 'SHP' && this.data.orderShiping[0].COD_CS_CARRIER != 'ONW'){
    //==
      var json  ={
                    language     : "es"
                    ,appToken     : null
                    ,uuidEomOrder : this.data.order.UUID_EOM_ORDER
                    ,labelType      : "B64"
                    ,trakingNumber  : this.data.orderShiping[0].TRAKING_NUMBER
                  }
      let tmp = await this._orderService.getOmsOrderOTLabel(json)
      if(type == 'download'){
        //##
       
        await this.openLabel('data:image/png;base64,'+tmp.labelData,tmp.labelType)
        //##        
      }else{
        //##
        await  this.printZebra(tmp.detail)
        //##        
      }

    //==
  }else {
    //==
    if(this.data.orderShiping[0].COD_SHIPING_METHOD == 'SHP' && this.data.orderShiping[0].COD_CS_CARRIER == 'ONW'){
      if(this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.FLAG_ADDRESS_3 == 'Y' ){
        address = this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.ADDRESS1 +' '+ this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.GLS_CUSTOMER_ADDRESS_TYPE  +' '+ this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.ADDRESS2 + ' ' +this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.ADDRESS3
      }else{
        address = this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.ADDRESS1 +' '+ this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.ADDRESS2
      }
      countPS = (this.data.orderDetail.length) - 1
      if(countPS == 0 ) countPS = 1 ;
      var ts_hms = new Date();
      let fechaRef = ts_hms.getFullYear() + '-' + ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + ("0" + (ts_hms.getDate())).slice(-2) 
      var detail= {
                       transportOrderNumber         : this.data.orderShiping[0].TRAKING_NUMBER
                      ,reference                    : "REF:"+this.data.order.FRIENLY_EOM_ORDER
                      ,productDescription           : "EB"
                    //  ,serviceDescription           : ""//this.data.orderShiping[0].COD_CS_CARRIER
                      ,genericString1               : "COPIA"
                      ,deliveryTypeCode             : "CL"
                      ,genericString2               : countPS.toString() + '/' + countPS.toString()
                      ,destinationCoverageAreaName  : this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.GLS_COMUNA                      
                      ,additionalProductDescription : ""
                      ,barcode                      : ""
                      ,classificationData           : 'tel:'+this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.PHONE_NUMBER
                      ,printedDate                  : fechaRef
                      ,labelVersion                 : "V1.0"
                      ,distributionDescription      : this.data.orderShipingHistory[0].DATA_EOM_ORDER_SHIPING.GLS_REGION
                      ,companyName                  : this.data.orderShiping[0].NAME_CS_CARRIER                      
                      ,recipient                    : this.data.order.RAZON_SOCIAL
                      ,address                      : address
                      ,groupReference               : this.data.order.COD_SP
                      ,frienlyEomOrder              : this.data.order.FRIENLY_EOM_ORDER
                      ,sgtmCustomer                 : this.data.order.SGTM_CUSTOMER
                      ,codCmCustomerValue           : this.data.order.COD_CM_CUSTOMER_VALUE
                      ,nameCmCustomerValue          : this.data.order.NAME_CM_CUSTOMER_VALUE
                      ,customerContactFirstName     : this.data.order.CUSTOMER_CONTACT_FIRST_NAME
                      ,customerContactLastName      : this.data.order.CUSTOMER_CONTACT_LAST_NAME
                      ,customerContactMail          : this.data.order.CUSTOMER_CONTACT_MAIL
                      ,customerContactPhone         : this.data.order.CUSTOMER_CONTACT_PHONE
                    }

            
      if(type == 'download'){

      }else{
          await  this.printZebra(detail)
      }

    }
    //==        
  }
  //=======================================
  if(this.data.orderShiping[0].COD_SHIPING_METHOD == 'C&C'){
        countPS = (this.data.orderDetail.length) - 1
        if(countPS == 0 ) countPS = 1 ;
        var ts_hms = new Date();
        let fechaRef = ts_hms.getFullYear() + '-' + ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + ("0" + (ts_hms.getDate())).slice(-2) 
        var detail= {
                         transportOrderNumber         : this.data.orderShiping[0].TRAKING_NUMBER
                        ,reference                    : "REF:"+this.data.order.FRIENLY_EOM_ORDER
                        ,productDescription           : "C&C"
                      //  ,serviceDescription           : ""//this.data.orderShiping[0].COD_CS_CARRIER
                        ,genericString1               : "COPIA"
                        ,deliveryTypeCode             : "CL"
                        ,genericString2               : countPS.toString() + '/' + countPS.toString()
                        ,destinationCoverageAreaName  : this.data.orderShiping[0].NOTE_SHIPING         
                        ,additionalProductDescription : ""
                        ,barcode                      : ""
                        ,classificationData           : ""
                        ,printedDate                  : fechaRef
                        ,labelVersion                 : "V1.0"
                        ,distributionDescription      : this.data.orderShiping[0].NAME_WAREHOUSE
                        ,companyName                  : this.data.orderShiping[0].NAME_CS_CARRIER 
                        ,recipient                    : this.data.order.RAZON_SOCIAL
                        ,address                      : this.data.orderShiping[0].ADDRESS_WAREHOUSE   
                        ,groupReference               : this.data.order.COD_SP
                        ,frienlyEomOrder              : this.data.order.FRIENLY_EOM_ORDER
                        ,sgtmCustomer                 : this.data.order.SGTM_CUSTOMER
                        ,codCmCustomerValue           : this.data.order.COD_CM_CUSTOMER_VALUE
                        ,nameCmCustomerValue          : this.data.order.NAME_CM_CUSTOMER_VALUE
                        ,customerContactFirstName     : this.data.order.CUSTOMER_CONTACT_FIRST_NAME
                        ,customerContactLastName      : this.data.order.CUSTOMER_CONTACT_LAST_NAME
                        ,customerContactMail          : this.data.order.CUSTOMER_CONTACT_MAIL
                        ,customerContactPhone         : this.data.order.CUSTOMER_CONTACT_PHONE
                      }

              
        if(type == 'download'){

        }else{
            await  this.printZebra(detail)
        }
  }

}
//############################################################
//############################################################
async openLabel(B64,type) {
  
  const newTab = window.open(null, null, 'width=600,height=400');
  var title ='Orden:' + this.data.order.FRIENLY_EOM_ORDER + '/ OT:' +this.data.orderShiping[0].TRAKING_NUMBER ;

  newTab.document.write(
    `<a href="${B64}" download="${this.data.order.FRIENLY_EOM_ORDER}" 
    style="position: absolute; top: 1.2rem; right: 3.6rem; cursor: pointer;font:Inter;
           background-color: #D6002A; color: white; padding: 8px 12px;
           text-decoration: none; border-radius: 5px;">
   Download
 </a><div style="height:100%; display: flex; justify-content: center;"><iframe style="height:100%; width:100%;"  src="${B64}" /></div>`
  );
  setTimeout(function () {
    newTab.document.title = title
  }, 100);
}
//############################################################
//############################################################
replaceOmsOrderOT(){

      if(this.flagReplaceOT == false){
        //===================================================
        this.flagReplaceOT = true;
        this.carrierForm.get('trackID').enable();
        this.carrierForm.get('trackID').setValue(null);
        //===================================================        
      }else{
        //===================================================        
        this.flagReplaceOT = false;
        this.carrierForm.get('trackID').setValue(this.data.orderShiping[0].TRAKING_NUMBER);
        this.carrierForm.get('trackID').disable();
        //===================================================        
      }
}
//############################################################
//############################################################
async saveReplaceOmsOrderOT(){
 
var oldTrackID = "Cambio de OT Anterior:" +this.data.orderShiping[0].TRAKING_NUMBER
var data = {
  language            : "es"
 ,appToken            : null
 ,uuidSSO             : null
 ,uuidEomOrder        : this.data.order.UUID_EOM_ORDER
 ,uuidEomOrderShiping : this.data.orderShiping[0].UUID_EOM_ORDER_SHIPING
 ,trakingNumber       : this.carrierForm.value.trackID
}
let udpTrackID = await this._salesPointService.setOmsOrderSetTrackID(data);
var data2 ={
             language           : "es"
            ,appToken           : null
            ,uuidSSO            : null
            ,uuidEomOrder       : this.data.order.UUID_EOM_ORDER
            ,statusHistory      : oldTrackID
            }
let setStatusHistory = await this._orderService.setStatusHistory(data2)
this.flagReplaceOT = false;
await this.reLoadOrder()
}
//############################################################
//############################################################
async getOmsOrderOTTracking(){
  if(this.data.orderShiping.length > 0){
    var json  ={
                    language      : "es"
                    ,appToken      : null
                    ,uuidSSO       : null
                    ,uuidEomOrder  : this.data.order.UUID_EOM_ORDER
                    ,trakingNumber : this.data.orderShiping[0].UUID_EOM_ORDER_SHIPING
                }                
                
    let tmp = await this._orderService.getOmsOrderOTTracking(json)

    this.orderOTTracking = tmp.data
    await this.loadTracking(this.orderOTTracking.trackingEvents )
 }
}
//############################################################
//############################################################
async loadTracking (data): Promise<any>{
  this.isLoading=true; 
  this.orderTracking = new MatTableDataSource(data);
  this.ref.detectChanges();
  this.orderTracking.paginator = this.pagTracking;
  this.isLoading=false; 
};

//############################################################ 
//############################################################  
async printZebra(detail){
  var printerConfig = this.data.orderShiping[0].CONFIG_CS_PRINTER
  if(printerConfig.active == true  ){
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
      this.labelPrint = new LabelPrinterService();
      await this.labelPrint.setPrinter(printerConfig.typePrinter,printerConfig.stringOconn,printerConfig.namePrinter,printerConfig.flagDegub)
      await this.labelPrint.initQZ() 
      await this.labelPrint.printLabel(printerConfig.function,detail)
      return(true)
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$    
  }else{
    console.log("Impresora inactiva")
    console.log(this.data.orderShiping[0].CONFIG_CS_PRINTER)
    return(true)
  }
}
//########################################################### 
}
