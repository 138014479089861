<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <div class="w-full">
<!-- ##########################################################  -->
<!-- ##########################################################  -->
            <!-- ///////////////////////////////////////////// -->
            <!-- ///////////////////////////////////////////// -->
            <form  [formGroup]="confirmReceptionForm" *ngIf="data.type == 'confirmReception'">
                    <!-- ##########################################################  -->
                    <div class="grid grid-cols-2">       
                        <div>
                            <h2 class="prose prose-xl">Acuse de Recibo {{data.dte.GLS}}?</h2>
                        </div>

                        <!-- ## -->
                        <div>
                            <button class="close" mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>         
                        </div>
                    </div>
                    <!-- ##########################################################  -->
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
            <mat-divider class="py-2"></mat-divider>
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->   
                <div>
                    <!--#######################-->
                    <div class="grid grid-cols-2">   

                                <div >
                                    <div class="w-1/2 prose">Rut: <b>{{data.dte.RUT  |  rut:'DOTS_DASH'  }}</b></div>
                                    <div class="w-1/2 prose">Razón Social: <b>{{data.dte.RAZON_SOCIAL  | titlecase  }}</b></div>
                                    <div class="w-1/2 prose">Giro: <b>{{data.dte.GIRO_IMPRESO | titlecase  }}</b></div>
                                </div>
                                <div >
                                    <div class="w-1/2 prose">Fecha de Emisión: <b>{{ data.dte.FECHA_EMISION |  date:'dd-MM-yyyy' }}</b></div>
                                    <div class="w-1/2 prose">Folio: <b>{{ data.dte.FOLIO |  number }}</b></div>
                                </div>
                    </div>
                    <div class="grid grid-cols-4">   
                                <div class="w-1/2 prose">Neto:   <b>{{ data.dte.MNTNETO  |  currency:'CLP':'symbol-narrow' }}</b></div>
                                <div class="w-1/2 prose">Extento:<b>{{ data.dte.MNTEXE   |  currency:'CLP':'symbol-narrow' }}</b></div>
                                <div class="w-1/2 prose">IVA:    <b>{{ data.dte.IVA      |  currency:'CLP':'symbol-narrow' }}</b></div>
                                <div class="w-1/2 prose">Total:  <b>{{ data.dte.MNTTOTAL |  currency:'CLP':'symbol-narrow' }}</b></div>
                    </div>
                    <!--#######################-->                    
                    </div>
                        <div class="w-full">
                            <!--#######################-->
                            <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet w-1/2">
                                <mat-label>Selecione estado </mat-label>
                                <mat-select formControlName="recepcionEnvio" (selectionChange)="setReason($event.value,'recepcionEnvio')" >
                                    <ng-container *ngFor="let p of recepcionEnvio">
                                        <mat-option [value]="p.cod">
                                                            {{p.gls}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <!--#######################-->
                        </div>
                        <div class="w-full">
                            <!--#######################-->
                            <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet w-1/2">
                                <mat-label>Selecione estado </mat-label>
                                <input [formControlName]="'glsRechazo'"  matInput>
                            </mat-form-field>
                            <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet w-1/2">
                                <mat-label>Selecione estado </mat-label>
                                <input [formControlName]="'motivoRechazo'"  matInput>
                            </mat-form-field>                            
                            <!--#######################-->
                        </div>                        
                        <div>
                            <!--#######################-->
                            <br>
                            <div class="shadowbox" >{{data.dte.ESTADO_DTE}}</div>
                            <br>
                            <!--#######################-->
                        </div>                        
                        <div class="w-3/4 ">
                            <!--####################### olor="accent"-->            
                            <button mat-flat-button color="primary" (click)="makRerecepcionEnvio()" [disabled]="dteForm.invalid"> Enviar Acuse de Recibo</button>
                            <!--#######################-->            
                        </div>
                    
                </form>                
             <!-- 
                    <div class="flex flex-auto flex-wrap">
                        <div class="flex">
                                Aceptar <mat-slide-toggle formControlName="accept" (change)="disabledInput()"> Rechazar </mat-slide-toggle>
                        </div>
                        <mat-form-field class="flex-1">
                            <mat-label> Razon de rechazo </mat-label>
                            <input matInput type="text" formControlName="rzn">
                        </mat-form-field>
                        <div class="flex ">
                            <button mat-flat-button color="accent" (click)="close()"> Enviar</button>
                        </div>
                    </div>
                </form>
                -->           
            <!-- ///////////////////////////////////////////// -->
            <!-- ///////////////////////////////////////////// --> 
            <!-- ///////////////////////////////////////////// -->
            <!-- ///////////////////////////////////////////// -->      
            <form  [formGroup]="dteForm" *ngIf="data.type == 'rspComercial'">        
                    <!-- ##########################################################  -->
                    <div class="grid grid-cols-2">       
                        <div>
                            <h2 class="prose prose-xl">Respuesta Comercial  {{data.dte.GLS}}?</h2>
                        </div>

                        <!-- ## -->
                        <div>
                            <button class="close" mat-button (click)="closeBtn()" > <mat-icon>close</mat-icon></button>         
                        </div>
                    </div>
                    <!-- ##########################################################  -->
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
            <mat-divider class="py-2"></mat-divider>
            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->             
                <div>
                        <!--#######################-->
                        <div class="grid grid-cols-2">   

                                    <div >
                                        <div class="w-1/2 prose">Rut: <b>{{data.dte.RUT  |  rut:'DOTS_DASH'  }}</b></div>
                                        <div class="w-1/2 prose">Razón Social: <b>{{data.dte.RAZON_SOCIAL  | titlecase  }}</b></div>
                                        <div class="w-1/2 prose">Giro: <b>{{data.dte.GIRO_IMPRESO | titlecase  }}</b></div>
                                    </div>
                                    <div >
                                        <div class="w-1/2 prose">Fecha de Emisión: <b>{{ data.dte.FECHA_EMISION |  date:'dd-MM-yyyy' }}</b></div>
                                        <div class="w-1/2 prose">Folio: <b>{{ data.dte.FOLIO |  number }}</b></div>
                                    </div>
                        </div>
                        <div class="grid grid-cols-4">   
                                    <div class="w-1/2 prose">Neto:   <b>{{ data.dte.MNTNETO  |  currency:'CLP':'symbol-narrow' }}</b></div>
                                    <div class="w-1/2 prose">Extento:<b>{{ data.dte.MNTEXE   |  currency:'CLP':'symbol-narrow' }}</b></div>
                                    <div class="w-1/2 prose">IVA:    <b>{{ data.dte.IVA      |  currency:'CLP':'symbol-narrow' }}</b></div>
                                    <div class="w-1/2 prose">Total:  <b>{{ data.dte.MNTTOTAL |  currency:'CLP':'symbol-narrow' }}</b></div>
                        </div>
                        <!--#######################-->                    
                </div>
                    <div class="w-full">
                        <!--#######################-->
                        <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet w-1/2">
                            <mat-label>Selecione estado </mat-label>
                            <mat-select formControlName="resultadoDTE" (selectionChange)="setReason($event.value,'resultadoDTE')" >
                                <ng-container *ngFor="let p of resultadoDTE">
                                    <mat-option [value]="p.cod">
                                                        {{p.gls}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <!--#######################-->
                    </div>
                    <div class="w-1/2">
                        <!--#######################-->
                        <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet w-1/2">
                            <mat-label>Selecione estado </mat-label>
                            <input [formControlName]="'glsRechazo'"  matInput readonly>
                        </mat-form-field>                           
                        <!--#######################-->
                    </div> 
                    <div *ngIf="toggleMotivoRechazo == true" class="w-full">
                        <!--#######################-->
                        <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet w-1/2">
                            <mat-label>Motivo Rechazo </mat-label>
                            <input [formControlName]="'motivoRechazo'"  matInput>
                        </mat-form-field>                            
                        <!--#######################-->
                    </div>                                        
                    <div>
                        <!--#######################-->
                        <br>
                        <div class="shadowbox" >{{data.dte.ESTADO_DTE}}</div>
                        <br>
                        <!--#######################-->
                    </div>                        
                    <div class="w-3/4 ">
                        <!--####################### olor="accent"-->            
                        <button mat-flat-button color="primary" (click)="makeRspComercial()"  [disabled]="dteForm.invalid"> Enviar Respuesta Comercial </button>
                        <!--#######################-->            
                    </div>
                
            </form>
        <!-- ///////////////////////////////////////////// -->
        <!-- ///////////////////////////////////////////// --> 
<!-- ##########################################################  -->
<!-- ##########################################################  -->
 </div>
</div>          