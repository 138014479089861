<div class="flex flex-col bg-white p-6 my-4 mx-6 rounded-lg shadow-md">
    <h1 class="text-2xl font-bold mb-4">Reportar Error</h1>
    <div class="mb-4">
      <textarea 
        class="border w-full p-2 rounded-md"  
        placeholder="Por favor, describe el error aquí"></textarea>
    </div>
    <div class="flex justify-end">
      <button
        mat-button
        (click)="closeWithoutSend()"
        class="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" 
        mat-dialog-close >Cancelar</button>
      <button 
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        (click)="closeAndSend()"
        cdkFocusInitial>Enviar</button>
    </div>
  </div>