<div class="w-full  sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"></div>
<div class="w-full">
    <!-- ##########################################################  --> 
    <div class="grid grid-cols-10">   
        <div class="col-span-8">
            <!-- ## -->
                <h2 class="prose prose-xl"><b> Selecione Producto y Combinación </b></h2>                       
               <!--  <h2 class="prose "> Editar Guia Entrada </h2>   -->
            <!-- ## -->
        </div>
        <div class="col-span-1">
            <!-- ## -->
            <button class="close " mat-button (click)="closeBtn()"> <mat-icon>close</mat-icon></button>    
            <!-- ## -->
        </div>
    </div>
    <!-- ##########################################################  -->    

</div>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
<mat-divider class="py-2"></mat-divider>
<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%  -->
  <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
    <!-- Search Bar -->
    <mat-form-field class="fuse-mat-dense fuse-mat-emphasized-affix selecet form-gen w-1/2">
      <mat-icon matPrefix> find_in_page </mat-icon>
      <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." #input>
    </mat-form-field>
    <!-- Search By Family -->
    <!-- Selec date -->
  
  </div>
  <div> 
    <div class="mat-elevation-z8">
  <!--====================================================================--> 

  <!--====================================================================--> 
      <mat-table [dataSource]="psTable" class="mat-elevation-z8">
        <!--//-->
        <ng-container matColumnDef="img">
            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-sku">img</mat-header-cell>
            <mat-cell *matCellDef="let o">
                                            <div class="flex items-center">
                                                <div class="relative flex flex-0 items-center justify-center w-12 h-12 mr-6 rounded overflow-hidden border">
                                                    <img
                                                        class="w-8"
                                                        *ngIf="o.IMG_D !== '0'"
                                                        [alt]="'Product thumbnail image'"
                                                        [src]="appImgURL + appTokenImg + '/' + o.IMG_D + appImgSizeA">
                                                      
                                                    <div
                                                        class="flex items-center justify-center w-full h-full text-xs font-semibold leading-none text-center uppercase"
                                                        *ngIf="o.IMG_D === '0'">
                                                        NO IMG
                                                    </div>
                                                </div>
                                            </div>
            </mat-cell>
        </ng-container>
        <!--//-->
        <ng-container matColumnDef="SKU">
            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-quantity">SKU</mat-header-cell>
            <mat-cell *matCellDef="let o">
                <small><b (click)="editOrCreateProduct(o.UUID_PS_SERVICES)" style="cursor: pointer;color: rgb(9, 9, 132)">{{o.COD_PS_SERVICES}}</b><br>{{o.NAME_PS_SERVICES}}</small>
            </mat-cell>
        </ng-container>        
        <!--//-->                                   
        <ng-container matColumnDef="namePS">
            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Nombre Producto</mat-header-cell>
            <mat-cell *matCellDef="let o">{{o.NAME_PS_SERVICES}}</mat-cell>
        </ng-container> 
        <!--//-->                                                
        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Neto</mat-header-cell>
            <mat-cell *matCellDef="let o">{{o.PRICE.PRICE | currency:'CLP':'symbol-narrow'}}</mat-cell>
        </ng-container>               
        <!--//-->   
            <!--//-->                                                
            <ng-container matColumnDef="combinacion_A">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Combinación 1</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.NAME_COMBINATION_PROPERTY_A}}</small></mat-cell>
            </ng-container>               
            <!--//-->   
            <ng-container matColumnDef="atributos_A">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Tipo Combinación 1</mat-header-cell>
                <mat-cell *matCellDef="let o">
                            <div> 
                                <!--// TYPE_COMBINATION_PROPERTY -->
                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_A == 'COLOR'" >
                                        <!--//-->
                                                <small>{{o.TYPE_COMBINATION_PROPERTY_A}}</small>
                                                <mat-icon   [ngStyle]="{'color': o.VALUE_COMBINATION_PROPERTY_A }"  [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                                <small><b>{{ o.VALUE_COMBINATION_PROPERTY_A }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_A }}</small>                                     
                                        <!--//-->                                
                                    </div>
                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_A == 'TXT'  " >    
                                        <!--//-->
                                                <small><b>{{ o.VALUE_COMBINATION_PROPERTY_A }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_A }}</small>                                        
                                        <!--//-->                                
                                    </div>                                                                  
                                <!--//-->
                            </div>
                    </mat-cell>
            </ng-container>                        
            <!--//-->   
        <!--//-->   
            <!--//-->                                                
            <ng-container matColumnDef="combinacion_B">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase text-sm text-gray-600">Combinación 2</mat-header-cell>
                <mat-cell *matCellDef="let o"><small>{{o.NAME_COMBINATION_PROPERTY_B}}</small></mat-cell>
            </ng-container>               
            <!--//-->   
            <ng-container matColumnDef="atributos_B">
                <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label">Tipo Combinación 2</mat-header-cell>
                <mat-cell *matCellDef="let o">
                            <div>
                                <!--// TYPE_COMBINATION_PROPERTY -->
                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_B == 'COLOR'" >
                                        <!--//-->
                                                <small>{{o.TYPE_COMBINATION_PROPERTY_B}}</small>
                                                <mat-icon   [ngStyle]="{'color': o.VALUE_COMBINATION_PROPERTY_B }"  [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                                <small><b>{{ o.VALUE_COMBINATION_PROPERTY_B }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_B }}</small>                                     
                                        <!--//-->                                
                                    </div>
                                    <div *ngIf="o.TYPE_COMBINATION_PROPERTY_B == 'TXT'  " >    
                                        <!--//-->
                                                <small><b>{{ o.VALUE_COMBINATION_PROPERTY_B }}</b> <br> {{ o.TXT_COMBINATION_PROPERTY_B }}</small>                                        
                                        <!--//-->                                
                                    </div>                                                                  
                                <!--//-->
                            </div>
                    </mat-cell>
            </ng-container>                        
            <!--//-->             
        <ng-container matColumnDef="acc">
            <mat-header-cell *matHeaderCellDef class="font-bold uppercase mat-column-cod-label"></mat-header-cell>
            <mat-cell *matCellDef="let o">
                <button
                    mat-button
                    color="primary"
                    (click)="addCombinationDetail(o)">
                    <mat-icon>playlist_add</mat-icon>
                </button>                
            </mat-cell>
        </ng-container>                       
        <!--//-->                                                                     
        <mat-header-row *matHeaderRowDef="psColums" class="bg-gray-100 border-b border-gray-200"></mat-header-row>
        <mat-row *matRowDef="let row;columns: psColums" class="hover:bg-gray-100 border-b border-gray-200"></mat-row>
        <!--//-->                    
    </mat-table>
        <!--//-->      
        <mat-spinner *ngIf="isLoadingPs" style="margin:0 auto"></mat-spinner>         
        <mat-paginator
        #pagPS
        [pageSize]="psPag"
        showFirstLastButtons
        [pageSizeOptions]="pageSizeOptions" 
        aria-label="Select page of periodic elements">
    </mat-paginator>               
        <!--//-->   
  <!--====================================================================--> 
  <!--====================================================================--> 
  </div>
  </div>