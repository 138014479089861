import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { orderTable } from '../model/order';
import { ctzTable,tktTable,dteTable,dteGetTable } from '../model/dte';
import { ctzOmsTable } from '../model/ctzOms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {


    appToken = localStorage.getItem('appToken');//environment.appTokenUser;



    constructor(
      private _http: HttpClient,
      private _toast: ToastrService
    ) { }
  
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getOrders(uuidSp : string,uuidCustomer: string ,year: number, month: number): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise (async function(resolve, reject) {
    try {
      const url = `${environment.endPoints.orderList}/${$this.appToken}/${uuidSp}/${uuidCustomer}/${year}/${month}`;
      const res = await $this._http.get<any>(url);
      res.subscribe(
        async (data) => {
          resolve(data);
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    }
    catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getOrdersDetail(uuidEomOrder : string): Promise<any>{
    const $this = this;
    this.appToken = localStorage.getItem('appToken');
    return await new Promise (async function(resolve, reject) {
      try {
        const url = `${environment.endPoints.orderDetail}/${$this.appToken}/${uuidEomOrder}`;
        const res = await $this._http.get<any>(url);
        res.subscribe(
          async (data) => {
            resolve(data);
          },
          (error) => {
            console.log(error);
            resolve(error);
          }
        );
      }
      catch (err) {
        console.log(err);
        resolve(err);
      }
    });
  };
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
public getOrderPagination(
  uuidSp             : string
 ,uuidCustomer       : string 
 ,year               : number
 ,month              : number
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,codEomOrderStatus  : string 
 ,uuidCmEmployee     : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<orderTable> {

 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidSp
 + '/' + uuidCustomer	
 + '/' + year
 + '/' + month
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + codEomOrderStatus			
 + '/' + uuidCmEmployee
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.orderListPagination}/${url}`;

 return this._http.get<orderTable>(url);
}
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
public getDtePagination(
  uuidCustomer       : string 
 ,type               : string
 ,year               : number
 ,month              : number
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<dteTable> {

 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidCustomer
 + '/' + type	
 + '/' + year
 + '/' + month
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.dteListPagination}/${url}`;

 return this._http.get<dteTable>(url);
}
//////////////////////////////////////////////////////////////////
public getTktPagination(
  uuidCustomer       : string 
 ,year               : number
 ,month              : number
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<tktTable> {

 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidCustomer
 + '/' + 'TKT'	
 + '/' + year
 + '/' + month
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.dteListPagination}/${url}`;

 return this._http.get<tktTable>(url);
}
//////////////////////////////////////////////////////////////////
public getCtzPagination(
  uuidCustomer       : string 
 ,year               : number
 ,month              : number
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<ctzTable> {

 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidCustomer
 + '/' + 'CTZ'	
 + '/' + year
 + '/' + month
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.dteListPagination}/${url}`;

 return this._http.get<ctzTable>(url);
}
//////////////////////////////////////////////////////////////////
public getDteGetPagination(
  uuidCustomer       : string 
 ,type               : string  
 ,year               : number
 ,month              : number
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<dteGetTable> {

 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidCustomer
 + '/' + type		
 + '/' + year
 + '/' + month
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.dteListPagination}/${url}`;

 return this._http.get<dteGetTable>(url); 
}
//////////////////////////////////////////////////////////////////
public getOmsCtzPagination(
  uuidCustomer       : string 
 ,year               : number
 ,month              : number
 ,page               : number
 ,perPage            : number
 ,from               : string 
 ,searchTerm         : string 
 ,orderBy            : string 
): Observable<ctzOmsTable> {

 const $this = this;
 var url;
 this.appToken = localStorage.getItem('appToken');
 url =  $this.appToken 
 + '/' + uuidCustomer
 + '/' + year
 + '/' + month
 + '/' + page	
 + '/' + perPage		
 + '/' + from											
 + '/' + searchTerm			
 + '/' + orderBy;
url = `${environment.endPoints.ctzListPagination}/${url}`;

 return this._http.get<ctzOmsTable>(url);
}
//////////////////////////////////////////////////////////////////
async newOmsOrderOT(order): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.newOmsOrderOT}`

      order.appToken = $this.appToken
      order.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(order)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('OT Creada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear OT', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////// 
async getOmsOrderOTLabel(label): Promise<any>{
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.getOmsOrderOTLabel}`

      label.appToken = $this.appToken
      label.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(label)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Etiqueta Creada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('No es posble crear Etiqueta', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async setStatusHistory(history): Promise<any>{ 
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.statusHistory}`

      history.appToken = $this.appToken
      history.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(history)
                                              })
      data.subscribe(
        (res: any) => {
            this._toast.success('Orden Actualizada', 'Éxito');
          resolve(res.dataSet);
        },
        (error) => {
          this._toast.warning(error.error.dataSet.glsStatus, 'Alerta');
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('Orden Actualizada', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
async getOmsOrderOTTracking(history): Promise<any>{ 
  const $this = this;
  this.appToken = localStorage.getItem('appToken');
  return await new Promise(async (resolve, reject) => {
    try {
      const url = `${environment.endPoints.orderOTTracking}`

      history.appToken = $this.appToken
      history.language = "es"

      const data = await $this._http.post(url,{
                                                'data': JSON.stringify(history)
                                              })
      data.subscribe(
        (res: any) => {
          resolve(res.dataSet);
        },
        (error) => {
          reject(error.error);
        }
      );
    }catch(err) {
      this._toast.error('Error al obtener traking', 'Error');
      console.log(err);
      reject(err);
    }
  });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
}