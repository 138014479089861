import { Pipe, PipeTransform } from '@angular/core';
import * as rut from '@fdograph/rut-utilities';

@Pipe({
  name: 'rut'
})
export class RUTformatPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const rutValue = value.toString();
    if(value){
      if(args.includes('DASH')){
        return rut.formatRut(rutValue, rut.RutFormat.DASH);
      };
      if(args.includes('DOTS')){
        return rut.formatRut(rutValue, rut.RutFormat.DOTS);
      };
      if(args.includes('DOTS_DASH')){
        //############################################################
        switch (value) {
          case '55555555-5':
            return '55.555.555-5';
            break;
          case '66666666-6':
            return '66.666.666-6';
            break;
          case '77777777-7':
            return '77.777.777-7';
            break;
          case '88888888-8':
            return '88.888.888-8';
            break;
          case '99999999-9':
            return '99.999.999-9';
            break;
          case '00000000-0':
            return '00.000.000-0';
            break;
          default:
            return rut.formatRut(rutValue, rut.RutFormat.DOTS_DASH);
        }
        //############################################################        
      }

    }
    return null;
  }

}
