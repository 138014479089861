/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';


@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
       

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials): Observable<any>{
        // console.log('credentials', credentials);
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post( environment.endPoints.signInSSO, {'data': JSON.stringify(credentials)}).pipe(
            switchMap((response: any) => {
                localStorage.setItem('ssoData', JSON.stringify(response.dataSet));
               // if(response.dataSet.user.flagAvatar === 'Y'){
                    localStorage.setItem('avatar', response.dataSet.user.urlAvatar);
               // }
                // Store the access token in the local storage
                this.accessToken = response.dataSet.token;
                localStorage.setItem('refreshToken', response.dataSet.refreshtoken);
                localStorage.setItem('Token', response.dataSet.token);
                // Set the authenticated flag to true
                this._authenticated = true;
                // Store the user on the user service
                this._userService.user = response.dataSet.user;
                // Return a new observable with the response
                return of(response);
            })
        );
    }
//==========================================================================================
async signIn2(credentials: object): Promise<any>{

    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.endPoints.signInSSO}`
        const data = await $this._httpClient.post(url,{
                                                  'data': JSON.stringify(credentials)
                                                })
        data.subscribe(
          (res: any) => {
            this.accessToken = res.dataSet.token;
            this._authenticated = true;
            this._userService.user = res.dataSet.user;
            resolve(res.dataSet);
          }
        );
      }catch(err) {
        console.log(err);
        reject(err);
      }
    });
  };
//==========================================================================================

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('Token');
        localStorage.removeItem('emisorData');
        localStorage.removeItem('rutEmpresa');
        localStorage.removeItem('appToken');
        localStorage.removeItem('ssoData');
        localStorage.removeItem('avatar');
        localStorage.removeItem('modules');
        localStorage.removeItem('uuidEmployee');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
 signUp(user: { name: string; email: string; password: string; company: string }) : Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    /**
     * Get emisor data
     */

//////////////////////////////////////////////////////////////////
async getEmisorData(rutEmpresa: any): Promise<any> {

    const $this = this;
    return await new Promise(async (resolve, reject) => {
      try {
        const data = await $this._httpClient.get(`${environment.endPoints.emisorData}${rutEmpresa}`);
        data.subscribe(
          (res: any) => {
            if(res.dataSet.status == false){
                reject (res)

            }
            else{
            localStorage.setItem('emisorData'  , JSON.stringify(res.dataSet));
            localStorage.setItem('uuidEmployee', res.dataSet.usrData[0].UUID_EMISOR_USR);
            resolve(res.dataSet.data);
            }
          },
          (err) => {
            reject(err)
          }
        );
      }catch(err) {     
        reject(err);
      }
    });
  
  }
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

    /*
  async  getEmisorData(rutEmpresa: any): Promise<any> {
        this._httpClient.get<any>(`${environment.endPoints.emisorData}${rutEmpresa}`).subscribe(
            (res) => {
                localStorage.setItem('emisorData'  , JSON.stringify(res.dataSet));
                localStorage.setItem('uuidEmployee', res.dataSet.usrData[0].UUID_EMISOR_USR);
                resolve(res)
            },
            (err) => {
                console.log('err', err);
            }
        );

    }
}

*/
}