<button mat-button (click)="btnClose()"> <mat-icon>close</mat-icon></button>
<div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- CREAR  CONTACTO  -->
<form [formGroup]="formContact" *ngIf="data.tab === 'contact' && data.type === 'create'" class="w-full">

    <mat-card >
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Crear Contacto</mat-card-title>
        </mat-card-header>
<mat-card-content>

    <div class="flex space-x-5">

        <mat-form-field class="w-3/4">
            <mat-label> Tipo </mat-label>
            <mat-select formControlName="idCrmCustomerContactType" #singleSelect>
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="tipoFilterCtrl"
                        placeholderLabel="Buscar..."
                        noEntriesFoundLabel="No se encontraron resultados"
                    ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let t of (filteredTipo | async)" [value]="t.ID_CRM_CUSTOMER_CONTACT_TYPE">
                    {{t.GLS_CONTACT_TYPE}}
                </mat-option>
            </mat-select>
        </mat-form-field>
</div>
<div>
        <mat-form-field class="w-1/2">
            <mat-label>Rut</mat-label>
            <input
                matInput
                formControlName="legalNumber"
            />
        </mat-form-field>
</div>
<div class="flex space-x-10">
        <mat-form-field class="w-1/2">
            <mat-label>Nombre</mat-label>
            <input
                matInput
                formControlName="firstName"
            />
        </mat-form-field>
        <mat-form-field class="w-1/2">
            <mat-label>Segundo nombre</mat-label>
            <input
                matInput
                formControlName="firstName2"
            />
        </mat-form-field>
        </div>
        <div class="flex space-x-10">
        <mat-form-field class="w-1/2">
            <mat-label>Apellido Paterno</mat-label>
            <input
                matInput
                formControlName="lastName"
            />
        </mat-form-field>
        <mat-form-field class="w-1/2">
            <mat-label>Apellido Materno</mat-label>
            <input
                matInput
                formControlName="lastName2"
            />
        </mat-form-field>
    </div>
    <div class="flex space-x-5">
        <mat-form-field class="w-3/4">
            <mat-label>Email</mat-label>
            <input
                matInput
                type="email"
                formControlName="mail"
            />
        </mat-form-field>
    </div>    
    <div class="flex space-x-10">
        <mat-form-field class="w-1/2">
            <mat-label>Telefono</mat-label>
            <input matInput formControlName="phone" />
        </mat-form-field>

        <mat-form-field class="w-1/2">
            <mat-label>Cumpleaños</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthday" >
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

        </mat-form-field>                
    </div>
</mat-card-content>
</mat-card >
<button mat-raised-button color="primary" (click)="createContact()" [disabled]="formContact.invalid"  > Crear Contacto </button>  
</form>
<!-- //////////////////////////////////////////////////////////////////////////////////////////////// -->
<!-- EDITAR CONTACTO  -->
<form [formGroup]="formContact" *ngIf="data.tab === 'contact' && data.type === 'edit'" class="w-full">

    <mat-card >
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Actualizar Contacto</mat-card-title>
        </mat-card-header>
<mat-card-content>

    <div class="flex space-x-5">

        <mat-form-field class="w-3/4">
            <mat-label> Tipo </mat-label>
            <mat-select formControlName="idCrmCustomerContactType" #singleSelect>
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="tipoFilterCtrl"
                        placeholderLabel="Buscar..."
                        noEntriesFoundLabel="No se encontraron resultados"
                    ></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let t of (filteredTipo | async)" [value]="t.ID_CRM_CUSTOMER_CONTACT_TYPE">
                    {{t.GLS_CONTACT_TYPE}}
                </mat-option>
            </mat-select>
        </mat-form-field>
</div>
<div>
        <mat-form-field class="w-1/2">
            <mat-label>Rut</mat-label>
            <input
                matInput
                formControlName="legalNumber"
            />
        </mat-form-field>
</div>
<div class="flex space-x-10">
        <mat-form-field class="w-1/2">
            <mat-label>Nombre</mat-label>
            <input
                matInput
                formControlName="firstName"
            />
        </mat-form-field>
        <mat-form-field class="w-1/2">
            <mat-label>Segundo nombre</mat-label>
            <input
                matInput
                formControlName="firstName2"
            />
        </mat-form-field>
        </div>
        <div class="flex space-x-10">
        <mat-form-field class="w-1/2">
            <mat-label>Apellido Paterno</mat-label>
            <input
                matInput
                formControlName="lastName"
            />
        </mat-form-field>
        <mat-form-field class="w-1/2">
            <mat-label>Apellido Materno</mat-label>
            <input
                matInput
                formControlName="lastName2"
            />
        </mat-form-field>
    </div>
    <div class="flex space-x-5">
        <mat-form-field class="w-3/4">
            <mat-label>Email</mat-label>
            <input
                matInput
                type="email"
                formControlName="mail"
            />
        </mat-form-field>
    </div>    
    <div class="flex space-x-10">
        <mat-form-field class="w-1/2">
            <mat-label>Telefono</mat-label>
            <input matInput formControlName="phone" />
        </mat-form-field>

        <mat-form-field class="w-1/2">
            <mat-label>Cumpleaños</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthday" >
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

        </mat-form-field>                
    </div>
</mat-card-content>
</mat-card >
<button mat-raised-button color="primary" (click)="updateContact()" [disabled]="formContact.invalid"  > Actualizar Contacto </button>  
</form>      
<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- CREAR CUSTOMER  -->
<form [formGroup]="formCustomer" *ngIf="data.tab === 'customer' && data.type === 'create'" class="w-full">

    <!--##############################################################-->
    <div class="w-full  items-center ">
    <mat-card *ngIf="flagSgtm == false">
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Rut Cliente</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex  items-center">

                <mat-form-field class="w-1/2">
                    <mat-label> Rut</mat-label>
                    <input matInput formControlName="rut"/>
                </mat-form-field>
                <button class="ml-4" mat-raised-button color="primary" (click)="chkSgtm()" [disabled]="flagSgtm == true"  > Crear </button>
            </div>
        </mat-card-content>
    </mat-card>
    <button *ngIf="flagSgtm == false" class="ml-4" mat-raised-button color="primary" (click)="chkSgtmExtranjero()"  > Crear Extranjero </button>
    </div>
    <!--##############################################################-->
    <mat-card *ngIf="flagSgtm == true">
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold"
                >Información General</mat-card-title
            >
        </mat-card-header>
        <mat-card-content>
            <div class="prose prose-xl">
                <div ><h2  style="color: rgb(9, 9, 132)"><b>{{rutCustomerFormat}}</b></h2></div>
                <div *ngIf="segmento == 'E'">Segmento: <b>Empresas</b></div>
                <div *ngIf="segmento == 'P'">Segmento: <b>Personas</b></div>
                <div *ngIf="segmento == 'X'">Segmento: <b>Extranjero</b></div>
            </div>
            <br>
            <div class="flex space-x-5">
                <mat-form-field class="w-1/2">
                    <mat-label *ngIf="segmento == 'E'">Razon Social</mat-label>
                    <mat-label *ngIf="segmento == 'P'">Nombre y Apellido</mat-label>
                    <input matInput formControlName="razonSocial"   (change)="setContactData('rzn',$event.target.value)"/>
                </mat-form-field>
            </div>
            <!--///-->
            <mat-slide-toggle color="primary" formControlName="flagShowFancyName"> Nombre de Fantasia</mat-slide-toggle>
                <div class="flex space-x-5" *ngIf="formCustomer.get('flagShowFancyName').value  == true">
                    <mat-form-field class="w-1/2">
                        <mat-label>Nombre de Fantasia</mat-label>
                        <input matInput formControlName="customerFancyName" />
                    </mat-form-field>
                </div>
            <!--///-->                
            <div class="flex space-x-5">
                <mat-form-field class="w-1/2">
                    <mat-label>Giro</mat-label>
                    <input matInput formControlName="giroImpreso" />
                </mat-form-field>
            </div>
            <div class="flex space-x-5">
                <mat-form-field class="w-1/2">
                    <mat-label>Dirección</mat-label>
                    <input matInput formControlName="direccion" />
                </mat-form-field>     
            </div>
                <!-- ///////////////////////////////////////////////-->
                <div class="flex space-x-5">
                <mat-form-field class="w-full">
                    <mat-label> Región </mat-label>
                    <mat-select formControlName="idRegion"  (selectionChange)="setCiudad('customer',$event.value)">
                        <ng-container *ngFor="let region of regionList">
                            <mat-option [value]="region.ID_REGION">
                                {{ region.GLS_REGION }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label> Provincia </mat-label>
                    <mat-select formControlName="idCiudad" (selectionChange)="setComuna('customer',$event.value)">
                        <ng-container *ngFor="let ciudad of ciudadListFiltered">
                            <mat-option [value]="ciudad.ID_CIUDAD">
                                {{ ciudad.GLS_CIUDAD }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label> Comuna </mat-label>
                    <mat-select formControlName="idComuna">
                        <ng-container *ngFor="let comuna of comunaListFiltered">
                            <mat-option [value]="comuna.ID_COMUNA">
                                {{ comuna.GLS_COMUNA }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <!--////////////////////////////////////////////////-->
            </div>
            <div class="flex space-x-5">
                <!-- 
                <mat-form-field class="w-1/2">
                    <mat-label>Contacto DTE</mat-label>
                    <input matInput formControlName="contactoDte" />
                </mat-form-field>
                -->
                <mat-form-field class="w-1/2">
                    <mat-label>Casilla envio DTE SII</mat-label>
                    <input matInput formControlName="emailDte"   (change)="setContactData('mail',$event.target.value)"/>
                </mat-form-field>     
                <!--
                <mat-form-field class="w-1/2">
                    <mat-label>Telefono DTE</mat-label>
                    <input matInput formControlName="telefonoDte" />
                </mat-form-field>                     
                 -->   
                 <mat-form-field class="w-full">
                    <mat-label> Valor Cliente </mat-label>
                    <mat-select formControlName="idCmCustomerValue">
                        <ng-container *ngFor="let v of customerValue">
                            <mat-option [value]="v.ID_CM_CUSTOMER_VALUE">
                                {{ v.COD_CM_CUSTOMER_VALUE }}-{{ v.NAME_CM_CUSTOMER_VALUE}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>                    
            </div> 
           
            <br>
            <div class="flex space-x-5">
                <mat-slide-toggle color="primary" formControlName="flagCustomer"> Cliente</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagSupplier"> Proveedor</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagEmployee"> Empleado</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagAssociate"> Socio</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagDistributor"> Distribuidor</mat-slide-toggle>  
            </div>            
    </mat-card-content>        
    </mat-card>
                                   
    <mat-card *ngIf="flagSgtm == true">
                <mat-card-header class="bg-gray-200">
                    <mat-card-title class="text-lg font-semibold"
                        >Contacto</mat-card-title
                    >
                </mat-card-header>
                <mat-card-content>

            <div class="flex space-x-5">
                <mat-form-field class="w-1/5">
                    <mat-label>Rut</mat-label>
                    <input
                        matInput
                        formControlName="contacRut"
                    />
                </mat-form-field>

                <mat-form-field class="w-1/5">
                    <mat-label>Nombre</mat-label>
                    <input
                        matInput
                        formControlName="contacFirstName"
                    />
                </mat-form-field>
                <mat-form-field class="w-1/5">
                    <mat-label>Segundo nombre</mat-label>
                    <input
                        matInput
                        formControlName="contacFirstName2"
                    />
                </mat-form-field>
                </div>
                <div class="flex space-x-5">
                <mat-form-field class="w-1/5">
                    <mat-label>Apellido Paterno</mat-label>
                    <input
                        matInput
                        formControlName="contacLastName"
                    />
                </mat-form-field>
                <mat-form-field class="w-1/5">
                    <mat-label>Apellido Materno</mat-label>
                    <input
                        matInput
                        formControlName="contacLastName2"
                    />
                </mat-form-field>
            </div>
            <div class="flex space-x-5">
                <mat-form-field class="w-1/4">
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        type="email"
                        formControlName="contacMail"
                    />
                </mat-form-field>
                <mat-form-field class="w-1/4">
                    <mat-label>Telefono</mat-label>
                    <input matInput formControlName="contacPhone" />
                </mat-form-field>
                <mat-form-field class="w-1/4">

                    <mat-label>Cumpleaños</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="contacBirthday" >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>

                </mat-form-field>                
            </div>


        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="flagSgtm == true">
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Datos adicionales</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex space-x-5">
                <mat-slide-toggle color="primary" formControlName="flagNewsletter"> Suscrito Newsletter</mat-slide-toggle>
            </div>
            <div class="flex space-x-5" >
                <mat-form-field class="w-1/2" *ngIf="segmento =='E'">
                    <mat-label>Sitio web</mat-label>
                    <input matInput formControlName="website" />
                </mat-form-field>     
                
                <mat-form-field class="w-1/2">
                    <mat-label>Dias de Vencimiento</mat-label>
                    <mat-select formControlName="maxPaymentDays" >
                        <ng-container *ngFor="let d of dueDates">
                            <mat-option [value]="d.id">
                                {{ d.val }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>     

                <mat-form-field class="w-1/2">
                    <mat-label>Limite de Credito</mat-label>
                    <input matInput formControlName="creditLimit" />
                </mat-form-field>     
            </div>  
        </mat-card-content>
    </mat-card>
    
    <mat-card *ngIf="flagSgtm == true && segmento =='X'">
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Extranjero</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex space-x-5">
             <!--    <mat-slide-toggle color="primary" formControlName="flagExtranjero"> Extranjero</mat-slide-toggle>-->
            </div>
            <div *ngIf="formCustomer.value.flagExtranjero == true" class="flex space-x-5" >
                <!-- ## -->
                <mat-form-field class="w-1/2 fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                    <mat-label> Nacionalidad </mat-label>
                    <mat-select  formControlName="codNacionalidad">
                        <ng-container *ngFor="let n of nacionalidad">
                            <mat-option [value]="n.codCndcn">{{n.desCndcn}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <!-- ## -->
                <mat-form-field class="w-1/2 fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                    <mat-label> Tipo de Documento del Turista </mat-label>
                    <mat-select  formControlName="tipoDocId">
                        <ng-container *ngFor="let n of tipoDocID">
                            <mat-option [value]="n.codCndcn">{{n.desCndcn}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <!-- ## -->                

            </div>  
        </mat-card-content>
    </mat-card>

    <button *ngIf="flagSgtm == true" mat-raised-button color="primary" (click)="createCustomer()" [disabled]="formCustomer.invalid"  > Crear Cliente </button>
</form>
<!-- ////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Editar CUSTOMER  -->
<form [formGroup]="formCustomer" *ngIf="data.tab === 'customer' && data.type === 'edit'" class="w-full">

    <!--##############################################################-->
    <mat-card *ngIf="flagSgtm == true">
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold"
                >Información General</mat-card-title
            >
        </mat-card-header>
        <mat-card-content>
            <div class="prose prose-xl">
                <div>
                        <h2  style="color: rgb(9, 9, 132)"><b>{{rutCustomerFormat}}</b></h2>
                        <button *ngIf="data.source == 'claim'"mat-raised-button color="primary"  mat-button (click)="selectCustomerClaim()"> Selecionar Cliente</button>
                </div>
                
                <div *ngIf="segmento == 'E'">Segmento: <b>Empresas</b></div>
                <div *ngIf="segmento == 'P'">Segmento: <b>Personas</b></div>
            </div>
         
            <div class="flex space-x-5">
                <mat-form-field class="w-1/2">
                    <mat-label *ngIf="segmento == 'E'">Razon Social</mat-label>
                    <mat-label *ngIf="segmento == 'P'">Nombre y Apellido</mat-label>
                    <input matInput formControlName="razonSocial"   (change)="setContactData('rzn',$event.target.value)"/>
                </mat-form-field>
            </div>
            <!--///-->
            <mat-slide-toggle color="primary" formControlName="flagShowFancyName"> Nombre de Fantasia</mat-slide-toggle>
                <div class="flex space-x-5" *ngIf="formCustomer.get('flagShowFancyName').value  == true">
                    <mat-form-field class="w-1/2">
                        <mat-label>Nombre de Fantasia</mat-label>
                        <input matInput formControlName="customerFancyName" />
                    </mat-form-field>
                </div>
            <!--///-->                
            <div class="flex space-x-5">
                <mat-form-field class="w-1/2">
                    <mat-label>Giro</mat-label>
                    <input matInput formControlName="giroImpreso" />
                </mat-form-field>
            </div>
            <div class="flex space-x-5">
                <mat-form-field class="w-1/2">
                    <mat-label>Dirección</mat-label>
                    <input matInput formControlName="direccion" />
                </mat-form-field>     
            </div>
                <!-- ///////////////////////////////////////////////-->
                <div class="flex space-x-5">
                <mat-form-field class="w-full">
                    <mat-label> Región </mat-label>
                    <mat-select formControlName="idRegion"  (selectionChange)="setCiudad('customer',$event.value)">
                        <ng-container *ngFor="let region of regionList">
                            <mat-option [value]="region.ID_REGION">
                                {{ region.GLS_REGION }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label> Provincia </mat-label>
                    <mat-select formControlName="idCiudad" (selectionChange)="setComuna('customer',$event.value)">
                        <ng-container *ngFor="let ciudad of ciudadListFiltered">
                            <mat-option [value]="ciudad.ID_CIUDAD">
                                {{ ciudad.GLS_CIUDAD }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label> Comuna </mat-label>
                    <mat-select formControlName="idComuna">
                        <ng-container *ngFor="let comuna of comunaListFiltered">
                            <mat-option [value]="comuna.ID_COMUNA">
                                {{ comuna.GLS_COMUNA }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <!--////////////////////////////////////////////////-->
            </div>
            <div class="flex space-x-5">
                <!-- 
                <mat-form-field class="w-1/2">
                    <mat-label>Contacto DTE</mat-label>
                    <input matInput formControlName="contactoDte" />
                </mat-form-field>
                -->
                <mat-form-field class="w-1/2">
                    <mat-label>Casilla envio DTE SII</mat-label>
                    <input matInput formControlName="emailDte"   (change)="setContactData('mail',$event.target.value)"/>
                </mat-form-field>     
                <!--
                <mat-form-field class="w-1/2">
                    <mat-label>Telefono DTE</mat-label>
                    <input matInput formControlName="telefonoDte" />
                </mat-form-field>                     
                 -->   
                 <mat-form-field class="w-full">
                    <mat-label> Valor Cliente </mat-label>
                    <mat-select formControlName="idCmCustomerValue">
                        <ng-container *ngFor="let v of customerValue">
                            <mat-option [value]="v.ID_CM_CUSTOMER_VALUE">
                                {{ v.COD_CM_CUSTOMER_VALUE }}-{{ v.NAME_CM_CUSTOMER_VALUE}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>                    
            </div> 
           
            <br>
            <div class="flex space-x-5">
                <mat-slide-toggle color="primary" formControlName="flagCustomer"> Cliente</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagSupplier"> Proveedor</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagEmployee"> Empleado</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagAssociate"> Socio</mat-slide-toggle>
                <mat-slide-toggle color="primary" formControlName="flagDistributor"> Distribuidor</mat-slide-toggle>  
            </div>            
    </mat-card-content>        
    </mat-card>

    <mat-card *ngIf="flagSgtm == true">
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Datos adicionales</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex space-x-5">
                <mat-slide-toggle color="primary" formControlName="flagNewsletter"> Suscrito Newsletter</mat-slide-toggle>
            </div>
            <div class="flex space-x-5" >
                <mat-form-field class="w-1/2" *ngIf="segmento =='E'">
                    <mat-label>Sitio web</mat-label>
                    <input matInput formControlName="website" />
                </mat-form-field>     
                
                <mat-form-field class="w-1/2">
                    <mat-label>Dias de Vencimiento</mat-label>
                    <mat-select formControlName="maxPaymentDays" >
                        <ng-container *ngFor="let d of dueDates">
                            <mat-option [value]="d.id">
                                {{ d.val }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>     

                <mat-form-field class="w-1/2">
                    <mat-label>Limite de Credito</mat-label>
                    <input matInput formControlName="creditLimit" />
                </mat-form-field>     
            </div>  
        </mat-card-content>
    </mat-card>
    
    <mat-card *ngIf="flagSgtm == true && segmento =='P'">
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Extranjero</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex space-x-5">
                <mat-slide-toggle color="primary" formControlName="flagExtranjero"> Extranjero</mat-slide-toggle>
            </div>
            <div *ngIf="formCustomer.value.flagExtranjero == true" class="flex space-x-5" >
                <!-- ## -->
                <mat-form-field class="w-1/2 fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                    <mat-label> Nacionalidad </mat-label>
                    <mat-select  formControlName="codNacionalidad">
                        <ng-container *ngFor="let n of nacionalidad">
                            <mat-option [value]="n.codCndcn">{{n.desCndcn}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <!-- ## -->
                <mat-form-field class="w-1/2 fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                    <mat-label> Tipo de Documento del Turista </mat-label>
                    <mat-select  formControlName="tipoDocId">
                        <ng-container *ngFor="let n of tipoDocID">
                            <mat-option [value]="n.codCndcn">{{n.desCndcn}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <!-- ## -->                

            </div>  
        </mat-card-content>
    </mat-card>
    <!-- [disabled]="formCustomer.invalid" -->
    <button *ngIf="flagSgtm == true" mat-raised-button color="primary" (click)="updateCustomer()" [disabled]="formCustomer.invalid"  > Actualizar Cliente </button>
</form>
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- CREAR NOTA -->
<form [formGroup]="notasForm" class="w-full" *ngIf="data.tab === 'notas' && data.type === 'create'">
<mat-card >
        <mat-card-header class="bg-gray-200">
            <mat-card-title class="text-lg font-semibold">Crear Nota</mat-card-title>
        </mat-card-header>
<mat-card-content>

    <div class="flex space-x-5">
            <!-- // -->            
            <mat-form-field appearance="fill" class="w-1/2  fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix selecet form-gen">
                <mat-label>Tipo Nota</mat-label>
                <mat-select  formControlName="valTpoNota" (selectionChange)="setReminders($event.value)">
                    <ng-container *ngFor="let f of arrTpoNota">
                        <mat-option [value]="f.tipo">{{f.gls}}</mat-option>
                    </ng-container> 
                </mat-select>
            </mat-form-field>
            <!-- // -->   
            </div>
            <div>
            <!-- // -->                   
                <mat-form-field class="w-full">
                    <mat-label> Nota </mat-label>
                    <textarea matInput formControlName="txt"></textarea>
                </mat-form-field>
                <div *ngIf="notasForm.get('valTpoNota').value == 'Y'">
                    <mat-form-field appearance="fill" class="w-1/4">
                    <mat-label>Fecha de Recordatorio</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateSchedule" >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field> 
                </div>
            <!-- // -->   
            </div>            
                <div *ngIf="isDisabledNote == true"> <div [innerHTML]="txtError"></div> </div> 
</mat-card-content>
</mat-card >
<button mat-raised-button color="primary" (click)="create()" [disabled]="notasForm.invalid"  > Crear Nota</button>
</form>
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- CREAR DIRECCION -->
<form [formGroup]="formAddress" *ngIf="data.tab === 'direcciones'" class="w-full">

    <mat-card >
        <mat-card-header class="bg-gray-200">
            
            
            <mat-card-title *ngIf="data.type === 'create'" class="text-lg font-semibold">Crear Dirección</mat-card-title>
            <mat-card-title *ngIf="data.type === 'update'" class="text-lg font-semibold">Actualizar Dirección</mat-card-title>
        </mat-card-header>
<mat-card-content>

<div class="flex space-x-5">

    <mat-form-field class="w-1/3">
        <mat-label> Alias </mat-label>
        <input matInput formControlName="aliasCustomerAddress">
    </mat-form-field>
</div>
<!--//-->
<div class="flex space-x-10">

<mat-form-field class="w-full">
    <mat-label> Tipo </mat-label>
    <mat-select formControlName="idCrmCustomerAddressType"  (selectionChange)="setAddressType($event.value)">
        <ng-container *ngFor="let t of addressType">
            <mat-option [value]="t.ID_CRM_CUSTOMER_ADDRESS_TYPE">
                {{ t.CRM_CUSTOMER_ADDRESS_TYPE }}
            </mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
</div>

<div class="flex space-x-10">
        <mat-form-field class="w-3/4">
            <mat-label>Dirección</mat-label>
            <input  type="text" matInput formControlName="address1">
        </mat-form-field>
    
        <mat-form-field class="w-1/5">
            <mat-label>Numero</mat-label>
            <input  type="number" matInput formControlName="address2">
        </mat-form-field>

        <mat-form-field class="w-1/5" *ngIf="showAddressType == true">
            <mat-label>{{glsAddressType}}</mat-label>
            <input  type="text" matInput formControlName="address3">
        </mat-form-field>

</div>
<!--//-->
<div class="flex space-x-10">
        <mat-form-field class="w-1/2">
            <mat-label>Codigo Postal</mat-label>
                <input matInput formControlName="postalCode">
        </mat-form-field>
        <mat-form-field class="w-1/2">
            <mat-label>Teléfono</mat-label>
                <input matInput formControlName="phoneNumber">
        </mat-form-field>
</div>
<!--//-->
<div class="flex space-x-5">
        <mat-form-field class="w-3/4">
            <mat-label>Notas de entrega</mat-label>
                <input matInput formControlName="deliveryNotes">
        </mat-form-field>
</div>    
<!--//-->
<div class="flex space-x-10">
    <mat-form-field class="w-full">
        <mat-label> Región </mat-label>
        <mat-select formControlName="idRegion">
            <ng-container *ngFor="let region of regionList">
                <mat-option [value]="region.ID_REGION">
                    {{ region.GLS_REGION }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label> Provincia </mat-label>
        <mat-select formControlName="idCiudad">
            <ng-container *ngFor="let ciudad of ciudadListFiltered">
                <mat-option [value]="ciudad.ID_CIUDAD">
                    {{ ciudad.GLS_CIUDAD }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
        <mat-label> Comuna </mat-label>
        <mat-select formControlName="idComuna">
            <ng-container *ngFor="let comuna of comunaListFiltered">
                <mat-option [value]="comuna.ID_COMUNA">
                    {{ comuna.GLS_COMUNA }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
<!--//-->
<div class="flex space-x-10">
    <mat-form-field class="w-full">
        <mat-label> Direccion por Defecto </mat-label>
        <mat-select formControlName="addressDefault">
            <ng-container >
                <mat-option value="Y" >Si </mat-option>
                <mat-option value="N">NO </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <!--////-->
    <mat-form-field class="w-full">
        <mat-label> Tipo de Direccion</mat-label>
        <mat-select formControlName="addressType">
            <ng-container >
                <mat-option value="D" >Despacho </mat-option>
                <mat-option value="O">Otra </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
<!--//-->
</mat-card-content>
</mat-card >
    <button mat-raised-button *ngIf="data.type === 'create'" color="primary" (click)="create()" [disabled]="formAddress.invalid" > Crear Dirección </button>
    <button mat-raised-button *ngIf="data.type === 'update'" color="primary" (click)="update()" [disabled]="formAddress.invalid" > Actualizar Dirección </button>
</form>
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- ACTUALIZAR DIRECCION -->
<!-- ///////////////////////////////////////////////////////////////////////////////////////////////-->
</div>
